import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useJustUser } from 'src/hooks/use-user'
import { Button, ButtonVariant } from 'src/system/ui/button/Button'
import { ConfirmDialog } from 'src/system/ui/dialog/ConfirmDialog'
import { NextBillingText } from 'src/screens/membership/manage/ManageMembership'
import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'
import { MembershipLevel } from 'src/graphql/types'
import {
  useCancelPaypalMembership,
  useToggleMembership
} from 'src/hooks/use-toggle-membership'
import { useMembershipEndDate } from 'src/hooks/use-membership'

export function CancelMembership() {
  const { t } = useTranslation()
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
  const toggleConfirmDialog = () =>
    setConfirmDialogVisible(!confirmDialogVisible)

  const { user } = useJustUser()
  const endDate = useMembershipEndDate()
  const text =
    user?.membership?.level === MembershipLevel.Mvp
      ? t('membership:lifetime')
      : t('membership:next_billing_date', { date: endDate })

  const { toggle, loading } = useToggleMembership()

  return (
    <>
      <NextBillingText>{text}</NextBillingText>
      <Button variant={ButtonVariant.link} onClick={toggleConfirmDialog}>
        {t('membership:cancel')}
      </Button>
      <ConfirmDialog
        isVisible={confirmDialogVisible}
        onClose={toggleConfirmDialog}
        processing={loading}
        handleProceed={toggle}
        title={t('membership:cancel_dialog:title')}
        description={t('membership:cancel_dialog:description', {
          date: endDate
        })}
        proceedLabel={t('membership:cancel_dialog:proceed_label')}
        cancelLabel={t('membership:cancel_dialog:cancel_label')}
      />
    </>
  )
}

export function CancelPaypalMembership() {
  const { t } = useTranslation()
  const [featureEnabled] = useFeatureFlags([FeatureFlag.cancelPaypal])
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
  const toggleConfirmDialog = () =>
    setConfirmDialogVisible(!confirmDialogVisible)

  const endDate = useMembershipEndDate()

  const { cancel, loading } = useCancelPaypalMembership()

  if (!featureEnabled) return null

  return (
    <>
      <Button variant={ButtonVariant.link} onClick={toggleConfirmDialog}>
        {t('membership:cancel_paypal')}
      </Button>
      <ConfirmDialog
        isVisible={confirmDialogVisible}
        onClose={toggleConfirmDialog}
        processing={loading}
        handleProceed={cancel}
        title={t('membership:cancel_paypal_dialog:title')}
        description={t('membership:cancel_paypal_dialog:description', {
          date: endDate
        })}
        proceedLabel={t('membership:cancel_dialog:proceed_label')}
        cancelLabel={t('membership:cancel_dialog:cancel_label')}
      />
    </>
  )
}
