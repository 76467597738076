import styled from '@emotion/styled'

export function SectionTitle(props: { children: string }) {
  return <Text {...props} />
}

const Text = styled.h2`
  color: ${(props) => props.theme.d.colors.text.secondary};
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 10px 0;
`
