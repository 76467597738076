import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

import { MembershipProvidence } from 'src/graphql/types'
import { AnalyticsEvent, useEventAnalytics } from 'src/hooks/use-analytics'
import {
  cancelPaypalMembershipMutation,
  cancelStripeMembershipMutation,
  pausePaypalMembershipMutation,
  uncancelStripeMembershipMutation,
  unpausePaypalMembershipMutation
} from 'src/graphql'
import { useJustUser } from 'src/hooks/use-user'
import type {
  CancelPaypalMembershipMutation,
  CancelPaypalMembershipMutationVariables,
  CancelStripeMembershipMutation,
  CancelStripeMembershipMutationVariables,
  PausePaypalMembershipMutation,
  PausePaypalMembershipMutationVariables,
  UncancelStripeMembershipMutation,
  UncancelStripeMembershipMutationVariables,
  UnpausePaypalMembershipMutation,
  UnpausePaypalMembershipMutationVariables
} from 'src/graphql/types'
import { useRouting } from 'src/routes'

export function useToggleMembership() {
  const { user } = useJustUser()
  const { toCancelMembership, toUncancelMembership } = useRouting()
  const navigate = useNavigate()

  const membershipProvidence = user?.membership?.providence
  const willCancelStripe =
    membershipProvidence === MembershipProvidence.Stripe &&
    !user.membership?.isCanceled

  const willCancelPaypal =
    membershipProvidence === MembershipProvidence.Paypal &&
    !user.membership?.isPaused

  const willCancel = willCancelPaypal || willCancelStripe

  const eventType = willCancel
    ? AnalyticsEvent.membershipCanceled
    : AnalyticsEvent.membershipUncanceled
  const sendEvent = useEventAnalytics(eventType)

  const { cancelMembership, loadingCancel, errorCancel } = useCancelMembership()
  const { uncancelMembership, loadingUncancel, errorUncancel } =
    useUncancelMembership()

  const action = willCancel ? cancelMembership : uncancelMembership
  const redirectToActionPage = useCallback(() => {
    const route = willCancel ? toCancelMembership : toUncancelMembership
    navigate(route())
  }, [navigate, toCancelMembership, toUncancelMembership, willCancel])

  const toggle = useCallback(async () => {
    sendEvent(
      membershipProvidence
        ? { membership_providence: membershipProvidence }
        : {}
    )
    await action()
    redirectToActionPage()
  }, [action, redirectToActionPage, sendEvent, membershipProvidence])

  const loading = willCancel ? loadingCancel : loadingUncancel
  const error = willCancel ? errorCancel : errorUncancel

  return {
    toggle,
    loading,
    error
  }
}

function useCancelMembership() {
  const { user } = useJustUser()
  const [
    stripeCancelMembership,
    { loading: stripeLoadingCancel, error: stripeErrorCancel }
  ] = useMutation<
    CancelStripeMembershipMutation,
    CancelStripeMembershipMutationVariables
  >(cancelStripeMembershipMutation)

  const [
    paypalCancelMembership,
    { loading: paypalLoadingCancel, error: paypalErrorCancel }
  ] = useMutation<
    PausePaypalMembershipMutation,
    PausePaypalMembershipMutationVariables
  >(pausePaypalMembershipMutation)

  if (user?.membership?.providence === MembershipProvidence.Stripe) {
    return {
      cancelMembership: stripeCancelMembership,
      loadingCancel: stripeLoadingCancel,
      errorCancel: stripeErrorCancel
    }
  }

  if (user?.membership?.providence === MembershipProvidence.Paypal) {
    return {
      cancelMembership: paypalCancelMembership,
      loadingCancel: paypalLoadingCancel,
      errorCancel: paypalErrorCancel
    }
  }

  return {
    cancelMembership: () => {},
    loadingCancel: false,
    errorCancel: false
  }
}

function useUncancelMembership() {
  const { user } = useJustUser()
  const [
    stripeUncancelMembership,
    { loading: stripeLoadingUncancel, error: stripeErrorUncancel }
  ] = useMutation<
    UncancelStripeMembershipMutation,
    UncancelStripeMembershipMutationVariables
  >(uncancelStripeMembershipMutation)

  const [
    paypalUncancelMembership,
    { loading: paypalLoadingUncancel, error: paypalErrorUncancel }
  ] = useMutation<
    UnpausePaypalMembershipMutation,
    UnpausePaypalMembershipMutationVariables
  >(unpausePaypalMembershipMutation)

  if (user?.membership?.providence === MembershipProvidence.Stripe) {
    return {
      uncancelMembership: stripeUncancelMembership,
      loadingUncancel: stripeLoadingUncancel,
      errorUncancel: stripeErrorUncancel
    }
  }

  if (user?.membership?.providence === MembershipProvidence.Paypal) {
    return {
      uncancelMembership: paypalUncancelMembership,
      loadingUncancel: paypalLoadingUncancel,
      errorUncancel: paypalErrorUncancel
    }
  }

  return {
    uncancelMembership: () => {},
    loadingUncancel: false,
    errorUncancel: false
  }
}

export function useCancelPaypalMembership() {
  const [cancel, { loading, error }] = useMutation<
    CancelPaypalMembershipMutation,
    CancelPaypalMembershipMutationVariables
  >(cancelPaypalMembershipMutation)

  return {
    cancel,
    loading,
    error
  }
}
