import { StrictMode, createElement } from 'react'
import { createRoot } from 'react-dom/client'

import { createSentry, withProfiler } from 'src/lib/analytics/sentry'

import * as config from './config'
import { App } from './App'
import { createDependencies } from './dependencies'
import translations from './i18n.json'

export const main = (rootElement: HTMLElement) => {
  const dependencies = createDependencies({ ...config, translations })
  const { init, props, initSentry } = dependencies
  initSentry()

  const initPromise = init()
  const app = createElement(withProfiler(App), props(initPromise), null)
  const strict = createElement(StrictMode, null, app)
  const root = createRoot(rootElement)
  root.render(strict)
}

export const handleError = (err: Error) => {
  const { sentryDsn, sentryTunnel, version, env, app, name } = config
  const { init, client } = createSentry({
    name: `${app}-${name}`,
    dsn: sentryDsn,
    tunnel: sentryTunnel,
    version,
    environment: env
  })
  init()
  client.captureException(err, { tags: { label: 'main' } })
}
