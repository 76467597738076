import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Box, Switch, switchClasses } from '@mui/material'

import type { PlanLevel } from 'src/screens/membership/pricing/PlanCard'
import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'

export function PlanSwitch(props: {
  level: PlanLevel
  isAnnualSelected: boolean
  setIsAnnualSelected: (isAnnualSelected: boolean) => void
}) {
  const { t } = useTranslation()
  const [isDualMembershipPlanEnabled] = useFeatureFlags([
    FeatureFlag.dualMembershipPlan
  ])

  const namespace = `membership:plans:${props.level}`

  if (!isDualMembershipPlanEnabled) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Label
        onClick={() => {
          props.setIsAnnualSelected(true)
        }}
      >
        {t(`${namespace}:plan:annual:switch`)}
      </Label>
      <Switch
        checked={!props.isAnnualSelected}
        onChange={() => {
          props.setIsAnnualSelected(!props.isAnnualSelected)
        }}
        inputProps={{ 'aria-label': 'controlled' }}
        sx={{
          [`& .${switchClasses.colorPrimary}`]: {
            color: (theme) => theme.d.colors.text.secondary
          },
          [`& .${switchClasses.colorSecondary}`]: {
            color: (theme) => theme.d.colors.text.secondary
          }
        }}
      />
      <Label
        onClick={() => {
          props.setIsAnnualSelected(false)
        }}
      >
        {t(`${namespace}:plan:monthly:switch`)}
      </Label>
    </Box>
  )
}

const Label = styled.p`
  color: ${(props) => props.theme.d.colors.text.secondary};
  cursor: pointer;
`
