import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import type {
  Facet,
  FacetBoolFieldsFragment,
  FacetBoolListFieldsFragment
} from 'src/graphql/types'
import { FacetShape } from 'src/graphql/types'

type FacetBool = Facet & FacetBoolFieldsFragment
export const isFacetBool = (facet: Facet): facet is FacetBool =>
  facet.shape === FacetShape.Bool

export function FacetBoolValue(props: { facet: FacetBool }) {
  const label = useLabel()
  return <Text>{label(props.facet.boolValue)}</Text>
}

type FacetBoolList = Facet & FacetBoolListFieldsFragment
export const isFacetBoolList = (facet: Facet): facet is FacetBoolList =>
  facet.shape === FacetShape.BoolList

export function FacetBoolListValue(props: { facet: FacetBoolList }) {
  const label = useLabel()
  return (
    <Text>
      {props.facet.boolValues.map((isTrue) => label(isTrue)).join(', ')}
    </Text>
  )
}

function useLabel() {
  const { t } = useTranslation()
  return (isTrue: boolean) =>
    isTrue
      ? t('assessments:assignment:facets:boolean:yes')
      : t('assessments:assignment:facets:boolean:no')
}

const Text = styled.span`
  color: ${(props) => props.theme.d.colors.text.secondary};
`
