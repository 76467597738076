import { gql } from '@apollo/client'

export const matchFragment = gql`
  fragment MatchFields on Match {
    id
    resourceId
    demoUrl
    platformUrl
    date
    createdAt
    updatedAt
    map
    totalRounds
    courseProgress
    isOwner
    platform
    players {
      name
      isOwner
      team
    }
    score {
      t
      ct
      winner
      isTie
    }
  }
`

export const matchQuery = gql`
  query Match($id: ID!) {
    match(id: $id) {
      ...MatchFields
    }
  }

  ${matchFragment}
`

export const addSharelinkMutation = gql`
  mutation AddSharelink($sharelink: String!, $platform: MatchPlatform!) {
    addSharelink(sharelink: $sharelink, platform: $platform)
  }
`

export const uploadDemoMutation = gql`
  mutation UploadDemo {
    uploadDemo
  }
`
