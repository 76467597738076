import styled from '@emotion/styled'
import { useTheme } from '@mui/system'
import { useTranslation } from 'react-i18next'

import { Badge } from 'src/screens/matches/collection/match/Badge'
import {
  Status,
  useComputedStatus
} from 'src/screens/matches/collection/match/status/Status'
import { PlatformIcon } from 'src/system/ui/match/PlatformIcon'
import { image, isPlaceholder } from 'src/lib/matches/map-image'
import { formattedDate } from 'src/lib/matches/matches'
import type { MatchProps } from 'src/screens/matches/collection/MatchCard'
import { MatchLink } from 'src/screens/matches/collection/match/MatchLink'
import { StatusDescription } from 'src/screens/matches/collection/match/status/StatusDescription'
import { Score } from 'src/system/ui/match/Score'
import { PendingMatchStatus } from 'src/graphql/types'

export function Card(props: { match: MatchProps }) {
  const { match } = props
  const backgroundImage = image(match.map)
  const { t } = useTranslation()
  const status = useComputedStatus(
    match.updatedAt,
    match.status,
    match.courseProgress
  )
  const canViewMatch = status === PendingMatchStatus.Completed

  return (
    <StyledMatchLink link={canViewMatch ? match.link : null}>
      <Body
        backgroundImage={backgroundImage}
        isPlaceholderBackground={isPlaceholder(backgroundImage)}
      >
        <Top>
          <StyledBadge map={match.map} />
          <div>
            <Status
              current={match.status}
              updatedAt={match.updatedAt}
              createdAt={match.createdAt}
              courseProgress={match.courseProgress}
            />
            <StyledStatusDescription
              status={match.status}
              updatedAt={match.updatedAt}
              courseProgress={match.courseProgress}
            />
          </div>
        </Top>
        <Bottom>
          <ResultBadge
            isPending={match.isPending}
            didWin={match.didWin}
            isTie={match.isTie}
          />
          <PlatformIcon size={25} platform={match.platform} />
        </Bottom>
      </Body>
      <Footer>
        <div>
          <DateLabel>{t('matches:upload_date_label')}</DateLabel>
          <Date>{formattedDate(match.uploadDate)}</Date>
        </div>
        <StyledScore
          ctScore={match.ctScore}
          tScore={match.tScore}
          players={match.players}
          didWin={match.didWin}
          isTie={match.isTie}
          isOwnerInMatch={match.isOwnerInMatch}
        />
      </Footer>
    </StyledMatchLink>
  )
}

function ResultBadge(props: {
  isPending: boolean
  didWin?: boolean
  isTie?: boolean
}) {
  const { t } = useTranslation()
  const theme = useTheme()

  const key = () => {
    if (props.isTie) {
      return 'tie'
    }

    return props.didWin ? 'win' : 'lose'
  }

  const color = () => {
    if (props.isTie) {
      return theme.d.colors.text.primary
    }

    return props.didWin ? theme.d.colors.success : theme.d.colors.error
  }

  if (props.isPending) {
    return null
  }

  return (
    <ResultBadgeBox color={color()}>
      {t(`matches:result_badge:${key()}`)}
    </ResultBadgeBox>
  )
}

const StyledMatchLink = styled(MatchLink)`
  border: 1px solid ${(props) => props.theme.d.colors.border.dark};
  width: 100%;
  border-radius: 4px;
`

const ResultBadgeBox = styled.div<{ color: string }>`
  background: ${(props) => props.theme.d.colors.winBadgeBoxBackground};
  border-radius: 4px;
  box-shadow: inset 0px -1px 0px ${(props) => props.color};
  border-bottom: 1px solid ${(props) => props.color};
  width: 75px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};
  font-family: ${(props) => props.theme.d.font.secondary};
  font-size: 10px;
`

const Body = styled.div<{
  backgroundImage: string
  isPlaceholderBackground: boolean
}>`
  background: ${(props) => `${props.theme.d.colors.mapCardGrid},
  url(${props.backgroundImage})`};
  padding: ${(props) =>
    `${props.theme.d.spacing[4]} ${props.theme.d.spacing[5]}`};
  background-size: ${(props) =>
    props.isPlaceholderBackground ? 'initial' : 'cover'};
  background-repeat: no-repeat;
  background-position: ${(props) =>
    props.isPlaceholderBackground ? '50% 50%' : '0 0'};
  height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DateLabel = styled.span`
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  display: block;
`

const StyledScore = styled(Score)`
  font-size: 22px;
`

const Date = styled.span`
  color: ${(props) => props.theme.d.colors.text.secondary};
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) =>
    `${props.theme.d.spacing[4]} ${props.theme.d.spacing[5]}`};
  border-top: 1px solid ${(props) => props.theme.d.colors.border.dark};
`

const StyledBadge = styled(Badge)`
  max-width: 40px;
  max-height: 40px;
`

const StyledStatusDescription = styled(StatusDescription)`
  display: block;
  text-align: right;
  margin-top: ${(props) => props.theme.d.spacing[1]};
`
