import { useTranslation } from 'react-i18next'

import { useRouting } from 'src/routes'
import logo from 'src/assets/logo.svg'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'

export function HomeLinkLogo(props: { className?: string }) {
  const { routes } = useRouting()
  const { t } = useTranslation()

  return (
    <RelativeLink
      disableStyles
      to={routes.root}
      aria-label={t('nav:home')}
      className={props.className}
    >
      <img src={logo} alt={t('common:name')} />
    </RelativeLink>
  )
}
