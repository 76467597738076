import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Button, ButtonVariant } from 'src/system/ui/button/Button'
import { useJustUser } from 'src/hooks/use-user'
import { useRouting } from 'src/routes'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'
import { ReactComponent as StarIcon } from 'src/assets/icons/upgrade-star.svg'
import { PremiumBadge } from 'src/system/ui/badge/PremiumBadge'
import { useIsMembershipUpgradable } from 'src/hooks/use-membership'

export function UpgradeMembershipButton(props: {
  className?: string
  fullWidth?: boolean
}) {
  const { t } = useTranslation()
  const { user } = useJustUser()
  const { routes } = useRouting()
  const isMembershipUpgradable = useIsMembershipUpgradable()
  const isSetupComplete = user.automatch.isActive

  if (!isSetupComplete) {
    return null
  }

  if (isMembershipUpgradable) {
    return (
      <RelativeLink
        to={routes.pricing}
        disableStyles
        className={props.className}
      >
        <StyledButton
          variant={ButtonVariant.accent}
          fullWidth={props.fullWidth}
        >
          <StarIcon />
          {t('nav:upgrade_membership')}
        </StyledButton>
      </RelativeLink>
    )
  }

  return <StyledPremiumBadge />
}

const StyledButton = styled(Button)`
  height: 32px;
  margin-right: ${(props) => props.theme.d.spacing[3]};
  padding: 0 ${(props) => props.theme.d.spacing[4]};
  text-transform: uppercase;
`

const StyledPremiumBadge = styled(PremiumBadge)`
  margin-right: ${(props) => props.theme.d.spacing[3]};
`
