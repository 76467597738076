import styled from '@emotion/styled'

import type { Breakdown, BreakdownText } from 'src/graphql/types'
import { BreakdownFormatType } from 'src/graphql/types'
import {
  SentimentContent,
  TextContent
} from 'src/screens/match/assessments/assignment-list/assignment/expansion/breakdowns/TextContent'

export function Breakdowns(props: {
  breakdowns: Breakdown[]
  className?: string
  narrow?: boolean
}) {
  return (
    <Container className={props.className}>
      {props.breakdowns.map((breakdown) => (
        <BreakdownContainer key={breakdown.title.text} narrow={props.narrow}>
          <Box>
            <SentimentContent breakdown={breakdown} />
            <Title narrow={props.narrow}>{breakdown.title.text}</Title>
          </Box>
          <Content breakdown={breakdown} />
        </BreakdownContainer>
      ))}
    </Container>
  )
}

function Content(props: { breakdown: Breakdown }) {
  if (props.breakdown.format === BreakdownFormatType.Text) {
    return <TextContent breakdown={props.breakdown as BreakdownText} />
  }

  throw new Error(`Unimplemented breakdown format: ${props.breakdown.format}`)
}

const Box = styled.div`
  display: flex;
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

const BreakdownContainer = styled.div<{ narrow?: boolean }>`
  padding: ${(props) => props.theme.d.spacing[3]};
  box-sizing: border-box;

  &:not(:first-of-type) {
    border-top: 1px solid ${(props) => props.theme.d.colors.border.dark};
  }

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    padding: ${(props) =>
      props.narrow
        ? `${props.theme.d.spacing[3]} ${props.theme.d.spacing[2]}`
        : `${props.theme.d.spacing[5]} ${props.theme.d.spacing[7]}`};
  }
`

const Title = styled.h5<{ narrow?: boolean }>`
  color: ${(props) => props.theme.d.colors.text.secondary};
  font-size: 20px;
  font-weight: bold;
  margin: 0 0
    ${(props) =>
      props.narrow
        ? `${props.theme.d.spacing[3]}`
        : `${props.theme.d.spacing[5]}`};
`
