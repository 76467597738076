import { gql } from '@apollo/client'

export const setMatchesViewModeMutation = gql`
  mutation SetMatchesViewMode($viewMode: UserInterfaceViewMode!) {
    setMatchesViewMode(viewMode: $viewMode) @client
  }
`

export const matchesUiQuery = gql`
  query MatchesUi {
    ui @client {
      matchesViewMode
    }
  }
`

export const uiQuery = gql`
  query Ui {
    ui @client {
      matchesViewMode
    }
  }
`
