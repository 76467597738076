import type { ReactElement } from 'react'
import styled from '@emotion/styled'

import { TopBar } from 'src/system/ui/top-bar/TopBar'
import { HomeLinkLogo } from 'src/system/app-bar/HomeLinkLogo'
import { VersionBadge } from 'src/system/app-bar/version/VersionBadge'
import { Menu as UserMenu } from 'src/system/app-bar/user/Menu'
import { UpgradeMembershipButton } from 'src/system/user/UpgradeMembershipButton'
import { HomeLinkIdentity } from 'src/system/app-bar/HomeLinkIdentity'
import { PromotionBanner } from 'src/system/app-bar/user/PromotionBanner'
import { NoticeBanner } from 'src/system/app-bar/NoticeBanner'
import {
  ContentDesktop,
  ContentMobile,
  Spacer
} from 'src/system/app-bar/Content'

export function UserAppBar(props: { content?: ReactElement }) {
  return (
    <>
      <TopBar>
        <StyledHomeLogo />
        <StyledHomeIdentity />
        <StyledVersionBadge />
        <Spacer withContent={!!props.content} />
        <ContentDesktop content={props.content} />
        <StyledUpgradeMembershipButton />
        <UserMenu />
      </TopBar>
      <ContentMobile content={props.content} />
      <NoticeBanner />
      <PromotionBanner />
    </>
  )
}

const StyledHomeLogo = styled(HomeLinkLogo)`
  margin-right: ${(props) => props.theme.d.spacing[3]};
  display: none;

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    display: block;
  }
`
const StyledHomeIdentity = styled(HomeLinkIdentity)`
  margin-right: ${(props) => props.theme.d.spacing[3]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    display: none;
  }
`

const StyledVersionBadge = styled(VersionBadge)`
  margin-right: ${(props) => props.theme.d.spacing[3]};
  display: none;

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    display: block;
  }
`

const StyledUpgradeMembershipButton = styled(UpgradeMembershipButton)`
  margin-right: ${(props) => props.theme.d.spacing[4]};
`
