import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import styled from '@emotion/styled'
import { Trans, useTranslation } from 'react-i18next'
import { Container } from '@mui/material'

import { SectionTitle } from 'src/system/ui/typography/SectionTitle'
import { Description } from 'src/screens/account/step/Description'
import { Instruction } from 'src/screens/account/step/auto-download/Instruction'
import { Emphasis } from 'src/system/ui/typography/Emphasis'
import { AuthCodeInput } from 'src/screens/account/step/auto-download/AuthCodeInput'
import { MatchTokenInput } from 'src/screens/account/step/auto-download/MatchTokenInput'
import type { RenderContent } from 'src/screens/account/Account'
import { UnlinkSteamAccount } from 'src/screens/account/step/UnlinkSteamAccount'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'
import { MatchPlatform } from 'src/graphql/types'
import type { UserFieldsFragment as User } from 'src/graphql/types'
import { MustConnectEmphasisBadge } from 'src/screens/account/step/MustConnectEmphasisBadge'
import { JoinDiscordPanel } from 'src/system/DiscordPanel'

export function AutoDownload(props: {
  renderBody: RenderContent
  renderAdditionalContent: RenderContent
  user: User
}) {
  const { t } = useTranslation()

  return (
    <>
      {props.renderBody(
        <>
          <SectionTitle>{t('account:match_token:title')}</SectionTitle>
          <Description>
            {t('account:match_token:description') as string}
          </Description>
          <StyledContainer>
            <AbsoluteLink
              to={t('account:link_account:instructional_video_link')}
              newTab
            >
              {t('account:link_account:instructional_video_text')}
            </AbsoluteLink>
          </StyledContainer>
          <MustConnectEmphasisBadge>
            {t('account:must_connect_api')}
          </MustConnectEmphasisBadge>
          <Content user={props.user} />
          <AbsoluteLink to={t('link:how_csgo_match_history_works')} newTab>
            {t('account:match_token:how_it_works_text')}
          </AbsoluteLink>
        </>
      )}
      {props.renderAdditionalContent(
        <>
          <StyledUnlinkSteamAccount user={props.user} />
          <JoinDiscordPanel
            title={t('discord_panel:problems:title')}
            description={t('discord_panel:problems:description')}
          />
        </>
      )}
    </>
  )
}

function Content(props: { user: User }) {
  const [matchToken, setMatchToken] = useState<string | null>(null)

  if (!matchToken) {
    return (
      <MatchTokenSteps matchToken={matchToken} setMatchToken={setMatchToken} />
    )
  }

  return (
    <>
      <MatchTokenSteps matchToken={matchToken} setMatchToken={setMatchToken} />
      <AuthCodeSteps user={props.user} />
    </>
  )
}

function MatchTokenSteps(props: {
  matchToken: string | null
  setMatchToken: Dispatch<SetStateAction<string | null>>
}) {
  const { t } = useTranslation()

  return (
    <>
      <Instruction
        number={1}
        content={
          <p>
            <Trans i18nKey='account:match_token:find_match_instruction'>
              {' '}
              <AbsoluteLink
                to={t('account:match_token:find_match_link')}
                newTab
              >
                {' '}
              </AbsoluteLink>
            </Trans>
          </p>
        }
      />
      <Instruction
        number={2}
        content={
          <p>
            {t('account:match_token:copy_token_instruction')}
            <br />
            <Emphasis>
              {t('account:match_token:not_authentication_code_text')}
            </Emphasis>
          </p>
        }
      />
      <Instruction
        number={3}
        content={<p>{t('account:match_token:paste_token_instruction')}</p>}
        footer={
          <MatchTokenInput
            matchPlatform={MatchPlatform.Steam}
            matchToken={props.matchToken}
            setMatchCode={props.setMatchToken}
          />
        }
      />
    </>
  )
}

function AuthCodeSteps(props: { user: User }) {
  const { t } = useTranslation()

  return (
    <>
      <Instruction
        number={4}
        content={
          <p>
            {t('account:match_token:copy_auth_code_instruction')}
            <br />
            <Emphasis>{t('account:match_token:not_match_token_text')}</Emphasis>
          </p>
        }
      />
      <Instruction
        number={5}
        content={<p>{t('account:match_token:paste_auth_code_instruction')}</p>}
        footer={<AuthCodeInput user={props.user} />}
      />
    </>
  )
}

const StyledUnlinkSteamAccount = styled(UnlinkSteamAccount)`
  margin-bottom: ${(props) => props.theme.d.spacing[4]};
`

const StyledContainer = styled(Container)`
  margin-bottom: 10px;
`
