import type { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import type { StyledComponent } from '@emotion/styled'
import { Box, Container, Grid } from '@mui/material'

import { GuestAppBar } from 'src/system/app-bar/GuestAppBar'
import { Background } from 'src/system/ui/layout/Background'
import type { ButtonProps } from 'src/system/ui/button/Button'
import { Button } from 'src/system/ui/button/Button'

export type RenderProps = {
  SubmitButton: StyledComponent<ButtonProps>
  FieldBox: FunctionComponent<PropsWithChildren<{ disableGutter?: boolean }>>
}

export function AuthForm(props: {
  error: string
  headline: string
  subhead: string
  footer?: ReactNode
  isVerified?: null | boolean
  render: (props: RenderProps) => ReactNode
}) {
  return (
    <>
      <GuestAppBar />
      <StyledBackground full>
        <StyledContainer>
          <Grid container>
            <HeroText item xs={12} md={5}>
              <div>
                <Headline>{props.headline}</Headline>
                {props.subhead ? <Subhead>{props.subhead}</Subhead> : null}
                <StyledVerifiedMessage isVerified={props.isVerified} />
              </div>
            </HeroText>
            <Grid item md={1} />
            <Grid item xs={12} md={6}>
              <FormContainer>
                {props.render({ SubmitButton, FieldBox })}
                <DesktopFooter>{props.footer}</DesktopFooter>
                <FormError error={props.error} />
              </FormContainer>
            </Grid>
            <MobileFooter>{props.footer}</MobileFooter>
          </Grid>
        </StyledContainer>
      </StyledBackground>
    </>
  )
}

function VerifiedMessage(props: {
  isVerified?: null | boolean
  className?: string
}) {
  const { t } = useTranslation()
  const { isVerified } = props
  if (isVerified == null) return null
  const verifiedText = isVerified
    ? t('login:account_verified')
    : t('login:account_not_verified')
  return <h2 className={props.className}>{verifiedText}</h2>
}

function DesktopFooter(props: { children?: ReactNode }) {
  if (!props.children) {
    return null
  }

  return <DesktopFooterBox>{props.children}</DesktopFooterBox>
}

function MobileFooter(props: { children?: ReactNode }) {
  if (!props.children) {
    return null
  }

  return (
    <Box
      display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }}
      textAlign='center'
      width='100%'
    >
      {props.children}
    </Box>
  )
}

function FormError(props: { error: string }) {
  if (!props.error) {
    return null
  }

  return <ErrorText>{props.error}</ErrorText>
}

const StyledVerifiedMessage = styled(VerifiedMessage)`
  font-size: 24;
  color: ${(props) => props.theme.d.colors.accent};
`

const StyledContainer = styled(Container)`
  padding: ${(props) => props.theme.d.spacing[4]};
`

const StyledBackground = styled(Background)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 16px;
  }
`

const HeroText = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Headline = styled.h1`
  font-family: ${(props) => props.theme.d.font.secondary};
  font-style: normal;
  font-weight: normal;
  font-size: 52px;
  color: ${(props) => props.theme.d.colors.text.secondary};
  margin: 0 0 ${(props) => props.theme.d.spacing[4]} 0;
`

const Subhead = styled.p`
  line-height: 150%;
  font-size: 14px;
  margin-bottom: ${(props) => props.theme.d.spacing[6]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    font-size: 16px;
    margin-bottom: 0;
  }
`

const FormContainer = styled.div`
  padding: ${(props) =>
    `${props.theme.d.spacing[6]} ${props.theme.d.spacing[7]}`};
  background: ${(props) => props.theme.d.colors.dialog};
  border: 1px solid ${(props) => props.theme.d.colors.border.dialog};
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: ${(props) => props.theme.d.spacing[10]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    margin-bottom: 0;
  }
`

const FieldBox = styled.div<{ disableGutter?: boolean }>`
  margin-bottom: ${(props) =>
    props.disableGutter ? 0 : props.theme.d.spacing[6]};
`

const SubmitButton = styled(Button)`
  height: 52px;
  margin-top: ${(props) => props.theme.d.spacing[6]};
`

const ErrorText = styled.div`
  color: ${(props) => props.theme.d.colors.error};
  margin-top: ${(props) => props.theme.d.spacing[4]};
  text-align: center;
`

const DesktopFooterBox = styled.div`
  display: none;
  text-align: center;
  margin-top: ${(props) => props.theme.d.spacing[5]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    display: block;
  }
`
