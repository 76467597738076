import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

import type { StringVoidMap } from 'src/types'

export function Meta(props: {
  name: string
  titleVars?: StringVoidMap
  image?: string
}) {
  const { t } = useTranslation()
  return (
    <Helmet>
      <title>
        {t(`meta:${props.name}:title`, props.titleVars)}
        {t('meta:global:title_suffix')}
      </title>
      {props.image ? <meta property='og:image' content={props.image} /> : null}
    </Helmet>
  )
}

export function NotModifiedMeta(props: { isModified: boolean }) {
  if (props.isModified) return null
  return (
    <Helmet>
      <meta name='prerender-status-code' content='304' />
    </Helmet>
  )
}

export function NotFoundMeta() {
  return (
    <Helmet>
      <meta name='prerender-status-code' content='404' />
    </Helmet>
  )
}

export function RedirectMeta(props: { pathname: string | null | undefined }) {
  if (props.pathname == null) return null
  const url = new URL(globalThis.location.href)
  url.pathname = props.pathname
  return (
    <Helmet>
      <meta name='prerender-status-code' content='301' />
      <meta name='prerender-header' content={`Location: ${url}`} />
    </Helmet>
  )
}
