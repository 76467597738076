import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Duration } from 'luxon'
import { Settings as SettingsIcon } from '@mui/icons-material'

import { Avatar } from 'src/system/ui/image/Avatar'
import { useProfile } from 'src/hooks/use-profile'
import { IconButton } from 'src/system/ui/button/IconButton'
import { Status } from 'src/system/user/Status'
import { useRouting } from 'src/routes'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'

const pollInterval = Duration.fromObject({ hours: 1 }).as('milliseconds')

export function Menu() {
  const { t } = useTranslation()
  const { profile, loading } = useProfile('cache-first', pollInterval)
  const { routes, toProfile } = useRouting()

  const username = profile?.steam?.name ?? t('user:no_name_placeholder')
  const profileId = profile?.id
  const profileLink =
    profileId == null ? routes.privateProfile : toProfile(profileId)

  return (
    <Box>
      <AccountLink to={profileLink} disableStyles>
        <StyledAvatar src={profile?.steam?.avatarUrl} />
      </AccountLink>
      <User>
        <Username>{loading ? '\u00A0' : username}</Username>
        <Status />
      </User>
      <MobileAccountLink to={routes.account} disableStyles>
        <IconButton>
          <SettingsIcon color='primary' />
        </IconButton>
      </MobileAccountLink>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1;
`

const StyledAvatar = styled(Avatar)`
  width: 30px;
  height: 30px;
  border: 1px solid ${(props) => props.theme.d.colors.userAvatarBorder};
  border-radius: 3px;

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    margin-right: ${(props) => props.theme.d.spacing[3]};
  }
`

const User = styled.div`
  margin-right: ${(props) => props.theme.d.spacing[6]};
  display: none;

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    display: block;
  }
`

const Username = styled.span`
  font-family: ${(props) => props.theme.d.font.primary};
  font-style: normal;
  font-weight: bold;
  color: ${(props) => props.theme.d.colors.text.secondary};
`

const AccountLink = styled(RelativeLink)`
  display: flex;
`

const MobileAccountLink = styled(AccountLink)`
  margin-left: ${(props) => props.theme.d.spacing[4]};
  display: flex;
`
