import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Duration } from 'luxon'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/client'

import { Redirect } from 'src/routes/Redirect'
import { matchesStatusQuery, triggerAutomatchMutation } from 'src/graphql'
import type {
  TriggerAutomatchMutation,
  TriggerAutomatchMutationVariables
} from 'src/graphql/types'
import { UserLinkType } from 'src/graphql/types'
import { Meta } from 'src/system/Meta'
import { CenterLoadingSpinner } from 'src/system/ui/LoadingSpinner'
import { useSearchParams } from 'src/lib/hooks/use-search-params'
import { AnalyticsEvent, useEventAnalytics } from 'src/hooks/use-analytics'
import { useRouting } from 'src/routes'
import { useProfile } from 'src/hooks/use-profile'
import { useJustUser, useUser } from 'src/hooks/use-user'
import { AppBar } from 'src/system/app-bar/AppBar'
import { JoinDiscordPanel } from 'src/system/DiscordPanel'

const timeToWaitForAutomatchTrigger = Duration.fromObject({ minutes: 1 }).as(
  'milliseconds'
)

type SearchParams = {
  platform?: UserLinkType
  status_code?: AccountLinkStatus // eslint-disable-line camelcase
}

enum AccountLinkStatus {
  success = '201',
  unauthorized = '401',
  conflict = '409',
  error = '500'
}

export function HandleAccountLink() {
  return (
    <>
      <Meta name='handle_account_link' />
      <Content />
    </>
  )
}

function Content() {
  const {
    routes: { account, root }
  } = useRouting()
  const searchParams = useSearchParams<SearchParams>()

  const { loading: loadingUser } = useUser('network-only')
  const { loading: loadingProfile } = useProfile('network-only')
  const loading = loadingUser || loadingProfile

  useAccountLinkAnalytics(searchParams)

  const { status_code: statusCode, platform } = searchParams

  const successRedirect = platform === UserLinkType.Faceit ? account : root

  if (loading) return <CenterLoadingSpinner />

  if (statusCode === AccountLinkStatus.success) {
    return <Redirect to={successRedirect} />
  }

  if (statusCode && Object.values(AccountLinkStatus).includes(statusCode)) {
    return <LinkMessage platform={platform} errorCode={statusCode} />
  }

  return <Redirect to={account} />
}

function useAccountLinkAnalytics(searchParams: SearchParams) {
  const isCalledRef = useRef(false)
  const { user } = useJustUser()
  const accountLinkedEvent = useEventAnalytics(AnalyticsEvent.accountLinked)
  const accountNotLinkedEvent = useEventAnalytics(
    AnalyticsEvent.accountNotLinked
  )
  const automatchTriggeredEvent = useEventAnalytics(
    AnalyticsEvent.automatchTriggered
  )
  const automatchNotTriggeredEvent = useEventAnalytics(
    AnalyticsEvent.automatchNotTriggered
  )

  const [triggerAutomatch] = useMutation<
    TriggerAutomatchMutation,
    TriggerAutomatchMutationVariables
  >(triggerAutomatchMutation, {
    refetchQueries: [{ query: matchesStatusQuery }]
  })

  const { status_code: statusCode, platform } = searchParams

  const linkId = user?.steamId

  const trigger = useCallback(async () => {
    if (!linkId) return
    try {
      await triggerAutomatch({ variables: { linkId } })
      automatchTriggeredEvent({ automatch_trigger_reason: 'faceit' })
    } catch (err: any) {
      automatchNotTriggeredEvent({
        error: err?.message,
        automatch_trigger_reason: 'faceit'
      })
    }
  }, [
    automatchTriggeredEvent,
    automatchNotTriggeredEvent,
    triggerAutomatch,
    linkId
  ])

  useEffect(() => {
    if (isCalledRef.current) return
    const platformStr = platform ? platform.toString() : ''
    const statusCodeStr = statusCode ? statusCode.toString() : ''
    if (statusCode === AccountLinkStatus.success) {
      accountLinkedEvent({ link_type: platformStr })
      if (platform === UserLinkType.Faceit) {
        globalThis.setTimeout(trigger, timeToWaitForAutomatchTrigger)
      }
    } else {
      accountNotLinkedEvent({
        status_code: statusCodeStr,
        link_type: platformStr
      })
    }
    isCalledRef.current = true
  }, [statusCode, platform, accountLinkedEvent, accountNotLinkedEvent, trigger])
}

const LinkMessage = (props: {
  platform?: UserLinkType
  errorCode: AccountLinkStatus
}) => {
  const { t } = useTranslation()
  const msg =
    props.platform === UserLinkType.Discord
      ? t('handle_account_link:error:discord')
      : t(`handle_account_link:error:${props.errorCode}`)

  return (
    <>
      <AppBar />
      <Message>{msg}</Message>
      <JoinDiscordPanel
        title={t('discord_panel:problems:title')}
        description={t('discord_panel:problems:description')}
      />
    </>
  )
}

const Message = styled.div`
  text-align: center;
  margin-top: ${(props) => props.theme.d.spacing[10]};
  font-size: 3em;
`
