import type { ReactElement } from 'react'
import { useState } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faPaypal } from '@fortawesome/free-brands-svg-icons'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'

import { MembershipPlan } from 'src/graphql/types'
import { PlanLevel } from 'src/screens/membership/pricing/PlanCard'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'
import { Button } from 'src/system/ui/button/Button'
import { useRouting } from 'src/routes'
import { usePaypalCheckout, useStripeCheckout } from 'src/hooks/use-checkout'
import { useJustUser } from 'src/hooks/use-user'
import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'

export function UpgradeButtons(props: { plan?: MembershipPlan }) {
  const [isDualMembershipPlanEnabled] = useFeatureFlags([
    FeatureFlag.dualMembershipPlan
  ])

  const defaultPlan = isDualMembershipPlanEnabled
    ? MembershipPlan.Annual
    : MembershipPlan.Monthly

  const plan = props.plan ?? defaultPlan

  const theme = useTheme()
  const { t } = useTranslation()
  const {
    routes: { account }
  } = useRouting()
  const { user } = useJustUser()
  const [dialogVisible, setDialogVisible] = useState(false)
  const toggleDialog = () => setDialogVisible(!dialogVisible)

  const isMembershipLifetime = plan === MembershipPlan.Lifetime
  const level = isMembershipLifetime ? PlanLevel.lifetime : PlanLevel.premium
  const color = isMembershipLifetime
    ? theme.d.colors.lifetime
    : theme.d.colors.accent

  if (!user.isAccountLinked) {
    return (
      <RelativeLink to={account} disableStyles>
        <StyledButton fullWidth color={color}>
          {t('membership:plans:premium:upgrade_must_link')}
        </StyledButton>
      </RelativeLink>
    )
  }

  const title = t(`membership:plans:${level}:subscribe`)
  return (
    <>
      <StyledButton color={color} onClick={toggleDialog}>
        {title}
        <ButtonIcons>
          <FontAwesomeIcon icon={faCreditCard} />
          <FontAwesomeIcon icon={faPaypal} />
        </ButtonIcons>
      </StyledButton>
      <UpgradeDialog
        title={title}
        color={color}
        plan={plan}
        isVisible={dialogVisible}
        onClose={toggleDialog}
      />
    </>
  )
}

export function StripeUpgradeButton(props: {
  plan: MembershipPlan
  applyDiscount: boolean
  color: string
}) {
  const { t } = useTranslation()
  const checkoutProps = useStripeCheckout(props.plan, props.applyDiscount)
  return (
    <>
      <UpgradeButton color={props.color} {...checkoutProps}>
        <FontAwesomeIcon icon={faCreditCard} />
        {t('membership:checkout:stripe')}
      </UpgradeButton>
    </>
  )
}

export function PaypalUpgradeButton(props: {
  plan: MembershipPlan
  applyDiscount: boolean
  color: string
}) {
  const { t } = useTranslation()
  const checkoutProps = usePaypalCheckout(props.plan, props.applyDiscount)
  return (
    <>
      <UpgradeButton color={props.color} {...checkoutProps}>
        <FontAwesomeIcon icon={faPaypal} />
        {t('membership:checkout:paypal')}
      </UpgradeButton>
    </>
  )
}

function UpgradeButton(props: {
  checkout: () => void
  isLoading: boolean
  isError: boolean
  color: string
  children: ReactElement | ReactElement[]
}) {
  const { t } = useTranslation()
  const { children, checkout, isLoading, isError } = props
  return (
    <>
      <StyledButton
        fullWidth
        color={props.color}
        processing={isLoading}
        onClick={checkout}
      >
        {children}
      </StyledButton>
      <ErrorText hidden={!isError}>{t('checkout:error')}</ErrorText>
    </>
  )
}

export function UpgradeDialog(props: {
  title: string
  plan: MembershipPlan
  color: string
  isVisible: boolean
  onClose: () => void
}) {
  const [isDiscountActive] = useFeatureFlags([FeatureFlag.discountActive])

  const applyDiscount = isDiscountActive && props.plan === MembershipPlan.Annual

  const theme = useTheme()
  const buttonProps = {
    plan: props.plan,
    applyDiscount,
    color: props.color
  }
  return (
    <Dialog fullWidth open={props.isVisible} onClose={props.onClose}>
      <DialogContent>
        <DialogTitle sx={{ fontFamily: theme.d.font.secondary }}>
          {props.title}
        </DialogTitle>
      </DialogContent>
      <DialogActions
        disableSpacing
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& button': { margin: '12px' }
        }}
      >
        <StripeUpgradeButton {...buttonProps} />
        <PaypalUpgradeButton {...buttonProps} />
      </DialogActions>
    </Dialog>
  )
}

const ErrorText = styled.div`
  color: ${(props) => props.theme.d.colors.error};
  margin-top: ${(props) => props.theme.d.spacing[4]};
`

const ButtonIcons = styled.span`
  padding-left: 1rem;
`

const StyledButton = styled(Button)`
  display: block;
  text-transform: uppercase;
  font-size: 18px;
`
