import { createContext, useContext } from 'react'
import type { Stripe } from '@stripe/stripe-js'

import type { ChildrenProps } from 'src/types'

export type StripeClient = Stripe

export function useStripe() {
  return useContext(stripeContext)
}

type Props = {
  client: StripeClient | null
} & ChildrenProps

export function StripeProvider({ client, children }: Props) {
  const { Provider } = stripeContext
  return <Provider value={client}>{children}</Provider>
}

export const stripeContext = createContext<StripeClient | null>(null)
