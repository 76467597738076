import { useMutation } from '@apollo/client'
import { Duration } from 'luxon'
import { useAsyncCallback } from 'react-async-hook'

import { useRouting } from 'src/routes'
import { newLinkAuthSessionMutation } from 'src/graphql'
import { AnalyticsEvent, useEventAnalytics } from 'src/hooks/use-analytics'
import type {
  NewLinkAuthSessionMutation,
  NewLinkAuthSessionMutationVariables,
  UserLinkType
} from 'src/graphql/types'

const timeToWaitForRedirect = Duration.fromObject({ minutes: 2 }).as(
  'milliseconds'
)

export function useLinkAccount(
  linkType: UserLinkType,
  asPopup: boolean = false
) {
  const { getAccountLinkCallback } = useRouting()

  const accountLinkStartedEvent = useEventAnalytics(
    AnalyticsEvent.accountLinkStarted
  )

  const accountLinkNotStartedEvent = useEventAnalytics(
    AnalyticsEvent.accountLinkNotStarted
  )

  const [newLinkAuthSession] = useMutation<
    NewLinkAuthSessionMutation,
    NewLinkAuthSessionMutationVariables
  >(newLinkAuthSessionMutation)

  const { loading, error, execute } = useAsyncCallback<void, []>(async () => {
    try {
      const callbackUrl = getAccountLinkCallback()
      const { data } = await newLinkAuthSession({
        variables: { callbackUrl, linkType }
      })
      const url = data?.newLinkAuthSession
      if (!url) throw new Error('Failed to get redirect URL')
      accountLinkStartedEvent({ link_type: linkType })
      asPopup ? openLinkAsPopup(url, linkType) : openLink(url)
      await new Promise((resolve) =>
        globalThis.setTimeout(resolve, timeToWaitForRedirect)
      )
    } catch (err: any) {
      accountLinkNotStartedEvent({ link_type: linkType, error: err?.message })
      throw err
    }
  })

  return {
    redirect: execute,
    isLoading: loading,
    isError: error != null
  }
}

const openLink = (url: string) => {
  globalThis.location.href = url
}

const openLinkAsPopup = (url: string, name: string) => {
  const features = [
    'directories=no',
    'titlebar=no',
    'toolbar=no',
    'location=no',
    'status=no',
    'menubar=no',
    'scrollbars=no',
    'resizable=no',
    'width=625',
    'height=820'
  ]
  globalThis.open(
    url,
    `link account to ${name}`.toUpperCase(),
    features.join(',')
  )
}
