import { createContext, useContext, useState } from 'react'
import { DateTime, Duration } from 'luxon'

import { useInterval } from 'src/lib/hooks/use-interval'
import type { ChildrenProps } from 'src/types'

export enum FeatureFlag {
  dexter = 'dexter',
  discord = 'discord',
  membershipPerks = 'membership_perks',
  discountActive = 'discount_active',
  dualMembershipPlan = 'dual_membership_plan',
  reloadButton = 'reload_button',
  scoreboard = 'scoreboard',
  welcomeVideo = 'welcome_video',
  welcomeVideoId = 'welcome_video_id',
  adhocDemo = 'adhoc_demo',
  adhocPromotional = 'adhoc_promotional',
  assignmentGauges = 'assignment_gauges',
  csconfederationPremium = 'csconfederation_premium',
  lifetimeMembership = 'lifetime_membership',
  cancelPaypal = 'cancel_paypal',
  gamersenseiBox = 'gamersensei_box',
  profileExperiments = 'profile_experiments'
}

type Props = {
  posthog: Posthog
} & ChildrenProps

type Posthog = {
  isFeatureEnabled: (key: string, options: FeatureMethodOptions) => boolean
  getFeatureFlag: (
    key: string,
    options: FeatureMethodOptions
  ) => string | boolean | null | undefined
}

type FeatureMethodOptions = {
  send_event: boolean // eslint-disable-line camelcase
}

const pollInterval = Duration.fromObject({ seconds: 1 }).as('milliseconds')
const minDurationBetweenEvents = Duration.fromObject({ minutes: -5 })

export function useFeatureFlags(featureFlags: FeatureFlag[] = []) {
  const { posthog } = useContext(featureFlagContext)

  const getFlags = () =>
    featureFlags.map(
      (k) =>
        !!posthog.isFeatureEnabled(k, {
          send_event: willSendEvent(k)
        })
    )

  const [flags, setFlags] = useState(getFlags())

  useInterval(() => {
    setFlags(getFlags())
  }, pollInterval)

  return flags
}

export function useMultivariateFeatureFlags(featureFlags: FeatureFlag[] = []) {
  const { posthog } = useContext(featureFlagContext)

  const getFlags = () =>
    featureFlags.map((k) => {
      const flag = posthog.getFeatureFlag(k, {
        send_event: willSendEvent(k)
      })
      if (flag === true || flag === false || flag == null) return ''
      return flag
    })

  const [flags, setFlags] = useState(getFlags())

  useInterval(() => {
    setFlags(getFlags())
  }, pollInterval)

  return flags
}

export function FeatureFlagProvider({ posthog, children }: Props) {
  const { Provider } = featureFlagContext
  return <Provider value={{ posthog }}>{children}</Provider>
}

export const featureFlagContext = createContext<{ posthog: Posthog }>({
  posthog: {
    isFeatureEnabled: (_key: string, _options: FeatureMethodOptions) => false,
    getFeatureFlag: (_key: string, _options: FeatureMethodOptions) => false
  }
})

const sentEventTimes: { [key in FeatureFlag]?: DateTime } = {}
const willSendEvent = (k: FeatureFlag) => {
  const lastEventTime = sentEventTimes[k]

  const isLongerThanMinInterval =
    lastEventTime &&
    lastEventTime.diffNow(['milliseconds']) < minDurationBetweenEvents

  if (!lastEventTime || isLongerThanMinInterval) {
    sentEventTimes[k] = DateTime.utc()
    return true
  }

  return false
}
