import { gql } from '@apollo/client'

export const changelogQuery = gql`
  query Changelog {
    changelog {
      notices {
        startDate
        endDate
        severity
        title {
          text
        }
        body {
          text
        }
      }
      releases {
        date
        notes {
          action
          title {
            text
          }
        }
      }
    }
  }
`
