import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { useTheme } from '@mui/system'
import { Box } from '@mui/material'

import {
  HeaderPrice,
  PlanCard,
  PlanLevel,
  SignUpButton,
  useFeatures
} from 'src/screens/membership/pricing/PlanCard'
import { MembershipPlan } from 'src/graphql/types'
import { UpgradeButtons } from 'src/screens/membership/pricing/UpgradeButton'
import { PlanSwitch } from 'src/screens/membership/pricing/PlanSwitch'
import { useMaybeUser } from 'src/hooks/use-user'
import {
  useIsMembershipUpgradable,
  useMembershipEndDate
} from 'src/hooks/use-membership'
import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'

const level = PlanLevel.premium
const namespace = `membership:plans:${level}`

export function PremiumPlanCard() {
  const { t } = useTranslation()
  const features = useFeatures(level)
  const [isAnnualSelected, setIsAnnualSelected] = useState(true)
  const plan = useSelectedPlan(isAnnualSelected)

  return (
    <RelBox>
      <SwitchBox>
        <PlanSwitch
          level={level}
          isAnnualSelected={isAnnualSelected}
          setIsAnnualSelected={setIsAnnualSelected}
        />
      </SwitchBox>
      <PlanCard
        color={PlanLevel.premium}
        title={t(`${namespace}:title`)}
        header={
          <PremiumHeader>
            <Price selectedPlan={plan} plan={MembershipPlan.Annual} />
            <Price selectedPlan={plan} plan={MembershipPlan.Monthly} />
          </PremiumHeader>
        }
        features={features}
        footer={<Footer plan={plan} />}
      />
    </RelBox>
  )
}

function useSelectedPlan(isAnnualSelected: boolean) {
  const [isDualMembershipPlanEnabled] = useFeatureFlags([
    FeatureFlag.dualMembershipPlan
  ])
  if (!isDualMembershipPlanEnabled) return MembershipPlan.Monthly
  return isAnnualSelected ? MembershipPlan.Annual : MembershipPlan.Monthly
}

function Price(props: { selectedPlan: MembershipPlan; plan: MembershipPlan }) {
  const { t } = useTranslation()

  const [isDiscountActive, isDualMembershipPlanEnabled] = useFeatureFlags([
    FeatureFlag.discountActive,
    FeatureFlag.dualMembershipPlan
  ])

  const isSelected = props.plan === props.selectedPlan
  const nonDualPrefix = isDualMembershipPlanEnabled ? '' : 'non_dual_'
  const priceKey = isDiscountActive ? 'discount:price' : 'price'

  if (props.plan === MembershipPlan.Annual && !isDualMembershipPlanEnabled) {
    return null
  }

  if (!isSelected) {
    return null
  }

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          display: 'flex'
        }}
      >
        <HeaderPrice selected={isSelected}>
          {t(`${namespace}:plan:${props.plan}:${nonDualPrefix}${priceKey}`)}
        </HeaderPrice>
        <PerMonthText selected={isSelected}>
          /{t(`${namespace}:plan:${props.plan}:${nonDualPrefix}per`)}
        </PerMonthText>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <PriceInfo plan={props.plan} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
          position: 'absolute',
          top: '10px',
          right: 0
        }}
      >
        <PriceHook plan={props.plan} />
        <DiscountOffer plan={props.plan} />
      </Box>
    </Box>
  )
}

function PriceInfo(props: { plan: MembershipPlan }) {
  const { t } = useTranslation()
  const [isDualMembershipPlanEnabled] = useFeatureFlags([
    FeatureFlag.dualMembershipPlan
  ])

  if (!isDualMembershipPlanEnabled) {
    return null
  }

  return <StyledInfo>{t(`${namespace}:plan:${props.plan}:info`)}</StyledInfo>
}

function PriceHook(props: { plan: MembershipPlan }) {
  const { t } = useTranslation()
  const [isDiscountActive] = useFeatureFlags([FeatureFlag.discountActive])

  const hookKey = isDiscountActive ? 'discount:hook' : 'hook'
  const hookText = t(`${namespace}:plan:${props.plan}:${hookKey}`)

  if (hookText === '') {
    return null
  }

  return <StyledHook>{hookText}</StyledHook>
}

function DiscountOffer(props: { plan: MembershipPlan }) {
  const { t } = useTranslation()
  const [isDiscountActive] = useFeatureFlags([FeatureFlag.discountActive])

  const discountOffer = t(`${namespace}:plan:${props.plan}:discount:offer`)

  if (!isDiscountActive || discountOffer === '') {
    return null
  }

  return <StyledOffer>{discountOffer}</StyledOffer>
}

function Footer(props: { plan: MembershipPlan }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { user } = useMaybeUser()
  const date = useMembershipEndDate()
  const isMembershipUpgradable = useIsMembershipUpgradable()

  if (user == null) {
    return <SignUpButton color={theme.d.colors.accent} />
  }

  const text =
    date == null
      ? t('membership:plans:lifetime:active')
      : t('membership:plans:premium:active', { date })

  if (isMembershipUpgradable) {
    return (
      <StyleBox>
        <UpgradeButtons plan={props.plan} />
      </StyleBox>
    )
  }

  return (
    <Container>
      <ActiveText>{text}</ActiveText>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const RelBox = styled.div`
  position: relative;
`

const PremiumHeader = styled.div`
  display: flex;
  align-items: center;
`

const PerMonthText = styled.span<{ selected: boolean }>`
  color: ${(props) =>
    props.selected ?? true
      ? props.theme.d.colors.text.secondary
      : props.theme.d.colors.text.primary};
  font-size: 22px;
  line-height: 29px;
`

const StyledInfo = styled.span`
  color: ${(props) => props.theme.d.colors.text.primary};
  line-height: initial;
`

const StyledHook = styled.div`
  padding: 2px 10px;
  font-weight: 600;
  color: ${(props) => props.theme.d.colors.text.secondary};
  background-color: ${(props) => props.theme.d.colors.button.primary};
  border-radius: 4px 0 0 4px;
`

const StyledOffer = styled.div`
  padding: 2px 10px;
  color: ${(props) => props.theme.d.colors.text.secondary};
`

const ActiveText = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`

const StyleBox = styled.div`
  padding: ${(props) => props.theme.d.spacing[4]};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 207px;
`

const SwitchBox = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  width: 99%; // 2px misalignment on most sizes if 100%
  top: -60px;
  border: 1px solid ${(props) => props.theme.d.colors.border.light};
  background: ${(props) => props.theme.d.colors.panel.dark};
`
