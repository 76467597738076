import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { PremiumBadge } from 'src/system/ui/badge/PremiumBadge'
import { useIsMembershipUpgradable } from 'src/hooks/use-membership'

export function Username(props: { name: string | null | undefined }) {
  const { t } = useTranslation()
  const text = props.name ?? t('user:no_name_placeholder')

  return (
    <Box>
      <Text>{text}</Text>
      <PremiumStatus />
    </Box>
  )
}

function PremiumStatus() {
  const isMembershipUpgradable = useIsMembershipUpgradable()
  if (isMembershipUpgradable) {
    return null
  }

  return <PremiumBadge />
}

const Box = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 0 0 auto;
  padding: 0 ${(props) => props.theme.d.spacing[4]};
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    padding: 0 46px;
  }
`

const Text = styled.h5`
  font-weight: bold;
  font-family: ${(props) => props.theme.d.font.primary};
  color: ${(props) => props.theme.d.colors.text.secondary};
  font-size: 14px;
  line-height: 18px;
  margin: 0;

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    font-size: 18px;
    line-height: 24px;
  }
`
