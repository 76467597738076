import { useEffect, useRef } from 'react'

import { AnalyticsEvent, useEventAnalytics } from 'src/hooks/use-analytics'
import type { MembershipPlan, MembershipProvidence } from 'src/graphql/types'

export function useCheckoutAnalytics(
  isSuccess: boolean,
  providence: MembershipProvidence | null | undefined,
  plan: MembershipPlan | null | undefined
) {
  const isCalledRef = useRef(false)
  const checkoutCompletedEvent = useEventAnalytics(
    AnalyticsEvent.checkoutCompleted
  )
  const checkoutFailedEvent = useEventAnalytics(AnalyticsEvent.checkoutFailed)

  useEffect(() => {
    const eventProps = {
      ...(providence ? { membership_providence: providence } : {}),
      ...(plan ? { membership_plan: plan } : {})
    }
    if (isCalledRef.current) return
    isSuccess
      ? checkoutCompletedEvent(eventProps)
      : checkoutFailedEvent(eventProps)
    isCalledRef.current = true
  }, [checkoutCompletedEvent, checkoutFailedEvent, isSuccess, providence, plan])
}
