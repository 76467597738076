import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useProfile } from 'src/hooks/use-profile'
import { UserLinkType } from 'src/graphql/types'
import { useUnlinkAccount } from 'src/hooks/use-unlink-account'
import { Button, ButtonVariant } from 'src/system/ui/button/Button'
import { ConfirmDialog } from 'src/system/ui/dialog/ConfirmDialog'

export function UnlinkDiscord() {
  const { t } = useTranslation()
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
  const [unlink, { loading }] = useUnlinkAccount(UserLinkType.Discord)
  const toggleConfirmDialog = () =>
    setConfirmDialogVisible(!confirmDialogVisible)

  const { profile } = useProfile('cache-first')
  const linkId = profile?.discord?.id
  if (!linkId) {
    return null
  }

  return (
    <>
      <Button variant={ButtonVariant.secondary} onClick={toggleConfirmDialog}>
        {t('account:discord:unlink_account_button')}
      </Button>
      <ConfirmDialog
        isVisible={confirmDialogVisible}
        onClose={toggleConfirmDialog}
        processing={loading}
        handleProceed={() => {
          unlink(linkId)
        }}
        title={t('account:discord:unlink_account_dialog_title')}
        description={t('account:discord:unlink_account_dialog_description')}
        proceedLabel={t('account:discord:unlink_account_dialog_confirm_button')}
        cancelLabel={t('account:discord:unlink_account_dialog_cancel_button')}
      />
    </>
  )
}
