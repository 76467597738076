import type { LogLevelDesc } from 'loglevel'
import createEmotionCache from '@emotion/cache'

import { createRouting } from 'src/routes/config'
import type { StringMap } from 'src/types'
import { createSession } from 'src/session'
import type { PossibleTypesResultData } from 'src/graphql/possible-types'
import { createResolvers, createTypePolicies } from 'src/graphql'
import type { FeatureFlags } from 'src/lib/analytics/posthog'
import type { I18nResources } from 'src/lib/i18n'
import { createI18n } from 'src/lib/i18n'
import { createAuthClient } from 'src/lib/auth-client'
import { createApolloClient } from 'src/lib/apollo-client'
import { createStripe } from 'src/lib/stripe'
import { createAnalytics } from 'src/lib/analytics'

type Config = {
  name: string
  app: string
  owner: string
  env: string
  version: string
  nonce: string | undefined
  logLevel: LogLevelDesc
  apolloClientOrigin: string
  apolloClientWsOrigin: string
  apolloClientPath: string
  apolloClientApiKey: string
  appsyncHost: string
  possibleTypesResultData: PossibleTypesResultData
  schemaVersion: string
  posthogApiKey: string | null
  posthogTunnel: string | null
  sentryDsn: string | null
  sentryTunnel: string | null
  stripePublishableKey: string
  translations: I18nResources
  userAttributes: StringMap
  userPoolClientId: string
  userPoolId: string
  userPoolOrigin: string
  userPoolPath: string
  featureFlags: FeatureFlags
  apiVerifyCallbackPath: string
  apiPasswordCallbackPath: string
}

export const createDependencies = ({
  name,
  app,
  owner,
  env,
  version,
  nonce,
  featureFlags,
  apolloClientOrigin,
  apolloClientWsOrigin,
  apolloClientPath,
  apolloClientApiKey,
  appsyncHost,
  schemaVersion,
  possibleTypesResultData,
  posthogApiKey,
  posthogTunnel,
  logLevel,
  sentryDsn,
  sentryTunnel,
  stripePublishableKey,
  translations,
  userAttributes,
  userPoolClientId,
  userPoolId,
  userPoolOrigin,
  userPoolPath,
  apiVerifyCallbackPath,
  apiPasswordCallbackPath
}: Config) => {
  const routing = createRouting({
    apiVerifyCallbackPath,
    apiPasswordCallbackPath
  })

  const {
    posthog,
    sentry,
    initSentry,
    trackingConsent,
    identify: identifyUser,
    reset: resetAnalytics,
    init: initAnalytics,
    log
  } = createAnalytics({
    name,
    app,
    owner,
    env,
    version,
    logLevel,
    featureFlags,
    sentryDsn,
    sentryTunnel,
    posthogApiKey,
    posthogTunnel
  })

  const { init: initStripe, getClient: getStripeClient } = createStripe({
    publishableKey: stripePublishableKey
  })

  const { i18n, init: initI18n } = createI18n({
    resources: translations,
    sentry,
    log
  })

  const { client: authClient } = createAuthClient({
    userPoolOrigin,
    userPoolPath,
    userPoolId,
    userPoolClientId,
    userAttributes
  })

  const resolvers = createResolvers()
  const typePolicies = createTypePolicies()

  const { client: apolloClient, init: initApolloClient } = createApolloClient({
    authClient,
    origin: apolloClientOrigin,
    wsOrigin: apolloClientWsOrigin,
    path: apolloClientPath,
    apiKey: apolloClientApiKey,
    appsyncHost,
    possibleTypes: possibleTypesResultData.possibleTypes,
    typePolicies,
    resolvers,
    schemaVersion,
    schemaVersionKey: 'apollo-schema-version'
  })

  const { init: initSession } = createSession({
    apolloClient,
    authClient,
    sentry,
    identifyUser,
    resetAnalytics,
    log
  })

  const emotionCache = createEmotionCache({
    nonce,
    key: 'css',
    prepend: true
  })

  const init = async () => {
    await initAnalytics()
    await Promise.all([initI18n(), initApolloClient(), initStripe()])
    await initSession()
  }

  const props = (initPromise: Promise<void>) => ({
    init: () => initPromise,
    version,
    log,
    posthog,
    trackingConsent,
    identifyUser,
    sentry,
    i18n,
    routing,
    emotionCache,
    apolloClient,
    getStripeClient,
    authClient
  })

  return {
    props,
    init,
    initSentry,
    authClient,
    log
  }
}
