import { DateTime } from 'luxon'
import styled from '@emotion/styled'

import { Note } from 'src/system/app-bar/version/Note'
import type { Release } from 'src/graphql/types'

export function ReleaseNotes(props: { release: Release; className?: string }) {
  const { date, notes } = props.release
  const formattedDate = DateTime.fromISO(date).toFormat('LLL dd, yyyy')

  return (
    <div className={props.className}>
      <Header>
        <Date>{formattedDate}</Date>
      </Header>
      <div>
        {notes.map((note, index) => (
          <Note note={note} key={index} />
        ))}
      </div>
    </div>
  )
}

const Header = styled.div`
  margin-bottom: ${(props) => props.theme.d.spacing[2]};
`

const Date = styled.span`
  font-size: 14px;
  line-height: 18px;
`
