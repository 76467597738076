import { DateTime } from 'luxon'
import styled from '@emotion/styled'

import type {
  Score,
  Trend
} from 'src/screens/match/assessments/progression/ProgressionChart'
import { ScoreChart } from 'src/screens/match/assessments/progression/ProgressionChart'
import type { Progression } from 'src/screens/progression-report/ProgressionResources'

export function OverviewProgressionChart(props: {
  progression: Progression
  fixedHeight?: boolean
}) {
  const { scoreTimeseries } = props.progression
  const data: Score[] = scoreTimeseries.points.map((point) => ({
    id: point.resourceId,
    date: DateTime.fromISO(point.t),
    isCurrent: false,
    gpa: point.y
  }))
  const trend: Trend = scoreTimeseries.trend
  return (
    <Box fixedHeight={props.fixedHeight}>
      <Title>{props.progression.assignment.title.text}</Title>
      <ChartContainer>
        <ScoreChart data={data} isReport trend={trend} />
      </ChartContainer>
    </Box>
  )
}

const Box = styled.div<{ fixedHeight?: boolean }>`
  background: ${(props) => props.theme.d.colors.panel.light};
  padding: ${(props) => props.theme.d.spacing[4]};
  border: 1px solid ${(props) => props.theme.d.colors.border.dark};
  box-sizing: border-box;
  border-radius: 4px;
  height: ${(props) => (props.fixedHeight ? '300px' : '100%')};
  display: flex;
  flex-direction: column;
`

const Title = styled.h4`
  color: ${(props) => props.theme.d.colors.text.secondary};
  font-size: 20px;
  margin: ${(props) => `0 0 ${props.theme.d.spacing[2]}`};
`

const ChartContainer = styled.div`
  flex: 1;
`
