import { useMaybeUser } from 'src/hooks/use-user'
import { useAuthCookies } from 'src/hooks/use-auth'

export function useReferrerCode() {
  const data = useMaybeUser()
  const { referrer: cookieReferrerCode } = useAuthCookies()
  const isAnonymousUser = data?.user == null

  // TODO:
  // const userReferrerCode = data?.user?.referrer?.code
  const userReferrerCode = null

  return isAnonymousUser ? cookieReferrerCode : userReferrerCode
}
