import { useState } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button } from 'src/system/ui/button/Button'
import { LinkDiscordButton } from 'src/screens/account/discord/LinkDiscordButton'
import { DiscordButton } from 'src/system/DiscordButton'

export function LinkDiscord(props: { className?: string }) {
  const theme = useTheme()
  const { t } = useTranslation()
  const [dialogVisible, setDialogVisible] = useState(false)
  const [joinedDiscord, setJoinedDiscord] = useState(false)
  const toggleConfirmDialog = () => setDialogVisible(!dialogVisible)

  const linkSection = joinedDiscord ? (
    <StyledDialogContent>
      <DialogTitle>{t('account:discord:step_2')}</DialogTitle>
      <LinkDiscordButton />
    </StyledDialogContent>
  ) : null

  return (
    <>
      <StyledButton
        className={props.className}
        color={theme.d.colors.discord.blurple}
        aria-label={t('account:discord:label')}
        onClick={toggleConfirmDialog}
      >
        <FontAwesomeIcon icon={faDiscord} size='lg' />
        {t('account:discord:label')}
      </StyledButton>
      <Dialog open={dialogVisible} onClose={toggleConfirmDialog}>
        <StyledDialogContent>
          <DialogTitle>{t('account:discord:step_1')}</DialogTitle>
          <DiscordButton
            onClick={() => {
              setJoinedDiscord(true)
            }}
          />
        </StyledDialogContent>
        {linkSection}
      </Dialog>
    </>
  )
}

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.d.colors.discord.white};
`

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`
