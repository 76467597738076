import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Markdown } from 'src/screens/match/assessments/assignment-list/assignment/expansion/resources/Markdown'
import type { ResourceMatchmakingSteamFieldsCsgoFragment as ResourceMatchmakingSteamCsgoData } from 'src/graphql/types'

export function ResourceMatchmakingSteamCsgo(props: {
  resource: ResourceMatchmakingSteamCsgoData
}) {
  const { t } = useTranslation()
  const namespace = 'assessments:assignment:resources:matchmaking'

  const title = [
    t(`${namespace}:platform:${props.resource.platform}`),
    t(`${namespace}:mode:${props.resource.mode}`)
  ].join(' - ')

  return (
    <Text>
      <h3>{title}</h3>
      <Markdown>{props.resource?.optionalBody?.text}</Markdown>
    </Text>
  )
}

const Text = styled.div`
  max-width: 600px;
`
