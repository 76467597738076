import { gql } from '@apollo/client'

import {
  breakdownFragments,
  localizedContentFragments,
  resourceFragments
} from './courses'

export const progressionPageSize = 100

export const courseProgressionQuery = gql`
  query CourseProgression($pageSize: Int!) {
    progression: matches(first: $pageSize) {
      edges {
        ... on MatchEdge {
          node {
            ...MatchProgressionFields
          }
        }
      }
    }
  }

  fragment MatchProgressionFields on Match {
    id
    resourceId
    date
    map
    courses {
      isDone
      coach
      department
      revision
      aggregate {
        gpa
      }
    }
  }
`

export const progressionReportFragments = gql`
  fragment ProgressionReportFields on ProgressionReport {
    id
    matchCount
    assignmentProgressions {
      id
      department
      scoreTimeseries {
        points {
          t
          y
          matchId
          resourceId
        }
        matchIds
        resourceIds
        ts
        ys
        trend {
          slope
          intercept
        }
      }
      assignment {
        id
        title {
          ...LocalizedContentFields
        }
        body {
          ...LocalizedContentFields
        }
        grade {
          score
        }
        breakdowns {
          ...BreakdownTextFields
        }
        resources {
          ...ResourceTextFields
          ...ResourceLinkFields
          ...ResourceLinkListFields
          ...ResourceYoutubeFields
          ...ResourceYoutubeListFields
          ...ResourceMatchmakingSteamFieldsCsgo
          ...ResourceWorkshopSteamFieldsCsgo
        }
      }
    }
  }

  ${breakdownFragments}

  ${resourceFragments}

  ${localizedContentFragments}
`
