import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Meta } from 'src/system/Meta'
import { useTrackingConsentAnalytics } from 'src/hooks/use-analytics'
import { AppBar } from 'src/system/app-bar/AppBar'
import { Box } from 'src/routes/HandleCheckout'
import { Button } from 'src/system/ui/button/Button'
import { LinkColor } from 'src/system/ui/link/types'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'

export function CookiesPolicy() {
  const { t } = useTranslation()
  const { reset } = useTrackingConsentAnalytics()

  return (
    <>
      <Meta name='cookies_policy' />
      <AppBar />
      <Box>
        <h1>{t('cookies_policy:title')}</h1>
        <StyledLink
          color={LinkColor.green}
          newTab
          to={t('link:cookies_policy')}
        >
          {t('cookies_policy:policy')}{' '}
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </StyledLink>
        <Button
          aria-label={t('cookies_policy:reset_label')}
          onClick={() => {
            reset()
          }}
        >
          {t('cookies_policy:reset_label')}
        </Button>
        <StyledP>{t('cookies_policy:reset_info')}</StyledP>
      </Box>
    </>
  )
}

const StyledP = styled.p`
  max-width: 600px;
  font-size: 18px;
  text-align: center;
`

const StyledLink = styled(AbsoluteLink)`
  font-size: 18px;
  margin: 30px;
`
