import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { useRouting } from 'src/routes'
import { Button } from 'src/system/ui/button/Button'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'
import { JoinDiscordPanel } from 'src/system/DiscordPanel'

export function ErrorPage(props: { title: string; description: string }) {
  const { t } = useTranslation()
  const { routes } = useRouting()

  return (
    <Box>
      <ErrorTitle>{props.title}</ErrorTitle>
      <ErrorDescription>{props.description}</ErrorDescription>
      <AbsoluteLink to={routes.root} disableStyles>
        <BackToHomepageButton>{t('error:back_to_home')}</BackToHomepageButton>
      </AbsoluteLink>
      <JoinDiscordPanel
        title={t('discord_panel:questions:title')}
        description={t('discord_panel:questions:description')}
      />
    </Box>
  )
}

const Box = styled.div`
  padding: ${(props) => props.theme.d.spacing[8]} 0;
  text-align: center;
`

export const ErrorTitle = styled.h1`
  font-family: ${(props) => props.theme.d.font.secondary};
  font-size: 52px;
  line-height: 63px;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 ${(props) => props.theme.d.spacing[3]};
  color: ${(props) => props.theme.d.colors.text.secondary};
`

export const ErrorDescription = styled.p`
  font-size: 18px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto ${(props) => props.theme.d.spacing[8]};
`

export const BackToHomepageButton = styled(Button)`
  font-size: 14px;
  padding: ${(props) =>
    `${props.theme.d.spacing[4]} ${props.theme.d.spacing[15]}`};
  margin: 0 auto;
`
