import styled from '@emotion/styled'
import { useTheme } from '@mui/system'
import { useTranslation } from 'react-i18next'

import { Button } from 'src/system/ui/button/Button'
import { useLinkAccount } from 'src/hooks/use-link-account'
import { UserLinkType } from 'src/graphql/types'

export function LinkDiscordButton() {
  const theme = useTheme()
  const { t } = useTranslation()
  const { redirect, isLoading, isError } = useLinkAccount(UserLinkType.Discord)
  return (
    <>
      <StyledButton
        color={theme.d.colors.discord.blurple}
        aria-label={t('account:discord:label')}
        processing={isLoading}
        onClick={redirect}
      >
        {t('account:discord:link_button')}
      </StyledButton>
      {isError && t('account:discord:error')}
    </>
  )
}

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.d.colors.discord.white};
`
