import styled from '@emotion/styled'
import { useTheme } from '@mui/system'
import { useTranslation } from 'react-i18next'

enum ScoreGroup {
  high = 'high',
  medium = 'medium',
  low = 'low'
}
const scoreThreshold = {
  [ScoreGroup.high]: 7,
  [ScoreGroup.medium]: 3,
  [ScoreGroup.low]: 0
}

const maxScore = 10

export function Score(props: { className?: string; value: number }) {
  const color = useScoreColor()

  if (props.value === 0) return null

  return (
    <Text className={props.className} color={color(props.value)}>
      {props.value}/{maxScore}
    </Text>
  )
}

const scoreCounts = (scores: number[]) => ({
  [ScoreGroup.high]: scores.filter(
    (value: number) => value >= scoreThreshold[ScoreGroup.high]
  ).length,
  [ScoreGroup.low]: scores.filter(
    (value: number) =>
      value >= scoreThreshold[ScoreGroup.low] &&
      value < scoreThreshold[ScoreGroup.high]
  ).length
})

export function Scores(props: { className?: string; values: number[] }) {
  const values = [...props.values].sort((a, b) => a - b)
  const counts = scoreCounts(values)

  if (values.length === 0) return null

  return (
    <Container>
      {Object.entries(counts).map(([group, count]) => (
        <StyledScoreCount
          key={group}
          group={group as unknown as ScoreGroup}
          count={count}
        />
      ))}
    </Container>
  )
}

function ScoreCount(props: {
  group: ScoreGroup
  count: number
  className?: string
}) {
  const color = useScoreGroupColor()
  const { t } = useTranslation()

  if (props.count === 0) return null

  return (
    <Text className={props.className} color={color(props.group)}>
      {t(`assessments:score_group:${props.group}`, { count: props.count })}
    </Text>
  )
}

export function useScoreGroupColor() {
  const theme = useTheme()

  const color = (group: ScoreGroup) => {
    if (group === ScoreGroup.high) {
      return theme.d.colors.success
    }

    return theme.d.colors.error
  }

  return color
}

export function useScoreColor() {
  const theme = useTheme()
  const color = (value: number) => {
    if (value >= scoreThreshold[ScoreGroup.high]) {
      return theme.d.colors.success
    }
    if (value >= scoreThreshold[ScoreGroup.medium]) {
      return theme.d.colors.warning
    }
    return theme.d.colors.error
  }
  return color
}

const StyledScoreCount = styled(ScoreCount)`
  display: block;
`

const Text = styled.span<{ color: string }>`
  color: ${(props) => props.color};
`

const Container = styled.div`
  display: block;
`
