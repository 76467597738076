import styled from '@emotion/styled'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

export function ViewMatchButton(props: {
  onClick?: () => void
  className?: string
  disabled: boolean
}) {
  const { t } = useTranslation()
  return (
    <ViewMatch
      onClick={props.onClick}
      className={props.className}
      disabled={props.disabled}
    >
      {t('matches:view_match_link')} <FontAwesomeIcon icon={faCaretRight} />
    </ViewMatch>
  )
}

const ViewMatch = styled.div<{ disabled: boolean }>`
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};

  svg {
    margin-left: 8px;
    font-size: 16px;
  }
`
