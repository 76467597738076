import type {
  MatchFieldsFragment as Match,
  PendingMatchFieldsFragment as PendingMatch
} from 'src/graphql/types'
import { PendingMatchStatus } from 'src/graphql/types'
import { formatMatchDate } from 'src/lib/utils/transforms'

export const findById = (id: string, matches: (Match | PendingMatch)[]) =>
  matches.find((m) => m.id === id)

export const isPendingMatch = (
  match: Match | PendingMatch
): match is PendingMatch => {
  return match.__typename === 'PendingMatch'
}

export const isMatch = (match: Match | PendingMatch): match is Match => {
  return match.__typename === 'Match'
}

export const findOwner = <T extends { isOwner: boolean }>(players: T[]) =>
  players.find((p) => p.isOwner)

export const ownerInMatch = <T extends { isOwner: boolean }>(players: T[]) => {
  const player = players.find((player) => player?.isOwner)
  if (!player) {
    return false
  }

  return true
}

export const userWon = (match: Pick<Match, 'players' | 'score'>) => {
  const player = findOwner(match.players)

  if (player) {
    return player.team === match.score.winner
  }
  return false
}

export const isProcessing = (pendingMatch: PendingMatch) => {
  const isProcessing = {
    [PendingMatchStatus.Created]: true,
    [PendingMatchStatus.Verified]: true,
    [PendingMatchStatus.Completed]: false,
    [PendingMatchStatus.Failed]: false
  }

  return isProcessing[pendingMatch.status]
}

export const formattedDate = (date?: string | null) => {
  if (!date) {
    return ''
  }

  return formatMatchDate(date)
}
