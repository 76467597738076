import { DateTime, Duration, Interval } from 'luxon'
import { useQuery } from '@apollo/client'

import { sortByDate } from 'src/lib/utils/date'
import { changelogQuery } from 'src/graphql'
import type {
  ChangelogQuery,
  ChangelogQueryVariables,
  Notice,
  Severity
} from 'src/graphql/types'

const pollInterval = Duration.fromObject({ minutes: 3 }).as('milliseconds')

export function useNotice(severities: Severity[], errorNotice: Notice) {
  const { data, loading, error } = useQuery<
    ChangelogQuery,
    ChangelogQueryVariables
  >(changelogQuery, {
    pollInterval,
    fetchPolicy: 'cache-and-network'
  })

  const isFailedToLoad = !loading && !(error == null)
  const defaultNotices = isFailedToLoad ? [errorNotice] : []

  const notices = data?.changelog?.notices ?? defaultNotices
  return getLatestNotice(severities, notices)
}

const getLatestNotice = (severities: Severity[], notices: Notice[]) => {
  return sortByDate('startDate', [...notices])
    .filter(isCurrent)
    .find(({ severity }) => severities.includes(severity))
}

const isCurrent = (notice: { startDate: string; endDate: string }) => {
  const start = DateTime.fromISO(notice.startDate)
  const end = DateTime.fromISO(notice.endDate)
  const interval = Interval.fromDateTimes(start, end)
  return interval.contains(DateTime.local())
}
