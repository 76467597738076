import { image } from 'src/lib/matches/map-image'
import { CardMobile } from 'src/screens/matches/collection/list/CardMobile'
import { CardDesktop } from 'src/screens/matches/collection/list/CardDesktop'
import type { MatchProps } from 'src/screens/matches/collection/MatchCard'

export function Card(props: { match: MatchProps }) {
  const { match } = props
  const background = image(match.map)

  return (
    <>
      <CardMobile background={background} {...match} />
      <CardDesktop background={background} {...match} />
    </>
  )
}
