import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { useTheme } from '@mui/system'

import { PendingMatchStatus } from 'src/graphql/types'
import { useComputedStatus } from 'src/screens/matches/collection/match/status/Status'
import { useTimedOut } from 'src/screens/matches/collection/match/status/utils'

export function StatusDescription(props: {
  status: PendingMatchStatus
  updatedAt: string
  courseProgress: number | null | undefined
  className?: string
}) {
  const status = useComputedStatus(
    props.updatedAt,
    props.status,
    props.courseProgress
  )
  const description = useDescription(
    props.status,
    props.updatedAt,
    props.courseProgress
  )
  const color = useColor(status)
  return (
    <Text color={color} className={props.className}>
      {description}
    </Text>
  )
}

function useDescription(
  status: PendingMatchStatus,
  updatedAt: string,
  courseProgress: number | null | undefined
) {
  const { t } = useTranslation()
  const timedOut = useTimedOut(updatedAt)

  const isDone = status === PendingMatchStatus.Completed && courseProgress === 1
  const isDoneViaTimeout = status === PendingMatchStatus.Completed && timedOut
  const isFailed = status === PendingMatchStatus.Failed
  const isFinished = isDone || isDoneViaTimeout || isFailed

  if (isFinished) return null

  if (!timedOut) {
    return t('matches:est_wait_time')
  }

  if (status === PendingMatchStatus.Created) {
    return t('matches:timed_out_downloading')
  }

  if (status === PendingMatchStatus.Verified) {
    return t('matches:timed_out_processing')
  }
}

function useColor(status: PendingMatchStatus) {
  const theme = useTheme()

  if (status === PendingMatchStatus.Failed) {
    return theme.d.colors.error
  }

  return theme.d.colors.text.primary
}

const Text = styled.span<{ color: string }>`
  color: ${(props) => props.color};
`
