import type { ReactNode } from 'react'
import styled from '@emotion/styled'

import bgHalf from 'src/assets/bg-half.png'
import bgFull from 'src/assets/bg-full.png'

export function Background(props: {
  children: ReactNode
  full?: boolean
  className?: string
}) {
  const url = props.full ? bgFull : bgHalf
  const size = props.full ? 'cover' : 'contain'
  const position = props.full ? '50% 50%' : '0 0'

  return (
    <Box className={props.className} url={url} size={size} position={position}>
      {props.children}
    </Box>
  )
}

interface BoxProps {
  url: string
  size: string
  position: string
}

const Box = styled.div<BoxProps>`
  background: url(${(props: BoxProps) => props.url}) no-repeat;
  background-size: ${(props) => props.size};
  flex: 1;
  background-position: ${(props) => props.position};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
`
