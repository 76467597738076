import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Section } from 'src/screens/account/header/Section'
import { useJustUser } from 'src/hooks/use-user'

export function Email(props: { className?: string }) {
  const { t } = useTranslation()
  const { user } = useJustUser()

  return (
    <Section title={t('account:header:mail')} className={props.className}>
      <Text>{user.username}</Text>
    </Section>
  )
}

const Text = styled.p`
  margin: 0;
  color: ${(props) => props.theme.d.colors.text.secondary};
  font-weight: 600;
`
