import { Global, css } from '@emotion/react'
import { useTheme } from '@mui/system'
import type { Theme } from '@mui/system'

const styles = (props: { theme: Theme }) => css`
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 16px;
    line-height: 1.5em;
  }

  body,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button {
    font-family: ${props.theme.d.font.primary};
  }

  a,
  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  h1 {
    line-height: 100%;
  }

  .dzu-previewButton {
    height: inherit;
    width: 20px;
  }

  .dzu-previewButton-cancel::after {
    cursor: pointer;
    content: '⏸︎';
  }

  .dzu-previewButton-remove::after {
    cursor: pointer;
    content: '✕';
  }

  .dzu-previewButton-restart::after {
    cursor: pointer;
    content: '⏵︎';
  }
`

export function GlobalStyles() {
  const theme = useTheme()
  return <Global styles={styles({ theme })} />
}
