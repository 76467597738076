import styled from '@emotion/styled'

import { Section } from 'src/screens/account/header/Section'
import { Status as UserStatus } from 'src/system/user/Status'

export function Status() {
  return (
    <Section title='Status'>
      <StyledStatus />
    </Section>
  )
}

const StyledStatus = styled(UserStatus)`
  height: 16px;
`
