import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import type { MatchPlatform } from 'src/graphql/types'

export function MatchTokenAdded(props: { matchPlatform: MatchPlatform }) {
  const { t } = useTranslation()
  return (
    <Box>
      <TokenText>
        {t(
          `account:match_token:${props.matchPlatform}:hidden_token_placeholder`
        )}
      </TokenText>
      <TokenAddedLabel>
        {t(`account:match_token:${props.matchPlatform}:token_added`)}
      </TokenAddedLabel>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  justify-content: space-between;
`

const TokenText = styled.span`
  color: ${(props) => props.theme.d.colors.text.secondary};
`

const TokenAddedLabel = styled.span`
  color: ${(props) => props.theme.d.colors.matchTokenAddedText};
`
