import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

import type { Mark } from 'src/graphql/types'
import { PerkProtector } from 'src/system/perk/PerkProtector'
import { PerkType } from 'src/system/perk/perks'
import { useMatchOwnerUpgradeStatus } from 'src/system/perk/use-public-perk'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'
import { useRouting } from 'src/routes'
import { useAssessment } from 'src/hooks/use-assessment'

export function ViewLink(props: { mark: Mark }) {
  const { resourceId } = useAssessment()
  const { t } = useTranslation()
  const routes = useRouting()
  const { loading, isOwner } = useMatchOwnerUpgradeStatus()

  if (loading) {
    return null
  }

  const disabled = props.mark.start.round == null

  const watchLink = routes.toViewer(
    resourceId,
    props.mark.start.round ?? 0,
    props.mark.start.tick
  )

  const link = (
    <StyledRelativeLink disableStyles to={watchLink} disabled={disabled}>
      <FontAwesomeIcon icon={faCaretRight} />{' '}
      {t('assessments:assignment:watch_link')}
    </StyledRelativeLink>
  )

  if (!isOwner) {
    return link
  }

  return (
    <PerkProtector perk={PerkType.matchViewerMark} renderGate={GatedLink}>
      {link}
    </PerkProtector>
  )
}

function GatedLink(props: { onClick: () => void }) {
  const { t } = useTranslation()

  return (
    <StyledUpgradeLink onClick={props.onClick}>
      <FontAwesomeIcon icon={faCaretRight} />{' '}
      {t('assessments:assignment:watch_link')}
    </StyledUpgradeLink>
  )
}

const StyledRelativeLink = styled(RelativeLink)`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${(props) => props.theme.d.colors.success};

  svg {
    width: 8px !important;
    height: 22px !important;
    margin: 0 ${(props) => props.theme.d.spacing[3]} 0 0;
  }
`

const StyledUpgradeLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${(props) => props.theme.d.colors.accent};

  svg {
    width: 8px !important;
    height: 22px !important;
    margin: 0 ${(props) => props.theme.d.spacing[3]} 0 0;
  }
`
