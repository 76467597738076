import { DateTime } from 'luxon'

import type {
  Resource,
  ResourceLink,
  ResourceLinkList,
  ResourceMatchmakingSteamFieldsCsgoFragment,
  ResourceText,
  ResourceWorkshopSteamFieldsCsgoFragment,
  ResourceYoutube,
  ResourceYoutubeList
} from 'src/graphql/types'
import { MatchPlatform, ResourceFormatType } from 'src/graphql/types'

type PlatformResource = { platform: MatchPlatform }

export const isTextResource = (resource: Resource): resource is ResourceText =>
  resource.format === ResourceFormatType.Text

export const isYoutubeResource = (
  resource: Resource
): resource is ResourceYoutube => resource.format === ResourceFormatType.Youtube

export const isYoutubeListResource = (
  resource: Resource
): resource is ResourceYoutubeList =>
  resource.format === ResourceFormatType.YoutubeList

export const isLinkResource = (resource: Resource): resource is ResourceLink =>
  resource.format === ResourceFormatType.Link

export const isLinkListResource = (
  resource: Resource
): resource is ResourceLinkList =>
  resource.format === ResourceFormatType.LinkList

export const isMatchmakingSteamCsgoResource = (
  resource: Resource
): resource is ResourceMatchmakingSteamFieldsCsgoFragment => {
  if (resource.format !== ResourceFormatType.Matchmaking) return false
  return (
    (resource as unknown as PlatformResource).platform === MatchPlatform.Steam
  )
}

export const isWorkshopSteamCsgoResource = (
  resource: Resource
): resource is ResourceWorkshopSteamFieldsCsgoFragment => {
  if (resource.format !== ResourceFormatType.Workshop) return false
  return (
    (resource as unknown as PlatformResource).platform === MatchPlatform.Steam
  )
}

const maxDaysOld = 7

// NOTE: Since this does not use useInterval, the value
// will only update on re-renders.
export const isNewResource = (resource: Resource) => {
  const createdAt = resource.createdAt
  const daysOld = DateTime.fromISO(createdAt).diffNow('days').days
  return daysOld <= 0 && Math.abs(daysOld) < maxDaysOld
}
