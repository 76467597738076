import styled from '@emotion/styled'

import type {
  Facet,
  FacetTeamFieldsFragment,
  FacetTeamListFieldsFragment
} from 'src/graphql/types'
import { FacetShape, FacetUnit } from 'src/graphql/types'
import { TeamBadge } from 'src/system/ui/match/TeamBadge'

type FacetTeam = Facet & FacetTeamFieldsFragment
export const isFacetTeam = (facet: Facet): facet is FacetTeam =>
  facet.shape === FacetShape.Enum && facet.unit === FacetUnit.Team

export function FacetTeamValue(props: { facet: FacetTeam }) {
  return <StyledTeamBadge team={props.facet.teamValue} />
}

type FacetTeamList = Facet & FacetTeamListFieldsFragment
export const isFacetTeamList = (facet: Facet): facet is FacetTeamList =>
  facet.shape === FacetShape.EnumList && facet.unit === FacetUnit.Team

export function FacetTeamListValue(props: { facet: FacetTeamList }) {
  return (
    <TeamsList>
      {props.facet.teamValues.map((team, index) => (
        <StyledTeamBadge key={index} team={team} />
      ))}
    </TeamsList>
  )
}

const TeamsList = styled.div`
  display: flex;

  img {
    margin-right: ${(props) => props.theme.d.spacing[1]};
  }
`

const StyledTeamBadge = styled(TeamBadge)`
  width: 26px;
`
