import { useLocation } from 'react-router-dom'

import type { StringMap } from 'src/types'

export function useSearchParams<SearchParams>() {
  const { search } = useLocation()
  const searchParams = getSearchParams(search)

  return searchParams as unknown as SearchParams // TODO: Remove unknown
}

export const isParamTrue = (p: string | null | undefined) => p === 'true'

const getSearchParams = (search: string): StringMap => {
  const searchParams = new URLSearchParams(search)

  const obj: StringMap = {}
  for (const [k, v] of searchParams.entries()) obj[k] = v
  return obj
}
