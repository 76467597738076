import type { SyntheticEvent } from 'react'
import { useState } from 'react'
import { Duration } from 'luxon'
import { useTranslation } from 'react-i18next'
import { Button, Snackbar } from '@mui/material'
import type { SnackbarCloseReason } from '@mui/material'

import { useInterval } from 'src/lib/hooks/use-interval'

const refreshRate = Duration.fromObject({ minutes: 1 }).as('milliseconds')

export function NewVersion(props: { version: string }) {
  const { t } = useTranslation()

  const [latestVersion, setLatestVersion] = useState<string | null>(null)

  const isNewVersion = latestVersion != null && props.version !== latestVersion

  useInterval(async () => {
    const version = await fetchLatestVersion()
    setLatestVersion(version)
  }, refreshRate)

  return (
    <Snackbar
      open={isNewVersion}
      onClose={handleClose}
      message={t('new_version:notification')}
      action={
        <Button color='primary' size='small' onClick={reloadPage}>
          {t('new_version:reload')}
        </Button>
      }
    />
  )
}

const reloadPage = () => {
  globalThis.location.reload()
}

const handleClose = (
  event: Event | SyntheticEvent,
  reason: SnackbarCloseReason
) => {
  if (reason === 'clickaway') return
  reloadPage()
}

const fetchLatestVersion = async () => {
  try {
    const res = await fetch(globalThis.location.origin)
    if (!res.ok) return null
    return res.headers.get('x-app-version')
  } catch {
    return null
  }
}
