import { Grid } from '@mui/material'

import { usePublicProfile } from 'src/hooks/use-profile'
import type { ProgressionReportFieldsFragment } from 'src/graphql/types'
import { ProfileAssignment } from 'src/screens/progression-report/ProfileAssignment'
import { OverviewProgressionChart } from 'src/screens/progression-report/OverviewProgressionChart'
import { ProfileHeader } from 'src/screens/progression-report/Header'
import { BodyAlt } from 'src/screens/progression-report/SimpleTextProgressionReport'
import {
  Box,
  DesktopOnlyGrid,
  MobileOnlyGrid
} from 'src/screens/progression-report/ProgressionResources'

export function PublicProgressionReport(props: { profileId: string }) {
  const res = usePublicProfile(props.profileId, 'cache-and-network')
  return <PublicContent profile={res} />
}

function PublicContent(props: {
  profile: ReturnType<typeof usePublicProfile>
}) {
  const { data, loading, error } = props.profile

  const progressionReport = data?.profile?.csgo?.progressionReport

  const hasProgressions = progressionReport
    ? (progressionReport?.assignmentProgressions?.length ?? []) > 0
    : false

  const hasError = Boolean(error)

  const isLoading = !data && loading

  return (
    <Box>
      <ProfileHeader
        isOwner={data?.profile?.isOwner}
        isLoading={loading}
        hasError={hasError}
        hasProgressions={hasProgressions}
      />
      <MultiPlotBody
        hasError={Boolean(error)}
        data={progressionReport}
        shouldRender={hasProgressions && !isLoading}
      />
      <BodyAlt
        hasError={Boolean(error)}
        data={progressionReport}
        shouldRender={hasProgressions && !isLoading}
      />
    </Box>
  )
}

function MultiPlotBody(props: {
  hasError: boolean
  data?: ProgressionReportFieldsFragment
  shouldRender: boolean
}) {
  const { data, hasError, shouldRender } = props

  if (!data || hasError || !shouldRender) {
    return null
  }
  return (
    <>
      {data.assignmentProgressions.map((progression) => (
        <Grid key={progression.id} container spacing={5}>
          <Grid item xs={12} md={6} lg={6}>
            <ProfileAssignment
              progression={progression}
              isSelected={false}
              onClickChart={() => {}}
            />
          </Grid>
          <MobileOnlyGrid item xs={12} md={6}>
            <OverviewProgressionChart progression={progression} fixedHeight />
          </MobileOnlyGrid>
          <DesktopOnlyGrid item xs={12} lg={6}>
            <OverviewProgressionChart progression={progression} fixedHeight />
          </DesktopOnlyGrid>
          <Grid item xs={12} md={6} lg={6}>
            {' '}
          </Grid>
        </Grid>
      ))}
    </>
  )
}
