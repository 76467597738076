import type { ReactNode } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Container } from '@mui/material'
import { ExitToApp as ExitToAppIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { Meta } from 'src/system/Meta'
import { UserAppBar } from 'src/system/app-bar/user/UserAppBar'
import { Background } from 'src/system/ui/layout/Background'
import { useJustUser } from 'src/hooks/use-user'
import { useProfile } from 'src/hooks/use-profile'
import { PageTitle } from 'src/system/ui/typography/PageTitle'
import { PageSubhead } from 'src/system/ui/typography/PageSubhead'
import { Username } from 'src/screens/account/header/Username'
import { Email } from 'src/screens/account/header/Email'
import { Status } from 'src/screens/account/header/Status'
import { Password } from 'src/screens/account/header/Password'
import { Membership } from 'src/screens/account/header/Membership'
import { Avatar } from 'src/system/ui/image/Avatar'
import { Button, ButtonVariant } from 'src/system/ui/button/Button'
import { useAuth } from 'src/hooks/use-auth'
import { useRouting } from 'src/routes'
import { Step } from 'src/screens/account/step/Step'
import { ChangePasswordForm } from 'src/screens/account/ChangePasswordForm'
import { BackLink } from 'src/system/ui/link/BackLink'
import type { UserFieldsFragment as User } from 'src/graphql/types'

export type RenderContent = (content: ReactNode) => ReactNode

export function Account() {
  const { routes } = useRouting()
  const navigate = useNavigate()
  const { user } = useJustUser()
  const { profile } = useProfile('cache-first')

  const { signOut } = useAuth()
  const { t } = useTranslation()
  const [changePasswordVisible, setChangePasswordVisible] = useState(false)

  const toggleChangePassword = () => {
    setChangePasswordVisible(!changePasswordVisible)
  }

  const redirectToLogin = () => {
    navigate(routes.login)
  }

  const logout = async () => {
    await signOut()
    redirectToLogin()
  }

  const avatarUrl = profile?.steam?.avatarUrl

  return (
    <>
      <Meta name='account' />
      <UserAppBar />
      <StyledBackground>
        <Container>
          <PageHeader>
            <div>
              <BackToMatchesLink user={user} />
              <PageTitle>{t('account:title')}</PageTitle>
              <PageSubhead>{t('account:subhead')}</PageSubhead>
            </div>
            <div>
              <LogoutButton variant={ButtonVariant.secondary} onClick={logout}>
                <ExitToAppIcon />
                <span className='label'>{t('account:logout_button')}</span>
              </LogoutButton>
            </div>
          </PageHeader>
          <Content>
            <ContentHeader>
              <DesktopAvatar src={avatarUrl} />
              <Sections>
                <MobileAvatar src={avatarUrl} />
                <Username name={profile?.steam?.name} />
                <StyledEmail />
                <Status />
                <StyledPassword onChangePasswordClick={toggleChangePassword} />
                <StyledMembership />
              </Sections>
            </ContentHeader>
            <ContentBody
              user={user}
              showChangePassword={changePasswordVisible}
              toggleChangePassword={toggleChangePassword}
            />
          </Content>
        </Container>
      </StyledBackground>
    </>
  )
}

function BackToMatchesLink(props: { user: User }) {
  const { routes } = useRouting()
  const { t } = useTranslation()
  const showBackToMatches =
    props.user.isAccountLinked && props.user.automatch.isActive
  if (!showBackToMatches) return null
  return <BackLink to={routes.root}>{t('match:back_to_matches_link')}</BackLink>
}

function ContentBody(props: {
  showChangePassword: boolean
  toggleChangePassword: () => void
  user: User
}) {
  if (props.showChangePassword) {
    return (
      <StepBody>
        <ChangePasswordForm cancelChangePassword={props.toggleChangePassword} />
      </StepBody>
    )
  }

  return (
    <Step
      user={props.user}
      renderBody={(content) => <StepBody>{content}</StepBody>}
      renderAdditionalContent={(content) => (
        <StepAdditionalContent>{content}</StepAdditionalContent>
      )}
    />
  )
}

const StyledBackground = styled(Background)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
`

const borderRadius = '4px'

const PageHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: ${(props) => props.theme.d.spacing[9]};
`

const LogoutButton = styled(Button)`
  padding: ${(props) => `${props.theme.d.spacing[2]}`};

  font-size: 11px;

  svg {
    margin-right: 0;
  }

  .label {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    padding: ${(props) =>
      `${props.theme.d.spacing[2]} ${props.theme.d.spacing[4]}`};

    svg {
      margin-right: 6px;
    }

    .label {
      display: inline-block;
    }
  }
`

const Content = styled.div`
  margin-top: ${(props) => props.theme.d.spacing[5]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    margin-top: ${(props) => props.theme.d.spacing[9]};
  }
`
const StepBody = styled.div`
  padding: ${(props) =>
    `${props.theme.d.spacing[4]} ${props.theme.d.spacing[2]} ${props.theme.d.spacing[8]}`};

  background: ${(props) => props.theme.d.colors.panel.light};
  border-radius: 0 0 ${borderRadius} ${borderRadius};
  border-right: 1px solid ${(props) => props.theme.d.colors.border.dark};
  border-bottom: 1px solid ${(props) => props.theme.d.colors.border.dark};
  border-left: 1px solid ${(props) => props.theme.d.colors.border.dark};

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    padding: ${(props) =>
      `${props.theme.d.spacing[6]} ${props.theme.d.spacing[9]} ${props.theme.d.spacing[12]}`};
  }
`

const StepAdditionalContent = styled.div`
  margin-top: ${(props) => props.theme.d.spacing[4]};
`

const ContentHeader = styled.div`
  display: flex;
  align-items: flex-end;
`

const MobileAvatar = styled(Avatar)`
  border-left: 1px solid ${(props) => props.theme.d.colors.border.dark};
  border-top: 1px solid ${(props) => props.theme.d.colors.border.dark};
  border-right: 1px solid ${(props) => props.theme.d.colors.border.dark};
  box-sizing: border-box;
  border-radius: ${borderRadius} ${borderRadius} 0 0;
  width: 56px;
  height: 56px;

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    display: none;
  }
`

const DesktopAvatar = styled(MobileAvatar)`
  height: 134px;
  width: 134px;
  display: none;

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    display: block;
  }
`

const StyledEmail = styled(Email)`
  border-left: 1px solid ${(props) => props.theme.d.colors.border.divider};
  padding-left: ${(props) => props.theme.d.spacing[4]};
`

const StyledPassword = styled(Password)`
  border-right: none;
  margin-right: 0;
`

const StyledMembership = styled(Membership)`
  border-left: 1px solid ${(props) => props.theme.d.colors.border.divider};
  border-right: none;
  padding-left: ${(props) => props.theme.d.spacing[4]};
`

const Sections = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  background: ${(props) => props.theme.d.colors.accountSectionBackground};
  border-radius: 0 ${borderRadius} 0 0;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.d.colors.border.dark};
  border-right: 1px solid ${(props) => props.theme.d.colors.border.dark};
  padding: ${(props) => props.theme.d.spacing[2]};
`
