import { useRouting } from 'src/routes'
import { useMatchesUi } from 'src/hooks/use-ui'
import { isPendingMatch, ownerInMatch, userWon } from 'src/lib/matches/matches'
import { Card as GridCard } from 'src/screens/matches/collection/grid/Card'
import { Card as ListCard } from 'src/screens/matches/collection/list/Card'
import type {
  Map,
  MatchFieldsFragment as Match,
  PendingMatchFieldsFragment as PendingMatch
} from 'src/graphql/types'
import {
  MatchPlatform,
  PendingMatchStatus,
  UserInterfaceViewMode as ViewMode
} from 'src/graphql/types'

export type MatchProps = {
  status: PendingMatchStatus
  map: Map | null
  platform: MatchPlatform
  ctScore: number
  tScore: number
  didWin: boolean
  isTie: boolean
  uploadDate: string | null
  courseProgress?: number | null
  isPending: boolean
  players: Match['players']
  link?: string
  createdAt: string
  updatedAt: string
  isOwnerInMatch: boolean
}

export function MatchCard(props: { match: Match | PendingMatch }) {
  const { match } = props
  const { toAssessments } = useRouting()
  const { matchesViewMode: viewMode } = useMatchesUi()

  const data = isPendingMatch(match)
    ? pendingMatchToProps(match)
    : matchToProps(match, toAssessments(match.resourceId))

  if (viewMode === ViewMode.Grid) return <GridCard match={data} />
  if (viewMode === ViewMode.List) return <ListCard match={data} />
  throw new Error(`Unsupported view mode: ${viewMode}`)
}

const matchToProps = (match: Match, link: string) => ({
  status: PendingMatchStatus.Completed,
  map: match.map,
  platform: match.platform,
  ctScore: match.score.ct,
  tScore: match.score.t,
  didWin: userWon(match),
  uploadDate: match.date,
  isTie: match.score.isTie,
  courseProgress: match.courseProgress,
  isPending: false,
  players: match.players,
  link,
  createdAt: match.createdAt,
  updatedAt: match.updatedAt,
  isOwnerInMatch: ownerInMatch(match.players)
})

const pendingMatchToProps = (match: PendingMatch) => ({
  status: match.status,
  map: null,
  platform: MatchPlatform.Unknown,
  ctScore: 0,
  tScore: 0,
  didWin: false,
  uploadDate: match.pendingMatchDate,
  isTie: true,
  courseProgress: match.courseProgress,
  isPending: true,
  players: [],
  createdAt: match.createdAt,
  updatedAt: match.updatedAt,
  isOwnerInMatch: true
})
