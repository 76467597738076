import type { TypePolicies } from '@apollo/client'

export const createTypePolicies = (): TypePolicies => {
  return {
    Profile: {
      fields: {
        csgo: {
          merge: true
        }
      }
    },
    Match: {
      fields: {
        score: {
          merge: true
        }
      }
    }
  }
}
