import { useLog } from 'src/lib/hooks/use-log'
import type { Match } from 'src/hooks/use-match'
import type { Assignment } from 'src/screens/match/assessments/assignment'
import type { Progression } from 'src/screens/progression-report/ProgressionResources'

export function useMatchAssignmentFeedback(
  assignment: Assignment,
  match: Match
) {
  const { sentry, log } = useLog()

  return () => {
    const { department } = assignment
    const title = assignment.title.text
    const matchId = match.id

    log.info({ matchId, department, title, assignment })

    sentry.captureMessage(
      `Feedback for ${department} on match assignment: ${title}`,
      {
        contexts: { match, assignment },
        tags: { matchId, department, assignment: title }
      }
    )
  }
}

export function useProgressionAssignmentFeedback(progression: Progression) {
  const { sentry, log } = useLog()

  return () => {
    const { assignment, department } = progression
    const title = assignment.title.text

    log.info({ department, title, assignment })

    sentry.captureMessage(
      `Feedback for ${department} on progression assignment: ${title}`,
      {
        contexts: { assignment },
        tags: { department, assignment: title }
      }
    )
  }
}
