import styled from '@emotion/styled'

export function Description(props: { children: string }) {
  return <Text {...props} />
}

const Text = styled.p`
  max-width: 800px;
  font-size: 16px;
  line-height: 16px;
  margin: 0 0 20px 0;
`
