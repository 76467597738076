import EventTarget from '@ungap/event-target'
import { promisify } from 'es6-promisify'
import Cookies from 'universal-cookie'
import type {
  CognitoUserAttribute,
  CognitoUserSession
} from 'amazon-cognito-identity-js'
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool
} from 'amazon-cognito-identity-js'

import type { StringMap } from 'src/types'

type Config = {
  userPoolOrigin: string
  userPoolPath: string
  userPoolId: string
  userPoolClientId: string
  userAttributes: StringMap | null | undefined
}

export enum AuthState {
  anonymous,
  authenticate,
  unauthenticate,
  session,
  expire
}

type AuthError = Error & {
  code?: Symbol
}

const affiliateAttr = 'custom:affiliate'
const retroAffiliateAttr = 'custom:retro_affiliate'

const userAlreadyAuthenticated = Symbol('UserAlreadyAuthenticated')

export const affiliateCookieName = 'affiliate'
export const referrerCookieName = 'referrer'

export type AuthStateDetail = {
  userId: string | null
  username: string | null
  affiliateCode: string | null
  state: AuthState
}

export const createAuthClient = ({
  userPoolOrigin,
  userPoolPath,
  userPoolId,
  userPoolClientId,
  userAttributes
}: Config) => {
  const client = new AuthClient({
    userPoolOrigin,
    userPoolPath,
    userPoolId,
    userPoolClientId,
    userAttributes
  })

  return {
    client
  }
}

export class AuthClient {
  private userPool: CognitoUserPool
  private userAttributes: StringMap
  private target: EventTarget

  constructor({
    userPoolOrigin,
    userPoolPath,
    userPoolId,
    userPoolClientId,
    userAttributes
  }: Config) {
    this.userPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: userPoolClientId,
      endpoint: [userPoolOrigin, userPoolPath].join('')
    })
    this.userAttributes = userAttributes ?? {}
    this.target = new EventTarget()
  }

  onAuthStateChanged(f: (e: CustomEvent<AuthStateDetail>) => void) {
    this.target.addEventListener('auth_state', f as EventListener)
    return () => {
      this.target.removeEventListener('auth_state', f as EventListener)
    }
  }

  async getUser() {
    const user = await this.getCurrentUser()

    if (!user) {
      this.dispatchAuthStateEvent({
        userId: null,
        username: null,
        affiliateCode: null,
        state: AuthState.anonymous
      })
      return null
    }

    const attrs = await getAttributes(user)
    const userId = getUserId(attrs)
    const username = user.getUsername()
    const affiliateCode = getAffiliateCode(attrs)

    this.dispatchAuthStateEvent({
      userId,
      username,
      affiliateCode,
      state: AuthState.session
    })

    return {
      username,
      attributes: attrs
    }
  }

  async getUserSession(): Promise<CognitoUserSession | null> {
    const user = await this.getCurrentUser()
    if (!user) return null
    return this.getSession(user)
  }

  async getJwtToken() {
    const session = await this.getUserSession()
    if (!session) return null
    return session.getIdToken().getJwtToken()
  }

  async signUp(username: string, password: string, attributes = {}) {
    const validationData = null
    const signUp = promisify(this.userPool.signUp).bind(this.userPool)
    await signUp(
      username,
      password,
      objectToAttributes({ ...this.userAttributes, ...attributes }),
      validationData
    )
  }

  private async getCurrentUser() {
    const user = this.userPool.getCurrentUser()
    if (!user) return null

    // NOTE: getSession must be called here with the above user instance
    // to refresh the user session before calling getUserAttributes.
    await this.getSession(user)

    return user
  }

  async authenticateUser(username: string, password: string) {
    const currentAuthenticatedUser = await this.getCurrentUser()
    if (currentAuthenticatedUser !== null) {
      const err: AuthError = new Error(
        'Cannot sign in: user already authenticated'
      )
      err.code = userAlreadyAuthenticated
      throw err
    }

    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password
    })

    const user = this.createCognitoUser(authenticationDetails.getUsername())
    const authenticateUser = promisify((cb) =>
      user.authenticateUser(authenticationDetails, {
        onSuccess: (data) => cb(null, data),
        onFailure: cb
      })
    )
    await authenticateUser()

    const attrs = await getAttributes(user)
    const userId = getUserId(attrs)
    const affiliateCode = getAffiliateCode(attrs)

    this.dispatchAuthStateEvent({
      userId,
      username: user.getUsername(),
      affiliateCode,
      state: AuthState.authenticate
    })
  }

  async signOut() {
    const user = await this.getCurrentUser()
    if (user === null) return
    user.signOut()
    this.dispatchAuthStateEvent({
      userId: null,
      username: null,
      affiliateCode: null,
      state: AuthState.unauthenticate
    })
  }

  async confirmRegistration(username: string, code: string) {
    const forceAliasCreation = true
    const user = this.createCognitoUser(username)
    const confirmRegistration = promisify(user.confirmRegistration).bind(user)
    await confirmRegistration(code, forceAliasCreation)
  }

  async forgotPassword(username: string) {
    const user = this.createCognitoUser(username)
    const forgotPassword = promisify((cb) =>
      user.forgotPassword({
        onSuccess: (data) => cb(null, data),
        onFailure: cb
      })
    )
    await forgotPassword()
  }

  async confirmPassword(username: string, code: string, password: string) {
    const user = this.createCognitoUser(username)
    const confirmPassword = promisify((cb) =>
      user.confirmPassword(code, password, {
        onSuccess: () => cb(null),
        onFailure: cb
      })
    )
    await confirmPassword()
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const user = await this.getCurrentUser()
    if (user === null) return
    const changePassword = promisify((cb) =>
      user.changePassword(oldPassword, newPassword, cb)
    )
    await changePassword()
  }

  async setAffiliateCode(code: string | null) {
    if (!code) return

    const user = await this.getCurrentUser()
    if (user === null) return

    const attrs = await getAttributes(user)
    const persistedCode = getPersistedAffiliateCode(attrs)

    if (persistedCode != null) return
    if (code === persistedCode) return

    await new Promise((resolve, reject) => {
      const req = [{ Name: retroAffiliateAttr, Value: code }]
      user.updateAttributes(req, (err, data) => {
        if (err) return reject(err)
        resolve(data)
      })
    })
  }

  expireAuthState() {
    this.dispatchAuthStateEvent({
      userId: null,
      username: null,
      affiliateCode: null,
      state: AuthState.expire
    })
  }

  private async getSession(user: CognitoUser) {
    const getSession = promisify(user.getSession).bind(
      user
    ) as () => Promise<CognitoUserSession>
    return getSession()
  }

  private createCognitoUser(username: string) {
    const user = new CognitoUser({
      Username: username,
      Pool: this.userPool
    })

    return user
  }

  private dispatchAuthStateEvent(detail: AuthStateDetail) {
    const e = new CustomEvent('auth_state', { detail })
    this.target.dispatchEvent(e)
  }
}

const attributesToObject = (attributes: CognitoUserAttribute[]) => {
  const obj: any = {}

  for (const attribute of attributes) {
    const Name = attribute.getName()
    const Value = attribute.getValue()

    if (Value === 'true') {
      obj[Name] = true
    } else if (Value === 'false') {
      obj[Name] = false
    } else {
      obj[Name] = Value
    }
  }

  return obj
}

const objectToAttributes = (attributes: any) => {
  const arr = []

  for (const [Name, Value] of Object.entries(attributes)) {
    if (Value === 'true') {
      arr.push({ Name, Value: true })
    } else if (Value === 'false') {
      arr.push({ Name, Value: false })
    } else {
      arr.push({ Name, Value })
    }
  }

  return arr
}

const getAttributes = async (user: CognitoUser) => {
  const getUserAttributes = promisify(user.getUserAttributes).bind(user)
  const attributes = await getUserAttributes()
  return attributesToObject(attributes)
}

const getUserId = (attributes: { sub: string | null }) => attributes.sub

const getPersistedAffiliateCode = (attributes: {
  'custom:affiliate': string | null
  'custom:retro_affiliate': string | null
}) => {
  return attributes[affiliateAttr] ?? attributes[retroAffiliateAttr]
}

const getAffiliateCode = (attributes: {
  'custom:affiliate': string | null
  'custom:retro_affiliate': string | null
}) => {
  const cookies = new Cookies()
  return (
    getPersistedAffiliateCode(attributes) ?? cookies.get(affiliateCookieName)
  )
}

export const isUserNotFoundError = (error: any) =>
  error?.code === 'UserNotFoundException'

export const isUserAlreadyAuthenticatedError = (error: any) =>
  error?.code === userAlreadyAuthenticated
