import { useTheme } from '@mui/system'

import type { LinkStyleProps } from 'src/system/ui/link/types'
import { LinkColor } from 'src/system/ui/link/types'

export function useLinkStyle(props: LinkStyleProps) {
  const color = useColor({
    color: props.color,
    disableStyles: props.disableStyles
  })

  const underline = !props.disableStyles && !props.noUnderline

  return {
    color,
    underline
  }
}

function useColor(props: { color?: LinkColor; disableStyles?: boolean }) {
  const theme = useTheme()
  if (props.disableStyles) {
    return 'inherit'
  }

  if (props.color === LinkColor.green) {
    return theme.d.colors.link.green
  }

  return theme.d.colors.link.default
}
