// SOURCE: https://bost.ocks.org/mike/shuffle/
export const shuffle = <T extends any[]>(array: T): T => {
  const arr = [...array]
  let m = arr.length
  let t, i

  while (m) {
    i = Math.floor(Math.random() * m--)
    t = arr[m]
    arr[m] = arr[i]
    arr[i] = t
  }

  return arr as T
}
