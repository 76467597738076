import { useTranslation } from 'react-i18next'

import { Meta, NotFoundMeta } from 'src/system/Meta'
import { AppBar } from 'src/system/app-bar/AppBar'
import { ErrorPage } from 'src/system/ErrorPage'
import { Page } from 'src/system/ui/layout/Page'

export function NotFound() {
  const { t } = useTranslation()

  return (
    <Page>
      <Meta name='not_found' />
      <NotFoundMeta />
      <AppBar />
      <ErrorPage
        title={t('error:not_found:headline')}
        description={t('error:not_found:description')}
      />
    </Page>
  )
}
