import styled from '@emotion/styled'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

import { Button, ButtonVariant } from 'src/system/ui/button/Button'

export function ConfirmDialog(props: {
  isVisible: boolean
  onClose: () => void
  processing: boolean
  handleProceed: () => void
  title: string
  description: string
  proceedLabel: string
  cancelLabel: string
}) {
  return (
    <Dialog open={props.isVisible} onClose={props.onClose}>
      <DialogContent>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContentText>{props.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledButton
          onClick={props.handleProceed}
          variant={ButtonVariant.danger}
          processing={props.processing}
        >
          {props.proceedLabel}
        </StyledButton>
        <StyledButton onClick={props.onClose} variant={ButtonVariant.secondary}>
          {props.cancelLabel}
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}

const StyledButton = styled(Button)`
  text-transform: uppercase;
`
