import { useMutation } from '@apollo/client'
import { useAsyncCallback } from 'react-async-hook'

import { MembershipProvidence } from 'src/graphql/types'
import type {
  MembershipPlan,
  NewPaypalCheckoutSessionMutation,
  NewPaypalCheckoutSessionMutationVariables,
  NewStripeCheckoutSessionMutation,
  NewStripeCheckoutSessionMutationVariables
} from 'src/graphql/types'
import { useRouting } from 'src/routes'
import { AnalyticsEvent, useEventAnalytics } from 'src/hooks/use-analytics'
import { useStripe } from 'src/lib/hooks/use-stripe'
import {
  newPaypalCheckoutSessionMutation,
  newStripeCheckoutSessionMutation
} from 'src/graphql'

export function useStripeCheckout(
  plan: MembershipPlan,
  applyDiscount: boolean = false
) {
  const { getCheckoutCallback } = useRouting()
  const checkoutStartedEvent = useEventAnalytics(AnalyticsEvent.checkoutStarted)
  const checkoutNotStartedEvent = useEventAnalytics(
    AnalyticsEvent.checkoutNotStarted
  )
  const stripe = useStripe()

  const [newStripeCheckoutSession] = useMutation<
    NewStripeCheckoutSessionMutation,
    NewStripeCheckoutSessionMutationVariables
  >(newStripeCheckoutSessionMutation, {
    variables: {
      applyDiscount,
      plan,
      callbackUrl: getCheckoutCallback()
    }
  })

  const { loading, error, execute } = useAsyncCallback<void, []>(async () => {
    const analyticsParams = {
      membership_providence: MembershipProvidence.Stripe,
      membership_plan: plan
    }
    try {
      const { data } = await newStripeCheckoutSession()
      const sessionId = data?.newStripeCheckoutSession
      if (!stripe || !sessionId) throw new Error('Failed to load')
      checkoutStartedEvent(analyticsParams)
      await stripe.redirectToCheckout({ sessionId })
    } catch (err: any) {
      checkoutNotStartedEvent({
        ...analyticsParams,
        error: err?.message
      })
      throw err
    }
  })

  return {
    checkout: execute,
    isLoading: loading,
    isError: error != null
  }
}

export function usePaypalCheckout(
  plan: MembershipPlan,
  applyDiscount: boolean = false
) {
  const { getCheckoutCallback } = useRouting()
  const checkoutStartedEvent = useEventAnalytics(AnalyticsEvent.checkoutStarted)
  const checkoutNotStartedEvent = useEventAnalytics(
    AnalyticsEvent.checkoutNotStarted
  )

  const [newPaypalCheckoutSession] = useMutation<
    NewPaypalCheckoutSessionMutation,
    NewPaypalCheckoutSessionMutationVariables
  >(newPaypalCheckoutSessionMutation, {
    variables: {
      plan,
      applyDiscount,
      callbackUrl: getCheckoutCallback()
    }
  })

  const { loading, error, execute } = useAsyncCallback<void, []>(async () => {
    const analyticsParams = {
      membership_providence: MembershipProvidence.Paypal,
      membership_plan: plan
    }
    try {
      const { data } = await newPaypalCheckoutSession()
      const url = data?.newPaypalCheckoutSession
      if (!url) throw new Error('Failed to load')
      checkoutStartedEvent(analyticsParams)
      openLink(url)
    } catch (err: any) {
      checkoutNotStartedEvent({
        ...analyticsParams,
        error: err?.message
      })
      throw err
    }
  })

  return {
    checkout: execute,
    isLoading: loading,
    isError: error != null
  }
}

const openLink = (url: string) => {
  globalThis.location.href = url
}
