import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { RelativeLink } from 'src/system/ui/link/RelativeLink'
import { useRouting } from 'src/routes'
import { useProfile } from 'src/hooks/use-profile'
import { Button, ButtonVariant } from 'src/system/ui/button/Button'

export function AssignmentOverviewButton(props: { label: string }) {
  const { t } = useTranslation()
  const { profile } = useProfile('cache-first')
  const { toAssignment } = useRouting()
  if (!profile?.id) return null
  return (
    <RelativeLink to={toAssignment(profile.id, props.label)}>
      <StyledButton variant={ButtonVariant.link}>
        <FontAwesomeIcon icon={faChartLine} />
        <Text>{t('assignment:overview_link')}</Text>
      </StyledButton>
    </RelativeLink>
  )
}

const Text = styled.span`
  padding-left: 5px;
`

const StyledButton = styled(Button)`
  font-size: 16px;
`
