import styled from '@emotion/styled'

import type { AssignmentViewProps } from 'src/screens/match/assessments/assignment-list/assignment/Assignment'
import { AssignmentDesktopSummary } from 'src/screens/match/assessments/assignment-list/assignment/AssignmentDesktopSummary'
import { GenericFeedbackButton } from 'src/screens/match/assessments/AssignmentFeedbackButton'
import { AssignmentOverviewButton } from 'src/screens/match/assessments/AssignmentOverviewButton'

export function AssignmentDesktop(props: AssignmentViewProps) {
  return (
    <Box narrow={props.narrow}>
      <Content>
        <Main
          backgroundImage={props.backgroundImage}
          narrow={props.narrow}
          hasBody={!props.hideBody}
        >
          <Title>{props.title}</Title>
          <HeaderActions>
            {props.showOverviewButton && (
              <AssignmentOverviewButton label={props.label} />
            )}
            <GenericFeedbackButton />
          </HeaderActions>
        </Main>
        <AssignmentDesktopSummary {...props} />
      </Content>
      {props.expansion}
    </Box>
  )
}

const HeaderActions = styled.div`
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
`

const Box = styled.div<{ narrow?: boolean }>`
  background: ${(props) => props.theme.d.colors.panel.light};
  border: 1px solid ${(props) => props.theme.d.colors.border.dark};
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: ${(props) =>
    props.narrow ? 'none' : props.theme.d.spacing[3]};

  display: none;
  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
`

const Main = styled.div<{
  backgroundImage: string
  narrow?: boolean
  hasBody: boolean
}>`
  background: ${(props) => props.theme.d.colors.panel.dark};
  position: relative;
  flex: 1 1 525px;
  padding: ${(props) =>
    props.narrow
      ? `${props.theme.d.spacing[3]} ${props.theme.d.spacing[2]}`
      : `${props.theme.d.spacing[4]} ${props.theme.d.spacing[5]}`};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.narrow ? 'flex-start' : 'top')};
  align-items: flex-start;
  border-left: 1px solid ${(props) => props.theme.d.colors.border.dark};
  /* Re-enables highlighting text by prevent background from overlay */
  > * {
    position: relative;
    z-index: 2;
  }

  &:after {
    background: url(${(props) => props.backgroundImage}) no-repeat;
    content: '';
    opacity: 0.05;
    background-size: ${(props) => (props.narrow ? '200px' : '300px')};
    background-position: ${(props) =>
      props.narrow ? '-45px 50%' : '-35px 50%'};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Title = styled.h4`
  font-weight: bold;
  font-size: 26px;
  color: ${(props) => props.theme.d.colors.text.secondary};
  margin: 0 0 ${(props) => props.theme.d.spacing[2]};
`
