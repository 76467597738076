import { useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Meta } from 'src/system/Meta'
import { useSearchParams } from 'src/lib/hooks/use-search-params'
import { useAuth } from 'src/hooks/use-auth'
import { AuthForm } from 'src/screens/auth/AuthForm'
import { TextField } from 'src/system/ui/form/TextField'
import { useRouting } from 'src/routes/use-routing'

type SearchParams = {
  confirmation_code?: string // eslint-disable-line camelcase
  user_name?: string // eslint-disable-line camelcase
}

type FormData = {
  password: string
  confirmPassword: string
}

export function ResetPassword() {
  return (
    <>
      <Meta name='reset_password' />
      <ResetPasswordForm />
    </>
  )
}

function ResetPasswordForm() {
  const params = useSearchParams<SearchParams>()
  const { confirmation_code: confirmationCode, user_name: username } = params
  const [error, setError] = useState('')
  const { confirmPassword: resetPassword } = useAuth()
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { isSubmitting, errors }
  } = useForm<FormData>({
    defaultValues: { password: '', confirmPassword: '' }
  })
  const { t } = useTranslation()
  const { routes } = useRouting()
  const navigate = useNavigate()

  if (!username || !confirmationCode) {
    return <InvalidCodeError />
  }

  const redirectToLogin = () => {
    navigate(routes.login)
  }

  const submit = async () => {
    if (isSubmitting) {
      return
    }

    try {
      const { password } = getValues()
      await resetPassword(username, confirmationCode, password)
      redirectToLogin()
    } catch (err: any) {
      setError(err?.message)
    }
  }

  return (
    <AuthForm
      headline={t('password_reset:headline')}
      subhead={t('password_reset:subhead')}
      error={error}
      render={({ SubmitButton, FieldBox }) => (
        <form onSubmit={handleSubmit(submit)}>
          <FieldBox>
            <TextField
              fullWidth
              label={t('password_fields:password_label')}
              placeholder={t('password_fields:password_placeholder')}
              type='password'
              error={errors?.password?.message}
              {...register('password', {
                required: `${t('password_fields:password_error')}`
              })}
            />
          </FieldBox>
          <FieldBox>
            <TextField
              fullWidth
              label={t('password_fields:confirm_password_label')}
              placeholder={t('password_fields:confirm_password_placeholder')}
              type='password'
              error={errors?.confirmPassword?.message}
              {...register('confirmPassword', {
                required: `${t(
                  'password_fields:confirm_password_error:required'
                )}`,
                validate: (value: any) =>
                  value === watch('password') ||
                  (t(
                    'password_fields:confirm_password_error:mismatch'
                  ) as string)
              })}
            />
          </FieldBox>
          <SubmitButton fullWidth type='submit' processing={isSubmitting}>
            {t('password_reset:submit')}
          </SubmitButton>
        </form>
      )}
    />
  )
}

function InvalidCodeError() {
  const { t } = useTranslation()
  return (
    <InvalidLinkBox>{t('password_reset:invalid_link_error')}</InvalidLinkBox>
  )
}

const InvalidLinkBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`
