import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/client'

import { ManageDiscord } from 'src/screens/account/discord/ManageDiscord'
import { ManageFaceit } from 'src/screens/account/faceit/ManageFaceit'
import { ManageUpload } from 'src/screens/account/adhoc/ManageUpload'
import { SectionTitle } from 'src/system/ui/typography/SectionTitle'
import { Description } from 'src/screens/account/step/Description'
import { Button, ButtonVariant } from 'src/system/ui/button/Button'
import type { RenderContent } from 'src/screens/account/Account'
import { AnalyticsEvent, useEventAnalytics } from 'src/hooks/use-analytics'
import { removeSteamIdKeyMutation, userQuery } from 'src/graphql'
import type {
  RemoveSteamIdKeyMutation,
  RemoveSteamIdKeyMutationVariables,
  UserFieldsFragment as User
} from 'src/graphql/types'
import { UnlinkSteamAccount } from 'src/screens/account/step/UnlinkSteamAccount'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'
import { ConfirmDialog } from 'src/system/ui/dialog/ConfirmDialog'
import { JoinDiscordPanel } from 'src/system/DiscordPanel'

export function Manage(props: {
  renderBody: RenderContent
  renderAdditionalContent: RenderContent
  user: User
}) {
  const { t } = useTranslation()
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
  const toggleConfirmDialog = () =>
    setConfirmDialogVisible(!confirmDialogVisible)

  const automatchDisabledEvent = useEventAnalytics(
    AnalyticsEvent.automatchDisabled
  )

  const [removeSteamIdKey, { loading: isRemovingSteamIdKey }] = useMutation<
    RemoveSteamIdKeyMutation,
    RemoveSteamIdKeyMutationVariables
  >(removeSteamIdKeyMutation, {
    refetchQueries: [{ query: userQuery }]
  })

  const linkId = props.user.steamId
  if (!linkId) {
    return null
  }

  const stopDownloads = () => {
    removeSteamIdKey({ variables: { linkId } })
    automatchDisabledEvent()
  }

  const additionalContent = (
    <>
      <ManageDiscord />
      <ManageFaceit />
      <ManageUpload />
      <UnlinkSteamAccount user={props.user} />
    </>
  )

  return (
    <>
      {props.renderAdditionalContent(additionalContent)}
      {props.renderBody(
        <>
          <SectionTitle>{t('account:manage:title')}</SectionTitle>
          <Description>{t('account:manage:description') as string}</Description>
          <DisableAutoDownloadButton
            variant={ButtonVariant.secondary}
            onClick={toggleConfirmDialog}
          >
            {t('account:manage:disable_auto_download_button')}
          </DisableAutoDownloadButton>
          <AbsoluteLink to={t('link:how_csgo_match_history_works')} newTab>
            {t('account:match_token:how_it_works_text')}
          </AbsoluteLink>
        </>
      )}
      <ConfirmDialog
        isVisible={confirmDialogVisible}
        onClose={toggleConfirmDialog}
        processing={isRemovingSteamIdKey}
        handleProceed={stopDownloads}
        title={t('account:manage:disable_auto_download_dialog_title')}
        description={t(
          'account:manage:disable_auto_download_dialog_description'
        )}
        proceedLabel={t(
          'account:manage:disable_auto_download_dialog_confirm_button'
        )}
        cancelLabel={t(
          'account:manage:disable_auto_download_dialog_cancel_button'
        )}
      />
      <JoinDiscordPanel
        title={t('discord_panel:questions:title')}
        description={t('discord_panel:questions:description')}
      />
    </>
  )
}

const DisableAutoDownloadButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.d.spacing[4]};
  margin-top: ${(props) => props.theme.d.spacing[4]};
`
