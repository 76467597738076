import { useEffect, useState } from 'react'

export function useRandom() {
  const [value, setValue] = useState(0)

  useEffect(() => {
    setValue(Math.random())
  }, [setValue])

  return value
}
