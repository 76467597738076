import styled from '@emotion/styled'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LinkColor } from 'src/system/ui/link/types'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'

export function BackLink(props: {
  className?: string
  to: string
  children: string
}) {
  return (
    <StyledRelativeLink
      className={props.className}
      to={props.to}
      color={LinkColor.green}
      noUnderline
    >
      <ArrowIcon icon={faArrowLeft} /> {props.children}
    </StyledRelativeLink>
  )
}

const StyledRelativeLink = styled(RelativeLink)`
  margin-bottom: ${(props) => props.theme.d.spacing[8]};
  display: block;
`

const ArrowIcon = styled(FontAwesomeIcon)`
  margin-right: ${(props) => props.theme.d.spacing[5]};
`
