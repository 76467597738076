import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Backdrop } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

import { useRouting } from 'src/routes'
import { Meta } from 'src/system/Meta'
import { AppBar } from 'src/system/app-bar/AppBar'
import { Button } from 'src/system/ui/button/Button'
import type { YouTubePlayer } from 'src/system/ui/video/YoutubeVideo'
import {
  FeatureFlag,
  useFeatureFlags,
  useMultivariateFeatureFlags
} from 'src/hooks/use-feature-flags'
import { YoutubeVideo } from 'src/system/ui/video/YoutubeVideo'

export function Welcome() {
  const [player, setPlayer] = useState<YouTubePlayer | null>(null)
  const [played, setPlayed] = useState(false)
  const navigate = useNavigate()

  const {
    routes: { root }
  } = useRouting()

  const [isVideoEnabled] = useFeatureFlags([FeatureFlag.welcomeVideoId])
  const [videoId] = useMultivariateFeatureFlags([FeatureFlag.welcomeVideoId])

  if (!isVideoEnabled) return null

  return (
    <>
      <Meta name='welcome' />
      <AppBar />

      <Backdrop
        sx={{
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={!played}
      >
        <PlayButton
          player={player}
          onPlay={() => {
            setPlayed(true)
          }}
        />
      </Backdrop>
      <Container>
        <Video
          videoId={videoId}
          setPlayer={setPlayer}
          onEnd={() => {
            navigate(root)
          }}
        />
      </Container>
    </>
  )
}

function Video(props: {
  videoId: string
  setPlayer: (player: YouTubePlayer) => void
  onEnd: () => void
}) {
  return (
    <YoutubeVideo
      label='welcome'
      id={props.videoId}
      captions
      controls={false}
      onEnd={props.onEnd}
      setPlayer={props.setPlayer}
    />
  )
}

function PlayButton(props: {
  onPlay: () => void
  player: YouTubePlayer | null
}) {
  const { t } = useTranslation()
  const [isPlaying, setIsPlaying] = useState(false)

  const handleClick = () => {
    props?.player?.playVideo()
    setIsPlaying(true)
    props.onPlay()
  }

  if (props.player == null) {
    return null
  }

  if (isPlaying) {
    return null
  }

  return (
    <StyledButton onClick={handleClick}>
      <FontAwesomeIcon icon={faHandshake} size='3x' /> {t('welcome:play_video')}
    </StyledButton>
  )
}

const Container = styled.div`
  margin: auto;
  max-width: 1200px;
  width: 90%;
`

const StyledButton = styled(Button)`
  font-size: 20px;
`
