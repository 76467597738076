import { gql } from '@apollo/client'

export const openSessionMutation = gql`
  mutation OpenSession($userId: ID!, $affiliateCode: String) {
    openSession(userId: $userId, affiliateCode: $affiliateCode) @client {
      userId
      isAuthenticated
    }
  }
`

export const closeSessionMutation = gql`
  mutation CloseSession {
    closeSession @client {
      userId
      isAuthenticated
    }
  }
`

export const sessionQuery = gql`
  query Session {
    session @client {
      userId
      affiliateCode
      isAuthenticated
    }
  }
`
