import styled from '@emotion/styled'
import { useTheme } from '@mui/system'
import { RadioButtonChecked as RadioButtonCheckedIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { useJustUser } from 'src/hooks/use-user'

export function Status(props: { className?: string }) {
  const { user } = useJustUser()

  const { isAccountLinked } = user
  const isAutomatchActive = user.automatch.isActive

  const color = useStatusColor(isAccountLinked, isAutomatchActive)
  const text = useStatusText(isAccountLinked, isAutomatchActive)

  return (
    <Box className={props.className}>
      <Icon statusColor={color} />
      <Text color={color}>{text}</Text>
    </Box>
  )
}

function useStatusColor(
  isAccountLinked?: boolean,
  isAutomatchActive?: boolean
) {
  const theme = useTheme()

  if (isAutomatchActive) {
    return theme.d.colors.success
  }

  if (isAccountLinked) {
    return theme.d.colors.warning
  }

  // Unlinked
  return theme.d.colors.error
}

function useStatusText(isAccountLinked?: boolean, isAutomatchActive?: boolean) {
  const { t } = useTranslation()

  if (isAutomatchActive) {
    return t('user:status:is_auto_downloading_matches')
  }

  if (isAccountLinked) {
    return t('user:status:steam_is_linked')
  }

  return t('user:status:steam_not_linked')
}

const Box = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled((props: { statusColor: string }) => {
  const { statusColor: _, ...otherProps } = props
  return <RadioButtonCheckedIcon {...otherProps} />
})`
  margin-right: 6px;
  width: 14px !important;
  color: ${(props) => props.statusColor};
`

const Text = styled.p`
  color: ${(props: { color: string }) => props.color};
  margin: 0;
  font-weight: bold;
`
