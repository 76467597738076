import styled from '@emotion/styled'
import { Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import { Meta } from 'src/system/Meta'
import { setMatchesViewModeMutation } from 'src/graphql'
import type {
  SetMatchesViewModeMutation,
  SetMatchesViewModeMutationVariables,
  UserInterfaceViewMode as ViewMode
} from 'src/graphql/types'
import { UserAppBar } from 'src/system/app-bar/user/UserAppBar'
import { Background } from 'src/system/ui/layout/Background'
import { PageTitle } from 'src/system/ui/typography/PageTitle'
import { PageSubhead } from 'src/system/ui/typography/PageSubhead'
import { SetViewModeButtons } from 'src/screens/matches/SetViewModeButtons'
import { useMatches } from 'src/hooks/use-matches'
import { MatchesCollection } from 'src/screens/matches/collection/MatchesCollection'
import { WelcomeMessage } from 'src/screens/matches/WelcomeMessage'
import { MeetCoachAgainLink } from 'src/screens/matches/MeetCoachAgain'
import { ProgressionReport } from 'src/screens/progression-report/ProgressionReportMatches'
import { CheckForNewMatchesControl } from 'src/screens/matches/CheckForNewMatchesControl'
import { JoinDiscordPanel } from 'src/system/DiscordPanel'
import { useMatchesUi } from 'src/hooks/use-ui'
import { FetchMoreMatchesButton } from 'src/screens/matches/FetchMoreMatchesButton'
import { GenericFeedbackButton } from 'src/screens/match/assessments/AssignmentFeedbackButton'

const welcomeMatchThreshold = 3

export function Matches() {
  const { t } = useTranslation()

  const { matchesViewMode: viewMode } = useMatchesUi()
  const [setMatchesViewMode] = useMutation<
    SetMatchesViewModeMutation,
    SetMatchesViewModeMutationVariables
  >(setMatchesViewModeMutation)
  const setViewMode = (viewMode: ViewMode) => {
    setMatchesViewMode({ variables: { viewMode } })
  }

  const { matches, loading, fetchMore, hasMoreMatches } = useMatches()

  const numVisibleMatches = matches.length
  const firstVisibleMatchId = matches[0] ? matches[0].id : null
  const showWelcomeMessage =
    numVisibleMatches <= welcomeMatchThreshold && !loading

  return (
    <>
      <Meta name='matches' />
      <UserAppBar />
      <Background>
        <Container>
          <ProgressionReport />
          <PageHeader>
            <div>
              <PageTitle>{t('matches:title')}</PageTitle>
              <PageSubhead>{t('matches:subhead')}</PageSubhead>
            </div>

            <HeaderRight>
              <CheckForNewMatchesControl
                loading={loading || matches == null}
                numVisibleMatches={numVisibleMatches}
                firstVisibleMatchId={firstVisibleMatchId}
              />
            </HeaderRight>
          </PageHeader>
          <Content>
            {showWelcomeMessage && <WelcomeMessage />}
            <ContentHeader>
              <NumMatches>
                {t('matches:showing_matches', { num: numVisibleMatches })}
              </NumMatches>
              <SetViewModeButtons
                currentMode={viewMode}
                handleSetMode={setViewMode}
              />
            </ContentHeader>
            <MatchesCollection
              viewMode={viewMode}
              matches={matches}
              loading={loading}
            />
            <FetchMoreMatchesButton
              processing={loading}
              hasMoreMatches={hasMoreMatches}
              handleClick={fetchMore}
            />
          </Content>
          <JoinDiscordPanel
            title={t('discord_panel:questions:title')}
            description={t('discord_panel:questions:description')}
          />
          <StyledDiv>
            <StyledGenericFeedbackButton />
          </StyledDiv>
          <MeetCoachAgainLink />
        </Container>
      </Background>
    </>
  )
}
const PageHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: ${(props) => props.theme.d.spacing[9]};

  align-items: flex-start;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    flex-direction: row;
    align-items: center;
  }
`

const HeaderRight = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    flex-direction: row;
    align-items: center;
  }
`

const Content = styled.div``

const ContentHeader = styled.div`
  margin-bottom: ${(props) => props.theme.d.spacing[4]};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NumMatches = styled.p`
  font-size: 16px;
  margin: 0;
`

const StyledGenericFeedbackButton = styled(GenericFeedbackButton)`
  text-align: center;
`

const StyledDiv = styled.div`
  text-align: center;
  margin-top: 20px;
`
