import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { Score } from 'src/screens/match/assessments/score/Score'
import type { AssignmentViewProps } from 'src/screens/match/assessments/assignment-list/assignment/Assignment'
import { Breakdowns } from 'src/screens/match/assessments/assignment-list/assignment/expansion/breakdowns/Breakdowns'
import type { Breakdown } from 'src/graphql/types'

const showReason = false
export function AssignmentDesktopSummary(props: AssignmentViewProps) {
  if (showReason && props.reason) {
    return <BodyWithReason reason={props.reason} score={props.score} />
  }

  return (
    <BodyWithBreakdowns
      score={props.score}
      breakdowns={props.breakdowns}
      narrow={props.narrow}
    />
  )
}

function BodyWithReason(props: { reason: string; score: number }) {
  const { t } = useTranslation()
  return (
    <>
      <ReasonSection>
        <Attribute>
          <div>
            <AttributeTitle>
              {t('assessments:assignment:reason')}
            </AttributeTitle>
            <ReasonText>{props.reason}</ReasonText>
          </div>
        </Attribute>
      </ReasonSection>
      <Section>
        <Attribute>
          <div>
            <AttributeTitle>{t('assessments:assignment:grade')}</AttributeTitle>
            <StyledScore value={props.score} />
          </div>
        </Attribute>
      </Section>
    </>
  )
}

function BodyWithBreakdowns(props: {
  score: number
  breakdowns: Breakdown[]
  narrow?: boolean
}) {
  const { t } = useTranslation()

  return (
    <>
      <StyledBreakdowns breakdowns={props.breakdowns} narrow={props.narrow} />
      <GradeContainer>
        <AttributeTitle>{t('assessments:assignment:grade')}</AttributeTitle>
        <StyledScore value={props.score} />
      </GradeContainer>
    </>
  )
}

const Section = styled.div`
  flex: 0 0 12%;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`

const StyledBreakdowns = styled(Breakdowns)`
  border-right: 1px solid ${(props) => props.theme.d.colors.border.dark};
  flex: 1 1 680px;
`

const GradeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 80px;
`

const Attribute = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid ${(props) => props.theme.d.colors.border.dark};
  span {
    font-size: 16px;
  }
`

const AttributeTitle = styled.h5<{ large?: boolean }>`
  font-size: ${(props) => (props.large ? '18px' : '14px')};
  line-height: ${(props) => (props.large ? '24px' : '18px')};
  font-weight: normal;
  margin: 0 0 2px 0;
`

const StyledScore = styled(Score)<{ large?: boolean }>`
  line-height: ${(props) => (props.large ? '42px' : '29px')};
  font-size: ${(props) => (props.large ? '32px!important' : '24px!important')};
  font-weight: bold;
`

const ReasonSection = styled(Section)`
  flex: 0 0 24%;
  > div {
    padding: 0 ${(props) => props.theme.d.spacing[11]};
  }
`

const ReasonText = styled.p`
  color: ${(props) => props.theme.d.colors.text.secondary};
  margin: 0;
  line-height: 21px;
  font-size: 16px;
`
