import type { DateTime, ToRelativeUnit } from 'luxon'
import { Duration } from 'luxon'
import { useCallback, useEffect, useState } from 'react'

import { useInterval } from 'src/lib/hooks/use-interval'

const defaultDelay = Duration.fromObject({ seconds: 1 }).as('milliseconds')

export function useRelativeTime(startTime: DateTime | null) {
  const [result, setResult] = useState('')

  const update = useCallback(() => {
    setResult(relativeTimeFrom(startTime))
  }, [startTime])

  useEffect(update, [update])
  useInterval(update, defaultDelay)

  return result
}

function relativeTimeFrom(startTime: DateTime | null) {
  if (!startTime) {
    return ''
  }

  const padding = Duration.fromObject({ minutes: 1 }).as('milliseconds')

  const unit: ToRelativeUnit[] = ['months', 'weeks', 'days', 'hours', 'minutes']

  return startTime.toRelative({ unit, padding }) ?? ''
}
