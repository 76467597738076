import styled from '@emotion/styled'
import { useTheme } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button } from 'src/system/ui/button/Button'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'

export function DiscordButton(props: { onClick?: () => void }) {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <StyledLink newTab to={t('social:discord:link')} disableStyles>
      <StyledButton
        onClick={props.onClick}
        color={theme.d.colors.discord.blurple}
      >
        <FontAwesomeIcon icon={faDiscord} size='lg' />
        {t('discord_panel:action')}
      </StyledButton>
    </StyledLink>
  )
}

const StyledLink = styled(AbsoluteLink)`
  position: relative;
  z-index: 2;
`

const StyledButton = styled(Button)`
  svg {
    margin-right: ${(props) => props.theme.d.spacing[3]};
  }
`
