import { useEffect, useRef, useState } from 'react'
import { Duration } from 'luxon'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Popover } from '@mui/material'
import { useQuery } from '@apollo/client'

import { changelogQuery } from 'src/graphql'
import type { ChangelogQuery, ChangelogQueryVariables } from 'src/graphql/types'
import { ReleaseLog } from 'src/system/app-bar/version/ReleaseLog'

const pollInterval = Duration.fromObject({ minutes: 3 }).as('milliseconds')

export function VersionBadge(props: { className?: string }) {
  const { t } = useTranslation()
  const badgeEl = useRef<HTMLDivElement | null>(null)
  const [releaseNotesVisible, setReleaseNotesVisible] = useState(false)

  const [lastUpdateDate, setLastUpdateDate] = useLastUpdateDate()

  const { data } = useQuery<ChangelogQuery, ChangelogQueryVariables>(
    changelogQuery,
    {
      pollInterval,
      fetchPolicy: 'cache-and-network'
    }
  )

  const releases = data?.changelog?.releases ?? []

  const latestUpdateDate = releases[0]?.date

  const openPopover = () => {
    setReleaseNotesVisible(true)
  }

  const closePopover = () => {
    setReleaseNotesVisible(false)
    setLastUpdateDate(latestUpdateDate)
  }

  const hasNewUpdate =
    latestUpdateDate != null && latestUpdateDate !== lastUpdateDate

  return (
    <>
      <Badge
        className={props.className}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
        ref={badgeEl}
        showDot={hasNewUpdate}
      >
        <span>{t('common:version')}</span>
      </Badge>
      <Popover
        sx={{ pointerEvents: 'none' }}
        open={releaseNotesVisible && releases.length > 0}
        anchorEl={badgeEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        PaperProps={{
          onMouseEnter: openPopover,
          onMouseLeave: closePopover,
          sx: { pointerEvents: 'auto' }
        }}
      >
        <ReleaseLog releases={releases} />
      </Popover>
    </>
  )
}

const Badge = styled.div<{ showDot: boolean }>`
  border: 1px solid ${(props) => props.theme.d.colors.border.light};
  border-radius: 12px;
  font-family: ${(props) => props.theme.d.font.secondary};
  padding: 4px 8px;
  font-size: 11px;
  text-transform: uppercase;
  display: none;
  cursor: default;
  position: relative;
  color: ${(props) => props.theme.d.colors.accent};

  span {
    color: ${(props) => props.theme.d.colors.grey};
  }

  &::after {
    top: -5px;
    right: -5px;
    position: absolute;
    font-size: 40px;
    content: ${(props) => `"${props.showDot ? '·' : ''}"`};
  }

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    display: block;
  }
`

const useLastUpdateDate = () => {
  const [lastUpdateDate, setLastUpdateDate] = useState<string | null>(null)

  useEffect(() => {
    const cachedDate = globalThis.localStorage.getItem('lastUpdateDate')
    if (cachedDate) setLastUpdateDate(cachedDate)
  }, [setLastUpdateDate])

  const storeLastUpdateDate = (date: string) => {
    localStorage.setItem('lastUpdateDate', date)
    setLastUpdateDate(date)
  }

  return [lastUpdateDate, storeLastUpdateDate] as const
}
