import type { ReactNode } from 'react'
import styled from '@emotion/styled'

import { Footer } from 'src/system/footer/Footer'

export function Page(props: { children: ReactNode }) {
  return (
    <Box>
      <Content>{props.children}</Content>
      <Footer />
    </Box>
  )
}

const Box = styled.div`
  background: ${(props) => props.theme.d.colors.background};
  color: ${(props) => props.theme.d.colors.text.primary};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
