import type { Resolver, Resolvers } from '@apollo/client'

import { matchesUiQuery, sessionQuery, uiQuery } from 'src/graphql'
import type { UserInterfaceViewMode } from 'src/graphql/types'

type ResolverParameters = Parameters<Resolver>
type Parent = ResolverParameters[0]
type Ctx = ResolverParameters[2]

export const createResolvers = (): Resolvers => {
  return {
    User: {
      isAccountLinked: isAccountLinkedResolver
    },
    Mutation: {
      setMatchesViewMode: setMatchesViewModeResolver,
      closeSession: createCloseSessionMutation,
      openSession: createOpenSessionMutation
    }
  }
}

type SessionMutationVariables = {
  userId: string | null
  affiliateCode: string | null
} | null

type Session = {
  __typename: string
  userId: string | null
  affiliateCode: string | null
  isAuthenticated: boolean
}

const createSessionMutation =
  (isAuthenticated: boolean) =>
  (_obj: Parent, vars: SessionMutationVariables, { cache }: Ctx): Session => {
    const userId = vars?.userId ?? null
    const affiliateCode = vars?.affiliateCode ?? null

    const session = {
      __typename: 'Session',
      userId,
      affiliateCode,
      isAuthenticated
    }

    cache.writeQuery({
      query: sessionQuery,
      data: { session }
    })

    return session
  }

const createOpenSessionMutation = createSessionMutation(true)
const createCloseSessionMutation = createSessionMutation(false)

const isAccountLinkedResolver = (obj: Parent): boolean => !!obj.steamId

const setMatchesViewModeResolver = (
  _obj: Parent,
  vars: { viewMode: UserInterfaceViewMode },
  { cache }: Ctx
): UserInterfaceViewMode => {
  const { viewMode } = vars

  const ui = {
    __typename: 'UserInterfaceState',
    matchesViewMode: viewMode
  }

  cache.writeQuery({
    query: uiQuery,
    data: { ui }
  })

  cache.writeQuery({
    query: matchesUiQuery,
    data: { ui }
  })

  return viewMode
}
