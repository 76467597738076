import { useTranslation } from 'react-i18next'

import { useProgressionAssignmentFeedback } from 'src/hooks/use-feedback'
import { useAssignmentViewProps } from 'src/screens/match/assessments/assignment-list/assignment/Assignment'
import { AssignmentDesktop } from 'src/screens/match/assessments/assignment-list/assignment/AssignmentDesktop'
import { AssignmentMobile } from 'src/screens/match/assessments/assignment-list/assignment/AssignmentMobile'
import { Expansion } from 'src/screens/match/assessments/assignment-list/assignment/expansion/Expansion'
import { ResourceList } from 'src/screens/match/assessments/assignment-list/assignment/expansion/resources/ResourceList'
import { isNewResource } from 'src/screens/match/assessments/assignment-list/assignment/expansion/resources/utils'
import type { Progression } from 'src/screens/progression-report/ProgressionResources'

export function ProfileAssignment(props: {
  progression: Progression
  isSelected: boolean
  onClickChart: () => void
}) {
  const { t } = useTranslation()
  const assignmentFeedback = useProgressionAssignmentFeedback(props.progression)

  const resources = props.progression.assignment.resources
  const hasNewResource = resources.filter(isNewResource).length > 0

  const viewProps = useAssignmentViewProps({
    department: props.progression.department,
    title: props.progression.assignment.title.text,
    description: props.progression.assignment.body.text,
    score: props.progression.assignment.grade.score,
    breakdowns: props.progression.assignment.breakdowns,
    onAssignmentFeedback: assignmentFeedback,
    narrow: true,
    hideBody: true,
    expansion: (
      <Expansion
        one={{
          title: t('assessments:assignment:expansion:tab:resources'),
          body: <ResourceList resources={resources} />,
          bodyCount: props.progression.assignment.resources.length,
          isNew: hasNewResource
        }}
      />
    )
  })

  return (
    <div id={viewProps.label}>
      <AssignmentDesktop {...viewProps} />
      <AssignmentMobile {...viewProps} />
    </div>
  )
}
