import {
  createTheme,
  paginationItemClasses,
  typographyClasses
} from '@mui/material'

const spacing = Array(60)
  .fill(4)
  .map((base, idx) => `${base * idx}px`)

const greys = {
  grey1: '#71828c',
  grey2: '#e0e0e0',
  grey3: '#a6a6a6',
  grey4: '#8e919d',
  grey5: '#222530',
  grey6: '#1c1e27',
  grey7: '#2e323d',
  grey8: '#232730',
  grey9: '#c7cdd1',
  grey10: '#373D4D',
  grey11: '#232632',
  grey12: '#363a47',
  grey13: '#252934',
  grey14: '#2c2f3c',
  grey15: '#1f222b',
  grey16: '#4f5362'
}

const borderGreys = {
  borderGrey1: '#2b2e39',
  borderGrey2: '#292c38',
  borderGrey3: '#2d313f',
  borderGrey4: '#323843',
  borderGrey5: '#353a46'
}

const palette = {
  boringBackgroundGrey: '#1e2029',
  ...greys,
  ...borderGreys,
  gold: '#c5b358',
  boPeepWhite: '#ffffff',
  csgoT: '#ebc79a', // actually same as youDidOkYellow
  csgoTLowOpacity: 'rgba(235, 199, 154, 0.4)',
  csgoCt: '#84a9dc',
  csgoCtLowOpacity: 'rgba(113, 130, 140, 0.4)',
  youDidGoodGreen: '#62cf7b',
  youDidOkYellow: '#ebc79a',
  tryAgainRed: '#cf6262',
  buttonGreen: '#53ac67', // slightly darker than youDidGoodGreen green
  matchTokenGreen: '#99cc99', // slightly lighter than youDidGoodGreen
  theFanciestPurple: '#9956ae',
  cuteBabyBlue: '#62cfcf'
}

const colors = {
  background: palette.boringBackgroundGrey,
  lifetime: palette.gold,
  text: {
    primary: palette.grey1,
    secondary: palette.boPeepWhite
  },
  gamersensei: {
    green: '#53ac67'
  },
  discord: {
    blurple: '#5865f2',
    green: '#57f287',
    yellow: '#fee75c',
    fuchsia: '#eb459e',
    red: '#ed4245',
    white: '#ffffff',
    black: '#000000'
  },
  faceit: {
    primary: {
      orange: 'rgb(255, 85, 0)',
      white: 'rgb(235, 239, 243)',
      black: 'rgb(20, 22, 22)'
    }
  },
  pendingMatchStatus: {
    completed: palette.grey3,
    created: palette.youDidOkYellow,
    failed: palette.tryAgainRed,
    verified: palette.youDidOkYellow
  },
  button: {
    primary: palette.buttonGreen,
    secondary: palette.grey5,
    danger: palette.tryAgainRed,
    disabled: {
      background: palette.grey2,
      text: palette.grey3
    }
  },
  icon: palette.grey9,
  success: palette.youDidGoodGreen,
  accent: palette.theFanciestPurple,
  error: palette.tryAgainRed,
  grey: palette.grey3,
  warning: palette.youDidOkYellow,
  link: {
    default: palette.cuteBabyBlue,
    green: palette.buttonGreen
  },
  border: {
    light: palette.borderGrey1,
    dark: palette.borderGrey2,
    divider: palette.borderGrey3,
    dialog: palette.borderGrey4,
    input: palette.borderGrey5
  },
  panel: {
    light: palette.grey5,
    dark: palette.grey6
  },
  highlight: palette.grey7,
  ct: palette.csgoCt,
  ctLowOpacity: palette.csgoCtLowOpacity,
  t: palette.csgoT,
  tLowOpacity: palette.csgoTLowOpacity,
  dialog: palette.grey8,
  matchTokenAddedText: palette.matchTokenGreen,
  connectAccountInstruction: {
    mainBackground: palette.grey11,
    numberColor: palette.grey11,
    numberBorder: palette.grey12
  },
  exclusiveResourceColor: palette.youDidGoodGreen,
  premiumResourceColor: palette.theFanciestPurple,
  accountSectionBackground: palette.grey13,
  winBadgeBoxBackground: palette.grey14,
  coursePanelGridHoverBackground: palette.grey10,
  viewerControlsBackground: palette.grey15,
  userAvatarBorder: palette.grey16,
  promotionBackground: 'rgba(153, 86, 174, 0.33)',
  promotionText: 'rgba(255, 255, 255, 0.77)',
  expansionTabTitleHighlight: 'rgba(98, 207, 123, 0.15)',
  memberWallOverlay: 'rgba(31, 34, 43, 0.33)',
  footerBackground: 'rgba(33, 36, 45, 0.7)',
  tab: {
    hover:
      'linear-gradient(0, rgba(98, 207, 123, 0.08) 3.52%, rgba(33, 36, 45, 0) 96.48%)',
    active:
      'linear-gradient(0, rgba(98, 207, 123, 0.18) 3.52%, rgba(33, 36, 45, 0) 96.48%)'
  },
  mapCardGrid:
    'linear-gradient(180deg, #292c38 0%, rgba(30, 33, 42, 0.34) 100%)',
  gridViewButtonActiveBackground:
    'linear-gradient(270deg, rgba(98, 207, 123, 0.18) 0%, rgba(33, 36, 45, 0) 100%)',
  assignmentSortAscendingActive:
    'linear-gradient(270deg, rgba(98, 207, 123, 0.18) 0%, rgba(33, 36, 45, 0) 100%)',
  assignmentSortDescendingActive:
    'linear-gradient(90deg, rgba(98, 207, 123, 0.18) 0%, rgba(33, 36, 45, 0) 100%)',
  topNavBarLinkBackground:
    'linear-gradient(0deg, rgba(98, 207, 123, 0.18) 3.52%, rgba(33, 36, 45, 0) 96.48%)',
  matchHeaderBackground: `
  radial-gradient(
    47.28% 130.44% at 50.67% 51.42%,
    rgba(34, 37, 48, 0.74) 0%,
    rgba(32, 34, 44, 0.94) 54.66%,
    #1e2029 99.54%
  )`,
  starBackground: `
  linear-gradient(
    0deg,
    rgba(31, 34, 43, 1) 50%,
    rgba(51, 56, 71, 0) 100%
  )`,
  discordBackground: `
  linear-gradient(
    0deg,
    rgba(31, 34, 43, 1) -10%,
    rgba(51, 56, 71, 0) 100%
  )`,
  mapCardBackground: `linear-gradient(
    90deg,
    rgba(30, 33, 42, 0.49) -40.77%,
    ${greys.grey5} 95.68%
  )`
}

const breakpoints = {
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px'
}

const font = {
  primary: "'Assistant', sans-serif",
  secondary: "'Archive', sans-serif",
  navigation: "'Poppins', sans-serif"
}

const mui = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: colors.text.primary
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiTypography: {
      variants: [
        {
          props: { color: 'secondary' },
          style: {
            color: colors.text.primary
          }
        }
      ],
      styleOverrides: {
        h1: {
          fontFamily: font.primary
        },
        h2: {
          fontFamily: font.primary
        },
        h3: {
          fontFamily: font.primary
        },
        h4: {
          fontFamily: font.primary
        },
        h5: {
          fontFamily: font.primary
        },
        h6: {
          fontFamily: font.primary
        },
        body1: {
          fontFamily: font.primary,
          color: colors.text.primary
        },
        body2: {
          fontFamily: font.primary,
          color: colors.text.primary
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: colors.background,
          color: colors.text.primary
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: colors.highlight
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          maxWidth: '420px'
        },
        paper: {
          borderRadius: '6px',
          border: `1px solid ${colors.border.dialog}`
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          background: colors.dialog,
          fontSize: '14px',
          textAlign: 'center',
          '&:first-of-type': {
            paddingTop: '24px'
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: colors.text.secondary,
          [`& .${typographyClasses.root}`]: {
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: 'center'
          }
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: colors.text.primary
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          background: colors.dialog,
          justifyContent: 'center',
          padding: spacing[7]
        },
        spacing: {
          '& *': {
            margin: `0 ${spacing[3]}`
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: `${colors.border.dark} !important`
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: colors.text.primary
        },
        outlined: {
          background: colors.panel.light,
          color: colors.text.primary,
          fontSize: '14px',
          padding: `${spacing[3]} ${spacing[8]} ${spacing[3]} ${spacing[4]}`
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: `1px solid ${colors.border.input}`,
          background: `${colors.panel.light}!important`,
          color: colors.text.primary,
          boxShadow: 'none'
        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          background: colors.button.secondary,
          border: `1px solid ${colors.border.dark}`,
          borderRadius: '4px',
          padding: `${spacing[3]} 0`,
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: colors.text.primary,
          fontWeight: 'bold',
          fontSize: '13px',
          [`&.${paginationItemClasses.selected}`]: {
            background: colors.button.primary,
            color: colors.text.secondary,
            '&:hover': {
              background: colors.button.primary
            }
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.border.input,
          '&:hover': {
            backgroundColor: 'transparent'
          }
        },
        colorPrimary: {
          '&:hover': {
            backgroundColor: 'transparent !important'
          }
        }
      }
    }
  }
})

export type ExtendedTheme = {
  breakpoints: typeof breakpoints
  colors: typeof colors
  font: typeof font
  spacing: typeof spacing
}

export const theme = {
  ...mui,
  d: {
    breakpoints,
    colors,
    font,
    spacing
  }
}
