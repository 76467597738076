import { gql } from '@apollo/client'

export const pendingMatchFragment = gql`
  fragment PendingMatchFields on PendingMatch {
    id
    courseProgress
    createdAt
    updatedAt
    pendingMatchDate: date
    isNotFound
    status
    platform
  }
`

export const pendingMatchQuery = gql`
  query PendingMatch($id: ID!) {
    pendingMatch(id: $id) {
      ...PendingMatchFields
    }
  }

  ${pendingMatchFragment}
`

export const pendingMatchSubscription = gql`
  subscription PendingMatchUpdated($id: ID!) {
    pendingMatch(id: $id) {
      ...PendingMatchFields
    }
  }

  ${pendingMatchFragment}
`
