import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { DateTime } from 'luxon'
import { Container } from '@mui/material'
import {
  faDiscord,
  faFacebook,
  faInstagram,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SocialMediaIcon } from 'src/system/footer/SocialMediaIcon'
import { useRouting } from 'src/routes'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'

export function Footer() {
  const { t } = useTranslation()
  const { routes } = useRouting()
  const currentYear = DateTime.local()
    .setZone(t('common:iana_time_zone'))
    .toFormat('yyyy')

  return (
    <Box>
      <Container>
        <SocialMediaLinks>
          <SocialMediaIcon
            link={t('social:twitter:link')}
            icon={<FontAwesomeIcon icon={faTwitter} />}
            name={t('social:twitter:name')}
          />
          <SocialMediaIcon
            link={t('social:facebook:link')}
            icon={<FontAwesomeIcon icon={faFacebook} />}
            name={t('social:facebook:name')}
          />
          <SocialMediaIcon
            link={t('social:instagram:link')}
            icon={<FontAwesomeIcon icon={faInstagram} />}
            name={t('social:instagram:name')}
          />
          <SocialMediaIcon
            link={t('social:discord:link')}
            icon={<FontAwesomeIcon icon={faDiscord} />}
            name={t('social:discord:name')}
          />
        </SocialMediaLinks>
        <PageLinks>
          <FooterLink to={t('link:home')}>{t('footer:home')}</FooterLink>
          <FooterLink to={routes.pricing}>{t('footer:pricing')}</FooterLink>
          <FooterLink to={t('link:team')}>{t('footer:team')}</FooterLink>
          <FooterLink to={t('link:contact')}>{t('footer:contact')}</FooterLink>
          <FooterLink to={t('link:terms_of_service')}>
            {t('footer:tos')}
          </FooterLink>
          <FooterLink to={t('link:site_terms')}>
            {t('footer:site_terms')}
          </FooterLink>
          <FooterLink to={t('link:privacy_policy')}>
            {t('footer:privacy')}
          </FooterLink>
          <FooterLink to={routes.cookiesPolicy}>
            {t('footer:cookies_policy')}
          </FooterLink>
        </PageLinks>
        <Disclaimer>
          <p>{t('common:disclaimer')}</p>
          <p>{t('common:copyright', { year: currentYear })}</p>
        </Disclaimer>
      </Container>
    </Box>
  )
}

const Box = styled.div`
  background: ${(props) => props.theme.d.colors.footerBackground};
  border-top: 1px solid ${(props) => props.theme.d.colors.border.light};
  padding: 20px 0 15px 0;
`

const PageLinks = styled.div`
  text-align: center;
  margin-top: ${(props) => props.theme.d.spacing[2]};
`

const FooterLink = styled(AbsoluteLink)`
  color: ${(props) => props.theme.d.colors.text.primary};
  text-transform: capitalize;
  &:not(:last-of-type) {
    &:after {
      content: '•';
      margin: 0 ${(props) => props.theme.d.spacing[4]};
      text-decoration: none;
      display: inline-block;
    }
  }
`

const Disclaimer = styled.div`
  font-family: ${(props) => props.theme.d.font.primary};
  font-weight: 500;
  font-size: 11px;
  line-height: 150%;
  text-align: center;
  max-width: 700px;
  margin: auto;
`

const SocialMediaLinks = styled.div`
  width: 100%;
  text-align: center;
  > a {
    margin: 0 25px;
  }
`
