import type { ReactNode } from 'react'
import styled from '@emotion/styled'

export function Section(props: {
  className?: string
  title: string
  children: ReactNode
}) {
  return (
    <Box className={props.className}>
      <Heading>{props.title}</Heading>
      <Content>{props.children}</Content>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid ${(props) => props.theme.d.colors.border.divider};
  margin: ${(props) =>
      `${props.theme.d.spacing[4]} ${props.theme.d.spacing[4]} ${props.theme.d.spacing[4]}`}
    0;
  padding-right: ${(props) => props.theme.d.spacing[4]};
`

const Content = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
`

const Heading = styled.h6`
  font-family: ${(props) => props.theme.d.font.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  margin: 0 0 ${(props) => props.theme.d.spacing[2]} 0;
`
