import type { Resource } from 'i18next'
import i18n from 'i18next'

import type { Logger } from 'src/lib/analytics/logger'
import type { Sentry } from 'src/lib/analytics/sentry'

const { createInstance } = i18n

export type I18nResources = Resource

type Config = {
  resources: I18nResources
  sentry: Sentry
  log: Logger
}

const language = 'en'
const defaultNamespace = 'common'

export const createI18n = ({ resources, sentry, log }: Config) => {
  const i18n = createInstance()

  const handleMissingKey = (
    i18nLanguages: string[],
    i18nNamespace: string,
    i18nKey: string
  ) => {
    const message = `i18n missing key: ${i18nLanguages}:${i18nNamespace}:${i18nKey}`
    log.warn(message)
    sentry.captureMessage(message, {
      level: 'warning',
      extra: {
        i18nLanguages
      },
      tags: {
        label: 'i18n',
        i18nNamespace,
        i18nKey
      }
    })
  }

  const handleFailedLoading = (
    i18nLanguage: string,
    i18nNamespace: string,
    i18nKey: string
  ) => {
    const message = `i18n failed loading: ${i18nLanguage}:${i18nNamespace}:${i18nKey}`
    log.warn(message)
    sentry.captureMessage(message, {
      tags: {
        label: 'i18n',
        i18nLanguage,
        i18nNamespace,
        i18nKey
      }
    })
  }

  i18n.on('missingKey', handleMissingKey)
  i18n.on('failedLoading', handleFailedLoading)

  const init = () =>
    i18n.init({
      resources,
      saveMissing: true,
      debug: false,
      lng: language,
      fallbackLng: language,
      ns: [defaultNamespace],
      defaultNS: defaultNamespace,
      interpolation: {
        escapeValue: false
      },
      react: {
        useSuspense: false
      }
    })

  return { i18n, init }
}
