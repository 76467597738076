import type { Ref } from 'react'
import { forwardRef } from 'react'
import styled from '@emotion/styled'

import type { LinkProps } from 'src/system/ui/link/types'
import { useLinkStyle } from 'src/system/ui/link/style'

type Props = LinkProps & {
  title?: string
  to: string
  newTab?: boolean
}

export const AbsoluteLink = forwardRef((props: Props, ref: Ref<any>) => {
  const target = props.newTab ? '_blank' : '_self'
  const rel = props.newTab ? 'noopener noreferrer' : ''

  const style = useLinkStyle(props)

  return (
    <StyledAnchor
      ref={ref}
      title={props.title}
      href={props.to}
      underline={style.underline}
      color={style.color}
      className={props.className}
      target={target}
      rel={rel}
    >
      {props.children}
    </StyledAnchor>
  )
})

const StyledAnchor = styled.a<{
  underline?: boolean
  color: string
}>`
  font-size: inherit;
  color: ${(props) => props.color};
  cursor: pointer;
  &:hover {
    ${(props) => (props.underline ? 'text-decoration: underline;' : null)}
  }
`
