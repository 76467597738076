import { DateTime } from 'luxon'

import { useMaybeUser } from 'src/hooks/use-user'
import { MembershipLevel } from 'src/graphql/types'

export function useMembershipEndDate() {
  const { user } = useMaybeUser()
  const endDate = user?.membership?.endDate
  if (endDate == null) return null
  return formatDate(endDate)
}

export function useIsMembershipUpgradable() {
  const { user } = useMaybeUser()

  if (!user?.membership) {
    return true
  }

  if (!user.membership.isActive) {
    return true
  }

  if (user.membership.level === MembershipLevel.Private) {
    return true
  }

  if (user.membership.level === MembershipLevel.Promotional) {
    return true
  }

  return false
}

function formatDate(date: string) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)
}
