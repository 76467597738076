import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import {
  faCheckCircle,
  faCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@mui/system'

import { Sentiment } from 'src/graphql/types'

export function SentimentIcon(props: { value: Sentiment; className?: string }) {
  const styles = useStylesFor(props.value)

  return (
    <FontAwesomeIcon
      className={props.className}
      icon={styles.icon}
      color={styles.color}
      size='lg'
    />
  )
}

function useStylesFor(sentiment: Sentiment): {
  icon: IconDefinition
  color: string
} {
  const theme = useTheme()

  if (sentiment === Sentiment.Positive) {
    return {
      icon: faCheckCircle,
      color: theme.d.colors.success
    }
  }

  if (sentiment === Sentiment.Neutral) {
    return {
      icon: faCircle,
      color: theme.d.colors.text.primary
    }
  }

  if (sentiment === Sentiment.Negative) {
    return {
      icon: faTimesCircle,
      color: theme.d.colors.error
    }
  }

  throw new Error(`Unimplemented sentiment style: ${sentiment}`)
}
