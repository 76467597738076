import type { ReactNode } from 'react'

import { RelativeLink } from 'src/system/ui/link/RelativeLink'

export function MatchLink(props: {
  children: ReactNode
  className?: string
  link?: string | null
  onClick?: () => void
}) {
  if (!props.link) {
    return <div className={props.className}>{props.children}</div>
  }

  return (
    <RelativeLink disableStyles to={props.link} className={props.className}>
      {props.children}
    </RelativeLink>
  )
}
