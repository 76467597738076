import { useState } from 'react'
import { DateTime, Duration } from 'luxon'
import { CircularProgress } from '@mui/material'
import { Refresh as RefreshIcon } from '@mui/icons-material'
import useSound from 'use-sound'
import { useTranslation } from 'react-i18next'

import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'
import { useInterval } from 'src/lib/hooks/use-interval'
import { useMatches, useTriggerAutomatch } from 'src/hooks/use-matches'
import { usePrivateProfile } from 'src/hooks/use-profile'
import { IconButton } from 'src/system/ui/button/IconButton'
import awpReload from 'src/assets/sounds/awp-reload.mp3'
import noAmmo from 'src/assets/sounds/no-ammo.mp3'

const reloadDuration = Duration.fromObject({ minutes: 1 })
const refreshRate = Duration.fromObject({ milliseconds: 100 }).as(
  'milliseconds'
)

export function CheckForMatchesButton(props: {
  cooldown: number
  resetCooldown: () => void
}) {
  const [reloadButtonEnabled] = useFeatureFlags([FeatureFlag.reloadButton])
  const { t } = useTranslation()

  if (!reloadButtonEnabled) {
    return null
  }

  if (props.cooldown === 0) {
    return (
      <ReloadButton
        aria-label={t('matches:reload')}
        resetCooldown={props.resetCooldown}
      />
    )
  }

  return (
    <CooldownButton
      aria-label={t('matches:cooldown')}
      cooldown={props.cooldown}
    />
  )
}

function ReloadButton(props: { resetCooldown: () => void }) {
  const [play] = useSound(awpReload)
  const { refetch: refetchMatches } = useMatches()
  const { refetch: refetchPrivateProfile } = usePrivateProfile()
  const { trigger } = useTriggerAutomatch()
  const handleClick = () => {
    props.resetCooldown()
    trigger('reload_button')
    refetchPrivateProfile()
    refetchMatches()
    play()
  }
  return (
    <IconButton onClick={handleClick}>
      <RefreshIcon />
    </IconButton>
  )
}

function CooldownButton(props: { cooldown: number }) {
  const [play] = useSound(noAmmo)
  const handleClick = () => {
    play()
  }
  return (
    <IconButton onClick={handleClick}>
      <CircularProgress
        size='18px'
        variant='determinate'
        value={props.cooldown * 100}
      />
    </IconButton>
  )
}

export function useCooldown() {
  const [reloadedAt, setReloadedAt] = useState<DateTime | null>(null)
  const [durationSinceReload, setDurationSinceReload] =
    useState<Duration | null>(null)

  const resetCooldown = () => setReloadedAt(DateTime.local())

  useInterval(() => {
    if (reloadedAt == null) return
    setDurationSinceReload(reloadedAt.diffNow(['seconds']))
  }, refreshRate)

  const cooldown =
    durationSinceReload == null
      ? 0
      : Math.max(
          0,
          1 +
            durationSinceReload.as('milliseconds') /
              reloadDuration.as('milliseconds')
        )

  return { cooldown, resetCooldown }
}
