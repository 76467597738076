import { gql } from '@apollo/client'

import { progressionReportFragments } from './progression'

export const profileQuery = gql`
  query Profile {
    profile {
      id
      isPublic
      createdAt
      discord {
        id
        name
        avatarUrl
      }
      faceit {
        id
        name
        avatarUrl
      }
      steam {
        id
        name
        avatarUrl
      }
    }
  }
`

export const privateProfileQuery = gql`
  query PrivateProfile {
    profile {
      id
      isPublic
      createdAt
      steam {
        id
        name
        avatarUrl
      }
      csgo {
        progressionReport(limit: 3) {
          ...ProgressionReportFields
        }
      }
    }
  }

  ${progressionReportFragments}
`

export const publicProfileQuery = gql`
  query PublicProfile($profileId: ID!) {
    profile: publicProfile(id: $profileId) {
      id
      isPublic
      isOwner
      affiliate {
        code
      }
      createdAt
      steam {
        id
        name
        avatarUrl
      }
      csgo {
        progressionReport(limit: 9999) {
          ...ProgressionReportFields
        }
      }
    }
  }

  ${progressionReportFragments}
`
