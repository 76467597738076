import type { LogLevelDesc, Logger as LoggerType } from 'loglevel'
import { getLogger } from 'loglevel'

export type Logger = LoggerType

type Config = {
  name: string
  level?: LogLevelDesc | null
}

export const createLogger = ({ name, level }: Config) => {
  const log = getLogger(name)
  if (level) isProd ? log.setLevel(level) : log.setDefaultLevel(level)
  return {
    log
  }
}

const isProd = process.env.NODE_ENV === 'production'
