import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { useMaybeUser } from 'src/hooks/use-user'
import { MembershipLevel } from 'src/graphql/types'

export function PremiumBadge(props: { className?: string }) {
  const { t } = useTranslation()
  const { user } = useMaybeUser()

  const isMvp = user?.membership?.level === MembershipLevel.Mvp
  return (
    <PremiumText isMvp={isMvp} className={props.className}>
      {t(isMvp ? 'membership:mvp' : 'membership:premium')}
    </PremiumText>
  )
}

const PremiumText = styled.span<{ isMvp: boolean }>`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) =>
    props.isMvp ? props.theme.d.colors.lifetime : props.theme.d.colors.accent};
`
