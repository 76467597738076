import { useAsync } from 'react-async-hook'

import { Redirect } from 'src/routes/Redirect'
import { Meta } from 'src/system/Meta'
import { useRouting } from 'src/routes'
import { useSearchParams } from 'src/lib/hooks/use-search-params'
import { useAuth } from 'src/hooks/use-auth'
import { FullLoading } from 'src/system/FullLoading'

type SearchParams = {
  confirmation_code?: string // eslint-disable-line camelcase
  user_name?: string // eslint-disable-line camelcase
}

export function VerifyAccount() {
  return (
    <>
      <Meta name='verify_account' />
      <HandleVerifyAccount />
    </>
  )
}

function HandleVerifyAccount() {
  const params = useSearchParams<SearchParams>()
  const { toCallbackSuccess, toCallbackFailed } = useRouting()
  const { confirmRegistration } = useAuth()

  const { confirmation_code: confirmationCode, user_name: username } = params

  if (!username || !confirmationCode) throw new Error('Bad verify URL')

  const { loading, error } = useAsync<
    void,
    [string | undefined, string | undefined]
  >(
    async (
      username: string | undefined,
      confirmationCode: string | undefined
    ) => {
      if (username == null || confirmationCode == null) return
      await confirmRegistration(username, confirmationCode)
    },
    [username, confirmationCode]
  )

  if (loading) return <FullLoading />
  if (error != null) return <Redirect to={toCallbackFailed()} />
  return <Redirect to={toCallbackSuccess()} />
}
