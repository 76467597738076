export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: any
  /** The `AWSURL` scalar type provided by AWS AppSync, represents a valid URL string (Ex: <https://www.amazon.com/>). The URL may use any scheme and may also be a local URL (Ex: <http://localhost/>).  URLs without schemes like "**amazon.com**" or "**www.amazon.com**" are considered invalid. URLs which contain double slashes (two consecutive forward slashes) in their path are also considered invalid. */
  AWSURL: any
}

export type Affiliate = {
  __typename?: 'Affiliate'
  code?: Maybe<AffiliateCode>
  id: Scalars['ID']
  isAnonymous: Scalars['Boolean']
  isInception: Scalars['Boolean']
}

export enum AffiliateCode {
  Csconfederation = 'csconfederation',
  Gamersensei = 'gamersensei'
}

export type Assignment = {
  __typename?: 'Assignment'
  body: LocalizedContent
  breakdowns: Array<Breakdown>
  grade: Grade
  id: Scalars['ID']
  marks: Array<Mark>
  resources: Array<Resource>
  title: LocalizedContent
}

export type AssignmentProgression = {
  __typename?: 'AssignmentProgression'
  assignment: Assignment
  department: Department
  id: Scalars['ID']
  scoreTimeseries: MatchProgressionTimeseries
}

export type Automatch = {
  __typename?: 'Automatch'
  checkedAt?: Maybe<Scalars['AWSDateTime']>
  isActive: Scalars['Boolean']
  triggeredAt?: Maybe<Scalars['AWSDateTime']>
}

export type Blind = {
  __typename?: 'Blind'
  attackerIndex: Scalars['String']
  duration: Scalars['Float']
  entityIndex: Scalars['String']
  playerIndex: Scalars['String']
}

export type Bomb = {
  __typename?: 'Bomb'
  playerIndex: Scalars['String']
  state: BombState
}

export enum BombState {
  DefuseAbort = 'defuse_abort',
  DefuseBegin = 'defuse_begin',
  DefuseEnd = 'defuse_end',
  Drop = 'drop',
  Explode = 'explode',
  Give = 'give',
  PlantAbort = 'plant_abort',
  PlantBegin = 'plant_begin',
  PlantEnd = 'plant_end',
  Take = 'take'
}

export type Breakdown = {
  format: BreakdownFormatType
  id: Scalars['ID']
  sentiment: Sentiment
  title: LocalizedContent
}

export type BreakdownAggregate = {
  __typename?: 'BreakdownAggregate'
  negativeCount: Scalars['Int']
  neutralCount: Scalars['Int']
  positiveCount: Scalars['Int']
}

export enum BreakdownFormatType {
  Text = 'text'
}

export type BreakdownText = Breakdown & {
  __typename?: 'BreakdownText'
  body: LocalizedContent
  format: BreakdownFormatType
  id: Scalars['ID']
  sentiment: Sentiment
  title: LocalizedContent
}

export type Changelog = {
  __typename?: 'Changelog'
  notices: Array<Notice>
  releases: Array<Release>
}

export enum Coach {
  Pureskillgg = 'pureskillgg'
}

export type Course = {
  __typename?: 'Course'
  aggregate?: Maybe<CourseAggregate>
  assignments?: Maybe<Array<Assignment>>
  coach: Coach
  department: Department
  isDone: Scalars['Boolean']
  revision: Scalars['Int']
}

export type CourseAggregate = {
  __typename?: 'CourseAggregate'
  assignmentCount: Scalars['Int']
  assignmentScores: Array<Scalars['Float']>
  breakdown: BreakdownAggregate
  breakdownCount: Scalars['Int']
  facetCount: Scalars['Int']
  gpa: Scalars['Float']
  markCount: Scalars['Int']
  resource: ResourceAggregate
  resourceCount: Scalars['Int']
}

export type CsgoMatchScoreboard = {
  __typename?: 'CsgoMatchScoreboard'
  id: Scalars['ID']
  match: Match
  matchId: Scalars['ID']
  playerScoreboards: Array<CsgoPlayerScoreboard>
}

export type CsgoPlayerScoreboard = {
  __typename?: 'CsgoPlayerScoreboard'
  damageStats: DamageStats
  entryStats: EntryStats
  id: Scalars['ID']
  killStats: KillStats
  mainStats: MainStats
  player: Player
  roundsPlayedCount: Scalars['Int']
  utilityStats: UtilityStats
  weapons: Array<WeaponStats>
}

export type CsgoProfile = {
  __typename?: 'CsgoProfile'
  progressionReport: ProgressionReport
  scoreboard: CsgoProfileScoreboard
  steamId: Scalars['ID']
}

export type CsgoProfileProgressionReportArgs = {
  limit?: InputMaybe<Scalars['Int']>
}

export type CsgoProfileScoreboardArgs = {
  limit?: InputMaybe<Scalars['Int']>
}

export type CsgoProfileScoreboard = {
  __typename?: 'CsgoProfileScoreboard'
  id: Scalars['ID']
  matchIds: Array<Scalars['ID']>
  matchScoreboards: Array<CsgoMatchScoreboard>
  playerScoreboard: CsgoPlayerScoreboard
  profileId: Scalars['ID']
}

export type CsgoRoundScoreboard = {
  __typename?: 'CsgoRoundScoreboard'
  id: Scalars['ID']
  matchId: Scalars['ID']
  playerScoreboards: Array<CsgoPlayerScoreboard>
  roundNumber: Scalars['Int']
}

export type DamageStats = {
  __typename?: 'DamageStats'
  dealt: Scalars['Int']
  dealtTeam: Scalars['Int']
  taken: Scalars['Int']
}

export type Death = {
  __typename?: 'Death'
  assisterIndex: Scalars['String']
  attackerIndex: Scalars['String']
  isHeadshot: Scalars['Boolean']
  playerIndex: Scalars['String']
  weapon: Weapon
}

export type DecoyStats = {
  __typename?: 'DecoyStats'
  thrown: Scalars['Int']
}

export enum Department {
  Crosshair = 'crosshair',
  Decisions = 'decisions',
  Dexter = 'dexter',
  Economy = 'economy',
  Grenades = 'grenades',
  Impact = 'impact',
  Movement = 'movement',
  Other = 'other',
  Scoreboard = 'scoreboard',
  Shooting = 'shooting',
  Teamwork = 'teamwork',
  WinProbability = 'win_probability'
}

export type DepartmentProgression = {
  __typename?: 'DepartmentProgression'
  aggregate: CourseAggregate
  department: Department
  gpaTimeseries: MatchProgressionTimeseries
  id: Scalars['ID']
}

export type DiscordProfile = {
  __typename?: 'DiscordProfile'
  avatarUrl?: Maybe<Scalars['AWSURL']>
  id: Scalars['ID']
  name: Scalars['String']
  url: Scalars['AWSURL']
}

export type EntryStats = {
  __typename?: 'EntryStats'
  firstDeath: Scalars['Int']
  firstKill: Scalars['Int']
}

export type FaceitProfile = {
  __typename?: 'FaceitProfile'
  avatarUrl?: Maybe<Scalars['AWSURL']>
  id: Scalars['ID']
  name: Scalars['String']
  url: Scalars['AWSURL']
}

export type Facet = {
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
}

export type FacetBool = Facet & {
  __typename?: 'FacetBool'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: Scalars['Boolean']
}

export type FacetBoolList = Facet & {
  __typename?: 'FacetBoolList'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: Array<Scalars['Boolean']>
}

export type FacetFloat = Facet & {
  __typename?: 'FacetFloat'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: Scalars['Float']
}

export type FacetFloatList = Facet & {
  __typename?: 'FacetFloatList'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: Array<Scalars['Float']>
}

export type FacetHitBox = Facet & {
  __typename?: 'FacetHitBox'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: HitBox
}

export type FacetHitBoxList = Facet & {
  __typename?: 'FacetHitBoxList'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: Array<HitBox>
}

export type FacetId = Facet & {
  __typename?: 'FacetId'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: Scalars['ID']
}

export type FacetIdList = Facet & {
  __typename?: 'FacetIdList'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: Array<Scalars['ID']>
}

export type FacetInt = Facet & {
  __typename?: 'FacetInt'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: Scalars['Int']
}

export type FacetIntList = Facet & {
  __typename?: 'FacetIntList'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: Array<Scalars['Int']>
}

export type FacetSentiment = Facet & {
  __typename?: 'FacetSentiment'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: Sentiment
}

export enum FacetShape {
  Bool = 'bool',
  BoolList = 'bool_list',
  Enum = 'enum',
  EnumList = 'enum_list',
  Float = 'float',
  FloatList = 'float_list',
  Int = 'int',
  IntList = 'int_list',
  Player = 'player',
  PlayerList = 'player_list'
}

export type FacetTeam = Facet & {
  __typename?: 'FacetTeam'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: Team
}

export type FacetTeamList = Facet & {
  __typename?: 'FacetTeamList'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: Array<Team>
}

export enum FacetUnit {
  Armor = 'armor',
  Boolean = 'boolean',
  Count = 'count',
  Health = 'health',
  HitBox = 'hit_box',
  Money = 'money',
  Percent = 'percent',
  Sentiment = 'sentiment',
  SteamId = 'steam_id',
  Team = 'team',
  Weapon = 'weapon'
}

export type FacetWeapon = Facet & {
  __typename?: 'FacetWeapon'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: Weapon
}

export type FacetWeaponList = Facet & {
  __typename?: 'FacetWeaponList'
  id: Scalars['ID']
  label: Scalars['String']
  shape: FacetShape
  unit: FacetUnit
  value: Array<Weapon>
}

export type FlashStats = {
  __typename?: 'FlashStats'
  enemy: Scalars['Int']
  team: Scalars['Int']
  thrown: Scalars['Int']
}

export type Grade = {
  __typename?: 'Grade'
  difficulty: Scalars['Float']
  impact: Scalars['Float']
  relevancy: Scalars['Float']
  score: Scalars['Float']
}

export type Grenade = {
  __typename?: 'Grenade'
  entityIndex: Scalars['String']
  playerIndex: Scalars['String']
  state: GrenadeState
  type: GrenadeType
  xPos: Scalars['Float']
  yPos: Scalars['Float']
}

export enum GrenadeState {
  Detonate = 'detonate',
  Expire = 'expire'
}

export enum GrenadeType {
  Decoy = 'decoy',
  Flash = 'flash',
  He = 'he',
  Molotov = 'molotov',
  Smoke = 'smoke'
}

export type HeStats = {
  __typename?: 'HeStats'
  damageStats: DamageStats
  thrown: Scalars['Int']
}

export enum HitBox {
  ArmLeft = 'arm_left',
  ArmRight = 'arm_right',
  Chest = 'chest',
  Gear = 'gear',
  Generic = 'generic',
  Head = 'head',
  LegLeft = 'leg_left',
  LegRight = 'leg_right',
  Miss = 'miss',
  Stomach = 'stomach'
}

export type Hurt = {
  __typename?: 'Hurt'
  armor: Scalars['Int']
  armorRemoved: Scalars['Int']
  attackerIndex?: Maybe<Scalars['String']>
  health: Scalars['Int']
  healthRemoved: Scalars['Int']
  hitBox?: Maybe<HitBox>
  playerIndex: Scalars['String']
  weapon?: Maybe<Weapon>
}

export type KillStats = {
  __typename?: 'KillStats'
  double: Scalars['Int']
  headshot: Scalars['Int']
  penta: Scalars['Int']
  quad: Scalars['Int']
  self: Scalars['Int']
  single: Scalars['Int']
  team: Scalars['Int']
  triple: Scalars['Int']
}

export enum Language {
  En = 'en'
}

export type LocalizedContent = {
  __typename?: 'LocalizedContent'
  text: Scalars['String']
}

export type LocalizedContentTextArgs = {
  language?: InputMaybe<Language>
}

export type LocalizedUrl = {
  __typename?: 'LocalizedUrl'
  href: Scalars['AWSURL']
  text: Scalars['String']
}

export type LocalizedUrlHrefArgs = {
  language?: InputMaybe<Language>
}

export type LocalizedUrlTextArgs = {
  language?: InputMaybe<Language>
}

export type LocalizedVideo = {
  __typename?: 'LocalizedVideo'
  externalId: Scalars['ID']
  platform: VideoPlatform
  text?: Maybe<Scalars['String']>
}

export type LocalizedVideoExternalIdArgs = {
  language?: InputMaybe<Language>
}

export type LocalizedVideoTextArgs = {
  language?: InputMaybe<Language>
}

export type MainStats = {
  __typename?: 'MainStats'
  assist: Scalars['Int']
  death: Scalars['Int']
  kast: Scalars['Int']
  kastPercent: Scalars['Float']
  kdRatio?: Maybe<Scalars['Float']>
  kill: Scalars['Int']
  survive: Scalars['Int']
  trade: Scalars['Int']
}

export enum Map {
  CsAgency = 'cs_agency',
  CsAgencyScrimmagemap = 'cs_agency_scrimmagemap',
  CsApollo = 'cs_apollo',
  CsApolloScrimmagemap = 'cs_apollo_scrimmagemap',
  CsClimb = 'cs_climb',
  CsClimbScrimmagemap = 'cs_climb_scrimmagemap',
  CsInsertion2 = 'cs_insertion2',
  CsInsertion2Scrimmagemap = 'cs_insertion2_scrimmagemap',
  CsOffice = 'cs_office',
  CsOfficeScrimmagemap = 'cs_office_scrimmagemap',
  DeAncient = 'de_ancient',
  DeAncientScrimmagemap = 'de_ancient_scrimmagemap',
  DeAnubis = 'de_anubis',
  DeAnubisScrimmagemap = 'de_anubis_scrimmagemap',
  DeAssembly = 'de_assembly',
  DeBasalt = 'de_basalt',
  DeBasaltScrimmagemap = 'de_basalt_scrimmagemap',
  DeBreach = 'de_breach',
  DeBreachScrimmagemap = 'de_breach_scrimmagemap',
  DeCache = 'de_cache',
  DeCacheScrimmagemap = 'de_cache_scrimmagemap',
  DeChlorine = 'de_chlorine',
  DeChlorineScrimmagemap = 'de_chlorine_scrimmagemap',
  DeDust2 = 'de_dust2',
  DeDust2Scrimmagemap = 'de_dust2_scrimmagemap',
  DeEngage = 'de_engage',
  DeEngageScrimmagemap = 'de_engage_scrimmagemap',
  DeGrind = 'de_grind',
  DeGrindScrimmagemap = 'de_grind_scrimmagemap',
  DeInferno = 'de_inferno',
  DeInfernoScrimmagemap = 'de_inferno_scrimmagemap',
  DeIris = 'de_iris',
  DeIrisScrimmagemap = 'de_iris_scrimmagemap',
  DeMemento = 'de_memento',
  DeMills = 'de_mills',
  DeMirage = 'de_mirage',
  DeMirageScrimmagemap = 'de_mirage_scrimmagemap',
  DeMocha = 'de_mocha',
  DeMochaScrimmagemap = 'de_mocha_scrimmagemap',
  DeMutiny = 'de_mutiny',
  DeMutinyScrimmagemap = 'de_mutiny_scrimmagemap',
  DeNuke = 'de_nuke',
  DeNukeScrimmagemap = 'de_nuke_scrimmagemap',
  DeOverpass = 'de_overpass',
  DeOverpassScrimmagemap = 'de_overpass_scrimmagemap',
  DeStudio = 'de_studio',
  DeStudioScrimmagemap = 'de_studio_scrimmagemap',
  DeSwamp = 'de_swamp',
  DeSwampScrimmagemap = 'de_swamp_scrimmagemap',
  DeThera = 'de_thera',
  DeTrain = 'de_train',
  DeTrainScrimmagemap = 'de_train_scrimmagemap',
  DeTuscan = 'de_tuscan',
  DeTuscanScrimmagemap = 'de_tuscan_scrimmagemap',
  DeVertigo = 'de_vertigo',
  DeVertigoScrimmagemap = 'de_vertigo_scrimmagemap'
}

export type Mark = {
  __typename?: 'Mark'
  end: Tickstamp
  facets: Array<Facet>
  grade?: Maybe<Grade>
  id: Scalars['ID']
  start: Tickstamp
}

export type Match = {
  __typename?: 'Match'
  courseProgress?: Maybe<Scalars['Float']>
  courses?: Maybe<Array<Course>>
  createdAt: Scalars['AWSDateTime']
  date: Scalars['AWSDateTime']
  demoTickRate: Scalars['Int']
  demoUrl: Scalars['AWSURL']
  id: Scalars['ID']
  isOwner: Scalars['Boolean']
  isShared: Scalars['Boolean']
  map: Map
  platform: MatchPlatform
  platformUrl?: Maybe<Scalars['AWSURL']>
  players: Array<Player>
  providence: MatchProvidence
  resourceId: Scalars['ID']
  round?: Maybe<Round>
  score: MatchScore
  scoreboard?: Maybe<CsgoMatchScoreboard>
  serverTickRate: Scalars['Int']
  sharecode: Scalars['ID']
  sourceId: Scalars['ID']
  totalRounds: Scalars['Int']
  type: MatchType
  updatedAt: Scalars['AWSDateTime']
}

export type MatchRoundArgs = {
  number: Scalars['Int']
}

export type MatchEdge = {
  __typename?: 'MatchEdge'
  cursor?: Maybe<Scalars['ID']>
  node?: Maybe<Match>
}

export enum MatchPlatform {
  Faceit = 'faceit',
  Steam = 'steam',
  Unknown = 'unknown'
}

export type MatchProgressionTimeseries = {
  __typename?: 'MatchProgressionTimeseries'
  matchIds: Array<Scalars['ID']>
  points: Array<MatchProgressionTimeseriesPoint>
  resourceIds: Array<Scalars['ID']>
  trend: TimeseriesLine
  ts: Array<Scalars['AWSDateTime']>
  ys: Array<Scalars['Float']>
}

export type MatchProgressionTimeseriesPoint = {
  __typename?: 'MatchProgressionTimeseriesPoint'
  matchId: Scalars['ID']
  resourceId: Scalars['ID']
  t: Scalars['AWSDateTime']
  y: Scalars['Float']
}

export enum MatchProvidence {
  Adhoc = 'adhoc',
  Auto = 'auto',
  User = 'user'
}

export type MatchScore = {
  __typename?: 'MatchScore'
  ct: Scalars['Int']
  isTie: Scalars['Boolean']
  loser?: Maybe<Team>
  t: Scalars['Int']
  winner?: Maybe<Team>
}

export enum MatchType {
  Competitive = 'competitive',
  Unknown = 'unknown'
}

export type Matches = {
  __typename?: 'Matches'
  edges: Array<Maybe<MatchesEdge>>
  pageInfo: PageInfo
}

export type MatchesEdge = MatchEdge | PendingMatchEdge

export enum MatchmakingModeSteamCsgo {
  Casual = 'casual',
  Competitive = 'competitive',
  DangerZone = 'danger_zone',
  Deathmatch = 'deathmatch',
  WarGames = 'war_games',
  Wingman = 'wingman'
}

export type Membership = {
  __typename?: 'Membership'
  endDate?: Maybe<Scalars['AWSDateTime']>
  isActive: Scalars['Boolean']
  isCanceled: Scalars['Boolean']
  isPaused: Scalars['Boolean']
  isTerminated: Scalars['Boolean']
  isTrial: Scalars['Boolean']
  level: MembershipLevel
  providence: MembershipProvidence
  startDate: Scalars['AWSDateTime']
}

export enum MembershipLevel {
  Mvp = 'mvp',
  Premium = 'premium',
  Private = 'private',
  Promotional = 'promotional'
}

export enum MembershipPlan {
  Annual = 'annual',
  Lifetime = 'lifetime',
  Monthly = 'monthly'
}

export enum MembershipProvidence {
  Internal = 'internal',
  Paypal = 'paypal',
  Promotion = 'promotion',
  Stripe = 'stripe'
}

export type Molotov = {
  __typename?: 'Molotov'
  entityIndex: Scalars['String']
  state: GrenadeState
  type: GrenadeType
  xPos: Scalars['Float']
  yPos: Scalars['Float']
}

export type MolotovStats = {
  __typename?: 'MolotovStats'
  damageStats: DamageStats
  thrown: Scalars['Int']
}

export type Mutation = {
  __typename?: 'Mutation'
  addSharelink: Scalars['String']
  addSteamIdKey?: Maybe<UserLink>
  cancelPaypalMembership: Scalars['ID']
  cancelStripeMembership: Scalars['ID']
  closeSession: Session
  newLinkAuthSession: Scalars['AWSURL']
  newPaypalCheckoutSession: Scalars['AWSURL']
  newPaypalCustomerPortalSession: Scalars['AWSURL']
  newStripeCheckoutSession: Scalars['ID']
  newStripeCustomerPortalSession: Scalars['AWSURL']
  notifyPendingMatch?: Maybe<PendingMatch>
  openSession: Session
  pausePaypalMembership: Scalars['ID']
  removeSteamIdKey?: Maybe<UserLink>
  setMatchesViewMode: UserInterfaceViewMode
  setProfileVisibility: Profile
  triggerAutomatch?: Maybe<Automatch>
  uncancelStripeMembership: Scalars['ID']
  unlink?: Maybe<UserLink>
  unpausePaypalMembership: Scalars['ID']
  uploadDemo: Scalars['AWSURL']
}

export type MutationAddSharelinkArgs = {
  platform: MatchPlatform
  sharelink: Scalars['String']
}

export type MutationAddSteamIdKeyArgs = {
  key: Scalars['String']
  linkId: Scalars['ID']
}

export type MutationNewLinkAuthSessionArgs = {
  callbackUrl: Scalars['AWSURL']
  linkType: UserLinkType
}

export type MutationNewPaypalCheckoutSessionArgs = {
  applyDiscount?: InputMaybe<Scalars['Boolean']>
  callbackUrl: Scalars['AWSURL']
  plan?: InputMaybe<MembershipPlan>
}

export type MutationNewStripeCheckoutSessionArgs = {
  applyDiscount?: InputMaybe<Scalars['Boolean']>
  callbackUrl: Scalars['AWSURL']
  plan?: InputMaybe<MembershipPlan>
}

export type MutationNewStripeCustomerPortalSessionArgs = {
  callbackUrl: Scalars['AWSURL']
}

export type MutationNotifyPendingMatchArgs = {
  input: NotifyPendingMatchInput
  matchId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export type MutationOpenSessionArgs = {
  affiliateCode?: InputMaybe<Scalars['String']>
  userId: Scalars['ID']
}

export type MutationRemoveSteamIdKeyArgs = {
  linkId: Scalars['ID']
}

export type MutationSetMatchesViewModeArgs = {
  viewMode: UserInterfaceViewMode
}

export type MutationSetProfileVisibilityArgs = {
  isPublic: Scalars['Boolean']
}

export type MutationTriggerAutomatchArgs = {
  linkId: Scalars['ID']
}

export type MutationUnlinkArgs = {
  linkId: Scalars['ID']
  linkType: UserLinkType
}

export type Notice = {
  __typename?: 'Notice'
  body?: Maybe<LocalizedContent>
  endDate: Scalars['AWSDateTime']
  severity: Severity
  startDate: Scalars['AWSDateTime']
  title: LocalizedContent
}

export type NotifyPendingMatchInput = {
  courseProgress?: InputMaybe<Scalars['Float']>
  createdAt: Scalars['AWSDateTime']
  errorCode?: InputMaybe<Scalars['String']>
  info?: InputMaybe<Scalars['Boolean']>
  matchDate?: InputMaybe<Scalars['AWSDateTime']>
  platform: MatchPlatform
  providence?: InputMaybe<MatchProvidence>
  sharecode: Scalars['ID']
  updatedAt: Scalars['AWSDateTime']
}

export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor?: Maybe<Scalars['String']>
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  size: Scalars['Int']
}

export type PendingMatch = {
  __typename?: 'PendingMatch'
  courseProgress?: Maybe<Scalars['Float']>
  createdAt: Scalars['AWSDateTime']
  date?: Maybe<Scalars['AWSDateTime']>
  id: Scalars['ID']
  isNotFound?: Maybe<Scalars['Boolean']>
  platform: MatchPlatform
  providence: MatchProvidence
  sharecode: Scalars['ID']
  status: PendingMatchStatus
  updatedAt: Scalars['AWSDateTime']
  userId: Scalars['ID']
}

export type PendingMatchEdge = {
  __typename?: 'PendingMatchEdge'
  cursor?: Maybe<Scalars['ID']>
  node?: Maybe<PendingMatch>
}

export enum PendingMatchStatus {
  Completed = 'completed',
  Created = 'created',
  Failed = 'failed',
  Verified = 'verified'
}

export type Player = {
  __typename?: 'Player'
  clanTag?: Maybe<Scalars['String']>
  index: Scalars['String']
  isOwner: Scalars['Boolean']
  name: Scalars['String']
  rank?: Maybe<Rank>
  startingTeam?: Maybe<Team>
  steamId: Scalars['ID']
  team: Team
  wins: Scalars['Int']
}

export type PlayerStatus = {
  __typename?: 'PlayerStatus'
  armor: Scalars['Int']
  currentAmmo: Scalars['Int']
  hasBomb: Scalars['Boolean']
  hasHelmet: Scalars['Boolean']
  hasKit: Scalars['Boolean']
  health: Scalars['Int']
  inventory: Array<Weapon>
  isDefusing: Scalars['Boolean']
  isFakePlayer: Scalars['Boolean']
  isWalking: Scalars['Boolean']
  money: Scalars['Int']
  playerIndex: Scalars['String']
  zoomLevel: Scalars['Int']
}

export type PlayerVector = {
  __typename?: 'PlayerVector'
  playerIndex: Scalars['String']
  theta: Scalars['Int']
  weapon?: Maybe<Weapon>
  xPos: Scalars['Int']
  yPos: Scalars['Int']
  zPos: Scalars['Int']
}

export type Profile = {
  __typename?: 'Profile'
  affiliate?: Maybe<Affiliate>
  createdAt: Scalars['AWSDateTime']
  csgo?: Maybe<CsgoProfile>
  discord?: Maybe<DiscordProfile>
  faceit?: Maybe<FaceitProfile>
  id: Scalars['ID']
  isOwner: Scalars['Boolean']
  isPublic: Scalars['Boolean']
  steam?: Maybe<SteamProfile>
}

export type ProgressionReport = {
  __typename?: 'ProgressionReport'
  assignmentProgressions: Array<AssignmentProgression>
  departmentProgressions: Array<DepartmentProgression>
  id: Scalars['ID']
  matchCount: Scalars['Int']
}

export type Query = {
  __typename?: 'Query'
  changelog: Changelog
  match?: Maybe<Match>
  matches: Matches
  pendingMatch?: Maybe<PendingMatch>
  profile: Profile
  publicProfile?: Maybe<Profile>
  session: Session
  ui: UserInterfaceState
  user: User
}

export type QueryMatchArgs = {
  id: Scalars['ID']
}

export type QueryMatchesArgs = {
  after?: InputMaybe<Scalars['ID']>
  first?: InputMaybe<Scalars['Int']>
}

export type QueryPendingMatchArgs = {
  id: Scalars['ID']
}

export type QueryPublicProfileArgs = {
  id: Scalars['ID']
}

export enum Rank {
  Dmg = 'dmg',
  Ge = 'ge',
  Gn1 = 'gn1',
  Gn2 = 'gn2',
  Gn3 = 'gn3',
  Gnm = 'gnm',
  Le = 'le',
  Lem = 'lem',
  Lost = 'lost',
  Mg1 = 'mg1',
  Mg2 = 'mg2',
  Mge = 'mge',
  S1 = 's1',
  S2 = 's2',
  S3 = 's3',
  S4 = 's4',
  Se = 'se',
  Sem = 'sem',
  Smfc = 'smfc',
  Unranked = 'unranked'
}

export type Release = {
  __typename?: 'Release'
  date: Scalars['AWSDateTime']
  notes: Array<ReleaseNote>
}

export type ReleaseNote = {
  __typename?: 'ReleaseNote'
  action: ReleaseNoteAction
  title: LocalizedContent
}

export enum ReleaseNoteAction {
  Add = 'add',
  Change = 'change',
  Fix = 'fix',
  Mvp = 'mvp',
  Premium = 'premium',
  Remove = 'remove'
}

export type Resource = {
  createdAt: Scalars['AWSDateTime']
  duration?: Maybe<Scalars['String']>
  format: ResourceFormatType
  id: Scalars['ID']
  isExclusive: Scalars['Boolean']
  isPremium: Scalars['Boolean']
  title: LocalizedContent
}

export type ResourceAggregate = {
  __typename?: 'ResourceAggregate'
  linkCount: Scalars['Int']
  textCount: Scalars['Int']
  youtubeCount: Scalars['Int']
}

export enum ResourceFormatType {
  Link = 'link',
  LinkList = 'link_list',
  Matchmaking = 'matchmaking',
  Text = 'text',
  Workshop = 'workshop',
  Youtube = 'youtube',
  YoutubeList = 'youtube_list'
}

export type ResourceLink = Resource & {
  __typename?: 'ResourceLink'
  createdAt: Scalars['AWSDateTime']
  duration?: Maybe<Scalars['String']>
  format: ResourceFormatType
  id: Scalars['ID']
  isExclusive: Scalars['Boolean']
  isPremium: Scalars['Boolean']
  title: LocalizedContent
  url: LocalizedUrl
}

export type ResourceLinkList = Resource & {
  __typename?: 'ResourceLinkList'
  createdAt: Scalars['AWSDateTime']
  duration?: Maybe<Scalars['String']>
  format: ResourceFormatType
  id: Scalars['ID']
  isExclusive: Scalars['Boolean']
  isPremium: Scalars['Boolean']
  title: LocalizedContent
  urls: Array<LocalizedUrl>
}

export type ResourceMatchmakingSteamCsgo = Resource & {
  __typename?: 'ResourceMatchmakingSteamCsgo'
  body?: Maybe<LocalizedContent>
  createdAt: Scalars['AWSDateTime']
  duration?: Maybe<Scalars['String']>
  format: ResourceFormatType
  id: Scalars['ID']
  isExclusive: Scalars['Boolean']
  isPremium: Scalars['Boolean']
  mode: MatchmakingModeSteamCsgo
  platform: MatchPlatform
  title: LocalizedContent
}

export type ResourceText = Resource & {
  __typename?: 'ResourceText'
  body: LocalizedContent
  createdAt: Scalars['AWSDateTime']
  duration?: Maybe<Scalars['String']>
  format: ResourceFormatType
  id: Scalars['ID']
  isExclusive: Scalars['Boolean']
  isPremium: Scalars['Boolean']
  title: LocalizedContent
}

export type ResourceWorkshopSteamCsgo = Resource & {
  __typename?: 'ResourceWorkshopSteamCsgo'
  body?: Maybe<LocalizedContent>
  createdAt: Scalars['AWSDateTime']
  duration?: Maybe<Scalars['String']>
  externalId: Scalars['ID']
  format: ResourceFormatType
  id: Scalars['ID']
  isExclusive: Scalars['Boolean']
  isPremium: Scalars['Boolean']
  platform: MatchPlatform
  title: LocalizedContent
  video?: Maybe<LocalizedVideo>
}

export type ResourceYoutube = Resource & {
  __typename?: 'ResourceYoutube'
  createdAt: Scalars['AWSDateTime']
  duration?: Maybe<Scalars['String']>
  format: ResourceFormatType
  id: Scalars['ID']
  isExclusive: Scalars['Boolean']
  isPremium: Scalars['Boolean']
  title: LocalizedContent
  video: LocalizedVideo
}

export type ResourceYoutubeList = Resource & {
  __typename?: 'ResourceYoutubeList'
  createdAt: Scalars['AWSDateTime']
  duration?: Maybe<Scalars['String']>
  format: ResourceFormatType
  id: Scalars['ID']
  isExclusive: Scalars['Boolean']
  isPremium: Scalars['Boolean']
  title: LocalizedContent
  videos: Array<LocalizedVideo>
}

export type Round = {
  __typename?: 'Round'
  aggregateScoreboard?: Maybe<CsgoRoundScoreboard>
  endReason: RoundEndReason
  endTick: Scalars['Int']
  number: Scalars['Int']
  officialEndTick?: Maybe<Scalars['Int']>
  players: Array<Player>
  score: RoundScore
  scoreboard?: Maybe<CsgoRoundScoreboard>
  startTick: Scalars['Int']
  tickUrl?: Maybe<Scalars['AWSURL']>
  ticks: Array<Tick>
  winProbabilities: Array<Maybe<WinProbability>>
  winProbabilityUrl?: Maybe<Scalars['AWSURL']>
}

export enum RoundEndReason {
  BombDefused = 'bomb_defused',
  CtSurrender = 'ct_surrender',
  CtWin = 'ct_win',
  HostagesNotRescued = 'hostages_not_rescued',
  HostagesRescued = 'hostages_rescued',
  TSurrender = 't_surrender',
  TWin = 't_win',
  TargetBombed = 'target_bombed',
  TargetSaved = 'target_saved'
}

export type RoundScore = {
  __typename?: 'RoundScore'
  ct: Scalars['Int']
  loser: Team
  t: Scalars['Int']
  winner: Team
}

export enum Sentiment {
  Negative = 'negative',
  Neutral = 'neutral',
  Positive = 'positive'
}

export type Session = {
  __typename?: 'Session'
  affiliateCode?: Maybe<Scalars['String']>
  isAuthenticated: Scalars['Boolean']
  userId?: Maybe<Scalars['ID']>
}

export enum Severity {
  Critical = 'critical',
  Info = 'info',
  Promotional = 'promotional',
  Warning = 'warning'
}

export type SmokeStats = {
  __typename?: 'SmokeStats'
  thrown: Scalars['Int']
}

export type SteamProfile = {
  __typename?: 'SteamProfile'
  avatarUrl: Scalars['AWSURL']
  id: Scalars['ID']
  name: Scalars['String']
  url: Scalars['AWSURL']
}

export type Subscription = {
  __typename?: 'Subscription'
  newPendingMatch?: Maybe<PendingMatch>
  pendingMatch?: Maybe<PendingMatch>
}

export type SubscriptionPendingMatchArgs = {
  id: Scalars['ID']
}

export enum Team {
  Ct = 'ct',
  T = 't'
}

export type Tick = {
  __typename?: 'Tick'
  blinds: Array<Blind>
  bombs: Array<Bomb>
  deaths: Array<Death>
  grenades: Array<Grenade>
  hurts: Array<Hurt>
  molotovs: Array<Molotov>
  number: Scalars['Int']
  playerStatus: Array<PlayerStatus>
  playerVectors: Array<PlayerVector>
  weaponFires: Array<WeaponFire>
}

export type Tickstamp = {
  __typename?: 'Tickstamp'
  round?: Maybe<Scalars['Int']>
  tick?: Maybe<Scalars['Int']>
}

export type TimeseriesLine = {
  __typename?: 'TimeseriesLine'
  intercept: Scalars['Float']
  slope: Scalars['Float']
}

export type User = {
  __typename?: 'User'
  affiliate?: Maybe<Affiliate>
  automatch: Automatch
  isAccountLinked: Scalars['Boolean']
  membership?: Maybe<Membership>
  steamId?: Maybe<Scalars['ID']>
  username: Scalars['String']
}

export type UserInterfaceState = {
  __typename?: 'UserInterfaceState'
  matchesViewMode: UserInterfaceViewMode
}

export enum UserInterfaceViewMode {
  Grid = 'grid',
  List = 'list'
}

export type UserLink = {
  __typename?: 'UserLink'
  linkId: Scalars['ID']
  linkType?: Maybe<UserLinkType>
}

export enum UserLinkType {
  Discord = 'discord',
  Faceit = 'faceit',
  Steam = 'steam'
}

export type UtilityStats = {
  __typename?: 'UtilityStats'
  decoyStats: DecoyStats
  flashStats: FlashStats
  heStats: HeStats
  molotovStats: MolotovStats
  smokeStats: SmokeStats
  zeusStats: ZeusStats
}

export enum VideoPlatform {
  Youtube = 'youtube'
}

export enum Weapon {
  Ak47 = 'ak47',
  Aug = 'aug',
  Awp = 'awp',
  Bayonet = 'bayonet',
  Bizon = 'bizon',
  C4 = 'c4',
  Cz75a = 'cz75a',
  Deagle = 'deagle',
  Decoy = 'decoy',
  DecoyProjectile = 'decoy_projectile',
  Defuser = 'defuser',
  Elite = 'elite',
  Famas = 'famas',
  Fiveseven = 'fiveseven',
  Flashbang = 'flashbang',
  G3sg1 = 'g3sg1',
  Galilar = 'galilar',
  Glock = 'glock',
  Hegrenade = 'hegrenade',
  Hkp2000 = 'hkp2000',
  Incgrenade = 'incgrenade',
  Inferno = 'inferno',
  Knife = 'knife',
  KnifeButterfly = 'knife_butterfly',
  KnifeCanis = 'knife_canis',
  KnifeCss = 'knife_css',
  KnifeFalchion = 'knife_falchion',
  KnifeFlip = 'knife_flip',
  KnifeGut = 'knife_gut',
  KnifeGypsyJackknife = 'knife_gypsy_jackknife',
  KnifeKarambit = 'knife_karambit',
  KnifeM9Bayonet = 'knife_m9_bayonet',
  KnifePush = 'knife_push',
  KnifeStiletto = 'knife_stiletto',
  KnifeSurvivalBowie = 'knife_survival_bowie',
  KnifeT = 'knife_t',
  KnifeTactical = 'knife_tactical',
  KnifeUrsus = 'knife_ursus',
  KnifeWidowmaker = 'knife_widowmaker',
  M4a1 = 'm4a1',
  M4a1Silencer = 'm4a1_silencer',
  M249 = 'm249',
  Mac10 = 'mac10',
  Mag7 = 'mag7',
  Molotov = 'molotov',
  MolotovProjectile = 'molotov_projectile',
  Mp5sd = 'mp5sd',
  Mp7 = 'mp7',
  Mp9 = 'mp9',
  Negev = 'negev',
  Nova = 'nova',
  P90 = 'p90',
  P250 = 'p250',
  Revolver = 'revolver',
  Sawedoff = 'sawedoff',
  Scar20 = 'scar20',
  Sg556 = 'sg556',
  Smokegrenade = 'smokegrenade',
  Ssg08 = 'ssg08',
  Taser = 'taser',
  Tec9 = 'tec9',
  Ump45 = 'ump45',
  UspSilencer = 'usp_silencer',
  Vest = 'vest',
  Vesthelm = 'vesthelm',
  Xm1014 = 'xm1014',
  Zeus = 'zeus'
}

export type WeaponFire = {
  __typename?: 'WeaponFire'
  playerIndex: Scalars['String']
}

export type WeaponStats = {
  __typename?: 'WeaponStats'
  kill: Scalars['Int']
  weapon: Weapon
}

export type WinProbability = {
  __typename?: 'WinProbability'
  ctWinProbability: Scalars['Float']
  tick: Scalars['Int']
}

export type ZeusStats = {
  __typename?: 'ZeusStats'
  damageStats: DamageStats
  used: Scalars['Int']
}

export type ChangelogQueryVariables = Exact<{ [key: string]: never }>

export type ChangelogQuery = {
  __typename?: 'Query'
  changelog: {
    __typename?: 'Changelog'
    notices: Array<{
      __typename?: 'Notice'
      startDate: any
      endDate: any
      severity: Severity
      title: { __typename?: 'LocalizedContent'; text: string }
      body?: { __typename?: 'LocalizedContent'; text: string } | null
    }>
    releases: Array<{
      __typename?: 'Release'
      date: any
      notes: Array<{
        __typename?: 'ReleaseNote'
        action: ReleaseNoteAction
        title: { __typename?: 'LocalizedContent'; text: string }
      }>
    }>
  }
}

export type LocalizedContentFieldsFragment = {
  __typename?: 'LocalizedContent'
  text: string
}

export type LocalizedUrlFieldsFragment = {
  __typename?: 'LocalizedUrl'
  text: string
  href: any
}

export type LocalizedVideoFieldsFragment = {
  __typename?: 'LocalizedVideo'
  text?: string | null
  externalId: string
}

export type BreakdownFieldsFragment = {
  __typename?: 'BreakdownText'
  id: string
  format: BreakdownFormatType
  sentiment: Sentiment
  title: { __typename?: 'LocalizedContent'; text: string }
}

export type BreakdownTextFieldsFragment = {
  __typename?: 'BreakdownText'
  id: string
  format: BreakdownFormatType
  sentiment: Sentiment
  body: { __typename?: 'LocalizedContent'; text: string }
  title: { __typename?: 'LocalizedContent'; text: string }
}

type ResourceFields_ResourceLink_Fragment = {
  __typename?: 'ResourceLink'
  id: string
  format: ResourceFormatType
  createdAt: any
  isExclusive: boolean
  isPremium: boolean
  title: { __typename?: 'LocalizedContent'; text: string }
}

type ResourceFields_ResourceLinkList_Fragment = {
  __typename?: 'ResourceLinkList'
  id: string
  format: ResourceFormatType
  createdAt: any
  isExclusive: boolean
  isPremium: boolean
  title: { __typename?: 'LocalizedContent'; text: string }
}

type ResourceFields_ResourceMatchmakingSteamCsgo_Fragment = {
  __typename?: 'ResourceMatchmakingSteamCsgo'
  id: string
  format: ResourceFormatType
  createdAt: any
  isExclusive: boolean
  isPremium: boolean
  title: { __typename?: 'LocalizedContent'; text: string }
}

type ResourceFields_ResourceText_Fragment = {
  __typename?: 'ResourceText'
  id: string
  format: ResourceFormatType
  createdAt: any
  isExclusive: boolean
  isPremium: boolean
  title: { __typename?: 'LocalizedContent'; text: string }
}

type ResourceFields_ResourceWorkshopSteamCsgo_Fragment = {
  __typename?: 'ResourceWorkshopSteamCsgo'
  id: string
  format: ResourceFormatType
  createdAt: any
  isExclusive: boolean
  isPremium: boolean
  title: { __typename?: 'LocalizedContent'; text: string }
}

type ResourceFields_ResourceYoutube_Fragment = {
  __typename?: 'ResourceYoutube'
  id: string
  format: ResourceFormatType
  createdAt: any
  isExclusive: boolean
  isPremium: boolean
  title: { __typename?: 'LocalizedContent'; text: string }
}

type ResourceFields_ResourceYoutubeList_Fragment = {
  __typename?: 'ResourceYoutubeList'
  id: string
  format: ResourceFormatType
  createdAt: any
  isExclusive: boolean
  isPremium: boolean
  title: { __typename?: 'LocalizedContent'; text: string }
}

export type ResourceFieldsFragment =
  | ResourceFields_ResourceLink_Fragment
  | ResourceFields_ResourceLinkList_Fragment
  | ResourceFields_ResourceMatchmakingSteamCsgo_Fragment
  | ResourceFields_ResourceText_Fragment
  | ResourceFields_ResourceWorkshopSteamCsgo_Fragment
  | ResourceFields_ResourceYoutube_Fragment
  | ResourceFields_ResourceYoutubeList_Fragment

export type ResourceTextFieldsFragment = {
  __typename?: 'ResourceText'
  id: string
  format: ResourceFormatType
  createdAt: any
  isExclusive: boolean
  isPremium: boolean
  body: { __typename?: 'LocalizedContent'; text: string }
  title: { __typename?: 'LocalizedContent'; text: string }
}

export type ResourceLinkFieldsFragment = {
  __typename?: 'ResourceLink'
  id: string
  format: ResourceFormatType
  createdAt: any
  isExclusive: boolean
  isPremium: boolean
  url: { __typename?: 'LocalizedUrl'; text: string; href: any }
  title: { __typename?: 'LocalizedContent'; text: string }
}

export type ResourceLinkListFieldsFragment = {
  __typename?: 'ResourceLinkList'
  id: string
  format: ResourceFormatType
  createdAt: any
  isExclusive: boolean
  isPremium: boolean
  urls: Array<{ __typename?: 'LocalizedUrl'; text: string; href: any }>
  title: { __typename?: 'LocalizedContent'; text: string }
}

export type ResourceYoutubeFieldsFragment = {
  __typename?: 'ResourceYoutube'
  id: string
  format: ResourceFormatType
  createdAt: any
  isExclusive: boolean
  isPremium: boolean
  video: {
    __typename?: 'LocalizedVideo'
    text?: string | null
    externalId: string
  }
  title: { __typename?: 'LocalizedContent'; text: string }
}

export type ResourceYoutubeListFieldsFragment = {
  __typename?: 'ResourceYoutubeList'
  id: string
  format: ResourceFormatType
  createdAt: any
  isExclusive: boolean
  isPremium: boolean
  videos: Array<{
    __typename?: 'LocalizedVideo'
    text?: string | null
    externalId: string
  }>
  title: { __typename?: 'LocalizedContent'; text: string }
}

export type ResourceMatchmakingSteamFieldsCsgoFragment = {
  __typename?: 'ResourceMatchmakingSteamCsgo'
  platform: MatchPlatform
  mode: MatchmakingModeSteamCsgo
  id: string
  format: ResourceFormatType
  createdAt: any
  isExclusive: boolean
  isPremium: boolean
  optionalBody?: { __typename?: 'LocalizedContent'; text: string } | null
  title: { __typename?: 'LocalizedContent'; text: string }
}

export type ResourceWorkshopSteamFieldsCsgoFragment = {
  __typename?: 'ResourceWorkshopSteamCsgo'
  platform: MatchPlatform
  externalId: string
  id: string
  format: ResourceFormatType
  createdAt: any
  isExclusive: boolean
  isPremium: boolean
  optionalBody?: { __typename?: 'LocalizedContent'; text: string } | null
  optionalVideo?: {
    __typename?: 'LocalizedVideo'
    text?: string | null
    externalId: string
  } | null
  title: { __typename?: 'LocalizedContent'; text: string }
}

export type CoursesQueryVariables = Exact<{
  matchId: Scalars['ID']
}>

export type CoursesQuery = {
  __typename?: 'Query'
  match?: {
    __typename?: 'Match'
    id: string
    resourceId: string
    date: any
    map: Map
    isOwner: boolean
    platform: MatchPlatform
    players: Array<{
      __typename?: 'Player'
      name: string
      isOwner: boolean
      steamId: string
      team: Team
    }>
    courses?: Array<{
      __typename?: 'Course'
      isDone: boolean
      coach: Coach
      department: Department
      revision: number
      aggregate?: {
        __typename?: 'CourseAggregate'
        gpa: number
        assignmentScores: Array<number>
      } | null
      assignments?: Array<{
        __typename?: 'Assignment'
        id: string
        title: { __typename?: 'LocalizedContent'; text: string }
        body: { __typename?: 'LocalizedContent'; text: string }
        grade: {
          __typename?: 'Grade'
          difficulty: number
          impact: number
          relevancy: number
          score: number
        }
        marks: Array<{
          __typename?: 'Mark'
          id: string
          start: {
            __typename?: 'Tickstamp'
            round?: number | null
            tick?: number | null
          }
          end: {
            __typename?: 'Tickstamp'
            round?: number | null
            tick?: number | null
          }
          facets: Array<
            | {
                __typename?: 'FacetBool'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                boolValue: boolean
              }
            | {
                __typename?: 'FacetBoolList'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                boolValues: Array<boolean>
              }
            | {
                __typename?: 'FacetFloat'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                floatValue: number
              }
            | {
                __typename?: 'FacetFloatList'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                floatValues: Array<number>
              }
            | {
                __typename?: 'FacetHitBox'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                hitBoxValue: HitBox
              }
            | {
                __typename?: 'FacetHitBoxList'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                hitBoxValues: Array<HitBox>
              }
            | {
                __typename?: 'FacetId'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                idValue: string
              }
            | {
                __typename?: 'FacetIdList'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                idValues: Array<string>
              }
            | {
                __typename?: 'FacetInt'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                intValue: number
              }
            | {
                __typename?: 'FacetIntList'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                intValues: Array<number>
              }
            | {
                __typename?: 'FacetSentiment'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                sentimentValue: Sentiment
              }
            | {
                __typename?: 'FacetTeam'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                teamValue: Team
              }
            | {
                __typename?: 'FacetTeamList'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                teamValues: Array<Team>
              }
            | {
                __typename?: 'FacetWeapon'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                weaponValue: Weapon
              }
            | {
                __typename?: 'FacetWeaponList'
                id: string
                shape: FacetShape
                unit: FacetUnit
                label: string
                weaponValues: Array<Weapon>
              }
          >
        }>
        breakdowns: Array<{
          __typename?: 'BreakdownText'
          id: string
          format: BreakdownFormatType
          sentiment: Sentiment
          body: { __typename?: 'LocalizedContent'; text: string }
          title: { __typename?: 'LocalizedContent'; text: string }
        }>
        resources: Array<
          | {
              __typename?: 'ResourceLink'
              id: string
              format: ResourceFormatType
              createdAt: any
              isExclusive: boolean
              isPremium: boolean
              url: { __typename?: 'LocalizedUrl'; text: string; href: any }
              title: { __typename?: 'LocalizedContent'; text: string }
            }
          | {
              __typename?: 'ResourceLinkList'
              id: string
              format: ResourceFormatType
              createdAt: any
              isExclusive: boolean
              isPremium: boolean
              urls: Array<{
                __typename?: 'LocalizedUrl'
                text: string
                href: any
              }>
              title: { __typename?: 'LocalizedContent'; text: string }
            }
          | {
              __typename?: 'ResourceMatchmakingSteamCsgo'
              platform: MatchPlatform
              mode: MatchmakingModeSteamCsgo
              id: string
              format: ResourceFormatType
              createdAt: any
              isExclusive: boolean
              isPremium: boolean
              optionalBody?: {
                __typename?: 'LocalizedContent'
                text: string
              } | null
              title: { __typename?: 'LocalizedContent'; text: string }
            }
          | {
              __typename?: 'ResourceText'
              id: string
              format: ResourceFormatType
              createdAt: any
              isExclusive: boolean
              isPremium: boolean
              body: { __typename?: 'LocalizedContent'; text: string }
              title: { __typename?: 'LocalizedContent'; text: string }
            }
          | {
              __typename?: 'ResourceWorkshopSteamCsgo'
              platform: MatchPlatform
              externalId: string
              id: string
              format: ResourceFormatType
              createdAt: any
              isExclusive: boolean
              isPremium: boolean
              optionalBody?: {
                __typename?: 'LocalizedContent'
                text: string
              } | null
              optionalVideo?: {
                __typename?: 'LocalizedVideo'
                text?: string | null
                externalId: string
              } | null
              title: { __typename?: 'LocalizedContent'; text: string }
            }
          | {
              __typename?: 'ResourceYoutube'
              id: string
              format: ResourceFormatType
              createdAt: any
              isExclusive: boolean
              isPremium: boolean
              video: {
                __typename?: 'LocalizedVideo'
                text?: string | null
                externalId: string
              }
              title: { __typename?: 'LocalizedContent'; text: string }
            }
          | {
              __typename?: 'ResourceYoutubeList'
              id: string
              format: ResourceFormatType
              createdAt: any
              isExclusive: boolean
              isPremium: boolean
              videos: Array<{
                __typename?: 'LocalizedVideo'
                text?: string | null
                externalId: string
              }>
              title: { __typename?: 'LocalizedContent'; text: string }
            }
        >
      }> | null
    }> | null
  } | null
}

export type CourseFieldsFragment = {
  __typename?: 'Course'
  isDone: boolean
  coach: Coach
  department: Department
  revision: number
  aggregate?: {
    __typename?: 'CourseAggregate'
    gpa: number
    assignmentScores: Array<number>
  } | null
  assignments?: Array<{
    __typename?: 'Assignment'
    id: string
    title: { __typename?: 'LocalizedContent'; text: string }
    body: { __typename?: 'LocalizedContent'; text: string }
    grade: {
      __typename?: 'Grade'
      difficulty: number
      impact: number
      relevancy: number
      score: number
    }
    marks: Array<{
      __typename?: 'Mark'
      id: string
      start: {
        __typename?: 'Tickstamp'
        round?: number | null
        tick?: number | null
      }
      end: {
        __typename?: 'Tickstamp'
        round?: number | null
        tick?: number | null
      }
      facets: Array<
        | {
            __typename?: 'FacetBool'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            boolValue: boolean
          }
        | {
            __typename?: 'FacetBoolList'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            boolValues: Array<boolean>
          }
        | {
            __typename?: 'FacetFloat'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            floatValue: number
          }
        | {
            __typename?: 'FacetFloatList'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            floatValues: Array<number>
          }
        | {
            __typename?: 'FacetHitBox'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            hitBoxValue: HitBox
          }
        | {
            __typename?: 'FacetHitBoxList'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            hitBoxValues: Array<HitBox>
          }
        | {
            __typename?: 'FacetId'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            idValue: string
          }
        | {
            __typename?: 'FacetIdList'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            idValues: Array<string>
          }
        | {
            __typename?: 'FacetInt'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            intValue: number
          }
        | {
            __typename?: 'FacetIntList'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            intValues: Array<number>
          }
        | {
            __typename?: 'FacetSentiment'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            sentimentValue: Sentiment
          }
        | {
            __typename?: 'FacetTeam'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            teamValue: Team
          }
        | {
            __typename?: 'FacetTeamList'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            teamValues: Array<Team>
          }
        | {
            __typename?: 'FacetWeapon'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            weaponValue: Weapon
          }
        | {
            __typename?: 'FacetWeaponList'
            id: string
            shape: FacetShape
            unit: FacetUnit
            label: string
            weaponValues: Array<Weapon>
          }
      >
    }>
    breakdowns: Array<{
      __typename?: 'BreakdownText'
      id: string
      format: BreakdownFormatType
      sentiment: Sentiment
      body: { __typename?: 'LocalizedContent'; text: string }
      title: { __typename?: 'LocalizedContent'; text: string }
    }>
    resources: Array<
      | {
          __typename?: 'ResourceLink'
          id: string
          format: ResourceFormatType
          createdAt: any
          isExclusive: boolean
          isPremium: boolean
          url: { __typename?: 'LocalizedUrl'; text: string; href: any }
          title: { __typename?: 'LocalizedContent'; text: string }
        }
      | {
          __typename?: 'ResourceLinkList'
          id: string
          format: ResourceFormatType
          createdAt: any
          isExclusive: boolean
          isPremium: boolean
          urls: Array<{ __typename?: 'LocalizedUrl'; text: string; href: any }>
          title: { __typename?: 'LocalizedContent'; text: string }
        }
      | {
          __typename?: 'ResourceMatchmakingSteamCsgo'
          platform: MatchPlatform
          mode: MatchmakingModeSteamCsgo
          id: string
          format: ResourceFormatType
          createdAt: any
          isExclusive: boolean
          isPremium: boolean
          optionalBody?: {
            __typename?: 'LocalizedContent'
            text: string
          } | null
          title: { __typename?: 'LocalizedContent'; text: string }
        }
      | {
          __typename?: 'ResourceText'
          id: string
          format: ResourceFormatType
          createdAt: any
          isExclusive: boolean
          isPremium: boolean
          body: { __typename?: 'LocalizedContent'; text: string }
          title: { __typename?: 'LocalizedContent'; text: string }
        }
      | {
          __typename?: 'ResourceWorkshopSteamCsgo'
          platform: MatchPlatform
          externalId: string
          id: string
          format: ResourceFormatType
          createdAt: any
          isExclusive: boolean
          isPremium: boolean
          optionalBody?: {
            __typename?: 'LocalizedContent'
            text: string
          } | null
          optionalVideo?: {
            __typename?: 'LocalizedVideo'
            text?: string | null
            externalId: string
          } | null
          title: { __typename?: 'LocalizedContent'; text: string }
        }
      | {
          __typename?: 'ResourceYoutube'
          id: string
          format: ResourceFormatType
          createdAt: any
          isExclusive: boolean
          isPremium: boolean
          video: {
            __typename?: 'LocalizedVideo'
            text?: string | null
            externalId: string
          }
          title: { __typename?: 'LocalizedContent'; text: string }
        }
      | {
          __typename?: 'ResourceYoutubeList'
          id: string
          format: ResourceFormatType
          createdAt: any
          isExclusive: boolean
          isPremium: boolean
          videos: Array<{
            __typename?: 'LocalizedVideo'
            text?: string | null
            externalId: string
          }>
          title: { __typename?: 'LocalizedContent'; text: string }
        }
    >
  }> | null
}

type FacetFields_FacetBool_Fragment = {
  __typename?: 'FacetBool'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

type FacetFields_FacetBoolList_Fragment = {
  __typename?: 'FacetBoolList'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

type FacetFields_FacetFloat_Fragment = {
  __typename?: 'FacetFloat'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

type FacetFields_FacetFloatList_Fragment = {
  __typename?: 'FacetFloatList'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

type FacetFields_FacetHitBox_Fragment = {
  __typename?: 'FacetHitBox'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

type FacetFields_FacetHitBoxList_Fragment = {
  __typename?: 'FacetHitBoxList'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

type FacetFields_FacetId_Fragment = {
  __typename?: 'FacetId'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

type FacetFields_FacetIdList_Fragment = {
  __typename?: 'FacetIdList'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

type FacetFields_FacetInt_Fragment = {
  __typename?: 'FacetInt'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

type FacetFields_FacetIntList_Fragment = {
  __typename?: 'FacetIntList'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

type FacetFields_FacetSentiment_Fragment = {
  __typename?: 'FacetSentiment'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

type FacetFields_FacetTeam_Fragment = {
  __typename?: 'FacetTeam'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

type FacetFields_FacetTeamList_Fragment = {
  __typename?: 'FacetTeamList'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

type FacetFields_FacetWeapon_Fragment = {
  __typename?: 'FacetWeapon'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

type FacetFields_FacetWeaponList_Fragment = {
  __typename?: 'FacetWeaponList'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
}

export type FacetFieldsFragment =
  | FacetFields_FacetBool_Fragment
  | FacetFields_FacetBoolList_Fragment
  | FacetFields_FacetFloat_Fragment
  | FacetFields_FacetFloatList_Fragment
  | FacetFields_FacetHitBox_Fragment
  | FacetFields_FacetHitBoxList_Fragment
  | FacetFields_FacetId_Fragment
  | FacetFields_FacetIdList_Fragment
  | FacetFields_FacetInt_Fragment
  | FacetFields_FacetIntList_Fragment
  | FacetFields_FacetSentiment_Fragment
  | FacetFields_FacetTeam_Fragment
  | FacetFields_FacetTeamList_Fragment
  | FacetFields_FacetWeapon_Fragment
  | FacetFields_FacetWeaponList_Fragment

export type FacetBoolFieldsFragment = {
  __typename?: 'FacetBool'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  boolValue: boolean
}

export type FacetBoolListFieldsFragment = {
  __typename?: 'FacetBoolList'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  boolValues: Array<boolean>
}

export type FacetIntFieldsFragment = {
  __typename?: 'FacetInt'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  intValue: number
}

export type FacetIntListFieldsFragment = {
  __typename?: 'FacetIntList'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  intValues: Array<number>
}

export type FacetFloatFieldsFragment = {
  __typename?: 'FacetFloat'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  floatValue: number
}

export type FacetFloatListFieldsFragment = {
  __typename?: 'FacetFloatList'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  floatValues: Array<number>
}

export type FacetWeaponFieldsFragment = {
  __typename?: 'FacetWeapon'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  weaponValue: Weapon
}

export type FacetWeaponListFieldsFragment = {
  __typename?: 'FacetWeaponList'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  weaponValues: Array<Weapon>
}

export type FacetTeamFieldsFragment = {
  __typename?: 'FacetTeam'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  teamValue: Team
}

export type FacetTeamListFieldsFragment = {
  __typename?: 'FacetTeamList'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  teamValues: Array<Team>
}

export type FacetIdFieldsFragment = {
  __typename?: 'FacetId'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  idValue: string
}

export type FacetIdListFieldsFragment = {
  __typename?: 'FacetIdList'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  idValues: Array<string>
}

export type FacetHitBoxFieldsFragment = {
  __typename?: 'FacetHitBox'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  hitBoxValue: HitBox
}

export type FacetHitBoxListFieldsFragment = {
  __typename?: 'FacetHitBoxList'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  hitBoxValues: Array<HitBox>
}

export type FacetSentimentFieldsFragment = {
  __typename?: 'FacetSentiment'
  id: string
  shape: FacetShape
  unit: FacetUnit
  label: string
  sentimentValue: Sentiment
}

export type GradeFieldsFragment = {
  __typename?: 'Grade'
  difficulty: number
  impact: number
  relevancy: number
  score: number
}

export type MatchFieldsFragment = {
  __typename?: 'Match'
  id: string
  resourceId: string
  demoUrl: any
  platformUrl?: any | null
  date: any
  createdAt: any
  updatedAt: any
  map: Map
  totalRounds: number
  courseProgress?: number | null
  isOwner: boolean
  platform: MatchPlatform
  players: Array<{
    __typename?: 'Player'
    name: string
    isOwner: boolean
    team: Team
  }>
  score: {
    __typename?: 'MatchScore'
    t: number
    ct: number
    winner?: Team | null
    isTie: boolean
  }
}

export type MatchQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type MatchQuery = {
  __typename?: 'Query'
  match?: {
    __typename?: 'Match'
    id: string
    resourceId: string
    demoUrl: any
    platformUrl?: any | null
    date: any
    createdAt: any
    updatedAt: any
    map: Map
    totalRounds: number
    courseProgress?: number | null
    isOwner: boolean
    platform: MatchPlatform
    players: Array<{
      __typename?: 'Player'
      name: string
      isOwner: boolean
      team: Team
    }>
    score: {
      __typename?: 'MatchScore'
      t: number
      ct: number
      winner?: Team | null
      isTie: boolean
    }
  } | null
}

export type AddSharelinkMutationVariables = Exact<{
  sharelink: Scalars['String']
  platform: MatchPlatform
}>

export type AddSharelinkMutation = {
  __typename?: 'Mutation'
  addSharelink: string
}

export type UploadDemoMutationVariables = Exact<{ [key: string]: never }>

export type UploadDemoMutation = { __typename?: 'Mutation'; uploadDemo: any }

export type MatchesStatusQueryVariables = Exact<{ [key: string]: never }>

export type MatchesStatusQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'User'
    isAccountLinked: boolean
    username: string
    steamId?: string | null
    affiliate?: { __typename?: 'Affiliate'; code?: AffiliateCode | null } | null
    automatch: {
      __typename?: 'Automatch'
      isActive: boolean
      checkedAt?: any | null
      triggeredAt?: any | null
    }
    membership?: {
      __typename?: 'Membership'
      level: MembershipLevel
      isTrial: boolean
      startDate: any
      endDate?: any | null
      isActive: boolean
      isCanceled: boolean
      isPaused: boolean
      isTerminated: boolean
      providence: MembershipProvidence
    } | null
  }
  latestMatches: {
    __typename?: 'Matches'
    edges: Array<
      | {
          __typename?: 'MatchEdge'
          node?: {
            __typename?: 'Match'
            id: string
            resourceId: string
            platform: MatchPlatform
            createdAt: any
          } | null
        }
      | {
          __typename?: 'PendingMatchEdge'
          node?: {
            __typename?: 'PendingMatch'
            id: string
            createdAt: any
          } | null
        }
      | null
    >
  }
}

export type MatchesQueryVariables = Exact<{
  pageSize: Scalars['Int']
  cursor?: InputMaybe<Scalars['ID']>
}>

export type MatchesQuery = {
  __typename?: 'Query'
  matches: {
    __typename?: 'Matches'
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
    }
    edges: Array<
      | {
          __typename?: 'MatchEdge'
          node?: {
            __typename?: 'Match'
            id: string
            resourceId: string
            demoUrl: any
            platformUrl?: any | null
            date: any
            createdAt: any
            updatedAt: any
            map: Map
            totalRounds: number
            courseProgress?: number | null
            isOwner: boolean
            platform: MatchPlatform
            players: Array<{
              __typename?: 'Player'
              name: string
              isOwner: boolean
              team: Team
            }>
            score: {
              __typename?: 'MatchScore'
              t: number
              ct: number
              winner?: Team | null
              isTie: boolean
            }
          } | null
        }
      | {
          __typename?: 'PendingMatchEdge'
          node?: {
            __typename?: 'PendingMatch'
            id: string
            courseProgress?: number | null
            createdAt: any
            updatedAt: any
            isNotFound?: boolean | null
            status: PendingMatchStatus
            platform: MatchPlatform
            pendingMatchDate?: any | null
          } | null
        }
      | null
    >
  }
}

export type NewPendingMatchSubscriptionVariables = Exact<{
  [key: string]: never
}>

export type NewPendingMatchSubscription = {
  __typename?: 'Subscription'
  newPendingMatch?: { __typename?: 'PendingMatch'; id: string } | null
}

export type PendingMatchFieldsFragment = {
  __typename?: 'PendingMatch'
  id: string
  courseProgress?: number | null
  createdAt: any
  updatedAt: any
  isNotFound?: boolean | null
  status: PendingMatchStatus
  platform: MatchPlatform
  pendingMatchDate?: any | null
}

export type PendingMatchQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type PendingMatchQuery = {
  __typename?: 'Query'
  pendingMatch?: {
    __typename?: 'PendingMatch'
    id: string
    courseProgress?: number | null
    createdAt: any
    updatedAt: any
    isNotFound?: boolean | null
    status: PendingMatchStatus
    platform: MatchPlatform
    pendingMatchDate?: any | null
  } | null
}

export type PendingMatchUpdatedSubscriptionVariables = Exact<{
  id: Scalars['ID']
}>

export type PendingMatchUpdatedSubscription = {
  __typename?: 'Subscription'
  pendingMatch?: {
    __typename?: 'PendingMatch'
    id: string
    courseProgress?: number | null
    createdAt: any
    updatedAt: any
    isNotFound?: boolean | null
    status: PendingMatchStatus
    platform: MatchPlatform
    pendingMatchDate?: any | null
  } | null
}

export type ProfileQueryVariables = Exact<{ [key: string]: never }>

export type ProfileQuery = {
  __typename?: 'Query'
  profile: {
    __typename?: 'Profile'
    id: string
    isPublic: boolean
    createdAt: any
    discord?: {
      __typename?: 'DiscordProfile'
      id: string
      name: string
      avatarUrl?: any | null
    } | null
    faceit?: {
      __typename?: 'FaceitProfile'
      id: string
      name: string
      avatarUrl?: any | null
    } | null
    steam?: {
      __typename?: 'SteamProfile'
      id: string
      name: string
      avatarUrl: any
    } | null
  }
}

export type PrivateProfileQueryVariables = Exact<{ [key: string]: never }>

export type PrivateProfileQuery = {
  __typename?: 'Query'
  profile: {
    __typename?: 'Profile'
    id: string
    isPublic: boolean
    createdAt: any
    steam?: {
      __typename?: 'SteamProfile'
      id: string
      name: string
      avatarUrl: any
    } | null
    csgo?: {
      __typename?: 'CsgoProfile'
      progressionReport: {
        __typename?: 'ProgressionReport'
        id: string
        matchCount: number
        assignmentProgressions: Array<{
          __typename?: 'AssignmentProgression'
          id: string
          department: Department
          scoreTimeseries: {
            __typename?: 'MatchProgressionTimeseries'
            matchIds: Array<string>
            resourceIds: Array<string>
            ts: Array<any>
            ys: Array<number>
            points: Array<{
              __typename?: 'MatchProgressionTimeseriesPoint'
              t: any
              y: number
              matchId: string
              resourceId: string
            }>
            trend: {
              __typename?: 'TimeseriesLine'
              slope: number
              intercept: number
            }
          }
          assignment: {
            __typename?: 'Assignment'
            id: string
            title: { __typename?: 'LocalizedContent'; text: string }
            body: { __typename?: 'LocalizedContent'; text: string }
            grade: { __typename?: 'Grade'; score: number }
            breakdowns: Array<{
              __typename?: 'BreakdownText'
              id: string
              format: BreakdownFormatType
              sentiment: Sentiment
              body: { __typename?: 'LocalizedContent'; text: string }
              title: { __typename?: 'LocalizedContent'; text: string }
            }>
            resources: Array<
              | {
                  __typename?: 'ResourceLink'
                  id: string
                  format: ResourceFormatType
                  createdAt: any
                  isExclusive: boolean
                  isPremium: boolean
                  url: { __typename?: 'LocalizedUrl'; text: string; href: any }
                  title: { __typename?: 'LocalizedContent'; text: string }
                }
              | {
                  __typename?: 'ResourceLinkList'
                  id: string
                  format: ResourceFormatType
                  createdAt: any
                  isExclusive: boolean
                  isPremium: boolean
                  urls: Array<{
                    __typename?: 'LocalizedUrl'
                    text: string
                    href: any
                  }>
                  title: { __typename?: 'LocalizedContent'; text: string }
                }
              | {
                  __typename?: 'ResourceMatchmakingSteamCsgo'
                  platform: MatchPlatform
                  mode: MatchmakingModeSteamCsgo
                  id: string
                  format: ResourceFormatType
                  createdAt: any
                  isExclusive: boolean
                  isPremium: boolean
                  optionalBody?: {
                    __typename?: 'LocalizedContent'
                    text: string
                  } | null
                  title: { __typename?: 'LocalizedContent'; text: string }
                }
              | {
                  __typename?: 'ResourceText'
                  id: string
                  format: ResourceFormatType
                  createdAt: any
                  isExclusive: boolean
                  isPremium: boolean
                  body: { __typename?: 'LocalizedContent'; text: string }
                  title: { __typename?: 'LocalizedContent'; text: string }
                }
              | {
                  __typename?: 'ResourceWorkshopSteamCsgo'
                  platform: MatchPlatform
                  externalId: string
                  id: string
                  format: ResourceFormatType
                  createdAt: any
                  isExclusive: boolean
                  isPremium: boolean
                  optionalBody?: {
                    __typename?: 'LocalizedContent'
                    text: string
                  } | null
                  optionalVideo?: {
                    __typename?: 'LocalizedVideo'
                    text?: string | null
                    externalId: string
                  } | null
                  title: { __typename?: 'LocalizedContent'; text: string }
                }
              | {
                  __typename?: 'ResourceYoutube'
                  id: string
                  format: ResourceFormatType
                  createdAt: any
                  isExclusive: boolean
                  isPremium: boolean
                  video: {
                    __typename?: 'LocalizedVideo'
                    text?: string | null
                    externalId: string
                  }
                  title: { __typename?: 'LocalizedContent'; text: string }
                }
              | {
                  __typename?: 'ResourceYoutubeList'
                  id: string
                  format: ResourceFormatType
                  createdAt: any
                  isExclusive: boolean
                  isPremium: boolean
                  videos: Array<{
                    __typename?: 'LocalizedVideo'
                    text?: string | null
                    externalId: string
                  }>
                  title: { __typename?: 'LocalizedContent'; text: string }
                }
            >
          }
        }>
      }
    } | null
  }
}

export type PublicProfileQueryVariables = Exact<{
  profileId: Scalars['ID']
}>

export type PublicProfileQuery = {
  __typename?: 'Query'
  profile?: {
    __typename?: 'Profile'
    id: string
    isPublic: boolean
    isOwner: boolean
    createdAt: any
    affiliate?: { __typename?: 'Affiliate'; code?: AffiliateCode | null } | null
    steam?: {
      __typename?: 'SteamProfile'
      id: string
      name: string
      avatarUrl: any
    } | null
    csgo?: {
      __typename?: 'CsgoProfile'
      progressionReport: {
        __typename?: 'ProgressionReport'
        id: string
        matchCount: number
        assignmentProgressions: Array<{
          __typename?: 'AssignmentProgression'
          id: string
          department: Department
          scoreTimeseries: {
            __typename?: 'MatchProgressionTimeseries'
            matchIds: Array<string>
            resourceIds: Array<string>
            ts: Array<any>
            ys: Array<number>
            points: Array<{
              __typename?: 'MatchProgressionTimeseriesPoint'
              t: any
              y: number
              matchId: string
              resourceId: string
            }>
            trend: {
              __typename?: 'TimeseriesLine'
              slope: number
              intercept: number
            }
          }
          assignment: {
            __typename?: 'Assignment'
            id: string
            title: { __typename?: 'LocalizedContent'; text: string }
            body: { __typename?: 'LocalizedContent'; text: string }
            grade: { __typename?: 'Grade'; score: number }
            breakdowns: Array<{
              __typename?: 'BreakdownText'
              id: string
              format: BreakdownFormatType
              sentiment: Sentiment
              body: { __typename?: 'LocalizedContent'; text: string }
              title: { __typename?: 'LocalizedContent'; text: string }
            }>
            resources: Array<
              | {
                  __typename?: 'ResourceLink'
                  id: string
                  format: ResourceFormatType
                  createdAt: any
                  isExclusive: boolean
                  isPremium: boolean
                  url: { __typename?: 'LocalizedUrl'; text: string; href: any }
                  title: { __typename?: 'LocalizedContent'; text: string }
                }
              | {
                  __typename?: 'ResourceLinkList'
                  id: string
                  format: ResourceFormatType
                  createdAt: any
                  isExclusive: boolean
                  isPremium: boolean
                  urls: Array<{
                    __typename?: 'LocalizedUrl'
                    text: string
                    href: any
                  }>
                  title: { __typename?: 'LocalizedContent'; text: string }
                }
              | {
                  __typename?: 'ResourceMatchmakingSteamCsgo'
                  platform: MatchPlatform
                  mode: MatchmakingModeSteamCsgo
                  id: string
                  format: ResourceFormatType
                  createdAt: any
                  isExclusive: boolean
                  isPremium: boolean
                  optionalBody?: {
                    __typename?: 'LocalizedContent'
                    text: string
                  } | null
                  title: { __typename?: 'LocalizedContent'; text: string }
                }
              | {
                  __typename?: 'ResourceText'
                  id: string
                  format: ResourceFormatType
                  createdAt: any
                  isExclusive: boolean
                  isPremium: boolean
                  body: { __typename?: 'LocalizedContent'; text: string }
                  title: { __typename?: 'LocalizedContent'; text: string }
                }
              | {
                  __typename?: 'ResourceWorkshopSteamCsgo'
                  platform: MatchPlatform
                  externalId: string
                  id: string
                  format: ResourceFormatType
                  createdAt: any
                  isExclusive: boolean
                  isPremium: boolean
                  optionalBody?: {
                    __typename?: 'LocalizedContent'
                    text: string
                  } | null
                  optionalVideo?: {
                    __typename?: 'LocalizedVideo'
                    text?: string | null
                    externalId: string
                  } | null
                  title: { __typename?: 'LocalizedContent'; text: string }
                }
              | {
                  __typename?: 'ResourceYoutube'
                  id: string
                  format: ResourceFormatType
                  createdAt: any
                  isExclusive: boolean
                  isPremium: boolean
                  video: {
                    __typename?: 'LocalizedVideo'
                    text?: string | null
                    externalId: string
                  }
                  title: { __typename?: 'LocalizedContent'; text: string }
                }
              | {
                  __typename?: 'ResourceYoutubeList'
                  id: string
                  format: ResourceFormatType
                  createdAt: any
                  isExclusive: boolean
                  isPremium: boolean
                  videos: Array<{
                    __typename?: 'LocalizedVideo'
                    text?: string | null
                    externalId: string
                  }>
                  title: { __typename?: 'LocalizedContent'; text: string }
                }
            >
          }
        }>
      }
    } | null
  } | null
}

export type CourseProgressionQueryVariables = Exact<{
  pageSize: Scalars['Int']
}>

export type CourseProgressionQuery = {
  __typename?: 'Query'
  progression: {
    __typename?: 'Matches'
    edges: Array<
      | {
          __typename?: 'MatchEdge'
          node?: {
            __typename?: 'Match'
            id: string
            resourceId: string
            date: any
            map: Map
            courses?: Array<{
              __typename?: 'Course'
              isDone: boolean
              coach: Coach
              department: Department
              revision: number
              aggregate?: { __typename?: 'CourseAggregate'; gpa: number } | null
            }> | null
          } | null
        }
      | { __typename?: 'PendingMatchEdge' }
      | null
    >
  }
}

export type MatchProgressionFieldsFragment = {
  __typename?: 'Match'
  id: string
  resourceId: string
  date: any
  map: Map
  courses?: Array<{
    __typename?: 'Course'
    isDone: boolean
    coach: Coach
    department: Department
    revision: number
    aggregate?: { __typename?: 'CourseAggregate'; gpa: number } | null
  }> | null
}

export type ProgressionReportFieldsFragment = {
  __typename?: 'ProgressionReport'
  id: string
  matchCount: number
  assignmentProgressions: Array<{
    __typename?: 'AssignmentProgression'
    id: string
    department: Department
    scoreTimeseries: {
      __typename?: 'MatchProgressionTimeseries'
      matchIds: Array<string>
      resourceIds: Array<string>
      ts: Array<any>
      ys: Array<number>
      points: Array<{
        __typename?: 'MatchProgressionTimeseriesPoint'
        t: any
        y: number
        matchId: string
        resourceId: string
      }>
      trend: { __typename?: 'TimeseriesLine'; slope: number; intercept: number }
    }
    assignment: {
      __typename?: 'Assignment'
      id: string
      title: { __typename?: 'LocalizedContent'; text: string }
      body: { __typename?: 'LocalizedContent'; text: string }
      grade: { __typename?: 'Grade'; score: number }
      breakdowns: Array<{
        __typename?: 'BreakdownText'
        id: string
        format: BreakdownFormatType
        sentiment: Sentiment
        body: { __typename?: 'LocalizedContent'; text: string }
        title: { __typename?: 'LocalizedContent'; text: string }
      }>
      resources: Array<
        | {
            __typename?: 'ResourceLink'
            id: string
            format: ResourceFormatType
            createdAt: any
            isExclusive: boolean
            isPremium: boolean
            url: { __typename?: 'LocalizedUrl'; text: string; href: any }
            title: { __typename?: 'LocalizedContent'; text: string }
          }
        | {
            __typename?: 'ResourceLinkList'
            id: string
            format: ResourceFormatType
            createdAt: any
            isExclusive: boolean
            isPremium: boolean
            urls: Array<{
              __typename?: 'LocalizedUrl'
              text: string
              href: any
            }>
            title: { __typename?: 'LocalizedContent'; text: string }
          }
        | {
            __typename?: 'ResourceMatchmakingSteamCsgo'
            platform: MatchPlatform
            mode: MatchmakingModeSteamCsgo
            id: string
            format: ResourceFormatType
            createdAt: any
            isExclusive: boolean
            isPremium: boolean
            optionalBody?: {
              __typename?: 'LocalizedContent'
              text: string
            } | null
            title: { __typename?: 'LocalizedContent'; text: string }
          }
        | {
            __typename?: 'ResourceText'
            id: string
            format: ResourceFormatType
            createdAt: any
            isExclusive: boolean
            isPremium: boolean
            body: { __typename?: 'LocalizedContent'; text: string }
            title: { __typename?: 'LocalizedContent'; text: string }
          }
        | {
            __typename?: 'ResourceWorkshopSteamCsgo'
            platform: MatchPlatform
            externalId: string
            id: string
            format: ResourceFormatType
            createdAt: any
            isExclusive: boolean
            isPremium: boolean
            optionalBody?: {
              __typename?: 'LocalizedContent'
              text: string
            } | null
            optionalVideo?: {
              __typename?: 'LocalizedVideo'
              text?: string | null
              externalId: string
            } | null
            title: { __typename?: 'LocalizedContent'; text: string }
          }
        | {
            __typename?: 'ResourceYoutube'
            id: string
            format: ResourceFormatType
            createdAt: any
            isExclusive: boolean
            isPremium: boolean
            video: {
              __typename?: 'LocalizedVideo'
              text?: string | null
              externalId: string
            }
            title: { __typename?: 'LocalizedContent'; text: string }
          }
        | {
            __typename?: 'ResourceYoutubeList'
            id: string
            format: ResourceFormatType
            createdAt: any
            isExclusive: boolean
            isPremium: boolean
            videos: Array<{
              __typename?: 'LocalizedVideo'
              text?: string | null
              externalId: string
            }>
            title: { __typename?: 'LocalizedContent'; text: string }
          }
      >
    }
  }>
}

export type RoundQueryVariables = Exact<{
  matchId: Scalars['ID']
  roundNumber: Scalars['Int']
}>

export type RoundQuery = {
  __typename?: 'Query'
  match?: {
    __typename?: 'Match'
    id: string
    resourceId: string
    sharecode: string
    type: MatchType
    platform: MatchPlatform
    sourceId: string
    date: any
    map: Map
    serverTickRate: number
    demoTickRate: number
    totalRounds: number
    score: {
      __typename?: 'MatchScore'
      t: number
      ct: number
      winner?: Team | null
      loser?: Team | null
      isTie: boolean
    }
    players: Array<{
      __typename?: 'Player'
      index: string
      name: string
      rank?: Rank | null
      team: Team
      wins: number
      clanTag?: string | null
      steamId: string
      isOwner: boolean
    }>
    round?: {
      __typename?: 'Round'
      number: number
      startTick: number
      endTick: number
      officialEndTick?: number | null
      endReason: RoundEndReason
      tickUrl?: any | null
      winProbabilityUrl?: any | null
      score: {
        __typename?: 'RoundScore'
        t: number
        ct: number
        winner: Team
        loser: Team
      }
      players: Array<{
        __typename?: 'Player'
        index: string
        name: string
        rank?: Rank | null
        team: Team
        wins: number
        clanTag?: string | null
        steamId: string
        isOwner: boolean
      }>
    } | null
  } | null
}

export type RoundPlayerFieldsFragment = {
  __typename?: 'Player'
  index: string
  name: string
  rank?: Rank | null
  team: Team
  wins: number
  clanTag?: string | null
  steamId: string
  isOwner: boolean
}

export type ScoreboardQueryVariables = Exact<{
  matchId: Scalars['ID']
  roundNumber: Scalars['Int']
}>

export type ScoreboardQuery = {
  __typename?: 'Query'
  match?: {
    __typename?: 'Match'
    round?: {
      __typename?: 'Round'
      aggregateScoreboard?: {
        __typename?: 'CsgoRoundScoreboard'
        id: string
        playerScoreboards: Array<{
          __typename?: 'CsgoPlayerScoreboard'
          id: string
          roundsPlayedCount: number
          player: {
            __typename?: 'Player'
            index: string
            startingTeam?: Team | null
            clanTag?: string | null
            name: string
            rank?: Rank | null
            steamId: string
            team: Team
            wins: number
            isOwner: boolean
          }
          mainStats: {
            __typename?: 'MainStats'
            kill: number
            assist: number
            trade: number
            survive: number
            death: number
            kdRatio?: number | null
            kast: number
            kastPercent: number
          }
          killStats: {
            __typename?: 'KillStats'
            team: number
            self: number
            headshot: number
            single: number
            double: number
            triple: number
            quad: number
            penta: number
          }
          damageStats: {
            __typename?: 'DamageStats'
            dealt: number
            dealtTeam: number
            taken: number
          }
          entryStats: {
            __typename?: 'EntryStats'
            firstDeath: number
            firstKill: number
          }
          utilityStats: {
            __typename?: 'UtilityStats'
            flashStats: {
              __typename?: 'FlashStats'
              thrown: number
              enemy: number
              team: number
            }
            smokeStats: { __typename?: 'SmokeStats'; thrown: number }
            heStats: {
              __typename?: 'HeStats'
              thrown: number
              damageStats: {
                __typename?: 'DamageStats'
                dealt: number
                dealtTeam: number
                taken: number
              }
            }
            molotovStats: {
              __typename?: 'MolotovStats'
              thrown: number
              damageStats: {
                __typename?: 'DamageStats'
                dealt: number
                dealtTeam: number
                taken: number
              }
            }
            decoyStats: { __typename?: 'DecoyStats'; thrown: number }
            zeusStats: {
              __typename?: 'ZeusStats'
              used: number
              damageStats: {
                __typename?: 'DamageStats'
                dealt: number
                dealtTeam: number
                taken: number
              }
            }
          }
          weapons: Array<{
            __typename?: 'WeaponStats'
            weapon: Weapon
            kill: number
          }>
        }>
      } | null
    } | null
  } | null
}

export type ScoreboardFieldsFragment = {
  __typename?: 'CsgoRoundScoreboard'
  id: string
  playerScoreboards: Array<{
    __typename?: 'CsgoPlayerScoreboard'
    id: string
    roundsPlayedCount: number
    player: {
      __typename?: 'Player'
      index: string
      startingTeam?: Team | null
      clanTag?: string | null
      name: string
      rank?: Rank | null
      steamId: string
      team: Team
      wins: number
      isOwner: boolean
    }
    mainStats: {
      __typename?: 'MainStats'
      kill: number
      assist: number
      trade: number
      survive: number
      death: number
      kdRatio?: number | null
      kast: number
      kastPercent: number
    }
    killStats: {
      __typename?: 'KillStats'
      team: number
      self: number
      headshot: number
      single: number
      double: number
      triple: number
      quad: number
      penta: number
    }
    damageStats: {
      __typename?: 'DamageStats'
      dealt: number
      dealtTeam: number
      taken: number
    }
    entryStats: {
      __typename?: 'EntryStats'
      firstDeath: number
      firstKill: number
    }
    utilityStats: {
      __typename?: 'UtilityStats'
      flashStats: {
        __typename?: 'FlashStats'
        thrown: number
        enemy: number
        team: number
      }
      smokeStats: { __typename?: 'SmokeStats'; thrown: number }
      heStats: {
        __typename?: 'HeStats'
        thrown: number
        damageStats: {
          __typename?: 'DamageStats'
          dealt: number
          dealtTeam: number
          taken: number
        }
      }
      molotovStats: {
        __typename?: 'MolotovStats'
        thrown: number
        damageStats: {
          __typename?: 'DamageStats'
          dealt: number
          dealtTeam: number
          taken: number
        }
      }
      decoyStats: { __typename?: 'DecoyStats'; thrown: number }
      zeusStats: {
        __typename?: 'ZeusStats'
        used: number
        damageStats: {
          __typename?: 'DamageStats'
          dealt: number
          dealtTeam: number
          taken: number
        }
      }
    }
    weapons: Array<{ __typename?: 'WeaponStats'; weapon: Weapon; kill: number }>
  }>
}

export type OpenSessionMutationVariables = Exact<{
  userId: Scalars['ID']
  affiliateCode?: InputMaybe<Scalars['String']>
}>

export type OpenSessionMutation = {
  __typename?: 'Mutation'
  openSession: {
    __typename?: 'Session'
    userId?: string | null
    isAuthenticated: boolean
  }
}

export type CloseSessionMutationVariables = Exact<{ [key: string]: never }>

export type CloseSessionMutation = {
  __typename?: 'Mutation'
  closeSession: {
    __typename?: 'Session'
    userId?: string | null
    isAuthenticated: boolean
  }
}

export type SessionQueryVariables = Exact<{ [key: string]: never }>

export type SessionQuery = {
  __typename?: 'Query'
  session: {
    __typename?: 'Session'
    userId?: string | null
    affiliateCode?: string | null
    isAuthenticated: boolean
  }
}

export type TicksQueryVariables = Exact<{
  matchId: Scalars['ID']
  round: Scalars['Int']
}>

export type TicksQuery = {
  __typename?: 'Query'
  match?: {
    __typename?: 'Match'
    round?: {
      __typename?: 'Round'
      ticks: Array<{
        __typename?: 'Tick'
        number: number
        playerVectors: Array<{
          __typename?: 'PlayerVector'
          playerIndex: string
          xPos: number
          yPos: number
          theta: number
          weapon?: Weapon | null
        }>
        playerStatus: Array<{
          __typename?: 'PlayerStatus'
          playerIndex: string
          armor: number
          currentAmmo: number
          hasBomb: boolean
          hasHelmet: boolean
          hasKit: boolean
          health: number
          inventory: Array<Weapon>
          isDefusing: boolean
          isFakePlayer: boolean
          isWalking: boolean
          money: number
          zoomLevel: number
        }>
        weaponFires: Array<{ __typename?: 'WeaponFire'; playerIndex: string }>
        grenades: Array<{
          __typename?: 'Grenade'
          type: GrenadeType
          state: GrenadeState
          entityIndex: string
          playerIndex: string
          xPos: number
          yPos: number
        }>
        molotovs: Array<{
          __typename?: 'Molotov'
          type: GrenadeType
          state: GrenadeState
          entityIndex: string
          xPos: number
          yPos: number
        }>
        blinds: Array<{
          __typename?: 'Blind'
          entityIndex: string
          playerIndex: string
          attackerIndex: string
          duration: number
        }>
        hurts: Array<{
          __typename?: 'Hurt'
          playerIndex: string
          attackerIndex?: string | null
          hitBox?: HitBox | null
          weapon?: Weapon | null
          health: number
          healthRemoved: number
          armor: number
          armorRemoved: number
        }>
        deaths: Array<{
          __typename?: 'Death'
          playerIndex: string
          attackerIndex: string
          assisterIndex: string
          weapon: Weapon
          isHeadshot: boolean
        }>
        bombs: Array<{
          __typename?: 'Bomb'
          playerIndex: string
          state: BombState
        }>
      }>
    } | null
  } | null
}

export type TickFieldsFragment = {
  __typename?: 'Tick'
  number: number
  playerVectors: Array<{
    __typename?: 'PlayerVector'
    playerIndex: string
    xPos: number
    yPos: number
    theta: number
    weapon?: Weapon | null
  }>
  playerStatus: Array<{
    __typename?: 'PlayerStatus'
    playerIndex: string
    armor: number
    currentAmmo: number
    hasBomb: boolean
    hasHelmet: boolean
    hasKit: boolean
    health: number
    inventory: Array<Weapon>
    isDefusing: boolean
    isFakePlayer: boolean
    isWalking: boolean
    money: number
    zoomLevel: number
  }>
  weaponFires: Array<{ __typename?: 'WeaponFire'; playerIndex: string }>
  grenades: Array<{
    __typename?: 'Grenade'
    type: GrenadeType
    state: GrenadeState
    entityIndex: string
    playerIndex: string
    xPos: number
    yPos: number
  }>
  molotovs: Array<{
    __typename?: 'Molotov'
    type: GrenadeType
    state: GrenadeState
    entityIndex: string
    xPos: number
    yPos: number
  }>
  blinds: Array<{
    __typename?: 'Blind'
    entityIndex: string
    playerIndex: string
    attackerIndex: string
    duration: number
  }>
  hurts: Array<{
    __typename?: 'Hurt'
    playerIndex: string
    attackerIndex?: string | null
    hitBox?: HitBox | null
    weapon?: Weapon | null
    health: number
    healthRemoved: number
    armor: number
    armorRemoved: number
  }>
  deaths: Array<{
    __typename?: 'Death'
    playerIndex: string
    attackerIndex: string
    assisterIndex: string
    weapon: Weapon
    isHeadshot: boolean
  }>
  bombs: Array<{ __typename?: 'Bomb'; playerIndex: string; state: BombState }>
}

export type SetMatchesViewModeMutationVariables = Exact<{
  viewMode: UserInterfaceViewMode
}>

export type SetMatchesViewModeMutation = {
  __typename?: 'Mutation'
  setMatchesViewMode: UserInterfaceViewMode
}

export type MatchesUiQueryVariables = Exact<{ [key: string]: never }>

export type MatchesUiQuery = {
  __typename?: 'Query'
  ui: {
    __typename?: 'UserInterfaceState'
    matchesViewMode: UserInterfaceViewMode
  }
}

export type UiQueryVariables = Exact<{ [key: string]: never }>

export type UiQuery = {
  __typename?: 'Query'
  ui: {
    __typename?: 'UserInterfaceState'
    matchesViewMode: UserInterfaceViewMode
  }
}

export type UserFieldsFragment = {
  __typename?: 'User'
  isAccountLinked: boolean
  username: string
  steamId?: string | null
  affiliate?: { __typename?: 'Affiliate'; code?: AffiliateCode | null } | null
  automatch: {
    __typename?: 'Automatch'
    isActive: boolean
    checkedAt?: any | null
    triggeredAt?: any | null
  }
  membership?: {
    __typename?: 'Membership'
    level: MembershipLevel
    isTrial: boolean
    startDate: any
    endDate?: any | null
    isActive: boolean
    isCanceled: boolean
    isPaused: boolean
    isTerminated: boolean
    providence: MembershipProvidence
  } | null
}

export type UserQueryVariables = Exact<{ [key: string]: never }>

export type UserQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'User'
    isAccountLinked: boolean
    username: string
    steamId?: string | null
    affiliate?: { __typename?: 'Affiliate'; code?: AffiliateCode | null } | null
    automatch: {
      __typename?: 'Automatch'
      isActive: boolean
      checkedAt?: any | null
      triggeredAt?: any | null
    }
    membership?: {
      __typename?: 'Membership'
      level: MembershipLevel
      isTrial: boolean
      startDate: any
      endDate?: any | null
      isActive: boolean
      isCanceled: boolean
      isPaused: boolean
      isTerminated: boolean
      providence: MembershipProvidence
    } | null
  }
}

export type UnlinkMutationVariables = Exact<{
  linkId: Scalars['ID']
  linkType: UserLinkType
}>

export type UnlinkMutation = {
  __typename?: 'Mutation'
  unlink?: {
    __typename?: 'UserLink'
    linkId: string
    linkType?: UserLinkType | null
  } | null
}

export type AddSteamIdKeyMutationVariables = Exact<{
  linkId: Scalars['ID']
  key: Scalars['String']
}>

export type AddSteamIdKeyMutation = {
  __typename?: 'Mutation'
  addSteamIdKey?: {
    __typename?: 'UserLink'
    linkId: string
    linkType?: UserLinkType | null
  } | null
}

export type RemoveSteamIdKeyMutationVariables = Exact<{
  linkId: Scalars['ID']
}>

export type RemoveSteamIdKeyMutation = {
  __typename?: 'Mutation'
  removeSteamIdKey?: {
    __typename?: 'UserLink'
    linkId: string
    linkType?: UserLinkType | null
  } | null
}

export type TriggerAutomatchMutationVariables = Exact<{
  linkId: Scalars['ID']
}>

export type TriggerAutomatchMutation = {
  __typename?: 'Mutation'
  triggerAutomatch?: {
    __typename?: 'Automatch'
    isActive: boolean
    checkedAt?: any | null
    triggeredAt?: any | null
  } | null
}

export type NewLinkAuthSessionMutationVariables = Exact<{
  callbackUrl: Scalars['AWSURL']
  linkType: UserLinkType
}>

export type NewLinkAuthSessionMutation = {
  __typename?: 'Mutation'
  newLinkAuthSession: any
}

export type NewStripeCheckoutSessionMutationVariables = Exact<{
  plan: MembershipPlan
  applyDiscount: Scalars['Boolean']
  callbackUrl: Scalars['AWSURL']
}>

export type NewStripeCheckoutSessionMutation = {
  __typename?: 'Mutation'
  newStripeCheckoutSession: string
}

export type NewStripeCustomerPortalSessionMutationVariables = Exact<{
  callbackUrl: Scalars['AWSURL']
}>

export type NewStripeCustomerPortalSessionMutation = {
  __typename?: 'Mutation'
  newStripeCustomerPortalSession: any
}

export type CancelStripeMembershipMutationVariables = Exact<{
  [key: string]: never
}>

export type CancelStripeMembershipMutation = {
  __typename?: 'Mutation'
  cancelStripeMembership: string
}

export type UncancelStripeMembershipMutationVariables = Exact<{
  [key: string]: never
}>

export type UncancelStripeMembershipMutation = {
  __typename?: 'Mutation'
  uncancelStripeMembership: string
}

export type NewPaypalCheckoutSessionMutationVariables = Exact<{
  plan: MembershipPlan
  applyDiscount: Scalars['Boolean']
  callbackUrl: Scalars['AWSURL']
}>

export type NewPaypalCheckoutSessionMutation = {
  __typename?: 'Mutation'
  newPaypalCheckoutSession: any
}

export type NewPaypalCustomerPortalSessionMutationVariables = Exact<{
  [key: string]: never
}>

export type NewPaypalCustomerPortalSessionMutation = {
  __typename?: 'Mutation'
  newPaypalCustomerPortalSession: any
}

export type CancelPaypalMembershipMutationVariables = Exact<{
  [key: string]: never
}>

export type CancelPaypalMembershipMutation = {
  __typename?: 'Mutation'
  cancelPaypalMembership: string
}

export type PausePaypalMembershipMutationVariables = Exact<{
  [key: string]: never
}>

export type PausePaypalMembershipMutation = {
  __typename?: 'Mutation'
  pausePaypalMembership: string
}

export type UnpausePaypalMembershipMutationVariables = Exact<{
  [key: string]: never
}>

export type UnpausePaypalMembershipMutation = {
  __typename?: 'Mutation'
  unpausePaypalMembership: string
}
