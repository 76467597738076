import type { ReactNode } from 'react'
import styled from '@emotion/styled'

import starIcon from 'src/assets/icons/upgrade-star-bg.svg'

export function StarBackgroundPage(props: { children: ReactNode }) {
  return (
    <Page>
      <Content>{props.children}</Content>
      <StarBg>
        <GradientBg />
      </StarBg>
    </Page>
  )
}

const Page = styled.div`
  position: relative;
  padding: 0 ${(props) => props.theme.d.spacing[2]};
  margin-bottom: 150px;
`

const Content = styled.div`
  position: relative;
  z-index: 2;
`

const starBgHeight = 364
const StarBg = styled.div`
  background: url(${starIcon});
  height: ${starBgHeight}px;
  position: absolute;
  top: 12px;
  left: 0;
  opacity: 0.05;
  width: 100%;
`

const GradientBg = styled.div`
  background: ${(props) => props.theme.d.colors.starBackground};
  height: ${starBgHeight}px;
`
