import styled from '@emotion/styled'

import type {
  MatchFieldsFragment as MatchData,
  PendingMatchFieldsFragment as PendingMatchData
} from 'src/graphql/types'
import { MatchLifecycle } from 'src/screens/matches/collection/MatchLifecycle'

export function MatchesGrid(props: {
  matches: (PendingMatchData | MatchData)[]
}) {
  return (
    <Box>
      {props.matches.map((match) => (
        <MatchLifecycle key={match.id} source={match} />
      ))}
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: ${(props) => props.theme.d.spacing[4]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
  }

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
  }
`
