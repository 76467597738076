import type { Logger } from 'src/lib/analytics/logger'
import type { Sentry } from 'src/lib/analytics/sentry'
import type { AuthClient } from 'src/lib/auth-client'
import { AuthState } from 'src/lib/auth-client'
import type { ApolloClient } from 'src/lib/apollo-client'
import type {
  CloseSessionMutation,
  CloseSessionMutationVariables,
  OpenSessionMutation,
  OpenSessionMutationVariables,
  SessionQuery,
  SessionQueryVariables,
  UiQuery,
  UiQueryVariables,
  UserQuery,
  UserQueryVariables
} from 'src/graphql/types'
import { UserInterfaceViewMode } from 'src/graphql/types'
import {
  closeSessionMutation,
  openSessionMutation,
  sessionQuery,
  uiQuery,
  userQuery
} from 'src/graphql'

type Config = {
  apolloClient: ApolloClient
  authClient: AuthClient
  identifyUser: (userId: string | null, username: string | null) => void
  resetAnalytics: () => void
  sentry: Sentry
  log: Logger
}

export const createSession = ({
  apolloClient,
  authClient,
  identifyUser,
  resetAnalytics,
  sentry,
  log
}: Config) => {
  const handleError = (err: Error) => {
    log.error(err)
    sentry.captureException(err, { tags: { label: 'session' } })
  }

  apolloClient.onClearStore(async () => {
    try {
      await apolloClient.mutate<
        CloseSessionMutation,
        CloseSessionMutationVariables
      >({
        mutation: closeSessionMutation
      })
      initUiState()
    } catch (err: any) {
      handleError(err)
    }
  })

  authClient.onAuthStateChanged(({ detail }) => {
    try {
      const { state, userId, username, affiliateCode } = detail
      log.info('Authentication State:', AuthState[state])

      identifyUser(userId, username)

      const isUnauthenticated = [
        AuthState.anonymous,
        AuthState.expire,
        AuthState.unauthenticate
      ].includes(state)

      const isAuthenticated = [
        AuthState.session,
        AuthState.authenticate
      ].includes(state)

      const handleUnauthenticated = () => {
        apolloClient.clearStore()
        if (state !== AuthState.anonymous) resetAnalytics()
      }

      const handleAuthenticated = async (
        userId: string | null,
        affiliateCode: string | null
      ) => {
        if (!userId) return
        await loadUser()
        await apolloClient.mutate<
          OpenSessionMutation,
          OpenSessionMutationVariables
        >({
          mutation: openSessionMutation,
          variables: { userId, affiliateCode }
        })
        authClient.setAffiliateCode(affiliateCode).catch(handleError)
      }

      if (isUnauthenticated) {
        handleUnauthenticated()
      }

      if (isAuthenticated) {
        handleAuthenticated(userId, affiliateCode).catch(handleError)
      }
    } catch (err: any) {
      handleError(err)
    }
  })

  const watchSession = () =>
    apolloClient.watchQuery<SessionQuery, SessionQueryVariables>({
      query: sessionQuery
    })

  const loadUser = () =>
    apolloClient.query<UserQuery, UserQueryVariables>({
      query: userQuery,
      fetchPolicy: 'cache-first'
    })

  const initUiState = () =>
    apolloClient.writeQuery<UiQuery, UiQueryVariables>({
      query: uiQuery,
      data: {
        ui: {
          matchesViewMode: UserInterfaceViewMode.Grid
        }
      }
    })

  const init = async () => {
    initUiState()

    let unsubscribe = () => {}

    const waitForSession = new Promise((resolve, reject) => {
      let onClearStoreSubscription = { unsubscribe: () => {} }

      const subscription = watchSession().subscribe({
        next: resolve,
        error: reject
      })

      // The store is cleared if the user is unauthenticated on init.
      // If the store is cleared after we have setup the above subscription,
      // Apollo Client will garbage collect the Observable and this promise will never resolve.
      // This creates a new subscription to replace the once above.
      const unsubscribeOnClearStore = apolloClient.onClearStore(async () => {
        onClearStoreSubscription.unsubscribe()
        onClearStoreSubscription = watchSession().subscribe({
          next: resolve,
          error: reject
        })
      })

      unsubscribe = () => {
        subscription.unsubscribe()
        onClearStoreSubscription.unsubscribe()
        unsubscribeOnClearStore()
      }
    })

    const waitForUser = new Promise((resolve, reject) => {
      const unsubscribe = authClient.onAuthStateChanged(async ({ detail }) => {
        try {
          const { state } = detail
          const isAuthenticated = [
            AuthState.session,
            AuthState.authenticate
          ].includes(state)
          if (isAuthenticated) await loadUser()
          unsubscribe()
          resolve(undefined)
        } catch (err) {
          reject(err)
        }
      })
    })

    const wait = Promise.all([waitForSession, waitForUser])

    try {
      await authClient.getUser()
    } catch (err) {
      log.warn(err)
      sentry.captureException(err, { tags: { label: 'session user' } })
      await authClient.signOut().catch(() => {
        globalThis.localStorage.clear()
        authClient.expireAuthState()
      })
    }

    await wait

    unsubscribe()
  }

  return {
    init
  }
}
