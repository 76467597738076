import type { ReactElement } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlock } from '@fortawesome/free-solid-svg-icons'

import { PerkType } from 'src/system/perk/perks'
import { PerkProtector } from 'src/system/perk/PerkProtector'

import { NewResourceIcon, SpecialResourceIcon } from './ResourceIcon'

export function Resource(props: {
  title: string
  children: ReactElement
  isNew: boolean
  isExclusive: boolean
  isPremium: boolean
  isFirst: boolean
}) {
  return (
    <Box withBorder={!props.isFirst}>
      <Title>
        {props.title}
        <NewResourceIcon isNew={props.isNew} />
        <SpecialResourceIcon
          isPremium={props.isPremium}
          isExclusive={props.isExclusive}
        />
      </Title>
      <ResourceContent isPremium={props.isPremium}>
        {props.children}
      </ResourceContent>
    </Box>
  )
}

export function ResourceContent(props: {
  children: ReactElement
  isPremium: boolean
}) {
  if (props.isPremium) {
    return (
      <PerkProtector
        perk={PerkType.resource}
        renderGate={HiddenPremiumResource}
      >
        {props.children}
      </PerkProtector>
    )
  }

  return props.children
}

function HiddenPremiumResource(props: { onClick: () => void }) {
  const { t } = useTranslation()

  return (
    <Container>
      <p>{t('assessments:premium_resource_info')}</p>
      <p>
        <StyledUpgradeLink onClick={props.onClick}>
          <FontAwesomeIcon icon={faUnlock} /> {t('assessments:unlock_resource')}
        </StyledUpgradeLink>
      </p>
    </Container>
  )
}

const Box = styled.div<{ withBorder: boolean }>`
  padding: ${(props) => props.theme.d.spacing[5]};
  ${(props) =>
    props.withBorder
      ? `border-top: 1px solid ${props.theme.d.colors.border.dark};`
      : ''}

  p {
    margin: 0;
    font-size: 16px;
  }
`

const Title = styled.h5`
  margin: 0 0 ${(props) => props.theme.d.spacing[3]};
  color: ${(props) => props.theme.d.colors.text.secondary};
  font-size: 22px;
`

const StyledUpgradeLink = styled.a`
  font-size: 16px;
  color: ${(props) => props.theme.d.colors.accent};
`
