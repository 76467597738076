import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { useTheme } from '@mui/system'

import {
  HeaderPrice,
  PlanCard,
  PlanLevel,
  SignUpButton,
  useFeatures
} from 'src/screens/membership/pricing/PlanCard'
import { UpgradeButtons } from 'src/screens/membership/pricing/UpgradeButton'
import { MembershipLevel, MembershipPlan } from 'src/graphql/types'
import { useMaybeUser } from 'src/hooks/use-user'

const level = PlanLevel.lifetime
const namespace = `membership:plans:${level}`

export function LifetimePlanCard() {
  const { t } = useTranslation()
  const features = useFeatures(level)

  return (
    <Box>
      <PlanCard
        color={PlanLevel.lifetime}
        title={t(`${namespace}:title`)}
        header={
          <PremiumHeader>
            <HeaderPrice>{t(`${namespace}:current_price`)}</HeaderPrice>
          </PremiumHeader>
        }
        features={features}
        footer={<Footer />}
      />
    </Box>
  )
}

function Footer() {
  const { t } = useTranslation()
  const theme = useTheme()
  const { user } = useMaybeUser()

  if (user == null) {
    return <SignUpButton color={theme.d.colors.lifetime} />
  }

  const isMembershipLifetime = user?.membership?.level === MembershipLevel.Mvp

  if (!isMembershipLifetime) {
    return (
      <StyleBox>
        <UpgradeButtons plan={MembershipPlan.Lifetime} />
      </StyleBox>
    )
  }

  return (
    <Container>
      <ActiveText>{t(`${namespace}:active`)}</ActiveText>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Box = styled.div`
  position: relative;
`

const PremiumHeader = styled.div`
  display: flex;
  align-items: center;
`

const ActiveText = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`

const StyleBox = styled.div`
  padding: ${(props) => props.theme.d.spacing[4]};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 207px;
`
