import { createContext, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useCookies } from 'react-cookie'

import type { ChildrenProps } from 'src/types'
import { affiliateCookieName, referrerCookieName } from 'src/lib/auth-client'
import type { AuthClient } from 'src/lib/auth-client'
import {
  AnalyticsEvent,
  useEventAnalytics,
  useIdentifyUser
} from 'src/hooks/use-analytics'

export function useAuth() {
  return useContext(authContext)
}

type Props = {
  client: AuthClient
} & ChildrenProps

const minPasswordLength = 12
const maxPasswordLength = 60

const normalizeEmail = (email: string) => `${email}`.trim().toLowerCase()

export function AuthProvider({ client, children }: Props) {
  const auth = useProvideAuth(client)
  const { Provider } = authContext
  return <Provider value={auth}>{children}</Provider>
}

export function useAuthCookies() {
  const [cookies] = useCookies([affiliateCookieName, referrerCookieName])
  return {
    affiliate: cookies[affiliateCookieName],
    referrer: cookies[referrerCookieName]
  }
}

function useProvideAuth(auth: AuthClient) {
  const sessionSigninEvent = useEventAnalytics(AnalyticsEvent.sessionSignin)
  const sessionSignoutEvent = useEventAnalytics(AnalyticsEvent.sessionSignout)
  const accountCreatedEvent = useEventAnalytics(AnalyticsEvent.accountCreated)
  const accountVerifiedEvent = useEventAnalytics(AnalyticsEvent.accountVerified)
  const accountNotVerifiedEvent = useEventAnalytics(
    AnalyticsEvent.accountNotVerified
  )
  const passwordForgotEvent = useEventAnalytics(AnalyticsEvent.passwordForgot)
  const passwordResetEvent = useEventAnalytics(AnalyticsEvent.passwordReset)
  const passwordChangedEvent = useEventAnalytics(AnalyticsEvent.passwordChanged)
  const { affiliate, referrer } = useAuthCookies()
  const identifyUser = useIdentifyUser()

  const signIn = async (
    ...args: Parameters<AuthClient['authenticateUser']>
  ) => {
    const [email, ...rest] = args
    await auth.authenticateUser(normalizeEmail(email), ...rest)
    sessionSigninEvent()
  }

  const signOut = async () => {
    sessionSignoutEvent()
    await auth.signOut()
  }

  const signUp = async (
    email: string,
    password: string,
    receiveMail: boolean
  ) => {
    const username = uuidv4()
    identifyUser(null, username)
    await auth.signUp(username, password, {
      email: normalizeEmail(email),
      ...getSignupAttributes({ receiveMail, affiliate, referrer })
    })
    accountCreatedEvent({
      marketing_signup: receiveMail.toString(),
      affiliate,
      referrer
    })
  }

  const confirmRegistration = async (
    ...args: Parameters<AuthClient['confirmRegistration']>
  ) => {
    try {
      const [username] = args
      identifyUser(null, username)
      await auth.confirmRegistration(...args)
      accountVerifiedEvent()
    } catch (err: any) {
      accountNotVerifiedEvent({ error: err?.message })
      throw err
    }
  }

  const forgotPassword = async (
    ...args: Parameters<AuthClient['forgotPassword']>
  ) => {
    const [email, ...rest] = args
    await auth.forgotPassword(normalizeEmail(email), ...rest)
    passwordForgotEvent()
  }

  const confirmPassword = async (
    ...args: Parameters<AuthClient['confirmPassword']>
  ) => {
    await auth.confirmPassword(...args)
    passwordResetEvent()
  }

  const changePassword = async (
    ...args: Parameters<AuthClient['changePassword']>
  ) => {
    await auth.changePassword(...args)
    passwordChangedEvent()
  }

  return {
    minPasswordLength,
    maxPasswordLength,
    confirmPassword,
    changePassword,
    confirmRegistration,
    forgotPassword,
    signIn,
    signOut,
    signUp
  }
}

type ContextProps = ReturnType<typeof useProvideAuth>
const asyncStubUndefined = async () => {}
const defaultAuth = {
  minPasswordLength,
  maxPasswordLength,
  confirmPassword: asyncStubUndefined,
  changePassword: asyncStubUndefined,
  confirmRegistration: asyncStubUndefined,
  forgotPassword: asyncStubUndefined,
  signIn: asyncStubUndefined,
  signOut: asyncStubUndefined,
  signUp: asyncStubUndefined
}
const authContext = createContext<ContextProps>(defaultAuth)

const getSignupAttributes = ({
  receiveMail,
  affiliate,
  referrer
}: {
  receiveMail: boolean
  affiliate: string | undefined
  referrer: string | undefined
}) => {
  return {
    ...(affiliate == null ? {} : { 'custom:affiliate': affiliate }),
    ...(referrer == null ? {} : { 'custom:referrer': referrer }),
    'custom:marketing_signup': receiveMail ? 'True' : 'False'
  }
}
