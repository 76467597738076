import type { Dispatch, ReactElement, SetStateAction } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import type { ExpansionTab } from 'src/screens/match/assessments/assignment-list/assignment/expansion/Expansion'

export function Tab(props: {
  tab: ExpansionTab
  currentTab: ExpansionTab | null
  setTab: Dispatch<SetStateAction<ExpansionTab | null>>
  className?: string
  title: string | ReactElement
  canExpand: boolean
  onClick?: () => void
  isActive?: boolean
  isNew?: boolean
  bodyCount?: number
}) {
  const hasItems = props.bodyCount != null && props.bodyCount > 0
  const isActive = props.isActive || props.tab === props.currentTab
  const handleClick = () => {
    if (props.onClick) {
      props.onClick()
      return
    }

    isActive ? props.setTab(null) : props.setTab(props.tab)
  }

  const canExpand = props.canExpand
  const isDisabled = props.canExpand && !hasItems
  const canExpandAndIsEnabled = props.canExpand && !isDisabled
  const highlight = isActive && canExpandAndIsEnabled
  return (
    <Box
      className={props.className}
      onClick={handleClick}
      canExpand={canExpand}
      isDisabled={isDisabled}
      highlight={highlight}
      isActive={isActive}
    >
      <Title
        highlight={
          (canExpandAndIsEnabled || !canExpand) && Boolean(props.isActive)
        }
      >
        {props.title} <BodyCount bodyCount={props.bodyCount} />
        <NewText isNew={props.isNew} />
      </Title>
      <ExpandIcon visible={canExpandAndIsEnabled} isActive={isActive} />
    </Box>
  )
}

function NewText(props: { isNew: boolean | undefined }) {
  const { t } = useTranslation()
  if (!props.isNew) return null
  return <StyledSup> {props.isNew ? t('assessments:new') : ''}</StyledSup>
}

const StyledSup = styled.sup`
  color: ${(props) => props.theme.d.colors.text.secondary};
`

function BodyCount(props: { bodyCount?: number }) {
  if (props.bodyCount) {
    return <>({props.bodyCount})</>
  }
  return null
}

function ExpandIcon(props: { visible: boolean; isActive: boolean }) {
  if (!props.visible) {
    return null
  }

  return <FontAwesomeIcon icon={props.isActive ? faCaretUp : faCaretDown} />
}

const Box = styled.div<{
  highlight: boolean
  canExpand: boolean
  isDisabled: boolean
  isActive: boolean
}>`
  flex: 1;
  padding: ${(props) => props.theme.d.spacing[2]};
  color: ${(props) =>
    props.isDisabled
      ? props.theme.d.colors.text.primary
      : props.theme.d.colors.success};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  user-select: none;

  background: ${(props) =>
    props.highlight ? props.theme.d.colors.tab.active : 'inherit'};

  ${(props) =>
    props.canExpand && !props.isDisabled
      ? `
        border-bottom: ${
          props.highlight
            ? `1px solid ${props.theme.d.colors.success}`
            : `1px solid ${props.theme.d.colors.background}`
        }
        margin-bottom: ${props.highlight ? '-1px' : '0'};

        &:hover {
          background:
            ${
              props.highlight
                ? props.theme.d.colors.tab.active
                : props.theme.d.colors.tab.hover
            };
        }
      `
      : null}

  svg {
    margin: 0px 0 3px 6px;
    width: 18px !important;
    height: 18px !important;
  }
`

const Title = styled.span<{ highlight: boolean }>`
  padding: ${(props) => props.theme.d.spacing[2]};
  border-radius: 4px;

  ${(props) =>
    props.highlight
      ? `
          background: ${props.theme.d.colors.expansionTabTitleHighlight};
        `
      : null}
`
