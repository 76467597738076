import { useCallback, useEffect } from 'react'
import type { ObservableQueryFields } from '@apollo/client'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { DateTime, Duration, Interval } from 'luxon'
import { useIdleTimer } from 'react-idle-timer'

import {
  courseProgressionQuery,
  matchesQuery,
  matchesStatusQuery,
  newPendingMatchSubscription,
  pageSize,
  progressionPageSize,
  triggerAutomatchMutation,
  userQuery
} from 'src/graphql'
import type {
  CourseProgressionQuery,
  CourseProgressionQueryVariables,
  MatchFieldsFragment as Match,
  MatchProgressionFieldsFragment,
  MatchesQuery,
  MatchesQueryVariables,
  MatchesStatusQuery,
  MatchesStatusQueryVariables,
  NewPendingMatchSubscription,
  NewPendingMatchSubscriptionVariables,
  PendingMatchFieldsFragment as PendingMatch,
  TriggerAutomatchMutation,
  TriggerAutomatchMutationVariables
} from 'src/graphql/types'
import { sortByDate } from 'src/lib/utils/date'
import { pageToEdges } from 'src/lib/utils/transforms'
import { AnalyticsEvent, useEventAnalytics } from 'src/hooks/use-analytics'

const automatchDuration = Duration.fromObject({ hours: 2 })
const pollInterval = Duration.fromObject({ seconds: 30 }).as('milliseconds')
const togglePollingInterval = Duration.fromObject({ seconds: 5 }).as(
  'milliseconds'
)
const idleTimeout = Duration.fromObject({ minutes: 30 }).as('milliseconds')

export function useMatches() {
  const {
    data,
    error,
    loading,
    refetch,
    fetchMore: fetchMoreQuery
  } = useQuery<MatchesQuery, MatchesQueryVariables>(matchesQuery, {
    variables: { pageSize },
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true
  })

  const hasMoreMatches = data?.matches.pageInfo.hasNextPage ?? false

  const matches = (pageToEdges(data?.matches) as (Match | PendingMatch)[]) ?? []

  return {
    refetch,
    loading,
    error,
    matches: sortByDate(['date', 'createdAt'], matches),
    hasMoreMatches,
    fetchMore: createFetchMore(fetchMoreQuery, data)
  }
}
export function useTriggerAutomatch() {
  const automatchTriggeredEvent = useEventAnalytics(
    AnalyticsEvent.automatchTriggered
  )
  const automatchNotTriggeredEvent = useEventAnalytics(
    AnalyticsEvent.automatchNotTriggered
  )

  const { data, refetch, startPolling, stopPolling } = useQuery<
    MatchesStatusQuery,
    MatchesStatusQueryVariables
  >(matchesStatusQuery, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  })

  const linkId = data?.user?.steamId

  const [triggerAutomatch] = useMutation<
    TriggerAutomatchMutation,
    TriggerAutomatchMutationVariables
  >(triggerAutomatchMutation, {
    refetchQueries: [{ query: userQuery }, { query: matchesStatusQuery }]
  })

  const trigger = useCallback(
    async (reason: string) => {
      if (!linkId) return
      try {
        await triggerAutomatch({ variables: { linkId } })
        automatchTriggeredEvent({ automatch_trigger_reason: reason })
      } catch (err: any) {
        automatchNotTriggeredEvent({
          error: err?.message,
          automatch_trigger_reason: reason
        })
      }
    },
    [
      automatchTriggeredEvent,
      automatchNotTriggeredEvent,
      triggerAutomatch,
      linkId
    ]
  )

  return {
    linkId,
    data,
    refetch,
    startPolling,
    stopPolling,
    trigger
  }
}

export function useMatchesStatus() {
  const automatchSkippedTriggerEvent = useEventAnalytics(
    AnalyticsEvent.automatchSkippedTrigger
  )

  const { linkId, data, refetch, startPolling, stopPolling, trigger } =
    useTriggerAutomatch()

  const automatch = data?.user?.automatch
  const matchEdges = data?.latestMatches?.edges
  const latestMatchDate = matchEdges?.find((m) => !!m)?.node?.createdAt

  useSubscription<
    NewPendingMatchSubscription,
    NewPendingMatchSubscriptionVariables
  >(newPendingMatchSubscription, {
    onSubscriptionData: () => {
      refetch()
    }
  })

  useEffect(() => {
    const togglePolling = () => {
      const checkedAt = data?.user?.automatch?.checkedAt

      if (checkedAt) {
        const now = DateTime.local()

        const duration = Interval.fromDateTimes(
          DateTime.fromISO(checkedAt),
          now
        ).toDuration()

        if (duration.isValid) {
          duration > automatchDuration
            ? startPolling(pollInterval)
            : stopPolling()
        }
      }
    }

    const interval = globalThis.setInterval(
      togglePolling,
      togglePollingInterval
    )

    return () => {
      stopPolling()
      globalThis.clearInterval(interval)
    }
  })

  const skipTrigger = useCallback(
    (reason: string) => {
      automatchSkippedTriggerEvent({ automatch_skip_trigger_reason: reason })
    },
    [automatchSkippedTriggerEvent]
  )

  const handleActivity = () => {
    if (!linkId || !automatch) return skipTrigger('unlinked')
    if (!automatch.triggeredAt) return trigger('never_triggered')

    const triggeredAt = DateTime.fromISO(automatch.triggeredAt)
    if (triggeredAt.diffNow('minutes').minutes > -5)
      return skipTrigger('rate_limit')

    if (!latestMatchDate) return trigger('no_matches')
    if (!automatch.checkedAt) return trigger('never_checked')

    const matchDate = DateTime.fromISO(latestMatchDate)
    if (Math.abs(matchDate.diffNow('minutes').minutes) > 30) {
      return trigger('old_match')
    } else {
      return skipTrigger('recent_match')
    }
  }

  useIdleTimer({
    timeout: idleTimeout,
    crossTab: true,
    onActive: handleActivity,
    onAction: handleActivity,
    debounce: 5000
  })

  return { data }
}

export function useCourseProgression() {
  const { data, loading, refetch } = useQuery<
    CourseProgressionQuery,
    CourseProgressionQueryVariables
  >(courseProgressionQuery, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    variables: { pageSize: progressionPageSize }
  })
  const edges = data?.progression?.edges
  const matches =
    edges &&
    (edges
      .filter((edge: any) => !!edge)
      .map((edge: any) => edge?.node)
      .filter((node: any) => !!node) as MatchProgressionFieldsFragment[])
  return { matches, loading, refetch }
}

function createFetchMore(
  fetchMoreQuery: ObservableQueryFields<
    MatchesQuery,
    MatchesQueryVariables
  >['fetchMore'],
  currentData?: MatchesQuery
) {
  return () => {
    if (!currentData) {
      return
    }

    const cursor = currentData.matches.pageInfo.endCursor

    if (!cursor) {
      return
    }

    fetchMoreQuery({
      variables: {
        pageSize,
        cursor
      },
      updateQuery(
        previousResult: MatchesQuery,
        { fetchMoreResult }: { fetchMoreResult?: MatchesQuery }
      ) {
        if (!fetchMoreResult) {
          return previousResult
        }

        const newEdges = fetchMoreResult.matches.edges
        const pageInfo = fetchMoreResult.matches.pageInfo

        if (newEdges.length === 0) {
          return previousResult
        }

        return {
          matches: {
            __typename: previousResult.matches.__typename,
            edges: [...previousResult.matches.edges, ...newEdges],
            pageInfo
          }
        }
      }
    })
  }
}
