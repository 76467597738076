import styled from '@emotion/styled'

import type {
  Facet,
  FacetIdFieldsFragment,
  FacetIdListFieldsFragment
} from 'src/graphql/types'
import { FacetShape, FacetUnit } from 'src/graphql/types'
import type { Assessment } from 'src/hooks/use-assessment'

export type FacetId = Facet & FacetIdFieldsFragment
export const isFacetId = (facet: Facet): facet is FacetId =>
  facet.shape === FacetShape.Player && facet.unit === FacetUnit.SteamId

export function FacetIdValue(props: {
  facet: FacetId
  assessment: Assessment
}) {
  return <span>{value(props.facet.idValue, props.assessment)}</span>
}

export type FacetIdList = Facet & FacetIdListFieldsFragment
export const isFacetIdList = (facet: Facet): facet is FacetIdList =>
  facet.shape === FacetShape.PlayerList && facet.unit === FacetUnit.SteamId

export function FacetIdListValue(props: {
  facet: FacetIdList
  assessment: Assessment
}) {
  return (
    <Idlist>
      {props.facet.idValues.map((id, index) => (
        <li key={index}>{value(id, props.assessment)}</li>
      ))}
    </Idlist>
  )
}

function value(id: string, assessment: Assessment) {
  const player = assessment.players.find((p) => p.steamId === id)
  if (!player) {
    return id
  }

  return player.name
}

const Idlist = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
`
