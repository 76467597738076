import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faSteamSymbol } from '@fortawesome/free-brands-svg-icons'

import { Markdown } from 'src/screens/match/assessments/assignment-list/assignment/expansion/resources/Markdown'
import { YoutubeVideo } from 'src/system/ui/video/YoutubeVideo'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'
import type { ResourceWorkshopSteamFieldsCsgoFragment as ResourceWorkshopSteamCsgoData } from 'src/graphql/types'

export function ResourceWorkshopSteamCsgo(props: {
  resource: ResourceWorkshopSteamCsgoData
}) {
  const url = `https://steamcommunity.com/sharedfiles/filedetails/?id=${props.resource.externalId}`
  const { t } = useTranslation()
  const namespace = 'assessments:assignment:resources:workshop'
  const title = props.resource.title.text

  return (
    <Text>
      <h3>
        <FontAwesomeIcon icon={faSteamSymbol} />{' '}
        {t(`${namespace}:platform:${props.resource.platform}`)}
      </h3>
      <AbsoluteLink to={url} newTab>
        {title} <FontAwesomeIcon icon={faExternalLinkAlt} />
      </AbsoluteLink>
      <Markdown>{props?.resource?.optionalBody?.text}</Markdown>
      <Video title={title} video={props.resource.optionalVideo} />
    </Text>
  )
}

function Video(props: {
  title: string
  video: ResourceWorkshopSteamCsgoData['optionalVideo']
}) {
  const { t } = useTranslation()
  const namespace = 'assessments:assignment:resources:workshop'

  if (props.video == null) return null

  return (
    <>
      <h3>{t(`${namespace}:video:title`)}</h3>
      <Markdown>{props.video.text}</Markdown>
      <YoutubeVideo title={props.title} id={props.video.externalId} />
    </>
  )
}

const Text = styled.div`
  max-width: 600px;
`
