import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'
import { useRandom } from 'src/lib/hooks/use-random'
import { useIsGamersenseiAffiliate } from 'src/hooks/use-affiliate'
import {
  ActionButtons,
  actionPanelHeight
} from 'src/screens/progression-report/ActionButtons'
import {
  DetailsSection,
  DetailsWithLoading
} from 'src/screens/progression-report/Details'

export interface HeaderProps {
  isLoading: boolean
  hasError: boolean
  hasProgressions: boolean
  isPublic?: boolean
  isOwner?: boolean
}

export function ProfileHeader(props: HeaderProps) {
  const { description, title } = useProfileText(props)

  return (
    <Header
      {...props}
      section={DetailsSection.profile}
      title={title}
      finalDescription={description}
    />
  )
}

function useProfileText(props: HeaderProps) {
  const { t } = useTranslation()
  const randomValue = useRandom()
  const isGamersenseiAffiliate = useIsGamersenseiAffiliate()
  const namespace = isGamersenseiAffiliate
    ? 'gamersensei_overview:profile:details'
    : 'overview:profile:details'

  if (!props.hasProgressions) {
    return {
      title: t(`${namespace}:need_more_matches_title`),
      description: t(`${namespace}:need_more_matches_description`)
    }
  }

  if (!props.isOwner) {
    return {
      title: t(`${namespace}:non_owner_title`),
      description: t(`${namespace}:non_owner_description`)
    }
  }

  const title = t(`${namespace}:title`)

  const description = t(`${namespace}:description`)

  if (isGamersenseiAffiliate) {
    const textList = t<string, { title: string; description: string }[]>(
      `${namespace}:text`,
      {
        returnObjects: true
      }
    )

    if (!textList) return { title, description }

    const idx = Math.floor(randomValue * textList.length)

    return {
      title: textList[idx].title,
      description: textList[idx].description
    }
  }

  return {
    title,
    description
  }
}

export function MatchesHeader(props: HeaderProps) {
  const { t } = useTranslation()
  const namespace = 'overview:matches:details'
  const title =
    props.isLoading || props.hasProgressions
      ? t(`${namespace}:title`)
      : t(`${namespace}:need_more_matches_title`)

  const finalDescription = t(`${namespace}:description`)

  return (
    <Header
      {...props}
      section={DetailsSection.matches}
      title={title}
      finalDescription={finalDescription}
    />
  )
}

function Header(
  props: {
    title: string
    finalDescription: string
    section: DetailsSection
  } & HeaderProps
) {
  const [featureEnabled] = useFeatureFlags([FeatureFlag.gamersenseiBox])
  return (
    <Box>
      <StyledDetails {...props} />
      <StyledActionButtons hasThreeBoxes={featureEnabled} />
    </Box>
  )
}

const Box = styled.div`
  margin-bottom: ${(props) => props.theme.d.spacing[5]};
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    flex-wrap: nowrap;
    height: ${actionPanelHeight}px;
  }
`

const StyledDetails = styled(DetailsWithLoading)`
  flex: 100%;
  margin-bottom: ${(props) => props.theme.d.spacing[5]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    margin-right: ${(props) => props.theme.d.spacing[5]};
    margin-bottom: 0;
    height: 100%;
    flex: 1;
  }
`

const StyledActionButtons = styled(ActionButtons)<{ hasThreeBoxes: boolean }>`
  flex: 100%;

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    flex: 1;
  }

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    flex: 0 0 ${(props) => (props.hasThreeBoxes ? '618' : '406')}px;
  }
`
