import { useState } from 'react'
import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

import { AnalyticsEvent, useEventAnalytics } from 'src/hooks/use-analytics'
import { PerkUpgradeButton } from 'src/system/perk/PerkUpgradeButton'
import { usePerks } from 'src/system/perk/use-perks'
import type { PerkType } from 'src/system/perk/perks'

export function PerkProtector(props: {
  perk: PerkType
  renderGate: (props: { onClick: () => void }) => ReactElement
  children: ReactElement
}) {
  const [isEnabled] = usePerks([props.perk])
  if (!isEnabled) {
    return <GateWrapper perk={props.perk} render={props.renderGate} />
  }
  return props.children
}

function GateWrapper(props: {
  perk: PerkType
  render: (props: { onClick: () => void }) => ReactElement
}) {
  const { t } = useTranslation()
  const perkProtectedEvent = useEventAnalytics(AnalyticsEvent.perkProtected)
  const [dialogVisible, setDialogVisible] = useState(false)
  const toggleDialog = () => setDialogVisible(!dialogVisible)
  const handleClick = () => {
    perkProtectedEvent({ perk: props.perk })
    toggleDialog()
  }
  return (
    <>
      {props.render({ onClick: handleClick })}
      <Dialog open={dialogVisible} onClose={toggleDialog}>
        <DialogContent>
          <DialogTitle>{t(`perk:${props.perk}:gate:title`)}</DialogTitle>
          <DialogContentText>
            {t(`perk:${props.perk}:gate:description`)}
          </DialogContentText>
          <DialogActions>
            <PerkUpgradeButton />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}
