import type { WatchQueryFetchPolicy } from '@apollo/client'
import { useQuery } from '@apollo/client'

import { userQuery } from 'src/graphql'
import { useSession } from 'src/hooks/use-session'
import type { UserQuery, UserQueryVariables } from 'src/graphql/types'

export function useMaybeUser() {
  return useUser('cache-only')
}

export function useDelayedUser() {
  return useUser('cache-and-network')
}

export function useJustUser() {
  const { user } = useUser('cache-only')

  if (!user) {
    throw new Error('No user')
  }

  return { user }
}

export function useUser(
  fetchPolicy: WatchQueryFetchPolicy,
  pollInterval?: number
) {
  const { data: sessionData } = useSession()
  const session = sessionData?.session
  const { data, error, loading } = useQuery<UserQuery, UserQueryVariables>(
    userQuery,
    {
      fetchPolicy,
      pollInterval,
      skip: session ? !session.isAuthenticated : false
    }
  )

  if (
    error?.networkError &&
    'statusCode' in error.networkError &&
    error.networkError.statusCode === 401
  ) {
    globalThis.location.reload()
    return { loading: false, user: null }
  }

  if (error) {
    throw error
  }

  const user = data?.user ?? null

  return { user, loading }
}
