import type { ReactElement } from 'react'
import { useEffect, useRef, useState } from 'react'
import { Duration } from 'luxon'

import type { Timeout } from 'src/types'
import { AnalyticsEvent, useEventAnalytics } from 'src/hooks/use-analytics'
import type { MembershipProvidence } from 'src/graphql/types'
import { VerificationFailed } from 'src/screens/checkout/VerificationFailed'
import { Box, Message } from 'src/routes/HandleCheckout'
import { BorderedCircularProgress } from 'src/system/ui/progress/BorderedCircularProgress'

const verificationTimeout = Duration.fromObject({ minutes: 2 }).as(
  'milliseconds'
)

export function Verify(props: {
  shouldVerify: boolean
  verified: boolean
  verifyingMessage: string
  failedMessage: string
  providence: MembershipProvidence | null | undefined
  children: ReactElement
}) {
  const { shouldVerify } = props
  const isCalledRef = useRef(false)
  const membershipFailedEvent = useEventAnalytics(
    AnalyticsEvent.membershipFailed
  )
  const failTimeoutRef = useRef<Timeout | null>(null)
  const [verificationFailed, setVerificationFailed] = useState(false)
  const { providence } = props

  useEffect(() => {
    failTimeoutRef.current = globalThis.setTimeout(() => {
      if (shouldVerify) {
        setVerificationFailed(true)
        if (!isCalledRef.current)
          membershipFailedEvent(
            providence ? { membership_providence: providence } : {}
          )
        isCalledRef.current = true
      }
    }, verificationTimeout)

    return () => {
      if (failTimeoutRef.current !== null) {
        globalThis.clearTimeout(failTimeoutRef.current)
      }
    }
  }, [shouldVerify, membershipFailedEvent, providence])

  if (verificationFailed) {
    return <VerificationFailed>{props.failedMessage}</VerificationFailed>
  }

  if (!props.verified) {
    return <Verifying>{props.verifyingMessage}</Verifying>
  }

  return props.children
}

function Verifying(props: { children: string }) {
  return (
    <Box>
      <BorderedCircularProgress size={64} />
      <Message>{props.children}</Message>
    </Box>
  )
}
