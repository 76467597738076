import posthog from 'posthog-js'
import { useEffect } from 'react'
import {
  captureException,
  captureMessage,
  configureScope,
  init,
  reactRouterV6Instrumentation,
  setUser,
  showReportDialog
} from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

import type { Logger } from 'src/lib/analytics/logger'

export {
  ErrorBoundary,
  withProfiler,
  withSentryReactRouterV6Routing
} from '@sentry/react'

type Config = {
  dsn: string | null
  organization?: string
  tunnel: string | null
  name: string
  environment: string
  version: string
  log?: Logger
}

export type Sentry = {
  captureException: typeof captureException
  captureMessage: typeof captureMessage
  showReportDialog: typeof showReportDialog
}

export const createSentry = ({
  dsn,
  organization,
  tunnel,
  name,
  environment,
  version,
  log
}: Config) => {
  let isOptedOut = false

  const client = {
    captureMessage,
    captureException,
    showReportDialog
  }

  const createIntegrations = () => {
    const integrations = []

    integrations.push(
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      })
    )

    if (dsn && organization) {
      integrations.push(
        new posthog.SentryIntegration(
          posthog,
          organization,
          Number(new URL(dsn).pathname.slice(1))
        )
      )
    }

    return integrations
  }

  const initSync = () => {
    if (!dsn) return
    try {
      init({
        dsn,
        tunnel: tunnel ?? undefined,
        release: `${name}@${version}`,
        environment,
        tracesSampleRate: 1.0,
        normalizeDepth: 10,
        beforeSend: (event) => {
          if (isOptedOut) return null
          return event
        },
        integrations: createIntegrations()
      })
    } catch (err) {
      if (log) log.error(err)
    }
  }

  const identify = (userId: string | null, username: string | null) => {
    if (!dsn) return
    const id = userId ?? username
    const sentryUsername = username === null ? undefined : username
    const user = id ? { id, username: sentryUsername } : null
    if (!id) return
    setUser(user)
  }

  const optOut = () => {
    isOptedOut = true
  }

  const reset = () => {
    if (!dsn) return
    configureScope((scope) => scope.setUser(null))
  }

  return {
    client,
    init: initSync,
    identify,
    optOut,
    reset
  }
}
