import styled from '@emotion/styled'

import type { ResourceLinkList as ResourceLinkListData } from 'src/graphql/types'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'

export function ResourceLinkList(props: { resource: ResourceLinkListData }) {
  return (
    <List>
      {props.resource.urls.map((url) => (
        <li key={url.href}>
          <AbsoluteLink to={url.href}>{url.text}</AbsoluteLink>
        </li>
      ))}
    </List>
  )
}

const List = styled.ul`
  list-style: none;
  padding: 0;
`
