import { useQuery } from '@apollo/client'

import type { SessionQuery, SessionQueryVariables } from 'src/graphql/types'
import { sessionQuery } from 'src/graphql'

export function useSession() {
  return useQuery<SessionQuery, SessionQueryVariables>(sessionQuery, {
    fetchPolicy: 'cache-only'
  })
}
