import posthog from 'posthog-js'
import { Duration } from 'luxon'

import type { StringMap } from 'src/types'
import type { Logger } from 'src/lib/analytics/logger'
import type { Sentry } from 'src/lib/analytics/sentry'

export type Posthog = {
  reset: (resetDeviceId: boolean) => void
  capture: (eventName: string, properties?: StringMap) => void
  people: {
    set: (properties?: StringMap) => void
  }
  identify: (id: string, properties?: StringMap) => void
  onFeatureFlags: (callback: () => void) => void
  isFeatureEnabled: (key: string, options: FeatureMethodOptions) => boolean
  getFeatureFlag: (
    key: string,
    options: FeatureMethodOptions
  ) => FeatureFlagValue
}

type FeatureMethodOptions = {
  send_event: boolean // eslint-disable-line camelcase
}

type FeatureFlagValue = string | boolean | null | undefined

export type FeatureFlags = {
  [key: string]: FeatureFlagValue
}

type Config = {
  apiKey: string | null
  tunnel: string | null
  app: string
  env: string
  version: string
  debug: boolean
  featureFlags: FeatureFlags
  sentry: Sentry
  useragent: string
  isBot: boolean
  log: Logger
}

const timeout = Duration.fromObject({ seconds: 5 }).as('milliseconds')
const featureFlagInterval = Duration.fromObject({ minutes: 1 }).as(
  'milliseconds'
)

export const createPosthog = ({
  app,
  env,
  version,
  debug,
  apiKey,
  tunnel,
  featureFlags,
  useragent,
  isBot,
  log,
  sentry
}: Config) => {
  if (!apiKey || isBot) return stub(log, featureFlags)

  const register = () => {
    posthog.register({ app, env, version, $useragent: useragent })
  }

  const init = async () => {
    let isResolved = false

    return new Promise((resolve) => {
      const handleError = (err: Error) => {
        log.error(err)
        sentry.captureException(err, { tags: { label: 'posthog' } })
      }

      globalThis.setTimeout(() => {
        if (isResolved) return
        const err = new Error(`PostHog timeout after ${timeout}`)
        handleError(err)
        isResolved = true
        resolve(undefined)
      }, timeout)

      posthog.init(apiKey, {
        debug,
        secure_cookie: true,
        api_host: tunnel ?? undefined,
        loaded: () => {
          try {
            register()
            globalThis.setInterval(() => {
              const ph = posthog as any
              ph.reloadFeatureFlags()
            }, featureFlagInterval)
            posthog.onFeatureFlags(() => {
              // UPSTREAM: Force additional reload
              const ph = posthog as any
              if (!isResolved) ph.reloadFeatureFlags()
              isResolved = true
              resolve(undefined)
            })
            posthog.opt_in_capturing()
          } catch (err: any) {
            handleError(err)
            isResolved = true
            resolve(undefined)
          }
        }
      })
    })
  }

  const identify = (userId: string | null, username: string | null) => {
    register()
    const id = username
    if (!id) return
    posthog.identify(id, {
      useragent,
      ...(username == null ? {} : { username }),
      ...(userId == null ? {} : { user_id: userId })
    })
  }

  const optOut = () => {
    if (posthog.has_opted_out_capturing()) return
    posthog.opt_out_capturing()
  }

  const reset = () => {
    posthog.reset(false)
  }

  return {
    client: posthog as Posthog,
    init,
    identify,
    optOut,
    reset
  }
}

export const posthogStub = (log: Logger, featureFlags: FeatureFlags) => ({
  reset: (resetDeviceId: boolean) => {
    log.debug(`posthog.reset(${resetDeviceId})`)
  },
  capture: (eventName: string, properties?: StringMap) => {
    log.debug(`posthog.capture(${eventName}, ${JSON.stringify(properties)})`)
  },
  people: {
    set: (properties?: StringMap) => {
      log.debug(`posthog.people.set(${JSON.stringify(properties)})`)
    }
  },
  identify: (id: string, properties?: StringMap) => {
    log.debug(`posthog.identify(${id}, ${JSON.stringify(properties)})`)
  },
  onFeatureFlags: (callback: () => void) => callback(),
  getFeatureFlag: (key: string, options: FeatureMethodOptions) => {
    if (options.send_event) {
      log.debug(`posthog.getFeatureFlag(${key}, ${JSON.stringify(options)})`)
    }
    return featureFlags[key]
  },
  isFeatureEnabled: (key: string, options: FeatureMethodOptions) => {
    if (options.send_event) {
      log.debug(`posthog.isFeatureEnabled(${key}, ${JSON.stringify(options)})`)
    }
    return !!featureFlags[key]
  }
})

const stub = (log: Logger, featureFlags: FeatureFlags) => ({
  client: posthogStub(log, featureFlags),
  init: () => {
    log.debug('Posthog: init()')
  },
  identify: (userId: string | null, username: string | null) => {
    log.debug(`Posthog: identify(${userId}, ${username})`)
  },
  optOut: () => {
    log.debug('Posthog: optOut()')
  },
  reset: () => {
    log.debug('Posthog: reset()')
  }
})
