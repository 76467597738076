import styled from '@emotion/styled'
import { useTheme } from '@mui/system'

import { Team } from 'src/graphql/types'
import { findOwner } from 'src/lib/matches/matches'

const defaultTeam = Team.Ct

export function Score<T extends { isOwner: boolean; team: Team }>(props: {
  className?: string
  players: T[]
  ctScore: number
  tScore: number
  didWin: boolean
  isTie: boolean
  isOwnerInMatch: boolean
}) {
  const theme = useTheme()

  const owner = findOwner(props.players)
  const ownerTeam = owner ? owner.team : defaultTeam

  const ownerScore = ownerTeam === Team.Ct ? props.ctScore : props.tScore
  const otherTeamScore = ownerTeam === Team.Ct ? props.tScore : props.ctScore

  const color = () => {
    if (!props.isOwnerInMatch) {
      return theme.d.colors.grey
    }

    if (props.didWin) {
      return theme.d.colors.success
    }

    if (props.isTie) {
      return theme.d.colors.grey
    }

    return theme.d.colors.error
  }

  return (
    <Box className={props.className}>
      <ScoreText color={color()}>{ownerScore}</ScoreText>
      <ScoreDash />
      <ScoreText>{otherTeamScore}</ScoreText>
    </Box>
  )
}

const Box = styled.div`
  text-align: center;
`

const ScoreText = styled.span<{ color?: string }>`
  font-weight: bold;
  font-size: inherit;
  font-family: ${(props) => props.theme.d.font.secondary};
  line-height: 100%;
  color: ${(props) => (props.color ? props.color : props.theme.d.colors.grey)};
`

const ScoreDash = styled(ScoreText)`
  padding: 0 6px;
  &:after {
    content: '-';
  }
`
