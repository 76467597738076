import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/free-solid-svg-icons'

import { useRouting } from 'src/routes'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'

export function MeetCoachAgainLink() {
  const { t } = useTranslation()
  const { routes } = useRouting()
  return (
    <StyledDiv>
      <MeetCoachLink to={routes.welcome}>
        <FontAwesomeIconStyled icon={faVideo} />
        {t('matches:meet_again')}
      </MeetCoachLink>
    </StyledDiv>
  )
}

const MeetCoachLink = styled(RelativeLink)`
  color: ${(props) => props.theme.d.colors.text.primary};
`

const StyledDiv = styled.div`
  text-align: center;
  margin-top: 20px;
`

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  margin-right: 7px;
`
