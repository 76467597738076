import styled from '@emotion/styled'

import type { Breakdown, BreakdownText } from 'src/graphql/types'
import { SentimentIcon } from 'src/screens/match/assessments/assignment-list/assignment/expansion/breakdowns/SentimentIcon'

export function TextContent(props: { breakdown: BreakdownText }) {
  return <Text>{props.breakdown.body.text}</Text>
}

export function SentimentContent(props: { breakdown: Breakdown }) {
  return <StyledSentimentIcon value={props.breakdown.sentiment} />
}

const StyledSentimentIcon = styled(SentimentIcon)`
  margin-right: ${(props) => props.theme.d.spacing[4]};
`

const Text = styled.p`
  font-size: 16px;
  margin: 0;
`
