import styled from '@emotion/styled'

export function ErrorText(props: {
  children?: string
  hidden?: boolean
  disablePadding?: boolean
}) {
  if (props.hidden || !props.children) {
    return null
  }

  return (
    <StyledSpan disablePadding={props.disablePadding}>
      {props.children}
    </StyledSpan>
  )
}

const StyledSpan = styled.span<{
  disablePadding?: boolean
}>`
  display: block;
  margin-top: ${(props) =>
    props.disablePadding ? 0 : props.theme.d.spacing[2]};
  color: ${(props) => props.theme.d.colors.error};
`
