import { useMutation } from '@apollo/client'
import { Duration } from 'luxon'
import { useAsyncCallback } from 'react-async-hook'

import { useJustUser } from 'src/hooks/use-user'
import { useRouting } from 'src/routes'
import { MembershipProvidence } from 'src/graphql/types'
import { AnalyticsEvent, useEventAnalytics } from 'src/hooks/use-analytics'
import type {
  NewPaypalCustomerPortalSessionMutation,
  NewPaypalCustomerPortalSessionMutationVariables,
  NewStripeCustomerPortalSessionMutation,
  NewStripeCustomerPortalSessionMutationVariables
} from 'src/graphql/types'
import {
  newPaypalCustomerPortalSessionMutation,
  newStripeCustomerPortalSessionMutation
} from 'src/graphql'

const timeToWaitForRedirect = Duration.fromObject({ seconds: 10 }).as(
  'milliseconds'
)

export function useCustomerPortal() {
  const { user } = useJustUser()
  const stripeCustomerPortal = useStripeCustomerPortal()
  const paypalCustomerPortal = usePaypalCustomerPortal()

  if (user?.membership?.providence === MembershipProvidence.Stripe) {
    return stripeCustomerPortal
  }

  if (user?.membership?.providence === MembershipProvidence.Paypal) {
    return paypalCustomerPortal
  }

  return { isLoading: false, isError: false, customerPortal: () => {} }
}

function useStripeCustomerPortal() {
  const { getCustomerPortalCallbackUrl } = useRouting()
  const customerPortalStartedEvent = useEventAnalytics(
    AnalyticsEvent.customerPortalStarted
  )
  const customerPortalNotStartedEvent = useEventAnalytics(
    AnalyticsEvent.customerPortalNotStarted
  )

  const [newStripeCustomerPortalSession] = useMutation<
    NewStripeCustomerPortalSessionMutation,
    NewStripeCustomerPortalSessionMutationVariables
  >(newStripeCustomerPortalSessionMutation, {
    variables: { callbackUrl: getCustomerPortalCallbackUrl() }
  })

  const { loading, error, execute } = useAsyncCallback<void, []>(async () => {
    const analyticsParams = {
      membership_providence: MembershipProvidence.Paypal
    }
    try {
      const { data } = await newStripeCustomerPortalSession()
      const url = data?.newStripeCustomerPortalSession
      if (!url) throw new Error('Failed to get url')
      customerPortalStartedEvent(analyticsParams)
      await openLink(url)
    } catch (err: any) {
      customerPortalNotStartedEvent({ ...analyticsParams, error: err?.message })
      throw err
    }
  })

  return {
    customerPortal: execute,
    isLoading: loading,
    isError: error != null
  }
}

function usePaypalCustomerPortal() {
  const customerPortalStartedEvent = useEventAnalytics(
    AnalyticsEvent.customerPortalStarted
  )
  const customerPortalNotStartedEvent = useEventAnalytics(
    AnalyticsEvent.customerPortalNotStarted
  )

  const [newPaypalCustomerPortalSession] = useMutation<
    NewPaypalCustomerPortalSessionMutation,
    NewPaypalCustomerPortalSessionMutationVariables
  >(newPaypalCustomerPortalSessionMutation)

  const { loading, error, execute } = useAsyncCallback<void, []>(async () => {
    const analyticsParams = {
      membership_providence: MembershipProvidence.Paypal
    }
    try {
      const { data } = await newPaypalCustomerPortalSession()
      const url = data?.newPaypalCustomerPortalSession
      if (!url) throw new Error('Failed to get url')
      customerPortalStartedEvent(analyticsParams)
      await openLink(url)
    } catch (err: any) {
      customerPortalNotStartedEvent({ ...analyticsParams, error: err?.message })
      throw err
    }
  })

  return {
    customerPortal: execute,
    isLoading: loading,
    isError: error != null
  }
}

const openLink = async (url: string) => {
  globalThis.location.href = url
  await new Promise((resolve) =>
    globalThis.setTimeout(resolve, timeToWaitForRedirect)
  )
}
