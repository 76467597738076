import type { ReactElement } from 'react'
import { Suspense, lazy, useEffect, useMemo } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { useIsGamersenseiAffiliate } from 'src/hooks/use-affiliate'
import { useRouting } from 'src/routes'
import { useSession } from 'src/hooks/use-session'
import { Redirect } from 'src/routes/Redirect'
import { usePageviewAnalytics, useUserAnalytics } from 'src/hooks/use-analytics'
import { withSentryReactRouterV6Routing } from 'src/lib/analytics/sentry'
import { useDelayedUser } from 'src/hooks/use-user'
import { FullLoading } from 'src/system/FullLoading'
import { RichErrorBoundary, ThrowError } from 'src/system/ErrorBoundary'
import { Login } from 'src/screens/auth/Login'
import { SignUp } from 'src/screens/auth/SignUp'
import { PublicProfile } from 'src/screens/player/PublicProfile'
import { PrivateProfile } from 'src/screens/player/PrivateProfile'
import { Matches } from 'src/screens/matches/Matches'
import { ForgotPassword } from 'src/screens/auth/ForgotPassword'
import { Account } from 'src/screens/account/Account'
import { Welcome } from 'src/screens/Welcome'
import { SignUpSuccess } from 'src/screens/auth/SignUpSuccess'
import { Pricing } from 'src/screens/membership/pricing/Pricing'
import { Page } from 'src/system/ui/layout/Page'
import { ManageMembership } from 'src/screens/membership/manage/ManageMembership'

import { ResetPassword } from '../screens/auth/ResetPassword'

import { VerifyAccount } from './VerifyAccount'
import { NotFound } from './NotFound'
import { HandleCheckout } from './HandleCheckout'
import { HandleAccountLink } from './HandleAccountLink'
import { CookiesPolicy } from './CookiesPolicy'

const Match = lazy(async () => {
  const { Match } = await import('src/screens/match/Match')
  return { default: Match }
})

export function Root() {
  const { error } = useSession()
  if (error) return <ThrowError error={error} />
  return (
    <BrowserRouter>
      <RichErrorBoundary>
        <Suspense fallback={<FullLoading hidden />}>
          <Page>
            <Analytics />
            <RouteTree />
          </Page>
        </Suspense>
      </RichErrorBoundary>
    </BrowserRouter>
  )
}

function Analytics() {
  usePageviewAnalytics()
  return null
}

function RouteTree() {
  const {
    routes: {
      account,
      accountLink,
      checkout,
      forgotPassword,
      login,
      viewer,
      privateProfile,
      publicProfile,
      assessments,
      scoreboard,
      resetPassword,
      root,
      signUp,
      cookiesPolicy,
      verifyCallback,
      signUpSuccess,
      pricing,
      welcome,
      membership
    }
  } = useRouting()

  const SentryRoutes = useMemo(() => withSentryReactRouterV6Routing(Routes), [])

  return (
    <SentryRoutes>
      <Route
        path={login}
        element={
          <UnauthenticatedRoute>
            <Login />
          </UnauthenticatedRoute>
        }
      />

      <Route
        path={signUp}
        element={
          <UnauthenticatedRoute>
            <SignUp />
          </UnauthenticatedRoute>
        }
      />

      <Route
        path={signUpSuccess}
        element={
          <UnauthenticatedRoute>
            <SignUpSuccess />
          </UnauthenticatedRoute>
        }
      />

      <Route
        path={forgotPassword}
        element={
          <UnauthenticatedRoute>
            <ForgotPassword />
          </UnauthenticatedRoute>
        }
      />

      <Route
        path={resetPassword}
        element={
          <UnauthenticatedRoute>
            <ResetPassword />
          </UnauthenticatedRoute>
        }
      />

      <Route
        path={verifyCallback}
        element={
          <UnauthenticatedRoute>
            <VerifyAccount />
          </UnauthenticatedRoute>
        }
      />

      <Route
        path={account}
        element={
          <AuthenticatedRoute>
            <Account />
          </AuthenticatedRoute>
        }
      />

      <Route
        path={root}
        element={
          <ProtectedRoute>
            <Matches />
          </ProtectedRoute>
        }
      />

      <Route
        path={privateProfile}
        element={
          <ProtectedRoute>
            <PrivateProfile />
          </ProtectedRoute>
        }
      />

      <Route
        path={accountLink}
        element={
          <AuthenticatedRoute>
            <HandleAccountLink />
          </AuthenticatedRoute>
        }
      />

      <Route
        path={checkout}
        element={
          <AuthenticatedRoute>
            <HandleCheckout />
          </AuthenticatedRoute>
        }
      />

      <Route path={pricing} element={<Pricing />} />

      <Route path={welcome} element={<Welcome />} />

      <Route
        path={membership}
        element={
          <AuthenticatedRoute>
            <ManageMembership />
          </AuthenticatedRoute>
        }
      />

      <Route path={cookiesPolicy} element={<CookiesPolicy />} />

      <Route
        path={publicProfile}
        element={
          <AuthProtectedRoute>
            <PublicProfile />
          </AuthProtectedRoute>
        }
      />

      <Route path={viewer} element={<Match />} />

      <Route path={assessments} element={<Match />} />

      <Route path={scoreboard} element={<Match />} />

      <Route element={<NotFound />} />
    </SentryRoutes>
  )
}

function UnauthenticatedRoute(props: { children: ReactElement }) {
  const { data, error } = useSession()
  const {
    routes: { root, privateProfile }
  } = useRouting()
  const isGamersenseiAffiliate = useIsGamersenseiAffiliate()

  if (error) return <ThrowError error={error} />
  const isAuthenticated = data?.session?.isAuthenticated ?? false
  if (!isAuthenticated) return props.children
  const redirectRoute = isGamersenseiAffiliate ? privateProfile : root
  return <Redirect to={redirectRoute} />
}

function AuthenticatedRoute(props: { children: ReactElement }) {
  const { session, user, error, isAuthenticated } = useUserSession()
  const {
    routes: { login }
  } = useRouting()

  if (error) return <ThrowError error={error} />
  if (!session) return null
  if (!isAuthenticated) return <Redirect to={login} />
  if (user) return props.children
  return <FullLoading />
}

function AuthProtectedRoute(props: { children: ReactElement }) {
  const { session, user, error, isAuthenticated } = useUserSession()
  if (error) return <ThrowError error={error} />
  if (!session) return null
  if (!isAuthenticated) return props.children
  if (user) return <ProtectedRoute {...props} />
  return <FullLoading />
}

function ProtectedRoute(props: { children: ReactElement }) {
  const { session, user, error, isAuthenticated } = useUserSession()

  const {
    routes: { account, login }
  } = useRouting()

  if (error) return <ThrowError error={error} />
  if (!session) return null
  if (!isAuthenticated) return <Redirect to={login} />

  if (user) {
    const { isAccountLinked, automatch } = user
    const isAutomatchActive = automatch.isActive
    if (isAccountLinked && isAutomatchActive) return props.children
    return <Redirect to={account} />
  }

  return <FullLoading />
}

function useUserSession() {
  const { data, error } = useSession()
  const session = data?.session
  const isAuthenticated = session?.isAuthenticated ?? false
  const { user } = useDelayedUser()
  const setUserAnalytics = useUserAnalytics()
  useEffect(() => {
    setUserAnalytics(user)
  }, [user, setUserAnalytics])
  return { session, user, error, isAuthenticated }
}
