import type { ReactElement } from 'react'
import { createContext, useContext } from 'react'

import type { MatchFieldsFragment } from 'src/graphql/types'

export type Match = MatchFieldsFragment

const MatchContext = createContext<Match | null>(null)

export function MatchProvider(props: {
  match: Match
  children: ReactElement[]
}) {
  return (
    <MatchContext.Provider value={props.match}>
      {props.children}
    </MatchContext.Provider>
  )
}

export function useMatch() {
  const match = useContext(MatchContext)
  if (match === null) {
    throw new Error('useMatch must be used within a MatchProvider')
  }

  return match
}
