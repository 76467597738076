import ReactMarkdown from 'react-markdown'
import styled from '@emotion/styled'

export function Markdown(props: { children: string | null | undefined }) {
  if (props.children == null) return null
  return (
    <Container>
      <ReactMarkdown>{props.children}</ReactMarkdown>
    </Container>
  )
}

const Container = styled.div`
  a {
    color: ${(props) => props.theme.d.colors.link.default};

    &:hover {
      text-decoration: underline;
    }
  }
`
