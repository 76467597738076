import { useTranslation } from 'react-i18next'

import { useUser } from 'src/hooks/use-user'
import { useCheckoutAnalytics } from 'src/hooks/use-checkout-analytics'
import { Verify } from 'src/screens/checkout/Verify'
import { CheckoutFailed } from 'src/screens/checkout/new-member/CheckoutFailed'
import { CheckoutSuccess } from 'src/screens/checkout/new-member/CheckoutSuccess'
import { MembershipLevel, MembershipPlan } from 'src/graphql/types'
import type { MembershipProvidence } from 'src/graphql/types'

export function VerifyNewMember(props: {
  isSuccess: boolean
  providence: MembershipProvidence | null | undefined
  plan: MembershipPlan | null | undefined
}) {
  const { isSuccess, providence, plan } = props
  const { t } = useTranslation()
  const { user } = useUser('network-only', 1000)
  useCheckoutAnalytics(isSuccess, providence, plan)

  const isActive =
    user?.membership?.providence === providence && !!user?.membership?.isActive

  const isLifetimeMembershipActiveIfRequested =
    props.plan === MembershipPlan.Lifetime
      ? user?.membership?.level === MembershipLevel.Mvp
      : true

  const shouldVerify = isSuccess && !isActive

  if (!props.isSuccess) return <Content isSuccess={false} />

  return (
    <Verify
      shouldVerify={shouldVerify}
      verified={isActive && isLifetimeMembershipActiveIfRequested}
      verifyingMessage={t(`checkout:verifying_${providence}`)}
      failedMessage={t('checkout:payment_verification_failure')}
      providence={providence}
    >
      <Content isSuccess />
    </Verify>
  )
}

function Content(props: { isSuccess: boolean }) {
  if (props.isSuccess) {
    return <CheckoutSuccess />
  }

  return <CheckoutFailed />
}
