import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'
import { Meta } from 'src/system/Meta'
import { AppBar } from 'src/system/app-bar/AppBar'
import { StarBackgroundPage } from 'src/system/ui/layout/StarBackgroundPage'
import { PageTitle } from 'src/system/ui/typography/PageTitle'
import { FreePlanCard } from 'src/screens/membership/pricing/FreePlanCard'
import { PremiumPlanCard } from 'src/screens/membership/pricing/PremiumPlanCard'
import { LifetimePlanCard } from 'src/screens/membership/pricing/LifetimePlanCard'
import { PaymentFaq } from 'src/screens/membership/pricing/PaymentFaq'
import { JoinDiscordPanel } from 'src/system/DiscordPanel'

export function Pricing() {
  const { t } = useTranslation()
  const [lifetimeMembershipEnabled] = useFeatureFlags([
    FeatureFlag.lifetimeMembership
  ])

  const cards = [FreePlanCard, PremiumPlanCard]

  if (lifetimeMembershipEnabled) cards.push(LifetimePlanCard)
  const minWidth = 273 * cards.length

  return (
    <>
      <Meta name='pricing' />
      <AppBar />
      <StarBackgroundPage>
        <StyledPageTitle>{t('membership:plans:title')}</StyledPageTitle>
        <Box
          sx={{
            margin: '0 auto',
            marginTop: '100px',
            maxWidth: '1250px',
            display: 'grid',
            gap: 10,
            '> div:nth-of-type(2)': {
              marginTop: '60px;'
            },
            [`@media screen and (min-width: ${minWidth}px)`]: {
              gridTemplateColumns: `repeat(${cards.length}, 1fr)`,
              '> div:nth-of-type(2)': {
                marginTop: 0
              }
            }
          }}
        >
          {cards.map((Card, i) => (
            <Card key={i} />
          ))}
        </Box>
        <Box>
          <PaymentFaq />
        </Box>
        <Box>
          <StyledDiscordPanel
            title={t('discord_panel:questions:title')}
            description={t('discord_panel:questions:description')}
          />
        </Box>
      </StarBackgroundPage>
    </>
  )
}

const StyledPageTitle = styled(PageTitle)`
  text-align: center;
  display: block;
  margin: ${(props) =>
    `${props.theme.d.spacing[8]} 0 ${props.theme.d.spacing[3]} 0`};
`

const StyledDiscordPanel = styled(JoinDiscordPanel)`
  margin-top: ${(props) => props.theme.d.spacing[3]};
`
