import { useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import type { UserFieldsFragment as User } from 'src/graphql/types'
import { UserLinkType } from 'src/graphql/types'
import { useUnlinkAccount } from 'src/hooks/use-unlink-account'
import { Button, ButtonVariant } from 'src/system/ui/button/Button'
import { ConfirmDialog } from 'src/system/ui/dialog/ConfirmDialog'
import { Panel } from 'src/system/ui/layout/Panel'
import { SectionTitle } from 'src/system/ui/typography/SectionTitle'
import { Description } from 'src/screens/account/step/Description'

export function UnlinkSteamAccount(props: { user: User; className?: string }) {
  const { t } = useTranslation()
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
  const [unlink, { loading }] = useUnlinkAccount(UserLinkType.Steam)
  const toggleConfirmDialog = () =>
    setConfirmDialogVisible(!confirmDialogVisible)

  const linkId = props.user.steamId
  if (!linkId) {
    return null
  }

  return (
    <StyledPanel className={props.className} disablePadding={false}>
      <SectionTitle>{t('account:manage:unlink_account_title')}</SectionTitle>
      <Description>
        {t('account:manage:unlink_account_instruction')}
      </Description>
      <Button variant={ButtonVariant.secondary} onClick={toggleConfirmDialog}>
        {t('account:manage:unlink_account_button')}
      </Button>
      <ConfirmDialog
        isVisible={confirmDialogVisible}
        onClose={toggleConfirmDialog}
        processing={loading}
        handleProceed={() => {
          unlink(linkId)
        }}
        title={t('account:manage:unlink_account_dialog_title')}
        description={t('account:manage:unlink_account_dialog_description')}
        proceedLabel={t('account:manage:unlink_account_dialog_confirm_button')}
        cancelLabel={t('account:manage:unlink_account_dialog_cancel_button')}
      />
    </StyledPanel>
  )
}

const StyledPanel = styled(Panel)`
  margin-top: ${(props) => props.theme.d.spacing[4]};
  margin-bottom: ${(props) => props.theme.d.spacing[4]};
`
