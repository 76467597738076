import { gql } from '@apollo/client'

export const userFragment = gql`
  fragment UserFields on User {
    isAccountLinked @client
    username
    steamId
    affiliate {
      code
    }
    automatch {
      isActive
      checkedAt
      triggeredAt
    }
    membership {
      level
      isTrial
      startDate
      endDate
      isActive
      isCanceled
      isPaused
      isTerminated
      providence
    }
  }
`

export const userQuery = gql`
  query User {
    user {
      ...UserFields
    }
  }

  ${userFragment}
`

export const unlinkMutation = gql`
  mutation Unlink($linkId: ID!, $linkType: UserLinkType!) {
    unlink(linkId: $linkId, linkType: $linkType) {
      linkId
      linkType
    }
  }
`

export const addSteamIdKeyMutation = gql`
  mutation AddSteamIdKey($linkId: ID!, $key: String!) {
    addSteamIdKey(linkId: $linkId, key: $key) {
      linkId
      linkType
    }
  }
`

export const removeSteamIdKeyMutation = gql`
  mutation RemoveSteamIdKey($linkId: ID!) {
    removeSteamIdKey(linkId: $linkId) {
      linkId
      linkType
    }
  }
`

export const triggerAutomatchMutation = gql`
  mutation TriggerAutomatch($linkId: ID!) {
    triggerAutomatch(linkId: $linkId) {
      isActive
      checkedAt
      triggeredAt
    }
  }
`

export const newLinkAuthSessionMutation = gql`
  mutation NewLinkAuthSession($callbackUrl: AWSURL!, $linkType: UserLinkType!) {
    newLinkAuthSession(callbackUrl: $callbackUrl, linkType: $linkType)
  }
`

export const newStripeCheckoutSessionMutation = gql`
  mutation NewStripeCheckoutSession(
    $plan: MembershipPlan!
    $applyDiscount: Boolean!
    $callbackUrl: AWSURL!
  ) {
    newStripeCheckoutSession(
      plan: $plan
      applyDiscount: $applyDiscount
      callbackUrl: $callbackUrl
    )
  }
`

export const newStripeCustomerPortalSessionMutation = gql`
  mutation NewStripeCustomerPortalSession($callbackUrl: AWSURL!) {
    newStripeCustomerPortalSession(callbackUrl: $callbackUrl)
  }
`

export const cancelStripeMembershipMutation = gql`
  mutation CancelStripeMembership {
    cancelStripeMembership
  }
`

export const uncancelStripeMembershipMutation = gql`
  mutation UncancelStripeMembership {
    uncancelStripeMembership
  }
`

export const newPaypalCheckoutSessionMutation = gql`
  mutation NewPaypalCheckoutSession(
    $plan: MembershipPlan!
    $applyDiscount: Boolean!
    $callbackUrl: AWSURL!
  ) {
    newPaypalCheckoutSession(
      plan: $plan
      applyDiscount: $applyDiscount
      callbackUrl: $callbackUrl
    )
  }
`

export const newPaypalCustomerPortalSessionMutation = gql`
  mutation NewPaypalCustomerPortalSession {
    newPaypalCustomerPortalSession
  }
`

export const cancelPaypalMembershipMutation = gql`
  mutation CancelPaypalMembership {
    cancelPaypalMembership
  }
`

export const pausePaypalMembershipMutation = gql`
  mutation PausePaypalMembership {
    pausePaypalMembership
  }
`

export const unpausePaypalMembershipMutation = gql`
  mutation UnpausePaypalMembership {
    unpausePaypalMembership
  }
`
