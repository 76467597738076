import styled from '@emotion/styled'

export function Emphasis(props: { children: string }) {
  return <Text {...props} />
}

const Text = styled.strong`
  color: ${(props) => props.theme.d.colors.text.secondary};
  font-weight: bold;
`
