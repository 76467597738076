import styled from '@emotion/styled'
import { useMutation } from '@apollo/client'
import Dropzone from 'react-dropzone-uploader'

import { uploadDemoMutation } from 'src/graphql'
import type {
  UploadDemoMutation,
  UploadDemoMutationVariables
} from 'src/graphql/types'

export function UploadDemo() {
  const [uploadDemo] = useMutation<
    UploadDemoMutation,
    UploadDemoMutationVariables
  >(uploadDemoMutation)

  return (
    <Container>
      <Dropzone
        classNames={classNames}
        getUploadParams={async ({ file }) => {
          const { data } = await uploadDemo()
          const url = data?.uploadDemo
          if (!url) throw new Error('Missing upload URL')
          const contentType = new URL(url).searchParams.get('Content-Type')
          if (!contentType) throw new Error('Missing Content-Type')
          return {
            method: 'PUT',
            body: file,
            headers: { 'Content-Type': contentType },
            url: data?.uploadDemo
          }
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  .dzu-inputLabel {
    color: #fff;
  }
  .dzu-dropzone {
    color: #fff;
    border: none;
    border-radius: 0;
    overflow: hidden;
  }
  .dzu-previewContainer {
    border-bottom: none;
  }
  .dzu-previewFileName {
    color: #fff;
  }
`

const classNames = {}
