import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { useIsProfileGamersenseiAffiliate } from 'src/hooks/use-affiliate'
import logo from 'src/assets/logo.svg'
import gamersenseiLogo from 'src/assets/gamersensei.svg'

export function GamersenseiBranding(props: { profileId: string }) {
  const { t } = useTranslation()
  const isGamersenseiAffiliate = useIsProfileGamersenseiAffiliate(
    props.profileId
  )
  if (!isGamersenseiAffiliate) return null
  return (
    <Container>
      <img src={logo} alt={t('common:name')} />
      <StyledFontAwesomeIcon icon={faPlus} size='lg' />
      <img
        src={gamersenseiLogo}
        alt={t('common:gamersensei')}
        height={50}
        width={200}
      />
    </Container>
  )
}

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  display: block;
  margin-left: 10px;
  height: 50px;
`

const Container = styled.div`
  max-width: 1256px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: ${(props) => props.theme.d.spacing[3]};
  margin-bottom: 0;
  img {
    display: block;
  }
`
