import styled from '@emotion/styled'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function LoadingSpinner(props: { className?: string }) {
  return (
    <FontAwesomeIcon
      icon={faCircleNotch}
      spin
      size='lg'
      className={props.className}
    />
  )
}

export const CenterLoadingSpinner = styled(LoadingSpinner)`
  display: block;
  margin: auto;
`
