import { useTranslation } from 'react-i18next'

import type {
  MatchFieldsFragment as Match,
  PendingMatchFieldsFragment as PendingMatch
} from 'src/graphql/types'
import { UserInterfaceViewMode as ViewMode } from 'src/graphql/types'
import { MatchesGrid } from 'src/screens/matches/collection/grid/MatchesGrid'
import { MatchesList } from 'src/screens/matches/collection/list/MatchesList'
import { LoadingSpinner } from 'src/system/ui/LoadingSpinner'

export function MatchesCollection(props: {
  loading: boolean
  viewMode: ViewMode
  matches: (PendingMatch | Match)[]
}) {
  const { t } = useTranslation()
  const noMatches = props.matches.length === 0

  if (props.loading && noMatches) {
    return <LoadingSpinner />
  }

  if (noMatches) {
    return <div>{t('matches:no_matches_text')}</div>
  }

  if (props.viewMode === ViewMode.Grid) {
    return <MatchesGrid matches={props.matches} />
  }

  if (props.viewMode === ViewMode.List) {
    return <MatchesList matches={props.matches} />
  }

  throw new Error(`Error showing matches; unknown view mode: ${props.viewMode}`)
}
