import { useTranslation } from 'react-i18next'

import { Section } from 'src/screens/account/header/Section'
import { Button, ButtonVariant } from 'src/system/ui/button/Button'
import { useRouting } from 'src/routes'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'
import { useJustUser } from 'src/hooks/use-user'
import { useIsMembershipUpgradable } from 'src/hooks/use-membership'

export function Membership(props: { className?: string }) {
  const { t } = useTranslation()
  const { routes } = useRouting()
  const { user } = useJustUser()

  const isMembershipUpgradable = useIsMembershipUpgradable()

  if (user.membership == null) return null

  const page = isMembershipUpgradable ? routes.pricing : routes.membership

  return (
    <Section className={props.className} title={t('account:header:membership')}>
      <RelativeLink to={page}>
        <Button variant={ButtonVariant.link}>
          {t('account:header:manage_membership')}
        </Button>
      </RelativeLink>
    </Section>
  )
}
