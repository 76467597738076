import type { ReactElement } from 'react'
import { Component } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import type { Children } from 'src/types'
import { useLog } from 'src/lib/hooks/use-log'
import { ErrorBoundary as SentryErrorBoundary } from 'src/lib/analytics/sentry'
import { Page } from 'src/system/ui/layout/Page'
import { AppBar } from 'src/system/app-bar/AppBar'
import { ErrorPage } from 'src/system/ErrorPage'

type Props = {
  render: () => ReactElement
  children: Children
}

type Message = { message?: string }

type ErrorProps = {
  error?: Error | Message
}

function ErrorBoundary(props: Props) {
  const { log } = useLog()
  return (
    <SentryErrorBoundary
      fallback={props.render}
      onError={(error) => {
        log.error(error)
        // UPSTREAM: https://github.com/apollographql/apollo-client/issues/7608#issue-791969580
        if (error.message === 'Observable cancelled prematurely') {
          globalThis.location.reload()
        }
      }}
    >
      {props.children}
    </SentryErrorBoundary>
  )
}

export function FatalErrorBoundary(props: { children: Children }) {
  const render = () => <ErrorIcon />
  return <ErrorBoundary render={render}>{props.children}</ErrorBoundary>
}

export function LocalErrorBoundary(props: { children: Children }) {
  const render = () => <ErrorIcon />
  return <ErrorBoundary render={render}>{props.children}</ErrorBoundary>
}

export function RichErrorBoundary(props: { children: Children }) {
  const { t } = useTranslation()
  const title = t('error:headline')
  const description = t('error:generic_description')

  const render = () => (
    <Page>
      <AppBar />
      <ErrorPage title={title} description={description} />
    </Page>
  )

  return <ErrorBoundary render={render}>{props.children}</ErrorBoundary>
}

export function FatalError(props: ErrorProps) {
  return (
    <FatalErrorBoundary>
      <ThrowError {...props} />
    </FatalErrorBoundary>
  )
}

export class ThrowError extends Component<ErrorProps> {
  componentDidMount() {
    const { error } = this.props
    if (error instanceof Error) throw error
    if (error?.message) throw new Error(error.message)
    if (error) throw new Error('Unknown error')
  }

  render() {
    return null
  }
}

function ErrorIcon() {
  return <Icon icon={faExclamationTriangle} size='6x' />
}

const Icon = styled(FontAwesomeIcon)`
  display: block;
  margin: auto;
  padding: 100px;
  color: #fff;
`
