import type { ReactNode } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@mui/system'

import { LoadingSpinner } from 'src/system/ui/LoadingSpinner'

export enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  accent = 'accent',
  danger = 'danger',
  warning = 'warning',
  text = 'text',
  link = 'link'
}

export interface ButtonProps {
  children: ReactNode
  className?: string
  onClick?: () => void
  type?: 'button' | 'submit'
  fullWidth?: boolean
  variant?: ButtonVariant
  disabled?: boolean
  cursor?: string
  pointerEvents?: string
  color?: string
  'aria-label'?: string
  processing?: boolean
}

type OptionalProps = Pick<ButtonProps, 'disabled' | 'color'>

type StyleProps = {
  width?: string
  background?: string
  border?: string
  padding: string
  fontFamily: string
  underline: boolean
}

export function Button(props: ButtonProps) {
  const width = props.fullWidth ? '100%' : 'auto'

  const defaultVariant = ButtonVariant.primary
  const variant = props.variant ?? defaultVariant

  const background = useBackground(variant, {
    disabled: props.disabled,
    color: props.color
  })
  const border = useBorder(variant)
  const cursor = props.disabled ? 'default' : 'pointer'
  const underline = props.variant === ButtonVariant.link
  const pointerEvents = props.disabled || props.processing ? 'none' : 'all'
  const color = useColor(variant, {
    disabled: props.disabled
  })
  const padding = usePadding(variant)
  const fontFamily = useFontFamily(variant)

  return (
    <StyledButton
      className={props.className}
      onClick={props.onClick}
      width={width}
      type={props.type ?? 'submit'}
      background={background}
      border={border}
      cursor={cursor}
      pointerEvents={pointerEvents}
      color={color}
      aria-label={props['aria-label']}
      disabled={!!props.disabled}
      padding={padding}
      fontFamily={fontFamily}
      underline={underline}
    >
      <Content processing={props.processing}>{props.children}</Content>
    </StyledButton>
  )
}

function Content(props: { children: ReactNode; processing?: boolean }) {
  if (props.processing) {
    return <LoadingSpinner />
  }

  return <>{props.children}</>
}

function useBackground(
  variant: ButtonVariant,
  { disabled, color }: OptionalProps
) {
  const theme = useTheme()

  if (variant === ButtonVariant.text || variant === ButtonVariant.link) {
    return 'transparent'
  }

  if (disabled) {
    return theme.d.colors.button.disabled.background
  }

  if (color) {
    return color
  }

  if (variant === ButtonVariant.accent) {
    return theme.d.colors.accent
  }

  if (variant === ButtonVariant.danger) {
    return theme.d.colors.button.danger
  }

  if (variant === ButtonVariant.secondary) {
    return theme.d.colors.button.secondary
  }

  return theme.d.colors.button.primary
}

function useBorder(variant: ButtonVariant) {
  const theme = useTheme()

  if (variant === ButtonVariant.text) {
    return 'none'
  }

  if (variant === ButtonVariant.secondary) {
    return `1px solid ${theme.d.colors.border.light}`
  }

  return 'none'
}

function useColor(variant: ButtonVariant, { disabled }: OptionalProps) {
  const theme = useTheme()
  if (disabled) {
    return theme.d.colors.button.disabled.text
  }

  if (variant === ButtonVariant.link) {
    return theme.d.colors.link.default
  }

  if (variant === ButtonVariant.text) {
    return theme.d.colors.button.primary
  }

  return theme.d.colors.text.secondary
}

function usePadding(variant: ButtonVariant = ButtonVariant.primary) {
  const theme = useTheme()

  if (variant === ButtonVariant.link) {
    return '0'
  }

  if (variant === ButtonVariant.text) {
    return '4px'
  }

  return `${theme.d.spacing[3]} ${theme.d.spacing[7]}`
}

function useFontFamily(variant: ButtonVariant) {
  const theme = useTheme()

  if (variant === ButtonVariant.link) {
    return theme.d.font.primary
  }

  return theme.d.font.secondary
}

const StyledButton = styled.button<ButtonProps & StyleProps>`
  padding: ${(props) => props.padding};
  font-weight: normal;
  border-radius: 4px;
  background-color: ${(props) => props.background};
  font-family: ${(props) => props.fontFamily};
  border: ${(props) => props.border};
  width: ${(props) => props.width};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => props.cursor};
  color: ${(props) => props.color};
  pointer-events: ${(props) => props.pointerEvents};

  svg {
    margin-right: 6px;
  }

  &:hover {
    text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  }
`
