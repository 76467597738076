import type { ReactElement } from 'react'
import styled from '@emotion/styled'

export function ContentDesktop(props: { content?: ReactElement }) {
  if (!props.content) {
    return null
  }

  return <ContentDesktopContainer>{props.content}</ContentDesktopContainer>
}

export function ContentMobile(props: {
  content?: ReactElement
  className?: string
}) {
  if (!props.content) {
    return null
  }

  return <ContentMobileContainer>{props.content}</ContentMobileContainer>
}

export const Spacer = styled.div<{
  withContent: boolean
}>`
  flex: 1;

  @media (min-width: ${(props) => props.theme.d.breakpoints.lg}) {
    display: ${(props) => (props.withContent ? 'none' : 'block')};
  }
`

const ContentDesktopContainer = styled.div`
  flex: 1;
  height: 100%;
  margin-right: ${(props) => props.theme.d.spacing[3]};

  display: none;
  @media (min-width: ${(props) => props.theme.d.breakpoints.lg}) {
    display: block;
  }
`

const ContentMobileContainer = styled.div`
  height: 50px;

  display: block;
  @media (min-width: ${(props) => props.theme.d.breakpoints.lg}) {
    display: none;
  }
`
