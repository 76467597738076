import styled from '@emotion/styled'
import { useTheme } from '@mui/system'
import { useTranslation } from 'react-i18next'

import { isPlaceholder } from 'src/lib/matches/map-image'
import { Badge } from 'src/screens/matches/collection/match/Badge'
import { PlatformIcon } from 'src/system/ui/match/PlatformIcon'
import { formattedDate } from 'src/lib/matches/matches'
import {
  Status,
  useComputedStatus
} from 'src/screens/matches/collection/match/status/Status'
import { ViewMatchButton } from 'src/screens/matches/collection/list/ViewMatchButton'
import type { MatchProps } from 'src/screens/matches/collection/MatchCard'
import { MatchLink } from 'src/screens/matches/collection/match/MatchLink'
import { StatusDescription } from 'src/screens/matches/collection/match/status/StatusDescription'
import { Score } from 'src/system/ui/match/Score'
import { PendingMatchStatus } from 'src/graphql/types'

export function CardDesktop(
  props: MatchProps & {
    background: string
  }
) {
  const { t } = useTranslation()
  const status = useComputedStatus(
    props.updatedAt,
    props.status,
    props.courseProgress
  )
  const canViewMatch = status === PendingMatchStatus.Completed
  return (
    <StyledMatchLink link={canViewMatch ? props.link : null}>
      <Map
        background={props.background}
        isPlaceholderBackground={isPlaceholder(props.background)}
      >
        <BadgeContainer>
          <StyledBadge map={props.map} />
        </BadgeContainer>
        <StyledScore
          ctScore={props.ctScore}
          tScore={props.tScore}
          players={props.players}
          didWin={props.didWin}
          isTie={props.isTie}
          isOwnerInMatch={props.isOwnerInMatch}
        />
        <IconContainer>
          <PlatformIcon size={25} platform={props.platform} />
        </IconContainer>
      </Map>
      <Stats>
        <Section>
          <SectionTitle>{t('matches:upload_date_label')}</SectionTitle>
          <SectionBody>{formattedDate(props.uploadDate) ?? '-'}</SectionBody>
        </Section>
        <Section>
          <SectionTitle>{t('matches:match_label')}</SectionTitle>
          <SectionBody>
            <MatchResult didWin={props.didWin} isTie={props.isTie} />
          </SectionBody>
        </Section>
        <Section>
          <SectionTitle>{t('matches:status_label')}</SectionTitle>
          <SectionBody>
            <Status
              current={props.status}
              updatedAt={props.updatedAt}
              createdAt={props.createdAt}
              courseProgress={props.courseProgress}
            />
            <StyledStatusDescription
              status={props.status}
              updatedAt={props.updatedAt}
              courseProgress={props.courseProgress}
            />
          </SectionBody>
        </Section>
      </Stats>
      <ViewMatchContainer>
        <ViewMatchButton disabled={!canViewMatch} />
      </ViewMatchContainer>
    </StyledMatchLink>
  )
}

function MatchResult(props: { didWin?: boolean; isTie?: boolean }) {
  const { t } = useTranslation()
  const theme = useTheme()

  if (props.didWin === undefined) {
    return <Result>-</Result>
  }

  if (props.isTie) {
    return (
      <Result color={theme.d.colors.button.disabled.text}>
        {t('matches:result_tie')}
      </Result>
    )
  }

  if (props.didWin) {
    return (
      <Result color={theme.d.colors.success}>{t('matches:result_win')}</Result>
    )
  }

  return (
    <Result color={theme.d.colors.error}>{t('matches:result_lost')}</Result>
  )
}

const IconContainer = styled.div`
  margin-left: 25px;
`

const StyledMatchLink = styled(MatchLink)`
  height: 100px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.d.colors.border.dark};
  border-radius: 4px;
  margin-bottom: ${(props) => props.theme.d.spacing[3]};
  background: ${(props) => props.theme.d.colors.panel.light};
  line-height: 14px;

  display: none;
  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    display: flex;
  }
`

const Map = styled.div<{
  background: string
  isPlaceholderBackground: boolean
}>`
  background: ${(props) => props.theme.d.colors.mapCardBackground},
    url(${(props) => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.d.spacing[4]};

  width: 270px;
  @media (min-width: ${(props) => props.theme.d.breakpoints.lg}) {
    width: 300px;
  }
  @media (min-width: ${(props) => props.theme.d.breakpoints.xl}) {
    width: 350px;
  }
`

const badgeSize = '80px'

const BadgeContainer = styled.div`
  width: ${badgeSize};
  height: ${badgeSize};
  display: flex;
  align-items: center;
  margin-right: ${(props) => props.theme.d.spacing[5]};
  justify-content: center;
`

const StyledBadge = styled(Badge)`
  max-width: ${badgeSize};
  max-height: ${badgeSize};
`

const StyledScore = styled(Score)`
  font-size: 30px;
`

const Stats = styled.div`
  display: flex;
  align-items: center;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${(props) => props.theme.d.colors.border.divider};
  height: 42px;
  box-sizing: border-box;

  padding: 0 ${(props) => props.theme.d.spacing[8]};
  @media (min-width: ${(props) => props.theme.d.breakpoints.lg}) {
    padding: 0 ${(props) => props.theme.d.spacing[12]};
  }

  @media (min-width: ${(props) => props.theme.d.breakpoints.xl}) {
    padding: 0 ${(props) => props.theme.d.spacing[15]};
  }

  &:last-of-type {
    border-right: none;
  }
`

const SectionTitle = styled.span`
  font-size: 11px;
  margin-bottom: ${(props) => props.theme.d.spacing[2]};
`

const SectionBody = styled.div`
  color: ${(props) => props.theme.d.colors.text.secondary};
  font-size: 12px;
`

const Result = styled.span<{
  color?: string
}>`
  color: ${(props) => props.color ?? props.theme.d.colors.text.secondary};
`

const StyledStatusDescription = styled(StatusDescription)`
  display: block;
  margin-top: ${(props) => props.theme.d.spacing[1]};
`

const ViewMatchContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding: ${(props) => props.theme.d.spacing[8]};
`
