import type { ReactNode } from 'react'
import styled from '@emotion/styled'

export function TopBar(props: { children: ReactNode }) {
  return <Container {...props} />
}

const Container = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.d.colors.border.light};

  padding: 0 ${(props) => props.theme.d.spacing[4]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    padding: 0 ${(props) => props.theme.d.spacing[9]};
  }
`
