import { ApolloProvider } from '@apollo/client'
import type { i18n as I18n } from 'i18next'
import { I18nextProvider } from 'react-i18next'
import { CookiesProvider } from 'react-cookie'
import type { EmotionCache } from '@emotion/cache'
import { HelmetProvider } from 'react-helmet-async'
import { useAsync } from 'react-async-hook'

import type { AuthClient } from 'src/lib/auth-client'
import type { ApolloClient } from 'src/lib/apollo-client'
import type { Logger } from 'src/lib/analytics/logger'
import type { Sentry } from 'src/lib/analytics/sentry'
import type { Posthog } from 'src/lib/analytics/posthog'
import { LogProvider } from 'src/lib/hooks/use-log'
import { FeatureFlagProvider } from 'src/hooks/use-feature-flags'
import { AnalyticsProvider } from 'src/hooks/use-analytics'
import { AuthProvider } from 'src/hooks/use-auth'
import type { StripeClient } from 'src/lib/hooks/use-stripe'
import { StripeProvider } from 'src/lib/hooks/use-stripe'
import { FullLoading } from 'src/system/FullLoading'
import { FatalError, FatalErrorBoundary } from 'src/system/ErrorBoundary'
import { NewVersion } from 'src/system/NewVersion'
import { TrackingConsent } from 'src/system/TrackingConsent'
import type { TrackingConsent as TrackingConsentClient } from 'src/lib/analytics/tracking-consent'
import { RoutesProvider } from 'src/routes'
import type { Routing } from 'src/routes'
import { Root } from 'src/routes/Root'
import { ThemeProvider } from 'src/system/ui/theme/provider'

type Init = () => Promise<void>

type Props = {
  authClient: AuthClient
  apolloClient: ApolloClient
  routing: Routing
  init: Init
  i18n: I18n
  getStripeClient: () => StripeClient | null
  sentry: Sentry
  posthog: Posthog
  trackingConsent: TrackingConsentClient
  identifyUser: (userId: string | null, username: string | null) => void
  version: string
  emotionCache: EmotionCache
  log: Logger
}

export function App({
  authClient,
  apolloClient,
  routing,
  init,
  i18n,
  getStripeClient,
  posthog,
  sentry,
  trackingConsent,
  identifyUser,
  version,
  emotionCache,
  log
}: Props) {
  const { loading, error } = useAsync<void, []>(init, [])

  if (loading) return <FullLoading />
  if (error != null) return <FatalError error={error} />

  return (
    <FatalErrorBoundary>
      <LogProvider log={log} sentry={sentry}>
        <FatalErrorBoundary>
          <AnalyticsProvider
            trackingConsent={trackingConsent}
            identifyUser={identifyUser}
            posthog={posthog}
          >
            <CookiesProvider>
              <FeatureFlagProvider posthog={posthog}>
                <I18nextProvider i18n={i18n}>
                  <HelmetProvider>
                    <RoutesProvider routing={routing}>
                      <AuthProvider client={authClient}>
                        <ApolloProvider client={apolloClient}>
                          <StripeProvider client={getStripeClient()}>
                            <ThemeProvider cache={emotionCache}>
                              <Root />
                              <NewVersion version={version} />
                              <TrackingConsent />
                            </ThemeProvider>
                          </StripeProvider>
                        </ApolloProvider>
                      </AuthProvider>
                    </RoutesProvider>
                  </HelmetProvider>
                </I18nextProvider>
              </FeatureFlagProvider>
            </CookiesProvider>
          </AnalyticsProvider>
        </FatalErrorBoundary>
      </LogProvider>
    </FatalErrorBoundary>
  )
}
