import { useTranslation } from 'react-i18next'

import { useUser } from 'src/hooks/use-user'
import { MembershipProvidence } from 'src/graphql/types'
import { ToggleComplete } from 'src/screens/checkout/toggle/ToggleComplete'
import { Verify } from 'src/screens/checkout/Verify'

export function VerifyToggle(props: { canceled: boolean }) {
  const { t } = useTranslation()
  const { user } = useUser('network-only', 1000)

  const uncanceled = !props.canceled

  const alreadyCanceledStripe =
    user?.membership?.providence === MembershipProvidence.Stripe &&
    user?.membership?.isCanceled === true
  const alreadyCanceledPaypal =
    user?.membership?.providence === MembershipProvidence.Paypal &&
    user?.membership?.isPaused === true
  const alreadyCanceled = alreadyCanceledStripe || alreadyCanceledPaypal

  const verifyingMessage = props.canceled
    ? t('checkout:verifying_membership_canceled')
    : t('checkout:verifying_membership_uncanceled')
  const failedMessage = props.canceled
    ? t('checkout:cancel_verification_failure')
    : t('checkout:uncancel_verification_failure')

  const wasCanceled = props.canceled && !alreadyCanceled
  const wasUncanceled = uncanceled && alreadyCanceled
  const wasUpdated = wasCanceled || wasUncanceled

  const hasUpdate = !user || wasUpdated

  return (
    <Verify
      shouldVerify={hasUpdate}
      verified={!hasUpdate}
      verifyingMessage={verifyingMessage}
      failedMessage={failedMessage}
      providence={user?.membership?.providence}
    >
      <ToggleComplete canceled={props.canceled} />
    </Verify>
  )
}
