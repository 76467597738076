import { useJustUser } from 'src/hooks/use-user'
import { MembershipLevel } from 'src/graphql/types'
import {
  useIsCsconfederationAffiliate,
  useIsGamersenseiAffiliate
} from 'src/hooks/use-affiliate'
import type { PerkType } from 'src/system/perk/perks'
import { PerkRole, usePerkAcls } from 'src/system/perk/perks'

export function usePerks(perks: PerkType[] = []) {
  const roles = usePerkRoles()
  const acls = usePerkAcls()
  return perks.map(
    (perk) => acls[perk].find((role) => roles.includes(role)) != null
  )
}

function usePerkRoles() {
  const { user } = useJustUser()
  const isGamersenseiAffiliate = useIsGamersenseiAffiliate()
  const isCsconfederationAffiliate = useIsCsconfederationAffiliate()
  const isMember = user?.membership?.isActive ?? false
  const isPromotional = user?.membership?.level === MembershipLevel.Promotional
  const isMvp = user?.membership?.level === MembershipLevel.Mvp

  const roles = []

  if (isMember) {
    const membershipRole = isPromotional
      ? PerkRole.promotional
      : PerkRole.premium
    roles.push(membershipRole)
  }

  if (isGamersenseiAffiliate) roles.push(PerkRole.gamersensei)
  if (isCsconfederationAffiliate) roles.push(PerkRole.csconfederation)
  if (isMember && isMvp) roles.push(PerkRole.mvp)

  return roles
}
