import type { ReactNode } from 'react'

export enum LinkColor {
  'green' = 'green'
}

export interface LinkBaseProps {
  className?: string
  children: string | ReactNode
  disabled?: boolean
}

export interface LinkStyleProps {
  disableStyles?: boolean
  noUnderline?: boolean
  color?: LinkColor
}

export type LinkProps = LinkBaseProps & LinkStyleProps
