import { useState } from 'react'
import { DateTime, Duration } from 'luxon'

import { useInterval } from 'src/lib/hooks/use-interval'
import { PendingMatchStatus } from 'src/graphql/types'

const interval = Duration.fromObject({ seconds: 1 }).as('milliseconds')
const avgMatchTime = Duration.fromObject({ minutes: 5 }).as('milliseconds')
const progressAtAvgMatchTime = 0.9

const doneStatuses = [PendingMatchStatus.Completed, PendingMatchStatus.Failed]

export function useProgress(createdAt: string, status: PendingMatchStatus) {
  const isDone = doneStatuses.includes(status)
  const initialProgress = isDone ? 1 : 0
  const [progress, setProgress] = useState(initialProgress)

  useInterval(() => {
    if (isDone) return
    const t = Math.abs(
      DateTime.fromISO(createdAt).diffNow('milliseconds').milliseconds
    )
    const p = asymptoticProgress(t)
    setProgress(Math.min(1, p))
  }, interval)

  return progress
}

const asymptoticProgress = (t: number) => {
  const a =
    (-1.0 * Math.log(2 / (progressAtAvgMatchTime + 1) - 1)) / avgMatchTime
  return 2.0 / (1.0 + Math.exp(-1.0 * t * a)) - 1.0
}
