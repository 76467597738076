import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import type { Department, Mark } from 'src/graphql/types'
import { Facet } from 'src/screens/match/assessments/assignment-list/assignment/expansion/marks/facet/Facet'

export function Summary(props: {
  mark: Mark
  className?: string
  department: Department
}) {
  const round = props.mark.start.round
  const facets = props.mark.facets

  return (
    <Box className={props.className}>
      <RoundFacet round={round} />
      {facets.map((facet) => (
        <Facet
          key={facet.id}
          department={props.department}
          facet={facet}
          render={(data) => (
            <Item>
              <Title>{data.title}</Title>
              <Value>{data.value}</Value>
            </Item>
          )}
        />
      ))}
    </Box>
  )
}

function RoundFacet(props: { round: number | null | undefined }) {
  const { t } = useTranslation()
  if (props.round == null) return null
  return (
    <Item>
      <Title>{t('assessments:assignment:round')}</Title>
      <Value>{props.round}</Value>
    </Item>
  )
}

const Box = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  row-gap: ${(props) => props.theme.d.spacing[3]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    grid-template-columns: auto auto auto;
  }

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    flex: 1;
    grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 ${(props) => props.theme.d.spacing[2]};
  overflow: hidden;
`

const Title = styled.span`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 6px;
`

const Value = styled.div`
  font-size: 16px;
  line-height: 21px;
  color: ${(props) => props.theme.d.colors.text.secondary};

  p,
  span {
    font-size: inherit;
  }
`
