import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Snackbar } from '@mui/material'

import { useRouting } from 'src/routes'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'
import { useTrackingConsentAnalytics } from 'src/hooks/use-analytics'

export function TrackingConsent() {
  const { routes } = useRouting()
  const { t } = useTranslation()
  const { setOptOut, isNotOptedInOrOut } = useTrackingConsentAnalytics()
  const [isSubmited, setIsSubmited] = useState(false)

  const handleOptInOrOut = (optedOut: boolean) => () => {
    setOptOut(optedOut)
    setIsSubmited(true)
  }

  return (
    <Snackbar
      open={!isSubmited && isNotOptedInOrOut}
      message={
        <Trans i18nKey='tracking_consent:notice'>
          {' '}
          <AbsoluteLink to={routes.cookiesPolicy}> </AbsoluteLink>
        </Trans>
      }
      action={
        <>
          <Button
            color='inherit'
            size='small'
            onClick={handleOptInOrOut(false)}
          >
            {t('tracking_consent:agree')}
          </Button>
          <Button color='primary' size='small' onClick={handleOptInOrOut(true)}>
            {t('tracking_consent:decline')}
          </Button>
        </>
      }
    />
  )
}
