import { DateTime, Duration } from 'luxon'
import { useState } from 'react'

import { useInterval } from 'src/lib/hooks/use-interval'

const refreshRate = Duration.fromObject({ seconds: 1 }).as('milliseconds')
const timeoutDuration = Duration.fromObject({ hours: -1 })

export function useTimedOut(updatedAt: string) {
  const lastUpdate = DateTime.fromISO(updatedAt)

  const [now, setNow] = useState(DateTime.local())

  useInterval(() => {
    setNow(DateTime.local())
  }, refreshRate)

  return lastUpdate.diff(now, ['milliseconds']) < timeoutDuration
}
