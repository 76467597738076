import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import {
  HeaderPrice,
  PlanCard,
  PlanLevel,
  SignUpButton,
  useFeatures
} from 'src/screens/membership/pricing/PlanCard'
import { useMaybeUser } from 'src/hooks/use-user'
import { useIsMembershipUpgradable } from 'src/hooks/use-membership'

const level = PlanLevel.basic
const namespace = `membership:plans:${level}`

export function FreePlanCard(props: { className?: string }) {
  const { t } = useTranslation()
  const features = useFeatures(level)

  return (
    <PlanCard
      color={PlanLevel.basic}
      title={t(`${namespace}:title`)}
      header={<HeaderPrice>{t(`${namespace}:price`)}</HeaderPrice>}
      features={features}
      footer={<Active />}
      className={props.className}
    />
  )
}

function Active() {
  const { t } = useTranslation()
  const { user } = useMaybeUser()
  const isMembershipUpgradable = useIsMembershipUpgradable()

  if (user == null) {
    return <SignUpButton />
  }

  if (isMembershipUpgradable) {
    return <ActiveText>{t(`${namespace}:active`)}</ActiveText>
  }

  return <ActiveText>{t(`${namespace}:inactive`)}</ActiveText>
}

const ActiveText = styled.span`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`
