import type {
  Facet,
  FacetFloatFieldsFragment,
  FacetFloatListFieldsFragment
} from 'src/graphql/types'
import { FacetShape, FacetUnit } from 'src/graphql/types'

type FacetFloat = Facet & FacetFloatFieldsFragment
export const isFacetFloat = (facet: Facet): facet is FacetFloat =>
  facet.shape === FacetShape.Float

export function FacetFloatValue(props: { facet: FacetFloat }) {
  return <span>{format(props.facet.unit, props.facet.floatValue)}</span>
}

type FacetFloatList = Facet & FacetFloatListFieldsFragment
export const isFacetFloatList = (facet: Facet): facet is FacetFloatList =>
  facet.shape === FacetShape.FloatList

export function FacetFloatListValue(props: { facet: FacetFloatList }) {
  return (
    <span>
      {props.facet.floatValues
        .map((val) => format(props.facet.unit, val))
        .join(', ')}
    </span>
  )
}

function format(unit: FacetUnit, value: number) {
  if (unit === FacetUnit.Percent) {
    const rounded = Math.round(value * 100)
    return `${rounded}%`
  }

  return value
}
