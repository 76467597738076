import { useState } from 'react'
import styled from '@emotion/styled'
import type { DeepMap, FieldError } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Button } from 'src/system/ui/button/Button'
import { TextField } from 'src/system/ui/form/TextField'
import { AnalyticsEvent, useEventAnalytics } from 'src/hooks/use-analytics'
import {
  addSharelinkMutation,
  matchesQuery,
  pageSize,
  userQuery
} from 'src/graphql'
import { MatchPlatform } from 'src/graphql/types'
import type {
  AddSharelinkMutation,
  AddSharelinkMutationVariables
} from 'src/graphql/types'
import { MatchTokenAdded } from 'src/screens/account/step/auto-download/MatchTokenAdded'

type FormData = {
  matchCode: string
}

export function MatchTokenInput(props: {
  matchToken: string | null
  setMatchCode: (code: string | null) => void
  matchPlatform: MatchPlatform
}) {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors }
  } = useForm<FormData>({ defaultValues: { matchCode: '' } })
  const [submitError, setSubmitError] = useState<string | null>(null)
  const [addSharelink, { loading }] = useMutation<
    AddSharelinkMutation,
    AddSharelinkMutationVariables
  >(addSharelinkMutation)
  const validationError = useValidationErrorMessage(errors, props.matchPlatform)

  const automatchSharelinkAddedEvent = useEventAnalytics(
    AnalyticsEvent.automatchSharelinkAdded
  )
  const automatchSharelinkNotAddedEvent = useEventAnalytics(
    AnalyticsEvent.automatchSharelinkNotAdded
  )

  if (props.matchToken) {
    return <MatchTokenAdded matchPlatform={props.matchPlatform} />
  }

  const handleSuccess = () => {
    const { matchCode } = getValues()
    props.setMatchCode(matchCode)
    automatchSharelinkAddedEvent()
    reset({ matchCode: '' })
  }

  const submit = async () => {
    setSubmitError(null)
    const { matchCode } = getValues()

    try {
      await addSharelink({
        variables: {
          platform: props.matchPlatform,
          sharelink: matchCode.trim()
        },
        refetchQueries: [
          { query: userQuery },
          { query: matchesQuery, variables: { pageSize } }
        ]
      })
      handleSuccess()
    } catch (err: any) {
      if (!err.graphQLErrors) {
        const msg = err.message ?? err
        setSubmitError(msg)
        automatchSharelinkNotAddedEvent({ error: msg })
        return
      }

      const gqlMsg = err.graphQLErrors[0].message
      setSubmitError(gqlMsg)
      automatchSharelinkNotAddedEvent({ error: gqlMsg })
    }
  }

  return (
    <>
      <SubmitError error={submitError} />
      <Form onSubmit={handleSubmit(submit)}>
        <StyledInput
          fullWidth
          placeholder={t(
            `account:match_token:${props.matchPlatform}:paste_match_token_placeholder`
          )}
          hideDetails
          error={validationError}
          disabled={loading}
          {...register('matchCode', {
            required: `${t(
              `account:match_token:${props.matchPlatform}:match_token_required_error`
            )}`,
            pattern: matchCodeRegex[props.matchPlatform]
          })}
        />
        <StyleButton processing={loading}>
          {t(
            `account:match_token:${props.matchPlatform}:add_match_token_button`
          )}
        </StyleButton>
      </Form>
      <ValidationError>{validationError}</ValidationError>
    </>
  )
}

function useValidationErrorMessage(
  errors: DeepMap<Record<string, any>, FieldError>,
  matchPlatform: MatchPlatform
) {
  const { t } = useTranslation()

  const { matchCode } = errors
  if (!matchCode) {
    return ''
  }

  if (matchCode.message) {
    return matchCode.message
  }

  if (matchCode.type === 'pattern') {
    return t(`account:match_token:${matchPlatform}:invalid_match_token`)
  }

  return t(
    `account:match_token:${matchPlatform}:match_token_unknown_form_error`
  )
}

function ValidationError(props: { children: string }) {
  if (!props.children) {
    return null
  }

  return <CodeErrorText>{props.children}</CodeErrorText>
}

function SubmitError(props: { error: string | null }) {
  if (!props.error) {
    return null
  }

  return <SubmitErrorText>{props.error}</SubmitErrorText>
}

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    flex-direction: row;
  }
`

const StyledInput = styled(TextField)`
  flex: 1;
  margin-right: ${(props) => props.theme.d.spacing[6]};
`
const matchCodeRegex = {
  [MatchPlatform.Steam]: /CSGO(-[a-zA-Z0-9]{5}){5}/,
  [MatchPlatform.Faceit]: /[0-9]-([a-fZ0-9-]+)/,
  [MatchPlatform.Unknown]: /.*/
}

const StyleButton = styled(Button)`
  padding: ${(props) =>
    `${props.theme.d.spacing[3]} ${props.theme.d.spacing[12]}`};
`

const CodeErrorText = styled.span`
  color: ${(props) => props.theme.d.colors.error};
`

const SubmitErrorText = styled.span`
  color: ${(props) => props.theme.d.colors.error};
  text-align: center;
  display: block;
  font-size: 16px;
  margin-bottom: ${(props) => props.theme.d.spacing[4]};
`
