import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Button, ButtonVariant } from 'src/system/ui/button/Button'
import { UploadDemo } from 'src/screens/account/adhoc/UploadDemo'
import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'
import { Panel } from 'src/system/ui/layout/Panel'
import { PerkProtector } from 'src/system/perk/PerkProtector'
import { PerkType } from 'src/system/perk/perks'
import { SectionTitle } from 'src/system/ui/typography/SectionTitle'
import { Description } from 'src/screens/account/step/Description'

export function ManageUpload() {
  const [featureEnabled] = useFeatureFlags([FeatureFlag.adhocDemo])
  if (!featureEnabled) return null
  return (
    <PerkProtector perk={PerkType.adhoc} renderGate={MembershipRequired}>
      <Content />
    </PerkProtector>
  )
}

function MembershipRequired(props: { onClick: () => void }) {
  const { t } = useTranslation()
  return (
    <StyledPanel>
      <SectionTitle>{t('account:adhoc:title')}</SectionTitle>
      <Button onClick={props.onClick} variant={ButtonVariant.accent}>
        {t('account:adhoc:upgrade_button')}
      </Button>
    </StyledPanel>
  )
}

function Content() {
  const { t } = useTranslation()
  return (
    <StyledPanel>
      <SectionTitle>{t('account:adhoc:title')}</SectionTitle>
      <Description>{t('account:adhoc:subtitle')}</Description>
      <UploadDemo />
    </StyledPanel>
  )
}

const StyledPanel = styled(Panel)`
  margin-top: ${(props) => props.theme.d.spacing[4]};
`
