import type {
  Facet,
  FacetIntFieldsFragment,
  FacetIntListFieldsFragment
} from 'src/graphql/types'
import { FacetShape, FacetUnit } from 'src/graphql/types'

const hasIntegerValues = (facet: Facet) => {
  return facet.unit === FacetUnit.Count || facet.unit === FacetUnit.Money
}

type FacetInt = Facet & FacetIntFieldsFragment
export const isFacetInt = (facet: Facet): facet is FacetInt =>
  facet.shape === FacetShape.Int && hasIntegerValues(facet)

export function FacetIntValue(props: { facet: FacetInt }) {
  return <span>{format(props.facet.unit, props.facet.intValue)}</span>
}

type FacetIntList = Facet & FacetIntListFieldsFragment
export const isFacetIntList = (facet: Facet): facet is FacetIntList =>
  facet.shape === FacetShape.IntList && hasIntegerValues(facet)

export function FacetIntListValue(props: { facet: FacetIntList }) {
  return (
    <span>
      {props.facet.intValues.map((i) => format(props.facet.unit, i)).join(', ')}
    </span>
  )
}

export function format(unit: FacetUnit, value: number) {
  if (unit === FacetUnit.Money) {
    return `$${value}`
  }

  return String(value)
}
