import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Button, ButtonVariant } from 'src/system/ui/button/Button'
import { PerkProtector } from 'src/system/perk/PerkProtector'
import { PerkType } from 'src/system/perk/perks'

export function FetchMoreMatchesButton(props: {
  hasMoreMatches: boolean
  handleClick: () => void
  processing: boolean
  className?: string
}) {
  const { t } = useTranslation()

  if (!props.hasMoreMatches) {
    return null
  }

  return (
    <PerkProtector perk={PerkType.matchHistory} renderGate={UnlockButton}>
      <StyledButton
        onClick={props.handleClick}
        fullWidth
        processing={props.processing}
        className={props.className}
      >
        {t('matches:more_matches_button')}
      </StyledButton>
    </PerkProtector>
  )
}

function UnlockButton(props: { onClick: () => void }) {
  const { t } = useTranslation()

  return (
    <StyledButton
      variant={ButtonVariant.accent}
      onClick={props.onClick}
      fullWidth
    >
      {t('matches:unlock_more_matches_button')}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  margin-top: ${(props) => props.theme.d.spacing[8]};
  height: 50px;
`
