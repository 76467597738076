import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Redirect } from 'src/routes/Redirect'
import { useIsGamersenseiAffiliate } from 'src/hooks/use-affiliate'
import { Meta, NotFoundMeta } from 'src/system/Meta'
import { useRouting } from 'src/routes'
import { CenterLoadingSpinner } from 'src/system/ui/LoadingSpinner'
import { Avatar } from 'src/system/ui/image/Avatar'
import { usePublicProfile } from 'src/hooks/use-profile'
import { AppBar } from 'src/system/app-bar/AppBar'
import { PublicProgressionReport } from 'src/screens/progression-report/ProgressionReportProfile'
import { GamersenseiBranding } from 'src/system/GamersenseiBranding'
import { Background } from 'src/system/ui/layout/Background'
import { PageTitle } from 'src/system/ui/typography/PageTitle'

export function PublicProfile() {
  const { profileId } = useParams<'profileId'>()

  if (profileId == null) {
    return null
  }

  return (
    <>
      <AppBar />
      <Meta name='profile' />
      <Background>
        <Profile profileId={profileId} />
      </Background>
    </>
  )
}

function Profile(props: { profileId: string }) {
  const { toProfile } = useRouting()
  const { profileId } = props

  const { data, loading } = usePublicProfile(profileId)

  if (!data && loading) return <CenterLoadingSpinner />
  const id = data?.profile?.id

  if (id != null && id !== profileId) {
    return <Redirect to={toProfile(id)} />
  }

  if (!loading && !data?.profile) return <ProfileNotFoundError />

  return (
    <Container>
      <ProfileInfo profileId={profileId} />
      <GamersenseiBranding profileId={profileId} />
      <PublicProgressionReport profileId={profileId} />
    </Container>
  )
}

function ProfileInfo(props: { profileId: string }) {
  const { data } = usePublicProfile(props.profileId)
  const isGamersenseiAffiliate = useIsGamersenseiAffiliate()
  const namespace = isGamersenseiAffiliate ? 'gamersensei_overview' : 'overview'
  const { t } = useTranslation()
  if (!data) return null
  const steamProfile = data?.profile?.steam
  const name = steamProfile?.name ?? props.profileId
  const image = steamProfile?.avatarUrl
  const titleText = t(`${namespace}:profile:intro`, {
    name
  })
  return (
    <TitleContainer>
      <Meta name='profile' image={image} titleVars={{ name }} />
      <InfoBox>
        <StyledAvatar src={data?.profile?.steam?.avatarUrl} />
        <StyledPageTitle>{titleText}</StyledPageTitle>
      </InfoBox>
    </TitleContainer>
  )
}

function ProfileNotFoundError() {
  const { t } = useTranslation()

  return (
    <>
      <NotFoundMeta />
      <NotFoundErrorText>
        {t('overview:profile:not_found_error')}
      </NotFoundErrorText>
    </>
  )
}

const NotFoundErrorText = styled.div`
  color: ${(props) => props.theme.d.colors.error};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InfoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledAvatar = styled(Avatar)`
  display: block;
  height: 64px;
  width: 64px;
  margin-right: 15px;
`

const TitleContainer = styled.div`
  max-width: 1256px;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => props.theme.d.spacing[10]};
`

const StyledPageTitle = styled(PageTitle)`
  margin-bottom: 0;
`

const Container = styled.div`
  flex: 1;
  flex-direction: column;
  max-width: 1256px;
  margin-left: auto;
  margin-right: auto;
`
