import { Navigate as RouterRedirect } from 'react-router-dom'
import type { NavigateProps } from 'react-router-dom'

import { RedirectMeta } from 'src/system/Meta'

export function Redirect(props: NavigateProps) {
  const pathname = getPathname(props)
  return (
    <>
      <RedirectMeta pathname={pathname} />
      <RouterRedirect {...props} />
    </>
  )
}

const getPathname = (props: NavigateProps) => {
  if (typeof props.to === 'string') return props.to
  return props?.to?.pathname
}
