import { forwardRef } from 'react'
import type {
  ChangeEventHandler,
  DetailedHTMLProps,
  InputHTMLAttributes
} from 'react'
import styled from '@emotion/styled'

import { ErrorText } from 'src/system/ui/form/ErrorText'

type InputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

type Props = {
  className?: string
  label?: string
  placeholder?: string
  fullWidth?: boolean
  type?: 'password' | 'email'
  value?: string
  onChange?: ChangeEventHandler
  name?: string
  required?: boolean
  error?: string
  hideDetails?: boolean
  disabled?: boolean
}

export const TextField = forwardRef<HTMLInputElement, Props & InputProps>(
  (props, ref) => {
    return (
      <Container className={props.className} fullWidth={props.fullWidth}>
        {props.label && (
          <Label hasError={Boolean(props.error)}>{props.label}</Label>
        )}
        <Input
          type={props.type ?? 'text'}
          placeholder={props.placeholder}
          fullWidth={props.fullWidth}
          value={props.value}
          onChange={props.onChange}
          ref={ref}
          name={props.name}
          required={props.required ?? false}
          hasError={Boolean(props.error)}
          disabled={props.disabled}
        />
        <ErrorText hidden={props.hideDetails}>{props.error}</ErrorText>
      </Container>
    )
  }
)

const Container = styled.div`
  width: ${(props: { fullWidth?: boolean }) =>
    props.fullWidth ? '100%' : 'auto'};
`

const Label = styled.span<{ hasError?: boolean }>`
  font-size: 12px;
  line-height: 157.4%;
  display: block;
  margin-bottom: ${(props) => props.theme.d.spacing[2]};
  font-weight: 600;
  ${(props) => (props.hasError ? `color: ${props.theme.d.colors.error}` : null)}
`

const Input = styled.input<{
  fullWidth?: boolean
  hasError?: boolean
  disabled?: boolean
}>`
  background: ${(props) => props.theme.d.colors.background};
  border: 1px solid
    ${(props) =>
      props.hasError
        ? props.theme.d.colors.error
        : props.theme.d.colors.border.input};
  opacity: ${(props) => (props.disabled ? '0.8' : '1')};
  box-sizing: border-box;
  border-radius: 6px;
  padding: ${(props) =>
    `${props.theme.d.spacing[5]} ${props.theme.d.spacing[4]}`};
  color: ${(props) => props.theme.d.colors.text.secondary};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  font-size: 13px;
  line-height: 180.5%;
`
