import { MatchTokenInput } from 'src/screens/account/step/auto-download/MatchTokenInput'
import { MatchPlatform } from 'src/graphql/types'

export function FaceitSharelink() {
  const setMatchCode = (_: string | null) => {}

  return (
    <MatchTokenInput
      matchPlatform={MatchPlatform.Faceit}
      setMatchCode={setMatchCode}
      matchToken=''
    />
  )
}
