import type { ReactElement } from 'react'
import { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import {
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeSeriesScale,
  Title,
  Tooltip
} from 'chart.js'
import { Line, getElementAtEvent } from 'react-chartjs-2'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import type { InteractionItem } from 'chart.js'

import { useCourseProgression } from 'src/hooks/use-matches'
import type {
  Department,
  MatchProgressionFieldsFragment
} from 'src/graphql/types'
import { CenterLoadingSpinner } from 'src/system/ui/LoadingSpinner'
import { useScoreColor } from 'src/screens/match/assessments/score/Score'
import {
  displayScore,
  internalScore
} from 'src/screens/match/assessments/score/utils'
import type { Assessment } from 'src/hooks/use-assessment'
import { useAssessment } from 'src/hooks/use-assessment'
import { useRouting } from 'src/routes'

ChartJS.register(
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeSeriesScale,
  Title,
  Tooltip
)

export function ProgressionChart(props: {
  selectedDepartment?: Department
  fallback: ReactElement
  className?: string
}) {
  const assessment = useAssessment()
  const { matches, loading, refetch } = useCourseProgression()
  const { t } = useTranslation()

  const hasCurrentMatch = hasMatch(assessment.id, matches)
  const isMatchTooOld = matchIsTooOld(assessment, matches)
  const couldHaveMatch = !hasCurrentMatch && !isMatchTooOld
  const willLoad = !matches || loading || couldHaveMatch
  const chartTitle =
    props.selectedDepartment ?? t('assessments:summary:overall_score')

  useEffect(() => {
    if (loading || hasCurrentMatch || isMatchTooOld) return
    refetch()
  }, [refetch, loading, hasCurrentMatch, isMatchTooOld])

  if (willLoad) return <CenterLoadingSpinner className={props.className} />

  const scoredMatches = matches!.map(matchToScoredMatch(assessment.id))

  if (!scoredMatches) return null
  const nonNullScoredMatches = scoredMatches.filter((x) => !!x) as ScoredMatch[]
  const missingMatchScore =
    !matchIsScored(assessment.id, nonNullScoredMatches) ||
    nonNullScoredMatches.length === 1

  if (missingMatchScore) {
    return props.fallback
  }

  return (
    <Box className={props.className}>
      <ChartTitle>{chartTitle}</ChartTitle>
      <Chart
        department={props.selectedDepartment}
        matches={nonNullScoredMatches}
      />
    </Box>
  )
}

function Chart(props: { department?: Department; matches: ScoredMatch[] }) {
  if (props.department) {
    return (
      <DepartmentProgressionChart
        department={props.department as Department}
        matches={props.matches}
      />
    )
  }

  return <OverallProgressionChart matches={props.matches} />
}

const calcAverageScore = (scores: Score[]) =>
  scores.reduce((acc, current) => acc + current.gpa, 0.0) / scores.length

const calcTrend = (scores: Score[]) => {
  const xMean = scores.length / 2
  const scoreMean = calcAverageScore(scores)
  const slope =
    scores.reduce(
      (acc, current, x) => acc + (current.gpa - scoreMean) * (x - xMean),
      0.0
    ) / scores.reduce((acc, current, x) => acc + (x - xMean) * (x - xMean), 0.0)
  const intercept = scoreMean - slope * xMean
  return {
    slope,
    intercept
  }
}

function DepartmentProgressionChart(props: {
  department: Department
  matches: ScoredMatch[]
}) {
  const data = props.matches
    .map(scoredMatchToDepartmentScore(props.department))
    .filter((x) => !!x) as Score[]
  const trend = calcTrend(data)
  return <ScoreChart data={data} trend={trend} />
}

function OverallProgressionChart(props: { matches: ScoredMatch[] }) {
  const data = props.matches
    .map(scoredMatchToOverallScores)
    .filter(({ gpa }) => !isNaN(gpa))
  const trend = calcTrend(data)
  return <ScoreChart data={data} trend={trend} />
}

export type Score = {
  id: string
  date: DateTime
  isCurrent: boolean
  gpa: number
}

export type Trend = {
  slope: number
  intercept: number
}

const commonDisplayOptions = {
  pointSize: 5,
  currentMatchPointSize: 10,
  hoverPointSize: 8,
  disableRatio: false
}

const reportDisplayOverrides = {
  pointSize: 7,
  currentMatchPointSize: 18,
  hoverPointSize: 11,
  disableRatio: true
}

const trendToScore = (index: number, trend: Trend) =>
  trend.intercept + trend.slope * index

export function ScoreChart(props: {
  data: Score[]
  isReport?: boolean
  trend: Trend
}) {
  const { t } = useTranslation()
  const color = useScoreColor()
  const data = props.data.map(({ date, gpa }) => ({
    x: date.toMillis(),
    y: internalScore(gpa)
  }))

  const dataTrend = props.data.map(({ date }, index) => ({
    x: date.toMillis(),
    y: internalScore(trendToScore(index, props.trend))
  }))

  const { toAssessments } = useRouting()
  const navigate = useNavigate()

  function handlePointClick(event: InteractionItem[]) {
    const idx = event[0]?.index
    if (idx == null) return
    const matchId = props.data[idx]?.id
    navigate(toAssessments(matchId))
  }

  const pointBackgroundColor = props.data.map(({ gpa }) =>
    color(displayScore(gpa))
  )

  const displayOptions = {
    ...commonDisplayOptions,
    ...(props.isReport ? reportDisplayOverrides : {})
  }

  const pointRadius = props.data.map(({ isCurrent }) =>
    isCurrent ? displayOptions.currentMatchPointSize : displayOptions.pointSize
  )

  const chartRef = useRef(null)
  return (
    <Line
      ref={chartRef}
      onClick={(event) => {
        if (!chartRef.current) return
        const element = getElementAtEvent(chartRef.current, event)
        handlePointClick(element)
      }}
      options={{
        scales,
        animation: false,
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: true,
            callbacks: {
              label: () => ` ${t('assessments:graph_tooltip')}`
            }
          }
        },
        layout: { padding },
        maintainAspectRatio: !displayOptions.disableRatio,
        onHover: (event, chartElement) => {
          if (chartElement[0] == null) return

          // UPSTREAM: Bad type definition.
          const e = event as unknown as { native: { target: HTMLElement } }

          if (e?.native?.target?.style == null) return
          e.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
        }
      }}
      data={{
        datasets: [
          {
            data,
            pointRadius,
            pointBackgroundColor,
            pointHoverRadius: displayOptions.hoverPointSize,
            ...datasetOptions
          },
          {
            data: dataTrend,
            pointBackgroundColor,
            ...trendlineOptions
          }
        ]
      }}
    />
  )
}

const datasetOptions = {
  label: 'points',
  fill: false,
  showLine: false
}

const trendlineOptions = {
  label: 'trendLine',
  pointStyle: 'line',
  pointRadius: 0,
  showLine: true,
  steppedLine: false,
  fill: false,
  borderColor: 'white'
}

const padding = {
  left: 10,
  right: 10,
  top: 10,
  bottom: 10
}

const scales = {
  y: {
    min: 1,
    max: 10,
    offset: true
  },
  x: {
    type: 'timeseries' as 'time',
    ticks: {
      source: 'labels' as 'labels'
    },
    time: {
      unit: 'month' as 'month'
    }
  }
}

const hasMatch = (
  matchId: string,
  matches: MatchProgressionFieldsFragment[] | null | undefined
) => {
  if (!matches) return false
  return !!matches.find(({ id }) => id === matchId)
}

const matchIsTooOld = (
  assessment: Assessment | null | undefined,
  matches: MatchProgressionFieldsFragment[] | null | undefined
) => {
  if (!assessment || !matches) return false
  if (!matches) return false
  const nonNullMatches = matches.filter((x) => !!x)
  const lastMatch = nonNullMatches[nonNullMatches.length - 1]
  return DateTime.fromISO(assessment.date) < DateTime.fromISO(lastMatch.date)
}

type ScoredMatch = {
  id: string
  date: DateTime
  isCurrent: boolean
  gpas: Array<{
    department: Department
    gpa: number
  }>
}

const matchToScoredMatch =
  (matchId: string) =>
  (match: MatchProgressionFieldsFragment | null | undefined) => {
    if (!match?.courses) return null

    if (
      match.courses.filter((course) => course?.aggregate?.gpa == null).length >
      0
    ) {
      return null
    }

    return {
      id: match.resourceId,
      date: DateTime.fromISO(match.date),
      isCurrent: match.id === matchId,
      gpas: match.courses
        .filter((course) => course?.aggregate?.gpa != null)
        .map((course) => ({
          department: course.department,
          gpa: course?.aggregate?.gpa as number
        }))
    }
  }

const scoredMatchToOverallScores = (scoredMatch: ScoredMatch) => {
  const gpas = scoredMatch.gpas
    .map(({ gpa }) => gpa)
    .filter((gpa) => !isNaN(gpa))
  return {
    id: scoredMatch.id,
    date: scoredMatch.date,
    isCurrent: scoredMatch.isCurrent,
    gpa: gpas.reduce(sum, 0) / gpas.length
  }
}

const scoredMatchToDepartmentScore =
  (department: Department) => (scoredMatch: ScoredMatch) => {
    const dept = scoredMatch.gpas.find((gpa) => gpa.department === department)
    if (!dept) return null
    return {
      id: scoredMatch.id,
      date: scoredMatch.date,
      isCurrent: scoredMatch.isCurrent,
      gpa: dept.gpa
    }
  }

const matchIsScored = (matchId: string, matches: ScoredMatch[]) => {
  return matches.map(({ id }) => id).includes(matchId)
}

const sum = (a: number, b: number) => a + b

const Box = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
`

const ChartTitle = styled.h5`
  font-size: 22px;
  font-weight: bold;
  text-transform: capitalize;
  color: ${(props) => props.theme.d.colors.text.secondary};
  text-align: center;
  margin: ${(props) =>
    `${props.theme.d.spacing[3]} 0 ${props.theme.d.spacing[5]}`};
`
