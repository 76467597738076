import styled from '@emotion/styled'

import { Markdown } from 'src/screens/match/assessments/assignment-list/assignment/expansion/resources/Markdown'
import type { ResourceYoutube as ResourceYoutubeData } from 'src/graphql/types'
import { YoutubeVideo } from 'src/system/ui/video/YoutubeVideo'

export function ResourceYoutube(props: { resource: ResourceYoutubeData }) {
  const title = props.resource.video.text
  return (
    <Container>
      <div>
        <Markdown>{title}</Markdown>
      </div>
      <YoutubeVideo title={title} id={props.resource.video.externalId} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    box-sizing: border-box;
    flex: 100%;

    @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
      flex: 50%;
    }
  }
`
