import { useState } from 'react'
import styled from '@emotion/styled'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Checkbox, FormControlLabel } from '@mui/material'

import { Meta } from 'src/system/Meta'
import { TextField } from 'src/system/ui/form/TextField'
import { useRouting } from 'src/routes'
import { useAuth } from 'src/hooks/use-auth'
import { AuthForm } from 'src/screens/auth/AuthForm'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'
import { LinkColor } from 'src/system/ui/link/types'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'
import { ErrorText } from 'src/system/ui/form/ErrorText'

type FormData = {
  receiveMail: boolean
  terms: boolean
  email: string
  password: string
  confirmPassword: string
}

export function SignUp() {
  return (
    <>
      <Meta name='signup' />
      <SignUpForm />
    </>
  )
}

function SignUpForm() {
  const [error, setError] = useState('')
  const { signUp } = useAuth()
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { isSubmitting, errors }
  } = useForm<FormData>({
    defaultValues: {
      receiveMail: true,
      terms: false,
      email: '',
      password: '',
      confirmPassword: ''
    }
  })

  const { routes } = useRouting()
  const navigate = useNavigate()

  const submit = async () => {
    if (isSubmitting) {
      return
    }

    try {
      const { email, password, receiveMail } = getValues()
      await signUp(email, password, receiveMail)
      navigate(routes.signUpSuccess)
    } catch (err: any) {
      setError(err?.message ?? err)
    }
  }

  return (
    <AuthForm
      headline={t('sign_up:headline')}
      subhead={t('sign_up:subhead')}
      footer={<ExistingAccount />}
      error={error}
      render={({ SubmitButton, FieldBox }) => (
        <form onSubmit={handleSubmit(submit)}>
          <FieldBox>
            <TextField
              label={t('sign_up:email_label')}
              placeholder={t('sign_up:email_placeholder')}
              fullWidth
              type='email'
              error={errors?.email?.message}
              {...register('email', {
                required: `${t('sign_up:email_required_error')}`
              })}
            />
          </FieldBox>
          <FieldBox>
            <TextField
              fullWidth
              label={t('password_fields:password_label')}
              placeholder={t('password_fields:password_placeholder')}
              type='password'
              error={errors?.password?.message}
              {...register('password', {
                required: `${t('password_fields:password_error')}`
              })}
            />
          </FieldBox>
          <FieldBox disableGutter>
            <TextField
              fullWidth
              label={t('password_fields:confirm_password_label')}
              placeholder={t('password_fields:confirm_password_placeholder')}
              type='password'
              error={errors?.confirmPassword?.message}
              {...register('confirmPassword', {
                required: `${t(
                  'password_fields:confirm_password_error:required'
                )}`,
                validate: (value: string) =>
                  value === watch('password') ||
                  (t(
                    'password_fields:confirm_password_error:mismatch'
                  ) as string)
              })}
            />
          </FieldBox>
          <RequiredCheckboxContainer>
            <FormControlLabel
              control={
                <Controller
                  name='terms'
                  control={control}
                  rules={{
                    required: `${t('sign_up:accept_terms_required_error')}`
                  }}
                  render={({ field }) => (
                    <Checkbox
                      color='primary'
                      disableRipple
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  )}
                />
              }
              label={
                <Trans i18nKey='sign_up:accept_terms_and_privacy'>
                  {' '}
                  <AbsoluteLink
                    color={LinkColor.green}
                    newTab
                    to={t('link:terms_of_service')}
                  >
                    {' '}
                  </AbsoluteLink>
                  <AbsoluteLink
                    color={LinkColor.green}
                    newTab
                    to={t('link:privacy_policy')}
                  >
                    {' '}
                  </AbsoluteLink>
                </Trans>
              }
            />
            <ErrorText disablePadding>{errors?.terms?.message}</ErrorText>
            <FormControlLabel
              control={
                <Controller
                  name='receiveMail'
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      color='primary'
                      disableRipple
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  )}
                />
              }
              label={t('sign_up:receive_mail_label') as string}
            />
          </RequiredCheckboxContainer>
          <SubmitButton fullWidth type='submit' processing={isSubmitting}>
            {t('sign_up:submit_button')}
          </SubmitButton>
        </form>
      )}
    />
  )
}

function ExistingAccount() {
  const { routes } = useRouting()
  const { t } = useTranslation()

  return (
    <ExistingAccountText>
      {t('sign_up:have_account')}{' '}
      <RelativeLink to={routes.login} color={LinkColor.green}>
        {t('sign_up:login_here')}
      </RelativeLink>
    </ExistingAccountText>
  )
}

const ExistingAccountText = styled.p`
  font-size: 16px;
  margin-bottom: ${(props) => props.theme.d.spacing[4]};
`

const RequiredCheckboxContainer = styled.div`
  margin-top: ${(props) => props.theme.d.spacing[6]};
`
