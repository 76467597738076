import type { ReactNode } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@mui/system'
import { Tooltip, tooltipClasses } from '@mui/material'
import type { TooltipProps } from '@mui/material'
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import {
  faAnglesUp,
  faBomb,
  faBookMedical,
  faCalendarCheck,
  faChartLine,
  faCheck,
  faCheckToSlot,
  faClock,
  faClockRotateLeft,
  faCloudArrowUp,
  faDownload,
  faFire,
  faHeartPulse,
  faIdCard,
  faInfinity,
  faKey,
  faLockOpen,
  faMessage,
  faPersonChalkboard,
  faRobot,
  faShare,
  faTicket,
  faTrashCan,
  faTv,
  faUpload
} from '@fortawesome/free-solid-svg-icons'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'

import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'
import { Button } from 'src/system/ui/button/Button'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'
import { useRouting } from 'src/routes'

export function useFeatures(plan: PlanLevel): Feature[] {
  const [membershipPerksEnabled] = useFeatureFlags([
    FeatureFlag.membershipPerks
  ])
  const { t } = useTranslation()
  const key = [
    'membership',
    'plans',
    plan,
    membershipPerksEnabled ? 'perks' : 'features'
  ].join(':')
  const features = t<string, I18nFeature[]>(key, {
    returnObjects: true
  })

  return features.map(({ icon, ...feature }) => {
    const ico = icons[icon]
    if (ico == null) {
      throw new Error(`No feature icon for ${icon}`)
    }
    return {
      ...feature,
      icon: ico
    }
  })
}

export function PlanCard(props: {
  color: PlanLevel
  title: string
  header: ReactNode
  footer: ReactNode
  features: Feature[]
  className?: string
}) {
  const theme = useTheme()

  const color = {
    [PlanLevel.basic]: theme.d.colors.success,
    [PlanLevel.premium]: theme.d.colors.accent,
    [PlanLevel.lifetime]: theme.d.colors.lifetime
  }

  const planColor = color[props.color]

  return (
    <Box className={props.className}>
      <Top>
        <Title color={planColor}>{props.title}</Title>
        {props.header}
      </Top>
      <Middle>
        {props.features.map((feature) => (
          <FeatureLine color={planColor} key={feature.text} feature={feature} />
        ))}
      </Middle>
      <Bottom>{props.footer}</Bottom>
    </Box>
  )
}

export function SignUpButton(props: { color?: string }) {
  const { t } = useTranslation()
  const { routes } = useRouting()

  return (
    <RelativeLink to={routes.signUp} disableStyles>
      <Button color={props.color}>{t('nav:create_account')}</Button>
    </RelativeLink>
  )
}

function FeatureLine(props: { feature: Feature; color: string }) {
  const icon = props.feature.icon
  return (
    <DetailTooltip
      title={props.feature.detail}
      arrow
      placement='top'
      enterTouchDelay={200}
    >
      <FeatureBox>
        {icon == null ? null : <FeatureIcon icon={icon} color={props.color} />}
        <FeatureText>{props.feature.text}</FeatureText>
      </FeatureBox>
    </DetailTooltip>
  )
}

export enum PlanLevel {
  'basic' = 'basic',
  'premium' = 'premium',
  'lifetime' = 'lifetime'
}

export type I18nFeature = {
  icon: string
  text: string
  detail: string
}

export type Feature = {
  icon: IconDefinition | undefined
  text: string
  detail: string
}

export const HeaderPrice = styled.h5<{ selected?: boolean }>`
  color: ${(props) =>
    props.selected ?? true
      ? props.theme.d.colors.text.secondary
      : props.theme.d.colors.text.primary};
  font-size: 22px;
  line-height: 29px;
  margin: 0;
`

const Box = styled.div`
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.d.colors.border.dark};
  background: ${(props) => props.theme.d.colors.panel.light};
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Top = styled.div`
  min-height: 70px;
  padding: ${(props) => props.theme.d.spacing[4]};
  border-bottom: 1px solid ${(props) => props.theme.d.colors.border.dark};
`

const Title = styled.h6<{
  color: string
}>`
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${(props) => props.color};
`

const Middle = styled.div`
  padding: 0 ${(props) => props.theme.d.spacing[4]};
  flex: 1;
`

const FeatureBox = styled.div`
  cursor: help;
  padding: ${(props) => props.theme.d.spacing[4]} 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.d.colors.border.dark};
  &:last-of-type {
    border-bottom: none;
  }
`

const FeatureIcon = styled(FontAwesomeIcon)`
  margin-right: ${(props) => props.theme.d.spacing[3]};
`

const FeatureText = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 150%;
`

export const Bottom = styled.div`
  padding: ${(props) => props.theme.d.spacing[4]};
  display: flex;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.d.colors.border.dark};
  justify-content: center;
  box-sizing: border-box;
`

const DetailTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))((props) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: props.theme.d.colors.accent,
    color: props.theme.d.colors.promotionText,
    fontSize: '16px',
    padding: props.theme.d.spacing[3]
  }
}))

const icons: { [key: string]: IconDefinition | undefined } = {
  robot: faRobot,
  'clock-rotate-left': faClockRotateLeft,
  share: faShare,
  'trash-can': faTrashCan,
  'angles-up': faAnglesUp,
  'chart-line': faChartLine,
  tv: faTv,
  infinity: faInfinity,
  'cloud-arrow-up': faCloudArrowUp,
  download: faDownload,
  check: faCheck,
  upload: faUpload,
  'heart-pulse': faHeartPulse,
  'check-to-slot': faCheckToSlot,
  discord: faDiscord,
  'lock-open': faLockOpen,
  'calendar-check': faCalendarCheck,
  clock: faClock,
  ticket: faTicket,
  'person-chalkboard': faPersonChalkboard,
  'book-medical': faBookMedical,
  bomb: faBomb,
  message: faMessage,
  key: faKey,
  'id-card': faIdCard,
  fire: faFire
}
