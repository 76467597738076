import { forwardRef } from 'react'
import styled from '@emotion/styled'
import { NavLink as BaseNavLink } from 'react-router-dom'
import type { NavLinkProps } from 'react-router-dom'

export function PageLink(props: {
  children: string
  href: string
  icon?: string
  absolute?: boolean
}) {
  if (props.absolute) {
    return <AbsoluteLink {...props} />
  }

  return <RelativeLink {...props} />
}

function AbsoluteLink(props: {
  children: string
  href: string
  icon?: string
}) {
  return (
    <StyledAnchor href={props.href}>
      {props.icon ? <Icon src={props.icon} alt={props.children} /> : null}
      {props.children}
    </StyledAnchor>
  )
}

function RelativeLink(props: {
  children: string
  href: string
  icon?: string
}) {
  return (
    <StyledNavLink end to={props.href}>
      {props.icon ? <Icon src={props.icon} alt={props.children} /> : null}
      {props.children}
    </StyledNavLink>
  )
}

const activeClassName = '__react-router_nav-link-active'

const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  return (
    <BaseNavLink
      ref={ref}
      {...props}
      className={({ isActive }) =>
        [props.className, isActive ? activeClassName : null]
          .filter(Boolean)
          .join(' ')
      }
    />
  )
})

const StyledAnchor = styled.a`
  font-family: ${(props) => props.theme.d.font.navigation};
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: ${(props) => `0 ${props.theme.d.spacing[4]}`};
  background: transparent;
  border-bottom: none;
  color: ${(props) => props.theme.d.colors.text.primary};
`

const StyledNavLink = styled(NavLink)`
  font-family: ${(props) => props.theme.d.font.navigation};
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: ${(props) => `0 ${props.theme.d.spacing[4]}`};
  background: transparent;
  border-bottom: none;
  color: ${(props) => props.theme.d.colors.text.primary};

  &.${activeClassName} {
    background: ${(props) => props.theme.d.colors.topNavBarLinkBackground};
    border-bottom: ${(props) => `1px solid ${props.theme.d.colors.success}`};
  }
`

const Icon = styled.img`
  margin-right: 10px;
`
