import styled from '@emotion/styled'

import { Panel } from 'src/system/ui/layout/Panel'
import { DiscordButton } from 'src/system/DiscordButton'
import discordLogo from 'src/assets/icons/discord-logo-bg.svg'

export function JoinDiscordPanel(props: {
  title: string
  description: string
  className?: string
}) {
  return (
    <StyledPanel disablePadding className={props.className}>
      <Box>
        <Left>
          <TitleText>{props.title}</TitleText>
          <DescriptionText>{props.description}</DescriptionText>
        </Left>
        <Right>
          <DiscordBg>
            <Gradient />
          </DiscordBg>
          <DiscordButton />
        </Right>
      </Box>
    </StyledPanel>
  )
}

const StyledPanel = styled(Panel)`
  margin: ${(props) => props.theme.d.spacing[20]} auto 0;
  max-width: 600px;
`

const Box = styled.div`
  display: flex;
  align-items: center;
`

const TitleText = styled.h5`
  text-transform: uppercase;
  color: ${(props) => props.theme.d.colors.discord.blurple};
  font-size: 14px;
  line-height: 18px;
  margin: 0;
`

const DescriptionText = styled.h3`
  color: ${(props) => props.theme.d.colors.text.secondary};
  font-size: 22px;
  line-height: 29px;
  margin: 0;
`

const Section = styled.div`
  padding: ${(props) =>
    `${props.theme.d.spacing[4]} ${props.theme.d.spacing[2]}`};

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    padding: ${(props) =>
      `${props.theme.d.spacing[6]} ${props.theme.d.spacing[9]}`};
  }
`

const Left = styled(Section)`
  flex: 1;
`
const Right = styled(Section)`
  position: relative;
`

const DiscordBg = styled.div`
  background: url(${discordLogo});
  opacity: 0.05;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: 28px;
`

const Gradient = styled.div`
  height: 100%;
  background: ${(props) => props.theme.d.colors.discordBackground};
`
