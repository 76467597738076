import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { useProfile } from 'src/hooks/use-profile'
import { LinkFaceit } from 'src/screens/account/faceit/LinkFaceit'
import { FaceitSharelink } from 'src/screens/account/faceit/FaceitSharelink'
import { UnlinkFaceit } from 'src/screens/account/faceit/UnlinkFaceit'
import { FaceitProfile } from 'src/screens/account/faceit/FaceitProfile'
import { Panel } from 'src/system/ui/layout/Panel'
import { SectionTitle } from 'src/system/ui/typography/SectionTitle'
import { Description } from 'src/screens/account/step/Description'

export function ManageFaceit() {
  const { profile, loading } = useProfile('cache-first')
  const { t } = useTranslation()
  if (loading) return null

  if (profile?.faceit?.name) {
    return (
      <>
        <StyledPanel>
          <SectionTitle>{t('account:faceit:linked_title')}</SectionTitle>
          <Description>{t('account:faceit:linked_subtitle')}</Description>
          <FaceitProfile />
          <UnlinkFaceit />
        </StyledPanel>
        <StyledPanel>
          <FaceitSharelink />
        </StyledPanel>
      </>
    )
  }

  return (
    <StyledPanel>
      <SectionTitle>{t('account:faceit:unlinked_title')}</SectionTitle>
      <Description>{t('account:faceit:unlinked_subtitle')}</Description>
      <LinkFaceit />
    </StyledPanel>
  )
}

const StyledPanel = styled(Panel)`
  margin-top: ${(props) => props.theme.d.spacing[4]};
`
