import styled from '@emotion/styled'

import { useProfile } from 'src/hooks/use-profile'
import { Avatar } from 'src/system/ui/image/Avatar'

export function DiscordProfile() {
  const { profile } = useProfile('cache-first')
  const discord = profile?.discord
  if (!discord) return null
  const { name, avatarUrl } = discord
  return (
    <div>
      <Container>
        <StyledAvatar src={avatarUrl} />
        <Username>{name}</Username>
      </Container>
    </div>
  )
}

const Username = styled.span`
  font-family: ${(props) => props.theme.d.font.primary};
  font-style: normal;
  font-weight: bold;
  color: ${(props) => props.theme.d.colors.text.secondary};
`

const StyledAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
  margin-right: 20px;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
`
