import styled from '@emotion/styled'
import { Grid } from '@mui/material'

import type { ProgressionReportFieldsFragment } from 'src/graphql/types'

export type Progression =
  ProgressionReportFieldsFragment['assignmentProgressions'][0]

export const Box = styled.div`
  padding: ${(props) =>
    `${props.theme.d.spacing[8]} 0 ${props.theme.d.spacing[16]} 0`};

  @media (min-width: ${(props) => props.theme.d.breakpoints.lg}) {
    padding: ${(props) =>
      `${props.theme.d.spacing[10]} 0 ${props.theme.d.spacing[20]} 0`};
  }
`

export const MobileOnlyGrid = styled(Grid)`
  @media (min-width: ${(props) => props.theme.d.breakpoints.lg}) {
    display: none;
  }
`

export const DesktopOnlyGrid = styled(Grid)`
  display: none;

  @media (min-width: ${(props) => props.theme.d.breakpoints.lg}) {
    display: block;
  }
`
