import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import type { Department, Facet as FacetData } from 'src/graphql/types'
import {
  FacetIntListValue,
  FacetIntValue,
  isFacetInt,
  isFacetIntList
} from 'src/screens/match/assessments/assignment-list/assignment/expansion/marks/facet/FacetInt'
import {
  FacetFloatListValue,
  FacetFloatValue,
  isFacetFloat,
  isFacetFloatList
} from 'src/screens/match/assessments/assignment-list/assignment/expansion/marks/facet/FacetFloat'
import {
  FacetBoolListValue,
  FacetBoolValue,
  isFacetBool,
  isFacetBoolList
} from 'src/screens/match/assessments/assignment-list/assignment/expansion/marks/facet/FacetBool'
import {
  FacetWeaponListValue,
  FacetWeaponValue,
  isFacetWeapon,
  isFacetWeaponList
} from 'src/screens/match/assessments/assignment-list/assignment/expansion/marks/facet/FacetWeapon'
import {
  FacetHealthListValue,
  FacetHealthValue,
  isFacetHealth,
  isFacetHealthList
} from 'src/screens/match/assessments/assignment-list/assignment/expansion/marks/facet/FacetHealth'
import {
  FacetKevlarListValue,
  FacetKevlarValue,
  isFacetKevlar,
  isFacetKevlarList
} from 'src/screens/match/assessments/assignment-list/assignment/expansion/marks/facet/FacetKevlar'
import {
  FacetSentimentValue,
  isFacetSentiment
} from 'src/screens/match/assessments/assignment-list/assignment/expansion/marks/facet/FacetSentiment'
import type { Assessment } from 'src/hooks/use-assessment'
import { useAssessment } from 'src/hooks/use-assessment'

import {
  FacetIdListValue,
  FacetIdValue,
  isFacetId,
  isFacetIdList
} from './FacetId'
import {
  FacetTeamListValue,
  FacetTeamValue,
  isFacetTeam,
  isFacetTeamList
} from './FacetTeam'

export type FacetRenderData = {
  title: string
  value: ReactElement | null
}

export function Facet(props: {
  department: Department
  facet: FacetData
  render: (data: FacetRenderData) => ReactElement
}) {
  const assessment = useAssessment()
  const { t } = useTranslation()
  const label = t([
    `assessments:assignment:facets:${props.department}:${props.facet.label}`,
    props.facet.label
  ]) // has facet.label as fallback
  return props.render({
    title: label,
    value: value(props.facet, assessment)
  })
}

function value(facet: FacetData, assessment: Assessment) {
  if (isFacetInt(facet)) {
    return <FacetIntValue facet={facet} />
  }

  if (isFacetIntList(facet)) {
    return <FacetIntListValue facet={facet} />
  }

  if (isFacetFloat(facet)) {
    return <FacetFloatValue facet={facet} />
  }

  if (isFacetFloatList(facet)) {
    return <FacetFloatListValue facet={facet} />
  }

  if (isFacetBool(facet)) {
    return <FacetBoolValue facet={facet} />
  }

  if (isFacetBoolList(facet)) {
    return <FacetBoolListValue facet={facet} />
  }

  if (isFacetWeapon(facet)) {
    return <FacetWeaponValue facet={facet} />
  }

  if (isFacetWeaponList(facet)) {
    return <FacetWeaponListValue facet={facet} />
  }

  if (isFacetTeam(facet)) {
    return <FacetTeamValue facet={facet} />
  }

  if (isFacetTeamList(facet)) {
    return <FacetTeamListValue facet={facet} />
  }

  if (isFacetId(facet)) {
    return <FacetIdValue facet={facet} assessment={assessment} />
  }

  if (isFacetIdList(facet)) {
    return <FacetIdListValue facet={facet} assessment={assessment} />
  }

  if (isFacetHealth(facet)) {
    return <FacetHealthValue facet={facet} />
  }

  if (isFacetHealthList(facet)) {
    return <FacetHealthListValue facet={facet} />
  }

  if (isFacetKevlar(facet)) {
    return <FacetKevlarValue facet={facet} />
  }

  if (isFacetKevlarList(facet)) {
    return <FacetKevlarListValue facet={facet} />
  }

  if (isFacetSentiment(facet)) {
    return <FacetSentimentValue facet={facet} />
  }

  return null
}
