import { gql } from '@apollo/client'

import { userFragment } from './user'
import { matchFragment } from './match'
import { pendingMatchFragment } from './pending-match'

export const pageSize = 18

export const matchesStatusQuery = gql`
  query MatchesStatus {
    user {
      ...UserFields
    }

    latestMatches: matches(first: 10) {
      edges {
        ... on PendingMatchEdge {
          node {
            id
            createdAt
          }
        }
        ... on MatchEdge {
          node {
            id
            resourceId
            platform
            createdAt
          }
        }
      }
    }
  }

  ${userFragment}
`

export const matchesQuery = gql`
  query Matches($pageSize: Int!, $cursor: ID) {
    matches(first: $pageSize, after: $cursor) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        ... on PendingMatchEdge {
          node {
            ...PendingMatchFields
          }
        }
        ... on MatchEdge {
          node {
            ...MatchFields
          }
        }
      }
    }
  }

  ${matchFragment}

  ${pendingMatchFragment}
`

export const newPendingMatchSubscription = gql`
  subscription NewPendingMatch {
    newPendingMatch {
      id
    }
  }
`
