import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'

export enum PerkType {
  adhoc = 'adhoc',
  resource = 'resource',
  demoDownload = 'demo_download',
  matchHistory = 'match_history',
  matchViewer = 'match_viewer',
  matchViewerMark = 'match_viewer_mark'
}

export enum PerkRole {
  premium = 'premium',
  mvp = 'mvp',
  promotional = 'promotional',
  gamersensei = 'gamersensei',
  csconfederation = 'csconfederation'
}

const baseAcl = [PerkRole.premium, PerkRole.mvp, PerkRole.gamersensei]

export function usePerkAcls() {
  const [adhocPromotional] = useFeatureFlags([FeatureFlag.adhocPromotional])
  const [csconfederationPremium] = useFeatureFlags([
    FeatureFlag.csconfederationPremium
  ])

  const acl = [...baseAcl]

  if (csconfederationPremium) {
    acl.push(PerkRole.csconfederation)
  }

  const nonPromotionalAcl = [...acl]
  const defaultAcl = [...acl, PerkRole.promotional]

  const perkAcls = {
    [PerkType.adhoc]: defaultAcl,
    [PerkType.resource]: defaultAcl,
    [PerkType.demoDownload]: defaultAcl,
    [PerkType.matchHistory]: defaultAcl,
    [PerkType.matchViewer]: defaultAcl,
    [PerkType.matchViewerMark]: defaultAcl
  }

  return {
    ...perkAcls,
    [PerkType.adhoc]: adhocPromotional ? defaultAcl : nonPromotionalAcl
  }
}
