import { Redirect } from 'src/routes/Redirect'
import { Meta } from 'src/system/Meta'
import { useRouting } from 'src/routes'
import { CenterLoadingSpinner } from 'src/system/ui/LoadingSpinner'
import { usePrivateProfile } from 'src/hooks/use-profile'

export function PrivateProfile() {
  return (
    <>
      <Meta name='profile' />
      <Content />
    </>
  )
}

function Content() {
  const { toProfile } = useRouting()
  const { data, loading } = usePrivateProfile()
  if (loading) return <CenterLoadingSpinner />
  const id = data?.profile?.id
  if (!id) return null
  return <Redirect to={toProfile(id)} />
}
