import { useTranslation } from 'react-i18next'

import { useRouting } from 'src/routes'
import identity from 'src/assets/logo-identity.svg'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'

export function HomeLinkIdentity(props: { className?: string }) {
  const { routes } = useRouting()
  const { t } = useTranslation()

  return (
    <RelativeLink
      disableStyles
      to={routes.root}
      aria-label={t('nav:home')}
      className={props.className}
    >
      <img src={identity} alt={t('common:name')} />
    </RelativeLink>
  )
}
