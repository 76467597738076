import styled from '@emotion/styled'
import YouTube from 'react-youtube'
import type {
  YouTubePlayer as YouTubePlayerType,
  YouTubeProps
} from 'react-youtube'

import { AnalyticsEvent, useEventAnalytics } from 'src/hooks/use-analytics'

export type YouTubePlayer = YouTubePlayerType

type PlayerVars = YouTubeProps['opts']['playerVars']

export function YoutubeVideo(props: {
  id: string
  label?: string | null
  title?: string | null
  controls?: boolean
  captions?: boolean
  onEnd?: YouTubeProps['onEnd']
  setPlayer?: (player: YouTubePlayer) => void
}) {
  const videoPlayEvent = useEventAnalytics(AnalyticsEvent.videoPlay)
  const videoPauseEvent = useEventAnalytics(AnalyticsEvent.videoPause)
  const videoEndEvent = useEventAnalytics(AnalyticsEvent.videoEnd)

  const analyticProperties = {
    video_id: props.id,
    ...(props.title == null ? {} : { title: props.title }),
    ...(props.label == null ? {} : { label: props.label })
  }

  const playerVars: PlayerVars = {
    autoplay: 0,
    rel: 0,
    modestbranding: 1,
    loop: 0,
    showinfo: 0
  }

  if (props.controls != null) {
    playerVars.controls = props.controls ? 1 : 0
    playerVars.disablekb = props.controls ? 0 : 1
  }

  if (props.captions === true) {
    playerVars.cc_load_policy = 1
  }

  // UPSTREAM: https://stackoverflow.com/a/60843860
  const onApiChange = (event: { target: YouTubePlayer }) => {
    const player = event.target
    if (props.captions === true) {
      player.setOption('captions', 'track', { languageCode: 'en' })
    }
  }

  const opts = {
    playerVars
  }

  const handleReady = (event: { target: YouTubePlayer }) => {
    const player = event.target
    if (player == null) return
    if (props.setPlayer != null) props.setPlayer(player)
    player.addEventListener('onApiChange', onApiChange)
  }

  return (
    <Box>
      <YouTube
        opts={opts}
        videoId={props.id}
        onPlay={() => {
          videoPlayEvent(analyticProperties)
        }}
        onPause={() => {
          videoPauseEvent(analyticProperties)
        }}
        onEnd={(...args) => {
          videoEndEvent(analyticProperties)
          if (props.onEnd != null) props.onEnd(...args)
        }}
        onReady={handleReady}
      />
    </Box>
  )
}

const Box = styled.div`
  position: relative;
  overflow: hidden;
  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  iframe {
    position: absolute;
    width: 100%;
    border: none;
    height: 100%;
    top: 0;
    left: 0;
  }
`
