import styled from '@emotion/styled'
import { faBars, faThLarge } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IconButton } from 'src/system/ui/button/IconButton'
import { UserInterfaceViewMode as ViewMode } from 'src/graphql/types'

export function SetViewModeButtons(props: {
  currentMode: ViewMode
  handleSetMode: (viewMode: ViewMode) => void
}) {
  const isMode = (mode: ViewMode) => props.currentMode === mode

  return (
    <div>
      <GridViewButton
        active={isMode(ViewMode.Grid)}
        onClick={() => props.handleSetMode(ViewMode.Grid)}
      >
        <FontAwesomeIcon icon={faThLarge} />
      </GridViewButton>
      <ListViewButton
        active={isMode(ViewMode.List)}
        onClick={() => props.handleSetMode(ViewMode.List)}
      >
        <FontAwesomeIcon icon={faBars} />
      </ListViewButton>
    </div>
  )
}

interface ButtonProps {
  active?: boolean
}

const StyledButton = styled(IconButton)<ButtonProps>`
  padding: 9px;
  width: 36px;
  height: 36px;
  border: 1px solid ${(props) => props.theme.d.colors.border.dark};
  border-color: ${(props) =>
    props.active
      ? props.theme.d.colors.button.primary
      : props.theme.d.colors.border.dark};
  box-sizing: border-box;

  svg {
    font-size: 20px;
    color: ${(props) =>
      props.active ? props.theme.d.colors.button.primary : 'inherit'};
  }
`

const GridViewButton = styled(StyledButton)<ButtonProps>`
  border-right: ${(props) =>
    props.active
      ? `1px solid ${props.theme.d.colors.button.primary}`
      : `1px solid ${props.theme.d.colors.button.secondary}`};
  border-radius: 4px 0 0 4px;
  background: ${(props) =>
    props.active
      ? props.theme.d.colors.gridViewButtonActiveBackground
      : props.theme.d.colors.button.secondary};
`

const ListViewButton = styled(StyledButton)<ButtonProps>`
  border-left: ${(props) =>
    props.active
      ? `1px solid ${props.theme.d.colors.button.primary}`
      : `1px solid ${props.theme.d.colors.button.secondary}`};
  border-radius: 0 4px 4px 0;
  background: ${(props) =>
    props.active
      ? 'linear-gradient(90deg, rgba(98, 207, 123, 0.18) 0%, rgba(33, 36, 45, 0) 100%)'
      : props.theme.d.colors.button.secondary};
`
