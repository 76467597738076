import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import type { AssignmentViewProps } from 'src/screens/match/assessments/assignment-list/assignment/Assignment'
import { GenericFeedbackButton } from 'src/screens/match/assessments/AssignmentFeedbackButton'
import { AssignmentOverviewButton } from 'src/screens/match/assessments/AssignmentOverviewButton'
import { Breakdowns } from 'src/screens/match/assessments/assignment-list/assignment/expansion/breakdowns/Breakdowns'
import { Score } from 'src/screens/match/assessments/score/Score'

export function AssignmentMobile(props: AssignmentViewProps) {
  const { t } = useTranslation()

  return (
    <Box>
      <Header backgroundImage={props.backgroundImage}>
        <Title>{props.title}</Title>
        <HeaderActions>
          {props.showOverviewButton && (
            <AssignmentOverviewButton label={props.label} />
          )}
          <GenericFeedbackButton />
        </HeaderActions>
      </Header>
      <StyledBreakdowns breakdowns={props.breakdowns} />
      <Grade>
        <GradeTitle>{t('assessments:assignment:grade')}</GradeTitle>
        <StyledScore value={props.score} />
      </Grade>
      {props.expansion}
    </Box>
  )
}

const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
`

const Box = styled.div`
  background: ${(props) => props.theme.d.colors.panel.light};
  border: 1px solid ${(props) => props.theme.d.colors.border.dark};
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: ${(props) => props.theme.d.spacing[5]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    display: none;
  }
`

const Header = styled.div<{ backgroundImage: string }>`
  background: ${(props) => props.theme.d.colors.panel.dark};
  padding: ${(props) => props.theme.d.spacing[3]};
  border-bottom: 1px solid ${(props) => props.theme.d.colors.border.dark};
  position: relative;

  &:after {
    pointer-events: none;
    background: url(${(props) => props.backgroundImage}) no-repeat;
    content: '';
    opacity: 0.05;
    background-size: 180px;
    background-position: 0px -35px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Title = styled.h4`
  font-weight: bold;
  font-size: 22px;
  color: ${(props) => props.theme.d.colors.text.secondary};
  margin: 0 0 ${(props) => props.theme.d.spacing[2]};
`

const StyledBreakdowns = styled(Breakdowns)`
  border-bottom: 1px solid ${(props) => props.theme.d.colors.border.dark};
`

const Grade = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.d.spacing[3]};
`

const GradeTitle = styled.span`
  font-size: 16px;
  line-height: 21px;
`

const StyledScore = styled(Score)``
