import type { ReactNode } from 'react'
import styled from '@emotion/styled'

export function IconButton(props: {
  children: ReactNode
  onClick?: (event: any) => void
  className?: string
}) {
  return (
    <StyledButton className={props.className} onClick={props.onClick}>
      {props.children}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  line-height: 1;
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
`
