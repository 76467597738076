/* eslint-disable camelcase */

import styled from '@emotion/styled'

import type { Map } from 'src/graphql/types'
import placeholder from 'src/assets/csgo/map/badge/badge_placeholder.svg'
import de_ancient from 'src/assets/csgo/map/badge/de_ancient.png'
import de_anubis from 'src/assets/csgo/map/badge/de_anubis.png'
import de_cache from 'src/assets/csgo/map/badge/de_cache.png'
import de_dust2 from 'src/assets/csgo/map/badge/de_dust2.png'
import de_inferno from 'src/assets/csgo/map/badge/de_inferno.png'
import de_mirage from 'src/assets/csgo/map/badge/de_mirage.png'
import de_nuke from 'src/assets/csgo/map/badge/de_nuke.png'
import de_overpass from 'src/assets/csgo/map/badge/de_overpass.png'
import de_train from 'src/assets/csgo/map/badge/de_train.png'
import de_vertigo from 'src/assets/csgo/map/badge/de_vertigo.png'

const badges: Record<Map, string | null> = {
  cs_agency: null,
  cs_agency_scrimmagemap: null,
  cs_apollo: null,
  cs_apollo_scrimmagemap: null,
  cs_climb: null,
  cs_climb_scrimmagemap: null,
  cs_insertion2: null,
  cs_insertion2_scrimmagemap: null,
  cs_office: null,
  cs_office_scrimmagemap: null,
  de_ancient,
  de_ancient_scrimmagemap: de_ancient,
  de_anubis,
  de_anubis_scrimmagemap: de_anubis,
  de_assembly: null,
  de_basalt: null,
  de_basalt_scrimmagemap: null,
  de_breach: null,
  de_breach_scrimmagemap: null,
  de_cache,
  de_cache_scrimmagemap: de_cache,
  de_chlorine: null,
  de_chlorine_scrimmagemap: null,
  de_dust2,
  de_dust2_scrimmagemap: de_dust2,
  de_engage: null,
  de_engage_scrimmagemap: null,
  de_grind: null,
  de_grind_scrimmagemap: null,
  de_inferno,
  de_inferno_scrimmagemap: de_inferno,
  de_iris: null,
  de_iris_scrimmagemap: null,
  de_memento: null,
  de_mills: null,
  de_mirage,
  de_mirage_scrimmagemap: de_mirage,
  de_mocha: null,
  de_mocha_scrimmagemap: null,
  de_mutiny: null,
  de_mutiny_scrimmagemap: null,
  de_nuke,
  de_nuke_scrimmagemap: de_nuke,
  de_overpass,
  de_overpass_scrimmagemap: de_overpass,
  de_studio: null,
  de_studio_scrimmagemap: null,
  de_swamp: null,
  de_swamp_scrimmagemap: null,
  de_thera: null,
  de_train,
  de_train_scrimmagemap: de_train,
  de_tuscan: null,
  de_tuscan_scrimmagemap: null,
  de_vertigo,
  de_vertigo_scrimmagemap: de_vertigo
}

export function Badge(props: { className?: string; map?: Map | null }) {
  return (
    <div className={props.className}>
      <Image src={imageFor(props.map)} alt='badge' />
    </div>
  )
}

function imageFor(map?: Map | null) {
  if (!map) {
    return placeholder
  }

  const badge = badges[map]
  if (!badge) {
    return placeholder
  }

  return badge
}

const Image = styled.img`
  width: 100%;
  height: 100%;
`
