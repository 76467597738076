import styled from '@emotion/styled'
import { Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { UpgradeMembershipButton } from 'src/system/user/UpgradeMembershipButton'
import { MembershipLevel, MembershipProvidence } from 'src/graphql/types'
import { LoadingSpinner } from 'src/system/ui/LoadingSpinner'
import { Background } from 'src/system/ui/layout/Background'
import { UserAppBar } from 'src/system/app-bar/user/UserAppBar'
import { PageTitle } from 'src/system/ui/typography/PageTitle'
import { PageSubhead } from 'src/system/ui/typography/PageSubhead'
import { useRouting } from 'src/routes'
import { Button, ButtonVariant } from 'src/system/ui/button/Button'
import { BackLink } from 'src/system/ui/link/BackLink'
import {
  CancelMembership,
  CancelPaypalMembership
} from 'src/screens/membership/manage/CancelMembership'
import { SectionTitle } from 'src/system/ui/typography/SectionTitle'
import { useJustUser } from 'src/hooks/use-user'
import { useCustomerPortal } from 'src/hooks/use-customer-portal'
import { UncancelMembership } from 'src/screens/membership/manage/UncancelMembership'

export function ManageMembership() {
  const { t } = useTranslation()
  const { routes } = useRouting()

  return (
    <>
      <UserAppBar />
      <StyledBackground>
        <Container>
          <PageHeader>
            <div>
              <BackLink to={routes.account}>
                {t('membership:back_to_settings')}
              </BackLink>
              <PageTitle>{t('membership:title')}</PageTitle>
              <PageSubhead>{t('membership:subhead')}</PageSubhead>
            </div>
          </PageHeader>
          <Content />
        </Container>
      </StyledBackground>
    </>
  )
}

function CustomerPortalButton() {
  const { customerPortal, isLoading } = useCustomerPortal()
  const { t } = useTranslation()
  return (
    <Button
      variant={ButtonVariant.link}
      onClick={() => {
        customerPortal()
      }}
      disabled={isLoading}
    >
      <CustomerPortalIcon isLoading={isLoading} />
      {t('membership:payments')}
    </Button>
  )
}

function CustomerPortalIcon(props: { isLoading: boolean }) {
  if (props.isLoading) return <LoadingSpinner />
  return <FontAwesomeIcon icon={faExternalLinkAlt} />
}

function Content() {
  const { t } = useTranslation()
  const { user } = useJustUser()

  if (user?.membership?.level === MembershipLevel.Mvp) {
    return <SectionTitle>{t('membership:lifetime')}</SectionTitle>
  }

  if (
    !user?.membership ||
    user?.membership?.level === MembershipLevel.Promotional ||
    user?.membership?.level === MembershipLevel.Private
  ) {
    return <SectionTitle>{t('membership:none')}</SectionTitle>
  }

  return (
    <ContentBox>
      <SectionTitle>{t('membership:current_plan')}</SectionTitle>
      <CustomerPortalButton />
      <ManageMembershipButton />
    </ContentBox>
  )
}

function ManageMembershipButton() {
  const { user } = useJustUser()

  if (user?.membership?.isTerminated) {
    return <UpgradeMembershipButton />
  }

  if (
    user.membership?.providence === MembershipProvidence.Stripe &&
    user.membership?.isCanceled
  ) {
    return <UncancelMembership />
  }

  if (
    user.membership?.providence === MembershipProvidence.Paypal &&
    user.membership?.isPaused
  ) {
    return (
      <>
        <UncancelMembership />
        <CancelPaypalMembership />
      </>
    )
  }

  return <CancelMembership />
}

const StyledBackground = styled(Background)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
`

const PageHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: ${(props) => props.theme.d.spacing[9]};
`

const ContentBox = styled.div`
  padding: ${(props) =>
    `${props.theme.d.spacing[4]} ${props.theme.d.spacing[6]}`};

  background: ${(props) => props.theme.d.colors.panel.light};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.d.colors.border.dark};

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    padding: ${(props) =>
      `${props.theme.d.spacing[6]} ${props.theme.d.spacing[9]}`};
  }
`

export const NextBillingText = styled.p`
  margin-bottom: ${(props) => props.theme.d.spacing[9]};
`
