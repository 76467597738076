import styled from '@emotion/styled'

export function MustConnectEmphasisBadge(props: { children: string }) {
  return <Badge>{props.children}</Badge>
}

const Badge = styled.span`
  display: inline-block;
  text-transform: uppercase;
  color: ${(props) => props.theme.d.colors.warning};
  font-size: 24px;
  border: 1px solid ${(props) => props.theme.d.colors.error};
  padding: ${(props) => `0 ${props.theme.d.spacing[3]}`};
  border-radius: 12px;
  font-weight: bold;
  margin-bottom: ${(props) => props.theme.d.spacing[2]};
`
