import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { useTheme } from '@mui/system'

import type { ReleaseNote } from 'src/graphql/types'
import { ReleaseNoteAction } from 'src/graphql/types'

export function Note(props: { note: ReleaseNote }) {
  const { t } = useTranslation()
  const statusColor = useStatusColor(props.note)
  const text = [
    t(`release_notes:prefix:${props.note.action}`),
    props.note.title.text
  ].join(': ')

  return (
    <Box>
      <Status color={statusColor}>•</Status>
      <span>{text}</span>
    </Box>
  )
}

function useStatusColor({ action }: { action: ReleaseNoteAction }) {
  const theme = useTheme()
  const color = {
    [ReleaseNoteAction.Mvp]: theme.d.colors.lifetime,
    [ReleaseNoteAction.Premium]: theme.d.colors.accent,
    [ReleaseNoteAction.Add]: theme.d.colors.success,
    [ReleaseNoteAction.Change]: theme.d.colors.link.default,
    [ReleaseNoteAction.Remove]: theme.d.colors.error,
    [ReleaseNoteAction.Fix]: theme.d.colors.warning
  }

  const statusColor = color[action]
  if (!statusColor) {
    throw new Error(`Color has not been defined for note action: ${action}`)
  }

  return statusColor
}

export type NoteText = {
  en: string
}

const Box = styled.div`
  font-size: 15px;
  display: flex;
`

const Status = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  margin-right: ${(props) => props.theme.d.spacing[1]};
`
