import { Grid } from '@mui/material'

import type { ProgressionReportFieldsFragment } from 'src/graphql/types'
import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'

function capitalize(s: string) {
  return s[0].toUpperCase() + s.slice(1)
}

function interpretSlope(s: number) {
  if (s * 200 > 0.4) {
    return 'Getting worse!!'
  }
  if (s * 200 < -0.4) {
    return 'Getting better'
  }
  return 'Staying the same'
}

export function BodyAlt(props: {
  hasError: boolean
  data?: ProgressionReportFieldsFragment
  shouldRender: boolean
}) {
  const { data, hasError, shouldRender } = props
  const [showExperiment] = useFeatureFlags([FeatureFlag.profileExperiments])

  if (!data || hasError || !shouldRender) {
    return null
  }

  if (!showExperiment) {
    return null
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          &nbsp;
        </Grid>
        <Grid item xs={1}>
          <b>Department</b>
        </Grid>
        <Grid item xs={3}>
          <b>Assessment Title</b>
        </Grid>
        <Grid item xs={2}>
          <b>Average Score</b>
        </Grid>
        <Grid item xs={2}>
          <b>Change</b>
        </Grid>
      </Grid>
      {data.assignmentProgressions.map((progression) => (
        <Grid key={progression.id} container spacing={2}>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={1}>
            {capitalize(progression.department)}
          </Grid>
          <Grid item xs={3}>
            {progression.assignment.title.text}
          </Grid>
          <Grid item xs={2}>
            {(
              Math.round(progression.assignment.grade.score * 100) / 10
            ).toFixed(1)}
          </Grid>
          <Grid item xs={2}>
            {interpretSlope(progression.scoreTimeseries.trend.slope)}
          </Grid>
        </Grid>
      ))}
    </>
  )
}
