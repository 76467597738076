import { useTheme } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { DoubleArrow as DoubleArrowIcon } from '@mui/icons-material'

export function WelcomeMessage() {
  const theme = useTheme()
  const { t } = useTranslation()
  const textList = t<string, string[]>('matches:new_users_message_list', {
    returnObjects: true
  })
  const iconStyle = { color: theme.d.colors.text.secondary }
  return (
    <List dense>
      {textList.map((txt, idx) => (
        <ListItem key={idx}>
          <ListItemIcon>
            <DoubleArrowIcon style={iconStyle} />
          </ListItemIcon>
          <ListItemText primary={txt} />
        </ListItem>
      ))}
    </List>
  )
}
