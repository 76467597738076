import styled from '@emotion/styled'
import { Trans, useTranslation } from 'react-i18next'

import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'
import { LinkColor } from 'src/system/ui/link/types'
import { ReactComponent as PoweredByStripeImage } from 'src/assets/powered_by_stripe.svg'
import { ReactComponent as PayPalLogoImage } from 'src/assets/icons/paypal-logo-white.svg'

export function PaymentFaq() {
  const { t } = useTranslation()
  return (
    <FaqBox>
      <p>
        <Trans i18nKey='membership:plans:payment_faq'>
          {' '}
          <AbsoluteLink
            color={LinkColor.green}
            newTab
            to={t('social:payment_faq:link')}
          >
            {' '}
          </AbsoluteLink>
        </Trans>
      </p>
      <div>
        <PoweredByStripeImage />
      </div>
      <div>
        <PayPalLogoImage />
      </div>
    </FaqBox>
  )
}

const FaqBox = styled.div`
  margin-top: ${(props) => props.theme.d.spacing[8]};
  text-align: center;
  width: 100%;
  & > div {
    display: inline-block;
    margin: ${(props) => props.theme.d.spacing[5]};
  }
`
