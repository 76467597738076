import { useEffect } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Box } from 'src/routes/HandleCheckout'
import {
  MembershipLevel,
  MembershipPlan,
  MembershipProvidence
} from 'src/graphql/types'
import { useUser } from 'src/hooks/use-user'
import { usePaypalCheckout, useStripeCheckout } from 'src/hooks/use-checkout'
import { BorderedCircularProgress } from 'src/system/ui/progress/BorderedCircularProgress'
import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'

export function RedirectToCheckout(props: {
  providence?: MembershipProvidence
  plan?: MembershipPlan
  discount?: MembershipLevel
}) {
  const [isDualMembershipPlanEnabled] = useFeatureFlags([
    FeatureFlag.dualMembershipPlan
  ])

  const defaultPlan = isDualMembershipPlanEnabled
    ? MembershipPlan.Annual
    : MembershipPlan.Monthly

  const plan = props.plan ?? defaultPlan

  const providence = props.providence ?? MembershipProvidence.Stripe
  const { t } = useTranslation()
  const { user, loading } = useUser('network-only')
  const isActive = user?.membership?.isActive
  const shouldRedirect =
    !isActive || user?.membership?.level !== MembershipLevel.Premium

  const { checkout, isError, isLoading } = useCheckout(
    providence,
    plan,
    props.discount
  )

  useEffect(() => {
    if (!shouldRedirect || isError || loading || isLoading) return
    checkout()
  }, [shouldRedirect, checkout, isError, loading, isLoading])

  const showError = isError || !shouldRedirect
  if (showError) {
    return <ErrorText hidden={!showError}>{t('checkout:error')}</ErrorText>
  }
  return (
    <Box>
      <BorderedCircularProgress size={64} />
    </Box>
  )
}

const ErrorText = styled.div`
  color: ${(props) => props.theme.d.colors.error};
  margin-top: ${(props) => props.theme.d.spacing[4]};
`

function useCheckout(
  providence: MembershipProvidence,
  plan: MembershipPlan,
  discount: MembershipLevel | undefined
) {
  const applyDiscount = discount != null
  const stripe = useStripeCheckout(plan, applyDiscount)
  const paypal = usePaypalCheckout(plan, applyDiscount)
  if (providence === MembershipProvidence.Stripe) return stripe
  if (providence === MembershipProvidence.Paypal) return paypal
  return { isError: true, isLoading: false, checkout: () => {} }
}
