import type { ReactElement } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useMatch } from 'src/hooks/use-match'
import { useMatchAssignmentFeedback } from 'src/hooks/use-feedback'
import type { Assignment as AssignmentData } from 'src/screens/match/assessments/assignment'
import type { Breakdown, Department, Grade } from 'src/graphql/types'
import { AssignmentMobile } from 'src/screens/match/assessments/assignment-list/assignment/AssignmentMobile'
import { AssignmentDesktop } from 'src/screens/match/assessments/assignment-list/assignment/AssignmentDesktop'
import { departmentIcon } from 'src/screens/match/assessments/department'
import { Expansion } from 'src/screens/match/assessments/assignment-list/assignment/expansion/Expansion'
import { ResourceList } from 'src/screens/match/assessments/assignment-list/assignment/expansion/resources/ResourceList'
import { Marks } from 'src/screens/match/assessments/assignment-list/assignment/expansion/marks/Marks'
import { isNewResource } from 'src/screens/match/assessments/assignment-list/assignment/expansion/resources/utils'

export type AssignmentViewProps = {
  label: string
  department: string
  title: string
  description: string
  expand: boolean
  handleExpandClick: () => void
  onAssignmentFeedback: () => void
  expansion: ReactElement
  backgroundImage: string
  breakdowns: Breakdown[]
  score: number
  reason: string | null
  narrow?: boolean
  hideBody?: boolean
  showOverviewButton?: boolean
}

export function Assignment(props: { assignment: AssignmentData }) {
  const { t } = useTranslation()
  const match = useMatch()
  const assignmentFeedback = useMatchAssignmentFeedback(props.assignment, match)

  const resources = props.assignment.resources
  const hasNewResource = resources.filter(isNewResource).length > 0

  const viewProps = useAssignmentViewProps({
    department: props.assignment.department,
    title: props.assignment.title.text,
    description: props.assignment.body.text,
    score: props.assignment.grade.score,
    breakdowns: props.assignment.breakdowns,
    onAssignmentFeedback: assignmentFeedback,
    expansion: (
      <Expansion
        one={{
          title: t('assessments:assignment:expansion:tab:resources'),
          body: <ResourceList resources={resources} />,
          bodyCount: props.assignment.resources.length,
          isNew: hasNewResource
        }}
        two={{
          title: t('assessments:assignment:expansion:tab:details'),
          body: <Marks assignment={props.assignment} />,
          bodyCount: props.assignment.marks.length
        }}
      />
    )
  })

  return (
    <>
      <AssignmentDesktop showOverviewButton={match.isOwner} {...viewProps} />
      <AssignmentMobile showOverviewButton={match.isOwner} {...viewProps} />
    </>
  )
}

export function useAssignmentViewProps(data: {
  department: Department
  title: string
  description: AssignmentData['body']['text']
  score: Grade['score']
  breakdowns: Breakdown[]
  expansion: ReactElement
  onAssignmentFeedback: () => void
  narrow?: boolean
  hideBody?: boolean
}): AssignmentViewProps {
  const { t } = useTranslation()
  const [expand, setExpand] = useState(false)

  const toggleExpand = () => setExpand(!expand)

  return {
    label: data.title.toLowerCase().replaceAll(' ', '-'), // TODO: update to use new label from schema
    department: t(`assessments:department:${data.department}:title`),
    title: data.title,
    description: data.description,
    expand,
    onAssignmentFeedback: data.onAssignmentFeedback,
    score: Math.round(data.score * 10),
    backgroundImage: departmentIcon[data.department],
    handleExpandClick: toggleExpand,
    reason: data.breakdowns[0] ? data.breakdowns[0].title.text : null,
    breakdowns: data.breakdowns,
    expansion: data.expansion,
    narrow: data.narrow,
    hideBody: data.hideBody
  }
}
