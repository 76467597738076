import { useMutation } from '@apollo/client'

import { profileQuery, unlinkMutation, userQuery } from 'src/graphql'
import { AnalyticsEvent, useEventAnalytics } from 'src/hooks/use-analytics'
import type {
  UnlinkMutation,
  UnlinkMutationVariables,
  UserLinkType
} from 'src/graphql/types'

export function useUnlinkAccount(linkType: UserLinkType) {
  const [unlinkAccount, { loading }] = useMutation<
    UnlinkMutation,
    UnlinkMutationVariables
  >(unlinkMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: userQuery }, { query: profileQuery }]
  })

  const accountUnlinkedEvent = useEventAnalytics(AnalyticsEvent.accountUnlinked)

  const unlink = (linkId: string) => {
    if (loading) return
    unlinkAccount({ variables: { linkType, linkId } })
    accountUnlinkedEvent()
  }

  return [unlink, { loading }] as const
}
