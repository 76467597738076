import 'chartjs-adapter-luxon'
import { Settings as LuxonSettings } from 'luxon'
import { config as fontawesomeConfig } from '@fortawesome/fontawesome-svg-core'

import { handleError, main } from 'src/main'

import 'src/index.css'

import '@fortawesome/fontawesome-svg-core/styles.css'
import 'react-dropzone-uploader/dist/styles.css'

import 'src/assets/fonts/archive/main.css'
import 'src/assets/fonts/assistant/main.css'
import 'src/assets/fonts/poppins/main.css'
import 'src/assets/fonts/teko/main.css'

import 'src/lib/match-viewer/styles/main.css'
import 'src/lib/match-viewer/styles/player.css'
import 'src/lib/match-viewer/styles/user.css'
import 'src/lib/match-viewer/styles/buttons.css'
import 'src/lib/match-viewer/styles/rounds.css'

import 'src/assets/favicon-192x192.png'
import 'src/assets/favicon-180x180.png'
import 'src/assets/favicon-32x32.png'

const globalConfig = () => {
  fontawesomeConfig.autoAddCss = false
  LuxonSettings.defaultLocale = 'en-US'
}

try {
  globalConfig()
  const rootElement = document.getElementById('root')
  if (!rootElement) throw new Error('Root element not found')
  main(rootElement)
} catch (err: any) {
  handleError(err)
}
