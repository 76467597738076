import type { CourseFieldsFragment as Course } from 'src/graphql/types'
import type { Assessment } from 'src/hooks/use-assessment'

export const matchScore = (courses: Assessment['courses']) => {
  if (!courses || courses.length === 0) {
    return 0
  }

  const numCourses = courses.filter((course) => course.isDone).length
  if (numCourses === 0) return 0

  const totalCourseScores = courses.map(courseScore).reduce(sum, 0)

  return Math.max(Math.round(totalCourseScores / numCourses), minScore)
}

const minScore = 1

export function courseScore(course?: Course) {
  if (!course) return 0

  const gpa = course?.aggregate?.gpa
  if (gpa != null) return displayScore(gpa)

  if (!course.assignments || course.assignments.length === 0) {
    return 0
  }

  const totalAssignmentScores = course.assignments
    .map((a) => a.grade.score * 10)
    .reduce(sum, 0)

  const numAssignments = course.assignments.length
  const score = Math.round(totalAssignmentScores / numAssignments) / 10
  return displayScore(score)
}

export function courseScores(course?: Course) {
  const scores = course?.aggregate?.assignmentScores ?? []
  if (scores.length !== 0) {
    return scores.map(displayScore)
  }

  if (!course?.assignments || course.assignments.length === 0) {
    return []
  }

  return course.assignments.map((a) => displayScore(a.grade.score))
}

const sum = (a: number, b: number) => a + b

export const displayScore = (score: number) =>
  Math.max(Math.round(10 * score), minScore)

export const internalScore = (score: number) => Math.max(10 * score, minScore)
