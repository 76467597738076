import type { ReactElement } from 'react'
import styled from '@emotion/styled'

export function Instruction(props: {
  number: number
  content: ReactElement
  footer?: ReactElement
}) {
  return (
    <>
      <Box>
        <Main hasFooter={!!props.footer}>
          <Number>{props.number}</Number>
          <Content>{props.content}</Content>
        </Main>

        {props.footer ? <Footer>{props.footer}</Footer> : null}
      </Box>
    </>
  )
}

const Box = styled.div`
  margin-bottom: ${(props) => props.theme.d.spacing[4]};
`

const Main = styled.div<{
  hasFooter?: boolean
}>`
  padding: 20px;
  background: ${(props) =>
    props.theme.d.colors.connectAccountInstruction.mainBackground};
  border-left: 1px solid ${(props) => props.theme.d.colors.border.light};
  border-top: 1px solid ${(props) => props.theme.d.colors.border.light};
  border-right: 1px solid ${(props) => props.theme.d.colors.border.light};

  border-bottom: ${(props) =>
    props.hasFooter
      ? 'none'
      : `1px solid ${props.theme.d.colors.border.light}`};

  box-sizing: border-box;
  border-radius: ${(props) => (props.hasFooter ? '4px 4px 0 0' : '4px')};
  display: flex;
`

const Number = styled.div`
  flex: 0 0 auto;
  background: ${(props) =>
    props.theme.d.colors.connectAccountInstruction.numberColor};
  border: 1px solid
    ${(props) => props.theme.d.colors.connectAccountInstruction.numberBorder};
  border-radius: 4px;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.d.colors.text.secondary};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: ${(props) => props.theme.d.spacing[4]};
`
const Content = styled.div`
  display: flex;
  align-items: center;
`

const Footer = styled.div`
  background: ${(props) => props.theme.d.colors.highlight};
  padding: ${(props) =>
    `${props.theme.d.spacing[7]} ${props.theme.d.spacing[6]}`};
  border-radius: 0 0 4px 4px;

  input {
    background: ${(props) => props.theme.d.colors.highlight};
  }
`
