import type { ClientOptions } from 'subscriptions-transport-ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { WebSocketLink } from '@apollo/client/link/ws'
import { print } from 'graphql'

type Config = {
  origin: string
  path: string
  host: string
  getAuthorization: () => Promise<string | null>
}

type WebSocketLinkWrapper = {
  wsLink: WebSocketLink
  setWsAuthorization: (authorization: string | null) => void
}

const getUri = (origin: string, path: string) => {
  const url = new URL(origin)
  url.pathname = path
  url.searchParams.set('ws', 'true')
  return url.toString()
}

export const createWebsocketLink = ({
  origin,
  path,
  host,
  getAuthorization
}: Config): WebSocketLinkWrapper => {
  const uri = getUri(origin, path)

  const middleware = {
    applyMiddleware: async (options: any, next: any) => {
      if (options.query) {
        const authorization = await getAuthorization()

        options.data = JSON.stringify({
          query:
            typeof options.query === 'string'
              ? options.query
              : print(options.query),
          variables: options.variables
        })

        options.extensions = {
          authorization: {
            authorization,
            host
          }
        }
      }
      next()
    }
  }

  let subscription: AWSSubscriptionClient | null = null

  const setWsAuthorization = (authorization: string | null) => {
    const url = new URL(uri)
    if (authorization) url.searchParams.set('authorization', authorization)
    if (subscription) subscription.setUrl(url.toString())
  }

  const connectionCallback = async (message: any) => {
    if (message) {
      const { errors } = message
      if (errors && errors?.length > 0) {
        const error = errors[0]
        if (error) {
          if (error.errorCode === 401) {
            if (subscription) {
              const authorization = await getAuthorization()
              setWsAuthorization(authorization)

              // Re-apply middleware to operation options since it could have
              // an invalid token embedded in the options.
              for (const key in Object.keys(subscription.operations)) {
                if (key) {
                  const val = subscription.operations[key]
                  if (val) {
                    val.options = await subscription.applyMiddlewares(
                      val.options
                    )
                  }
                }
              }

              // Force close after a 401.
              // This will auto-reconnect
              // if reconnect = true on the client options.
              subscription.close(false, false)
            }
          }
        }
      }
    }
  }

  subscription = new AWSSubscriptionClient(uri, {
    lazy: true,
    reconnect: true,
    timeout: 5 * 60 * 1000,
    connectionCallback
  })

  const wsLink = new WebSocketLink(subscription)

  // @ts-ignore
  wsLink.subscriptionClient.use([middleware])

  return {
    wsLink,
    setWsAuthorization
  }
}

class AWSSubscriptionClient extends SubscriptionClient {
  constructor(
    url: string,
    options?: ClientOptions,
    webSocketImpl?: any,
    webSocketProtocols?: string | string[]
  ) {
    super(url, options, webSocketImpl, webSocketProtocols)

    // Since we are in TS and these functions are private
    // we cannot directly override in this child class,
    // so we use this trick (which is not safe) to override
    // the parent functions.

    // @ts-ignore
    this.flushUnsentMessagesQueue = this.flush
    // @ts-ignore
    this.processReceivedData = this.process
  }

  public setUrl(url: string) {
    // @ts-ignore
    this.url = url
  }

  // Filter out duplicate messages before flushing queue.
  private flush() {
    const messages = this.getUnsentMessagesQueue()

    const map = new Map()
    for (const message of messages) {
      const id = message.id
      if (!map.has(id)) {
        map.set(id, true)
        // @ts-ignore
        super.sendMessageRaw(message)
      }
    }

    this.setUnsentMessagesQueue([])
  }

  // Ignore start_ack message from AppSync:
  // they are not a valid GQL message type.
  private process(receivedData: string) {
    let message: any = null

    try {
      message = JSON.parse(receivedData)
    } catch (e) {
      throw new Error(`Message must be JSON-parseable. Got: ${receivedData}`)
    }

    if (message.type === 'start_ack') {
      const newQueue = this.getUnsentMessagesQueue().filter(
        (el) => el.id !== message.id
      )

      this.setUnsentMessagesQueue(newQueue)

      return
    }

    // @ts-ignore
    super.processReceivedData(receivedData)
  }

  private getUnsentMessagesQueue(): any[] {
    // @ts-ignore
    return this.unsentMessagesQueue ?? []
  }

  private setUnsentMessagesQueue(queue: any[]): void {
    // @ts-ignore
    this.unsentMessagesQueue = queue
  }
}
