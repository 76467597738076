import styled from '@emotion/styled'
import { Trans, useTranslation } from 'react-i18next'

import logo from 'src/assets/logo.svg'

export function NewResourceIcon(props: { isNew: boolean }) {
  const { t } = useTranslation()
  if (!props.isNew) return null
  return <StyledSup> {t('assessments:new')}</StyledSup>
}

export function SpecialResourceIcon(props: {
  isPremium: boolean
  isExclusive: boolean
}) {
  if (props.isPremium) return <PremiumResourceIcon />
  if (props.isExclusive) return <ExclusivemResourceIcon />
  return null
}

function ExclusivemResourceIcon() {
  return (
    <SpecialContainer>
      <Trans i18nKey='assessments:exclusive'>
        Exclusive
        <Logo src={logo} />
      </Trans>
    </SpecialContainer>
  )
}

function PremiumResourceIcon() {
  return (
    <SpecialContainer isPremium>
      <Trans i18nKey='assessments:premium'>
        Pro
        <Logo src={logo} />
      </Trans>
    </SpecialContainer>
  )
}

const Logo = styled.img`
  vertical-align: middle;
  display: inline;
  margin-top: 1px;
  height: 16px;
`

const StyledSup = styled.sup`
  color: ${(props) => props.theme.d.colors.success};
  font-size: 16px;
  padding-left: ${(props) => props.theme.d.spacing[1]};
`

const SpecialContainer = styled.span<{ isPremium?: boolean }>`
  float: right;
  font-family: ${(props) => props.theme.d.font.secondary};
  font-size: 13px;
  font-weight: normal;
  color: ${(props) =>
    props.isPremium
      ? props.theme.d.colors.premiumResourceColor
      : props.theme.d.colors.exclusiveResourceColor};
`
