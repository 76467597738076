import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { useTheme } from '@mui/system'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useNotice } from 'src/hooks/use-notice'
import { Severity } from 'src/graphql/types'

export function NoticeBanner() {
  const theme = useTheme()
  const { t } = useTranslation()

  const notice = useNotice([Severity.Warning, Severity.Critical], {
    title: {
      text: t('notice:title')
    },
    body: {
      text: t('notice:body')
    },
    endDate: '3000-01-01',
    startDate: '2000-01-01',
    severity: Severity.Warning
  })

  const colors = {
    [Severity.Info]: theme.d.colors.success,
    [Severity.Warning]: theme.d.colors.warning,
    [Severity.Critical]: theme.d.colors.error,
    [Severity.Promotional]: theme.d.colors.accent
  }

  if (!notice) {
    return null
  }

  const color = colors[notice?.severity]

  return (
    <Box>
      <Title color={color}>
        <FontAwesomeIcon icon={faExclamationTriangle} /> {notice?.title?.text}{' '}
      </Title>
      <Description>{notice?.body?.text}</Description>
    </Box>
  )
}

const Box = styled.div`
  padding: ${(props) => props.theme.d.spacing[3]};
  text-align: center;
`

const Title = styled.h3<{ color: string }>`
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: 12px;
  margin: 0;
`

const Description = styled.p`
  margin: 0;
  font-size: 12px;
`
