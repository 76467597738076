export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Breakdown: ['BreakdownText'],
    Facet: [
      'FacetBool',
      'FacetBoolList',
      'FacetFloat',
      'FacetFloatList',
      'FacetHitBox',
      'FacetHitBoxList',
      'FacetId',
      'FacetIdList',
      'FacetInt',
      'FacetIntList',
      'FacetSentiment',
      'FacetTeam',
      'FacetTeamList',
      'FacetWeapon',
      'FacetWeaponList'
    ],
    MatchesEdge: ['MatchEdge', 'PendingMatchEdge'],
    Resource: [
      'ResourceLink',
      'ResourceLinkList',
      'ResourceMatchmakingSteamCsgo',
      'ResourceText',
      'ResourceWorkshopSteamCsgo',
      'ResourceYoutube',
      'ResourceYoutubeList'
    ]
  }
}
export default result
