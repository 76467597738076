import { useMaybeUser } from 'src/hooks/use-user'
import { useAuthCookies } from 'src/hooks/use-auth'
import { useSession } from 'src/hooks/use-session'
import { AffiliateCode } from 'src/graphql/types'
import { usePublicProfile } from 'src/hooks/use-profile'

export function useAffiliateCode() {
  const data = useMaybeUser()
  const { data: sessionData } = useSession()
  const { affiliate: cookieAffiliateCode } = useAuthCookies()
  const sessionAffiliateCode = sessionData?.session?.affiliateCode
  const userAffiliateCode = data?.user?.affiliate?.code

  const affiliateCode =
    userAffiliateCode ?? sessionAffiliateCode ?? cookieAffiliateCode

  return affiliateCode
}

export function useIsAffiliate(code: AffiliateCode) {
  const affiliateCode = useAffiliateCode()
  if (affiliateCode === code) return true
  return false
}

export function useIsGamersenseiAffiliate() {
  return useIsAffiliate(AffiliateCode.Gamersensei)
}

export function useIsCsconfederationAffiliate() {
  return useIsAffiliate(AffiliateCode.Csconfederation)
}

export function useIsProfileGamersenseiAffiliate(profileId: string) {
  const isGamersenseiAffiliate = useIsGamersenseiAffiliate()
  const { data, loading } = usePublicProfile(profileId)
  if (loading) return false
  if (data?.profile?.isOwner) return isGamersenseiAffiliate
  return data?.profile?.affiliate?.code === AffiliateCode.Gamersensei
}
