import type { LogLevelDesc } from 'loglevel'

import { createTrackingConsent } from 'src/lib/analytics/tracking-consent'
import type { FeatureFlags } from 'src/lib/analytics/posthog'
import { createPosthog } from 'src/lib/analytics/posthog'
import { createLogger } from 'src/lib/analytics/logger'
import { createSentry } from 'src/lib/analytics/sentry'

type Config = {
  name: string
  app: string
  owner: string
  env: string
  version: string
  logLevel: LogLevelDesc
  posthogApiKey: string | null
  posthogTunnel: string | null
  sentryDsn: string | null
  sentryTunnel: string | null
  featureFlags: FeatureFlags
}

export const createAnalytics = ({
  name,
  app,
  owner,
  env,
  version,
  logLevel,
  featureFlags,
  posthogApiKey,
  posthogTunnel,
  sentryDsn,
  sentryTunnel
}: Config) => {
  const level = process.env.NODE_ENV === 'test' ? 'silent' : logLevel
  const debug = level?.toString().toLowerCase() === 'debug' ?? false

  const useragent = globalThis.navigator.userAgent
  const isBot = useragent.toLowerCase().includes('prerender')

  const { log } = createLogger({
    name: app,
    level
  })

  const {
    client: sentry,
    identify: identifySentry,
    optOut: optOutSentry,
    init: initSentry,
    reset: resetSentry
  } = createSentry({
    dsn: sentryDsn,
    tunnel: sentryTunnel,
    name: `${app}-${name}`,
    environment: env,
    organization: owner,
    version,
    log
  })

  const {
    client: posthog,
    identify: identifyPosthog,
    optOut: optOutPosthog,
    init: initPosthog,
    reset: resetPosthog
  } = createPosthog({
    apiKey: posthogApiKey,
    tunnel: posthogTunnel,
    debug,
    featureFlags,
    app,
    env,
    version,
    sentry,
    useragent,
    isBot,
    log
  })

  const optOut = () => {
    optOutSentry()
    optOutPosthog()
  }

  const reset = () => {
    resetSentry()
    resetPosthog()
  }

  const identify = (userId: string | null, username: string | null) => {
    identifySentry(userId, username)
    identifyPosthog(userId, username)
  }

  const { client: trackingConsent, init: initTrackingConsent } =
    createTrackingConsent({ optOut })

  const init = async () => {
    await initPosthog()
    await initTrackingConsent()
  }

  return {
    log,
    posthog,
    sentry,
    initSentry,
    trackingConsent,
    identify,
    reset,
    init
  }
}
