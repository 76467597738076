import type { Department } from 'src/graphql/types'
import crosshairIcon from 'src/assets/assessments/crosshair_placement.svg'
import decisionsIcon from 'src/assets/assessments/decision_making.svg'
import economyIcon from 'src/assets/assessments/economy.svg'
import grenadesIcon from 'src/assets/assessments/grenade_use.svg'
import impactIcon from 'src/assets/assessments/round_impact.svg'
import movementIcon from 'src/assets/assessments/movement.svg'
import otherIcon from 'src/assets/assessments/other_mistakes.svg'
import shootingIcon from 'src/assets/assessments/shooting.svg'
import teamworkIcon from 'src/assets/assessments/teamwork.svg'

export const departmentIcon: Record<Department, string> = {
  crosshair: crosshairIcon,
  decisions: decisionsIcon,
  dexter: otherIcon,
  economy: economyIcon,
  grenades: grenadesIcon,
  impact: impactIcon,
  movement: movementIcon,
  other: otherIcon,
  scoreboard: otherIcon,
  shooting: shootingIcon,
  teamwork: teamworkIcon,
  win_probability: otherIcon
}
