import { useTheme } from '@mui/system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'

import { MatchPlatform } from 'src/graphql/types'
import { ReactComponent as FaceitIcon } from 'src/assets/icons/faceit-pheasant.svg'
import { ReactComponent as SteamIcon } from 'src/assets/icons/steam-icon.svg'

export function PlatformIcon(props: {
  size?: number
  platform: MatchPlatform
}) {
  const theme = useTheme()
  const iconProps = {
    height: props.size,
    width: props.size
  }

  if (props.platform === MatchPlatform.Steam) {
    return <SteamIcon {...iconProps} />
  }

  if (props.platform === MatchPlatform.Faceit) {
    return <FaceitIcon {...iconProps} />
  }

  if (props.platform === MatchPlatform.Unknown) {
    return (
      <FontAwesomeIcon
        icon={faCloudUploadAlt}
        color={theme.d.colors.text.secondary}
      />
    )
  }

  return null
}
