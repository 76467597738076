import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import { useIsGamersenseiAffiliate } from 'src/hooks/use-affiliate'
import coach from 'src/assets/coach-right.svg'
import { changelogQuery } from 'src/graphql'
import { Severity } from 'src/graphql/types'
import type { ChangelogQuery, ChangelogQueryVariables } from 'src/graphql/types'
import { useRandom } from 'src/lib/hooks/use-random'
import type { HeaderProps } from 'src/screens/progression-report/Header'
import { TypedMessage } from 'src/system/ui/TypedMessage'

export enum DetailsSection {
  profile = 'profile',
  matches = 'matches'
}

export function DetailsWithLoading(
  props: {
    className?: string
    title: string
    finalDescription: string
    section: DetailsSection
  } & HeaderProps
) {
  const description = useDescription({
    finalDescription: props.finalDescription,
    section: props.section,
    headerProps: props
  })

  return (
    <TalkingCoach
      className={props.className}
      title={props.title}
      message={description}
    />
  )
}

export function DetailsMatch(props: { className?: string }) {
  const { title, body } = useMatchCoachText()

  if (!title || !body) return null

  return (
    <TalkingCoach className={props.className} title={title} message={body} />
  )
}

function TalkingCoach(props: {
  title: string
  message: string
  className?: string
}) {
  return (
    <Box className={props.className}>
      <Coach>
        <img src={coach} />
      </Coach>
      <CalloutBubble>
        <Title>{props.title}</Title>
        <TypedMessage>{props.message}</TypedMessage>
      </CalloutBubble>
    </Box>
  )
}

function useDescription(props: {
  finalDescription: string
  headerProps: HeaderProps
  section: DetailsSection
}) {
  const { section } = props
  const { t } = useTranslation()
  const isGamersenseiAffiliate = useIsGamersenseiAffiliate()
  const namespace =
    isGamersenseiAffiliate && section === DetailsSection.profile
      ? `gamersensei_overview:${section}:details`
      : `overview:${section}:details`

  if (props.headerProps.isLoading) {
    return t(`${namespace}:loading`)
  }

  if (props.headerProps.hasError) {
    return t(`${namespace}:error`)
  }

  if (!props.headerProps.hasProgressions) {
    return t(`${namespace}:need_more_matches_description`)
  }

  return props.finalDescription
}

function useMatchCoachText() {
  const { t } = useTranslation()
  const randomValue = useRandom()

  const { data } = useQuery<ChangelogQuery, ChangelogQueryVariables>(
    changelogQuery,
    {
      fetchPolicy: 'cache-and-network'
    }
  )

  const notices = data?.changelog?.notices?.filter(
    ({ severity }) => severity === Severity.Info
  )

  // Use notices.length + 1 so we sometimes show the default message
  const idx = notices == null ? 0 : Math.floor(randomValue * notices.length + 1)

  const title = notices?.[idx]?.title?.text
  const body = notices?.[idx]?.body?.text

  if (!title || !body) {
    return {
      title: t('overview:match:title'),
      body: t('overview:match:body')
    }
  }

  return { title, body }
}

const Box = styled.div`
  display: flex;
  height: 100%;
`

const Coach = styled.div`
  width: 80px;
  margin-right: ${(props) => props.theme.d.spacing[4]};
  display: flex;
  align-items: center;

  img {
    max-width: 100%;
  }

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    width: 100px;
    align-items: flex-start;
  }

  @media (min-width: ${(props) => props.theme.d.breakpoints.lg}) {
    width: 144px;
    margin-right: ${(props) => props.theme.d.spacing[12]};
    align-items: center;
  }
`

const arrowWidth = 10
const arrowWidthDesktop = 16

const CalloutBubble = styled.div`
  flex: 1;
  background: ${(props) => props.theme.d.colors.panel.light};
  padding: ${(props) =>
    `${props.theme.d.spacing[4]} ${props.theme.d.spacing[5]}`};
  position: relative;
  border: 1px solid ${(props) => props.theme.d.colors.border.dark};
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: ${arrowWidth}px ${arrowWidth}px 0;
    border-color: ${(props) => props.theme.d.colors.panel.light} transparent;
    display: block;
    width: 0;
    z-index: 1;
    transform: rotate(90deg);
    right: 100%;
    margin-right: -${arrowWidth / 2}px;
    top: calc(50% - ${arrowWidth / 2}px);

    @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
      top: calc(${arrowWidth * 2}px);
    }

    @media (min-width: ${(props) => props.theme.d.breakpoints.lg}) {
      border-width: ${arrowWidthDesktop}px ${arrowWidthDesktop}px 0;
      margin-right: -${arrowWidthDesktop / 2}px;
      top: calc(50% - ${arrowWidthDesktop / 2}px);
    }
  }

  &:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: ${arrowWidth + 1}px ${arrowWidth + 1}px 0;
    border-color: ${(props) => props.theme.d.colors.border.dark} transparent;
    display: block;
    width: 0;
    z-index: 1;
    transform: rotate(90deg);
    right: 100%;
    margin-right: -${arrowWidth / 2}px;
    top: calc(50% - ${arrowWidth / 2}px);

    @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
      top: calc(${arrowWidth * 2}px);
    }

    @media (min-width: ${(props) => props.theme.d.breakpoints.lg}) {
      border-width: ${arrowWidthDesktop + 1}px ${arrowWidthDesktop + 1}px 0;
      margin-right: -${arrowWidthDesktop / 2}px;
      top: calc(50% - ${arrowWidthDesktop / 2}px);
    }
  }
`

const Title = styled.h5`
  color: ${(props) => props.theme.d.colors.text.secondary};
  font-size: 22px;
  margin: 0 0 ${(props) => props.theme.d.spacing[1]};
`
