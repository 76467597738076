import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { Meta } from 'src/system/Meta'
import { TextField } from 'src/system/ui/form/TextField'
import { useAuth } from 'src/hooks/use-auth'
import { AuthForm } from 'src/screens/auth/AuthForm'

type FormData = {
  email: string
}

export function ForgotPassword() {
  return (
    <>
      <Meta name='forgot_password' />
      <ForgotPasswordForm />
    </>
  )
}

function ForgotPasswordForm() {
  const [error, setError] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
    reset
  } = useForm<FormData>({ defaultValues: { email: '' } })

  const { forgotPassword } = useAuth()

  const submit = async () => {
    if (isSubmitting) {
      return
    }

    setEmailSent(false)
    setError('')

    try {
      const { email } = getValues()
      await forgotPassword(email)
      setEmailSent(true)
      reset()
    } catch (err: any) {
      setError(err?.message)
    }
  }

  return (
    <AuthForm
      headline={t('forgot_password:headline')}
      subhead={t('forgot_password:subhead')}
      error={error}
      render={({ SubmitButton, FieldBox }) => (
        <form onSubmit={handleSubmit(submit)}>
          <FieldBox>
            <TextField
              label={t('forgot_password:email_label')}
              placeholder={t('forgot_password:email_placeholder')}
              fullWidth
              type='email'
              error={errors?.email?.message}
              {...register('email', {
                required: `${t('forgot_password:email_required_error')}`
              })}
            />
          </FieldBox>
          <SubmitButton fullWidth type='submit' processing={isSubmitting}>
            {t('forgot_password:submit_button')}
          </SubmitButton>
          <EmailSent hidden={!emailSent}>
            {t('forgot_password:email_sent')}
          </EmailSent>
        </form>
      )}
    />
  )
}

const EmailSent = styled.div`
  margin-top: ${(props) => props.theme.d.spacing[4]};
  text-align: center;
  color: ${(props) => props.theme.d.colors.success};
`
