import type { ReactElement } from 'react'

import { useMaybeUser } from 'src/hooks/use-user'
import { UserAppBar } from 'src/system/app-bar/user/UserAppBar'
import { GuestAppBar } from 'src/system/app-bar/GuestAppBar'

export function AppBar(props: { content?: ReactElement }) {
  const { user } = useMaybeUser()

  if (user) {
    return <UserAppBar {...props} />
  }

  return <GuestAppBar {...props} />
}
