import { useTheme } from '@mui/system'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import { Box, Icon, Message } from 'src/routes/HandleCheckout'

export function VerificationFailed(props: { children: string }) {
  const theme = useTheme()
  return (
    <Box>
      <Icon icon={faExclamationCircle} color={theme.d.colors.error} />
      <Message>{props.children}</Message>
    </Box>
  )
}
