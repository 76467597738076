import type { ResourceLink as ResourceLinkData } from 'src/graphql/types'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'

export function ResourceLink(props: { resource: ResourceLinkData }) {
  return (
    <AbsoluteLink to={props.resource.url.href}>
      {props.resource.url.text}
    </AbsoluteLink>
  )
}
