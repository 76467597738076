import { useTheme } from '@mui/system'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import { RelativeLink } from 'src/system/ui/link/RelativeLink'
import { useRouting } from 'src/routes'
import { Box, Icon, Message } from 'src/routes/HandleCheckout'
import { Button } from 'src/system/ui/button/Button'
import { JoinDiscordPanel } from 'src/system/DiscordPanel'

export function CheckoutFailed() {
  const theme = useTheme()
  const { t } = useTranslation()
  const {
    routes: { pricing }
  } = useRouting()

  return (
    <Box>
      <Icon icon={faExclamationCircle} color={theme.d.colors.error} />
      <Message>{t('checkout:failure')}</Message>
      <RelativeLink to={pricing} disableStyles>
        <Button>{t('checkout:try_again')}</Button>
      </RelativeLink>
      <JoinDiscordPanel
        title={t('discord_panel:problems:title')}
        description={t('discord_panel:problems:description')}
      />
    </Box>
  )
}
