import type { ReactElement } from 'react'
import { useState } from 'react'
import styled from '@emotion/styled'
import { Box, Menu, MenuItem } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { TopBar } from 'src/system/ui/top-bar/TopBar'
import { NoticeBanner } from 'src/system/app-bar/NoticeBanner'
import { HomeLinkLogo } from 'src/system/app-bar/HomeLinkLogo'
import { VersionBadge } from 'src/system/app-bar/version/VersionBadge'
import { PageLink } from 'src/system/ui/top-bar/PageLink'
import { useRouting } from 'src/routes'
import { Button } from 'src/system/ui/button/Button'
import { IconButton } from 'src/system/ui/button/IconButton'
import Account from 'src/assets/icons/account.svg'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'
import {
  ContentDesktop,
  ContentMobile,
  Spacer
} from 'src/system/app-bar/Content'

export function GuestAppBar(props: { content?: ReactElement }) {
  return (
    <>
      <TopBar>
        <StyledHomeLogo />
        <StyledVersionBadge />
        <Spacer withContent={!!props.content} />
        <ContentDesktop content={props.content} />
        <Box
          display={{
            md: 'none'
          }}
        >
          <MobileMenu />
        </Box>
        <Box
          display={{
            xs: 'none',
            md: 'flex',
            height: '100%'
          }}
        >
          <DesktopLinks />
        </Box>
      </TopBar>
      <ContentMobile content={props.content} />
      <NoticeBanner />
    </>
  )
}

function MobileMenu() {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const { routes } = useRouting()
  const { t } = useTranslation()

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton aria-label='menu' onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem component={AbsoluteLink} to={t('link:home')}>
          {t('nav:home')}
        </MenuItem>
        <MenuItem
          component={AbsoluteLink}
          to={t('link:how_pureskill_gg_works')}
        >
          {t('nav:how_it_works')}
        </MenuItem>
        <MenuItem component={RelativeLink} to={routes.pricing}>
          {t('nav:pricing')}
        </MenuItem>
        <MenuItem component={RelativeLink} to={routes.signUp}>
          {t('nav:create_account')}
        </MenuItem>
        <MenuItem component={RelativeLink} to={routes.login}>
          {t('nav:login')}
        </MenuItem>
      </Menu>
    </>
  )
}

function DesktopLinks() {
  const { routes } = useRouting()
  const { t } = useTranslation()

  return (
    <>
      <PageLink href={t('link:home')} absolute>
        {t('nav:home')}
      </PageLink>
      <PageLink href={t('link:how_pureskill_gg_works')} absolute>
        {t('nav:how_it_works')}
      </PageLink>
      <PageLink href={routes.pricing}>{t('nav:pricing')}</PageLink>
      <PageLink icon={Account} href={routes.signUp}>
        {t('nav:create_account')}
      </PageLink>
      <StyledLoginLink to={routes.login} disableStyles>
        <StyledLoginButton>{t('nav:login')}</StyledLoginButton>
      </StyledLoginLink>
    </>
  )
}

const StyledHomeLogo = styled(HomeLinkLogo)`
  margin-right: ${(props) => props.theme.d.spacing[3]};
`

const StyledVersionBadge = styled(VersionBadge)`
  margin-right: ${(props) => props.theme.d.spacing[3]};
`

const StyledLoginLink = styled(RelativeLink)`
  margin: auto 0;
`

const StyledLoginButton = styled(Button)`
  padding: ${(props) =>
    `${props.theme.d.spacing[3]} ${props.theme.d.spacing[9]}`};
`
