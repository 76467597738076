import styled from '@emotion/styled'

import { isPlaceholder } from 'src/lib/matches/map-image'
import { Badge } from 'src/screens/matches/collection/match/Badge'
import { formattedDate } from 'src/lib/matches/matches'
import {
  Status,
  useComputedStatus
} from 'src/screens/matches/collection/match/status/Status'
import { PlatformIcon } from 'src/system/ui/match/PlatformIcon'
import { ViewMatchButton } from 'src/screens/matches/collection/list/ViewMatchButton'
import type { MatchProps } from 'src/screens/matches/collection/MatchCard'
import { MatchLink } from 'src/screens/matches/collection/match/MatchLink'
import { Score } from 'src/system/ui/match/Score'
import { PendingMatchStatus } from 'src/graphql/types'

export function CardMobile(
  props: MatchProps & {
    background: string
  }
) {
  const status = useComputedStatus(
    props.updatedAt,
    props.status,
    props.courseProgress
  )
  const canViewMatch = status === PendingMatchStatus.Completed
  return (
    <StyledMatchLink
      background={props.background}
      isPlaceholderBackground={isPlaceholder(props.background)}
      link={canViewMatch ? props.link : null}
    >
      <Left>
        <BadgeContainer>
          <StyledBadge map={props.map} />
        </BadgeContainer>
        <Details>
          <StyledScore
            ctScore={props.ctScore}
            tScore={props.tScore}
            players={props.players}
            didWin={props.didWin}
            isTie={props.isTie}
            isOwnerInMatch={props.isOwnerInMatch}
          />
          <Date>
            <PlatformIcon size={15} platform={props.platform} />{' '}
            {formattedDate(props.uploadDate)}
          </Date>
        </Details>
      </Left>
      <Right>
        <StyledStatus
          current={props.status}
          updatedAt={props.updatedAt}
          createdAt={props.createdAt}
          courseProgress={props.courseProgress}
        />
        <StyledViewMatchButton disabled={!canViewMatch} />
      </Right>
    </StyledMatchLink>
  )
}

const StyledMatchLink = styled(MatchLink)<{
  background: string
  isPlaceholderBackground: boolean
}>`
  border-radius: 4px;
  height: 100px;
  padding: ${(props) =>
    `${props.theme.d.spacing[5]} ${props.theme.d.spacing[2]}`};
  background: ${(props) => props.theme.d.colors.mapCardBackground},
    url(${(props) => props.background});
  background-size: ${(props) =>
    props.isPlaceholderBackground ? 'initial' : 'cover'};
  background-position: 50%;
  background-repeat: no-repeat;
  margin-bottom: ${(props) => props.theme.d.spacing[3]};
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.d.colors.border.dark};

  display: flex;
  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    display: none;
  }
`

const Left = styled.div`
  display: flex;
  align-items: center;
`

const badgeSize = '60px'

const BadgeContainer = styled.div`
  width: ${badgeSize};
  height: ${badgeSize};
  display: flex;
  align-items: center;
  margin-right: ${(props) => props.theme.d.spacing[3]};
  justify-content: center;
`

const StyledBadge = styled(Badge)`
  max-width: ${badgeSize};
  max-height: ${badgeSize};
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledScore = styled(Score)`
  font-size: 30px;
`

const Date = styled.span`
  color: ${(props) => props.theme.d.colors.text.secondary};
`

const StyledStatus = styled(Status)`
  font-size: 11px;
  justify-content: flex-end;
  margin-top: ${(props) => props.theme.d.spacing[1]};
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledViewMatchButton = styled(ViewMatchButton)`
  margin: ${(props) =>
    `${props.theme.d.spacing[2]} 0 ${props.theme.d.spacing[1]}`};
  font-size: 14px;

  svg {
    font-size: 16px;
  }
`
