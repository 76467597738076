import { gql } from '@apollo/client'

export const roundQuery = gql`
  query Round($matchId: ID!, $roundNumber: Int!) {
    match(id: $matchId) {
      id
      resourceId
      sharecode
      type
      platform
      sourceId
      date
      map
      score {
        t
        ct
        winner
        loser
        isTie
      }
      players {
        index
        name
        rank
        team
        wins
        clanTag
        steamId
        isOwner
      }
      serverTickRate
      demoTickRate
      totalRounds
      round(number: $roundNumber) {
        number
        score {
          t
          ct
          winner
          loser
        }
        startTick
        endTick
        officialEndTick
        endReason
        players {
          ...RoundPlayerFields
        }
        tickUrl
        winProbabilityUrl
      }
    }
  }

  fragment RoundPlayerFields on Player {
    index
    name
    rank
    team
    wins
    clanTag
    steamId
    isOwner
  }
`
