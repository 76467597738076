import { useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { DateTime, Duration } from 'luxon'

import { useIsMembershipUpgradable } from 'src/hooks/use-membership'
import { Button, ButtonVariant } from 'src/system/ui/button/Button'
import { useInterval } from 'src/lib/hooks/use-interval'
import { useNotice } from 'src/hooks/use-notice'
import { useRouting } from 'src/routes'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'
import { Severity } from 'src/graphql/types'

const refreshRate = Duration.fromObject({ milliseconds: 200 }).as(
  'milliseconds'
)

export function PromotionBanner() {
  const isMembershipUpgradable = useIsMembershipUpgradable()
  const [timeLeft, setTimeLeft] = useState<Duration | null>(null)
  const { t } = useTranslation()
  const { routes } = useRouting()

  const notice = useNotice([Severity.Promotional], {
    title: {
      text: t('promotion_banner:title')
    },
    body: {
      text: t('promotion_banner:body')
    },
    endDate: '3000-01-01',
    startDate: '2000-01-01',
    severity: Severity.Promotional
  })

  useInterval(() => {
    if (!notice) return
    const end = DateTime.fromISO(notice.endDate)
    setTimeLeft(end.diffNow(['days', 'hours', 'minutes', 'seconds']))
  }, refreshRate)

  if (!notice || !isMembershipUpgradable) {
    return null
  }

  return (
    <Box show={Boolean(timeLeft)}>
      <RelativeLink to={routes.pricing} disableStyles>
        <UpgradeButton variant={ButtonVariant.accent}>
          {t('promotion_banner:button')}
        </UpgradeButton>
      </RelativeLink>
      <Title>{notice?.title?.text}</Title>{' '}
      <Description>{notice?.body?.text}</Description>{' '}
      <Countdown timeLeft={timeLeft} />
    </Box>
  )
}

function Countdown(props: { timeLeft: Duration | null }) {
  const { t } = useTranslation()

  if (!props.timeLeft || props.timeLeft.days > 14) return null

  const { days = 0, hours = 0, minutes = 0, seconds = 0 } = props.timeLeft

  return (
    <CountdownText>
      {t('promotion_banner:time_left', {
        days,
        hours: hours.toString().padStart(2, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        seconds: Math.round(seconds).toString().padStart(2, '0')
      })}
    </CountdownText>
  )
}

const Box = styled.div<{ show: boolean }>`
  background: ${(props) => props.theme.d.colors.promotionBackground};
  padding: ${(props) => props.theme.d.spacing[3]} 0;
  text-align: center;

  * {
    opacity: ${(props) => (props.show ? 1 : 0)};
  }
`

const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => props.theme.d.colors.text.secondary};
`

const Description = styled.span`
  font-size: 18px;
  color: ${(props) => props.theme.d.colors.text.secondary};
`

const UpgradeButton = styled(Button)`
  text-transform: uppercase;
  margin: 0 auto ${(props) => props.theme.d.spacing[3]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    margin: 0 ${(props) => props.theme.d.spacing[3]} 0 0;
    display: inline-block;
  }
`

const CountdownText = styled.span`
  font-size: 18px;
  color: ${(props) => props.theme.d.colors.promotionText};
  font-weight: bold;
  white-space: nowrap;
`
