import { loadStripe } from '@stripe/stripe-js/pure'
import type { Stripe } from '@stripe/stripe-js'

type Config = {
  publishableKey: string
}

export const createStripe = ({ publishableKey }: Config) => {
  let client: Stripe | null = null

  const init = async () => {
    client = await loadStripe(publishableKey)
  }

  return {
    getClient: () => client,
    init
  }
}
