import { CircularProgress } from '@mui/material'
import type { CircularProgressProps } from '@mui/material'
import styled from '@emotion/styled'

export function ProgressIcon(props: { progress: number; className?: string }) {
  const childProps = { size: 16, thickness: 7 }
  return (
    <Container className={props.className}>
      <CircularProgressBottom value={100} {...childProps} />
      <CircularProgressTop value={100 * (1 - props.progress)} {...childProps} />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const CircularProgressTop = styled((props: CircularProgressProps) => (
  <CircularProgress variant='determinate' {...props} />
))`
  @keyframes circular-rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation: circular-rotate 1.4s linear infinite;
  position: absolute;
  left: 0;
`

const CircularProgressBottom = styled((props: CircularProgressProps) => (
  <CircularProgress variant='determinate' color='inherit' {...props} />
))`
  color: ${(props) => props.theme.d.colors.success};
  position: absolute;
`
