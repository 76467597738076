import Cookies from 'universal-cookie'
import { DateTime, Duration } from 'luxon'

const optOutCookieName = 'tracking_opt_out'

const optOutValue = '1'
const optInValue = '0'

type Config = {
  optOut: () => void
}

const interval = Duration.fromObject({ seconds: 10 }).as('milliseconds')

export type TrackingConsent = {
  optOutCookieName: string
  setOptOut: (isOptedOut: boolean) => void
  reset: () => void
}

export const createTrackingConsent = ({ optOut }: Config) => {
  const cookies = new Cookies()

  const isOptedOut = () =>
    navigator?.doNotTrack === optOutValue ||
    cookies.get(optOutCookieName) === optOutValue

  const optInOrOut = () => {
    if (isOptedOut()) optOut()
  }

  const init = async () => {
    optInOrOut()
    globalThis.setInterval(optInOrOut, interval)
  }

  const setOptOut = (optedOut: boolean) => {
    const value = optedOut ? optOutValue : optInValue
    cookies.set(optOutCookieName, value, {
      path: '/',
      secure: true,
      sameSite: 'lax',
      expires: oneYearFromNow().toJSDate()
    })
    optInOrOut()
  }

  const reset = () => {
    cookies.remove(optOutCookieName)
    globalThis.location.reload()
  }

  const client = {
    optOutCookieName,
    setOptOut,
    reset
  }

  return {
    client,
    init
  }
}

const oneYearFromNow = () => DateTime.local().plus({ years: 1 })

export const trackingConsentStub = () => ({
  optOutCookieName: '',
  setOptOut: (_isOptedOut: boolean) => {},
  reset: () => {}
})
