import { useEffect } from 'react'
import styled from '@emotion/styled'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

import { useMatches, useMatchesStatus } from 'src/hooks/use-matches'
import { useRelativeTime } from 'src/hooks/use-relative-time'
import {
  CheckForMatchesButton,
  useCooldown
} from 'src/screens/matches/CheckForMatchesButton'

export function CheckForNewMatchesControl(props: {
  loading: boolean
  firstVisibleMatchId: string | null
  numVisibleMatches: number
}) {
  const { refetch: refetchMatches } = useMatches()

  const { t } = useTranslation()
  const { cooldown, resetCooldown } = useCooldown()
  const { data } = useMatchesStatus()

  const checkedAt = data?.user?.automatch?.checkedAt
  const checkedAtTime = checkedAt ? DateTime.fromISO(checkedAt) : null
  const time = useRelativeTime(checkedAtTime)

  const latestMatches = data?.latestMatches?.edges
  const latestMatch = latestMatches
    ? latestMatches.find((row) => !!row?.node)
    : null
  const latestMatchId = latestMatch?.node?.id

  const hasFirstNewMatch = props.numVisibleMatches === 0
  const hasNewerMatch = props.firstVisibleMatchId !== latestMatchId
  const latestMatchIsValid = !!latestMatchId
  const hasNewMatches =
    latestMatchIsValid && (hasNewerMatch || hasFirstNewMatch)

  useEffect(() => {
    if (hasNewMatches) refetchMatches()
  }, [hasNewMatches, refetchMatches])

  if (!checkedAt) return null

  const text =
    cooldown === 0 ? t('matches:last_update', { time }) : t('matches:updating')

  return (
    <Box sx={{ '& > button': { marginRight: '10px' } }}>
      <CheckForMatchesButton
        cooldown={cooldown}
        resetCooldown={resetCooldown}
      />
      <LastUpdateTime>{text}</LastUpdateTime>
    </Box>
  )
}

const LastUpdateTime = styled.span`
  font-size: 16px;
  margin-right: ${(props) => props.theme.d.spacing[3]};
  margin-bottom: ${(props) => props.theme.d.spacing[2]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    margin-bottom: 0;
  }
`
