import { useQuery } from '@apollo/client'
import type { WatchQueryFetchPolicy } from '@apollo/client'

import {
  privateProfileQuery,
  profileQuery,
  publicProfileQuery
} from 'src/graphql'
import type {
  PrivateProfileQuery,
  PrivateProfileQueryVariables,
  ProfileQuery,
  ProfileQueryVariables,
  PublicProfileQuery,
  PublicProfileQueryVariables
} from 'src/graphql/types'

export function useProfile(
  fetchPolicy: WatchQueryFetchPolicy,
  pollInterval?: number
) {
  const { data, loading } = useQuery<ProfileQuery, ProfileQueryVariables>(
    profileQuery,
    {
      fetchPolicy,
      pollInterval
    }
  )
  return { loading, profile: data?.profile }
}

export function usePrivateProfile() {
  const { data, loading, error, refetch } = useQuery<
    PrivateProfileQuery,
    PrivateProfileQueryVariables
  >(privateProfileQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all'
  })
  return { data, loading, error, refetch }
}

export function usePublicProfile(
  profileId: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
) {
  const { data, loading, error, refetch } = useQuery<
    PublicProfileQuery,
    PublicProfileQueryVariables
  >(publicProfileQuery, {
    variables: { profileId },
    fetchPolicy,
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all'
  })
  return { data, loading, error, refetch }
}
