import { useTranslation } from 'react-i18next'

import { Box, Message } from 'src/routes/HandleCheckout'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'
import { useRouting } from 'src/routes'
import { Button } from 'src/system/ui/button/Button'

export function ToggleComplete(props: { canceled: boolean }) {
  const { t } = useTranslation()
  const message = props.canceled
    ? t('checkout:membership_canceled')
    : t('checkout:membership_uncanceled')
  const { routes } = useRouting()

  return (
    <Box>
      <Message>{message}</Message>
      <RelativeLink to={routes.root} disableStyles>
        <Button>{t('checkout:go_to_matches')}</Button>
      </RelativeLink>
    </Box>
  )
}
