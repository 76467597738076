import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'

import { usePrivateProfile } from 'src/hooks/use-profile'
import type { ProgressionReportFieldsFragment } from 'src/graphql/types'
import type { Progression } from 'src/screens/progression-report/ProgressionResources'
import { ProgressionAssignment } from 'src/screens/progression-report/ProgressionAssignment'
import { MatchesHeader } from 'src/screens/progression-report/Header'
import { Box } from 'src/screens/progression-report/ProgressionResources'

export function ProgressionReport() {
  return <PrivateProgressionReport />
}

function PrivateProgressionReport() {
  const res = usePrivateProfile()
  return <PrivateContent profile={res} />
}

function PrivateContent(props: {
  profile: ReturnType<typeof usePrivateProfile>
}) {
  const { data, loading, error } = props.profile
  const [selected, setSelected] = useState<Progression | null>(null)

  const isLoading = !data && loading

  const progressionReport = data?.profile?.csgo?.progressionReport

  const hasProgressions = progressionReport
    ? (progressionReport?.assignmentProgressions?.length ?? []) > 0
    : false

  /**
   * Select first progression
   */
  useEffect(() => {
    if (!progressionReport) {
      return
    }

    const alreadySelected =
      selected &&
      !!progressionReport?.assignmentProgressions?.find(
        (p) => p.id === selected.id
      )
    if (alreadySelected) {
      return
    }

    if (!hasProgressions) {
      return
    }

    const newSelected = progressionReport?.assignmentProgressions[0]
    if (newSelected) setSelected(newSelected)
  }, [progressionReport, selected, hasProgressions])

  const hasError = Boolean(error)

  return (
    <Box>
      <MatchesHeader
        isLoading={loading}
        hasError={hasError}
        hasProgressions={hasProgressions}
      />
      <SinglePlotBody
        hasError={Boolean(error)}
        selected={selected}
        onSelect={setSelected}
        data={progressionReport}
        shouldRender={hasProgressions && !isLoading}
      />
    </Box>
  )
}

function SinglePlotBody(props: {
  hasError: boolean
  data?: ProgressionReportFieldsFragment
  shouldRender: boolean
  selected: Progression | null
  onSelect: (progression: Progression) => void
}) {
  const { data, selected, onSelect, hasError, shouldRender } = props

  if (!data || !selected || hasError || !shouldRender) {
    return null
  }
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} lg={12}>
        <Grid container spacing={5}>
          {data.assignmentProgressions.map((progression) => (
            <Grid key={progression.id} item xs={12} md={12} lg={12}>
              <ProgressionAssignment
                progression={progression}
                isSelected={selected.id === progression.id}
                onClickChart={() => onSelect(progression)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
