import type { ReactElement } from 'react'
import { useState } from 'react'
import styled from '@emotion/styled'

import { Tab } from 'src/screens/match/assessments/assignment-list/assignment/expansion/Tab'

export enum ExpansionTab {
  One = 'one',
  Two = 'two'
}

export interface TabProps {
  title: string | ReactElement
  body?: ReactElement
  handleOnClick?: () => void
  isNew?: boolean
  isActive?: boolean
  bodyCount?: number
}

export function Expansion(props: { one: TabProps; two?: TabProps }) {
  const [tab, setTab] = useState<ExpansionTab | null>(null)

  let optionalTab = null
  if (props.two) {
    optionalTab = (
      <SecondTab
        tab={ExpansionTab.Two}
        currentTab={tab}
        setTab={setTab}
        title={props.two.title}
        canExpand={Boolean(props.two.body)}
        onClick={props.two.handleOnClick}
        isActive={props.two.isActive}
        bodyCount={props.two.bodyCount}
        isNew={props.two.isNew}
      />
    )
  }

  return (
    <Box>
      <Header>
        <FirstTab
          tab={ExpansionTab.One}
          currentTab={tab}
          setTab={setTab}
          title={props.one.title}
          canExpand={Boolean(props.one.body)}
          bodyCount={props.one.bodyCount}
          isNew={props.one.isNew}
        />
        {optionalTab}
      </Header>
      <ExpandedSection
        tab={tab}
        one={props.one.body}
        two={props.two?.body ?? undefined}
      />
    </Box>
  )
}

function ExpandedSection(props: {
  tab: ExpansionTab | null
  one?: ReactElement
  two?: ReactElement
}) {
  const isVisible = Boolean(props.tab)

  return (
    <Container visible={isVisible}>
      <Content tab={props.tab} one={props.one} two={props.two} />
    </Container>
  )
}

function Content(props: {
  tab: ExpansionTab | null
  one?: ReactElement
  two?: ReactElement
}) {
  if (!props.tab) {
    return null
  }
  if (props.tab === ExpansionTab.One) {
    return props.one ? <BorderBox>{props.one}</BorderBox> : null
  }

  if (props.tab === ExpansionTab.Two) {
    return props.two ? <BorderBox>{props.two}</BorderBox> : null
  }

  return null
}

const Box = styled.div`
  background: ${(props) => props.theme.d.colors.panel.dark};
  border-top: 1px solid ${(props) => props.theme.d.colors.border.dark};
`

const Header = styled.div`
  display: flex;
`

const FirstTab = styled(Tab)`
  border-right: 1px solid ${(props) => props.theme.d.colors.border.dark};
`

const SecondTab = styled(Tab)``

const BorderBox = styled.div`
  border-top: 1px solid ${(props) => props.theme.d.colors.border.dark};
`

const maxHeight = '10000px'
const Container = styled.div<{
  visible: boolean
}>`
  max-height: ${(props) => (props.visible ? `${maxHeight}` : '0')};
  overflow: hidden;
`
