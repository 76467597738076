import type { ReactNode } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@mui/system'

type PanelProps = {
  children: ReactNode
  className?: string
  disablePadding?: boolean
}

export function Panel(props: PanelProps) {
  const padding = usePadding(props.disablePadding)
  const paddingMobile = usePaddingMobile(props.disablePadding)
  return (
    <Box
      className={props.className}
      padding={padding}
      paddingMobile={paddingMobile}
    >
      {props.children}
    </Box>
  )
}

const Box = styled.div<{
  padding: string
  paddingMobile: string
}>`
  background: ${(props) => props.theme.d.colors.panel.light};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.d.colors.border.dark};
  padding: ${(props) => props.padding};

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    padding: ${(props) => props.paddingMobile};
  }
`

function usePadding(disablePadding: boolean | undefined) {
  const theme = useTheme()
  if (disablePadding) return '0'
  return `${theme.d.spacing[4]} ${theme.d.spacing[2]}`
}

function usePaddingMobile(disablePadding: boolean | undefined) {
  const theme = useTheme()
  if (disablePadding) return '0'
  return `${theme.d.spacing[6]} ${theme.d.spacing[9]}`
}
