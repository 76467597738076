import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { ReleaseNotes } from 'src/system/app-bar/version/ReleaseNotes'
import type { Release } from 'src/graphql/types'

export function ReleaseLog(props: { releases: Release[] }) {
  const { t } = useTranslation()

  return (
    <Box>
      <Title>{t('release_notes:title')}</Title>
      {props.releases.map((release) => (
        <StyledReleaseNotes key={release.date} release={release} />
      ))}
    </Box>
  )
}

const Box = styled.div`
  padding: ${(props) => props.theme.d.spacing[4]};
  width: 380px;
  max-height: 370px;
  max-width: 100%;
  pointer-events: none;
`

const Title = styled.h5`
  margin: 0 0 ${(props) => props.theme.d.spacing[6]};
  color: ${(props) => props.theme.d.colors.text.secondary};
  font-size: 18px;
  line-height: 24px;
`

const StyledReleaseNotes = styled(ReleaseNotes)`
  padding-bottom: ${(props) => props.theme.d.spacing[6]};

  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.d.colors.border.dark};
    margin-bottom: ${(props) => props.theme.d.spacing[6]};
  }
`
