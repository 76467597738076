import styled from '@emotion/styled'
import { useTheme } from '@mui/system'
import { useTranslation } from 'react-i18next'

import { ReactComponent as FaceitIcon } from 'src/assets/icons/faceit-pheasant.svg'
import { Button } from 'src/system/ui/button/Button'
import { useLinkAccount } from 'src/hooks/use-link-account'
import { UserLinkType } from 'src/graphql/types'

export function LinkFaceit(props: { className?: string }) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { redirect, isLoading, isError } = useLinkAccount(UserLinkType.Faceit)
  return (
    <>
      <StyledButton
        className={props.className}
        color={theme.d.colors.faceit.primary.black}
        aria-label={t('account:faceit:label')}
        processing={isLoading}
        onClick={redirect}
      >
        <FaceitIcon width={24} height={24} />
        {t('account:faceit:label')}
      </StyledButton>
      {isError && t('account:faceit:error')}
    </>
  )
}

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.d.colors.faceit.primary.white};
`
