import type { ReactNode } from 'react'
import { CacheProvider } from '@emotion/react'
import type { EmotionCache } from '@emotion/cache'
import {
  StyledEngineProvider,
  ThemeProvider as StyledThemeProvider
} from '@mui/system'

import { theme } from 'src/system/ui/theme/config'
import { GlobalStyles } from 'src/system/ui/theme/GlobalStyles'
import type { ExtendedTheme } from 'src/system/ui/theme/config'

export { StyledEngineProvider } from '@mui/material'

export function ThemeProvider(props: {
  cache?: EmotionCache
  children: ReactNode
}) {
  const children = (
    <StyledThemeProvider theme={theme}>
      <GlobalStyles />
      <StyledEngineProvider injectFirst>{props.children}</StyledEngineProvider>
    </StyledThemeProvider>
  )
  if (props.cache == null) return children
  return <CacheProvider value={props.cache}>{children}</CacheProvider>
}

declare module '@emotion/react' {
  export interface Theme {
    d: ExtendedTheme
  }
}

declare module '@mui/system' {
  interface Theme {
    d: ExtendedTheme
  }
}
