import styled from '@emotion/styled'

import type { ResourceYoutubeList as ResourceYoutubeListData } from 'src/graphql/types'
import { YoutubeVideo } from 'src/system/ui/video/YoutubeVideo'

export function ResourceYoutubeList(props: {
  resource: ResourceYoutubeListData
}) {
  return (
    <Grid>
      {props.resource.videos.map((video) => (
        <Video key={video.externalId}>
          <Title>{video.text}</Title>
          <YoutubeVideo title={video.text} id={video.externalId} />
        </Video>
      ))}
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin: -${(props) => props.theme.d.spacing[2]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: ${(props) => props.theme.d.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const Video = styled.div`
  margin: ${(props) => props.theme.d.spacing[2]};
`

const Title = styled.h5`
  font-size: 16px;
  margin: 0 0 8px;
`
