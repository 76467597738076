import { useTranslation } from 'react-i18next'

import { Button, ButtonVariant } from 'src/system/ui/button/Button'
import { useRouting } from 'src/routes'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'

export function PerkUpgradeButton() {
  const { t } = useTranslation()
  const { routes } = useRouting()
  return (
    <RelativeLink to={routes.pricing} disableStyles>
      <Button variant={ButtonVariant.accent}>
        {t('perk_gate:upgrade_button')}
      </Button>
    </RelativeLink>
  )
}
