import ak47Svg, { ReactComponent as Ak47Icon } from './ak47.svg'
import augSvg, { ReactComponent as AugIcon } from './aug.svg'
import awpSvg, { ReactComponent as AwpIcon } from './awp.svg'
import bayonetSvg, { ReactComponent as BayonetIcon } from './bayonet.svg'
import bizonSvg, { ReactComponent as BizonIcon } from './bizon.svg'
import c4Svg, { ReactComponent as C4Icon } from './c4.svg'
import cz75aSvg, { ReactComponent as Cz75aIcon } from './cz75a.svg'
import deagleSvg, { ReactComponent as DeagleIcon } from './deagle.svg'
import decoySvg, { ReactComponent as DecoyIcon } from './decoy.svg'
import defuserSvg, { ReactComponent as DefuserIcon } from './defuser.svg'
import eliteSvg, { ReactComponent as EliteIcon } from './elite.svg'
import famasSvg, { ReactComponent as FamasIcon } from './famas.svg'
import fivesevenSvg, { ReactComponent as FiveSevenIcon } from './fiveseven.svg'
import flashbangSvg, { ReactComponent as FlashbangIcon } from './flashbang.svg'
import g3sg1Svg, { ReactComponent as G3sg1Icon } from './g3sg1.svg'
import galilarSvg, { ReactComponent as GalilarIcon } from './galilar.svg'
import glockSvg, { ReactComponent as GlockIcon } from './glock.svg'
import hegrenadeSvg, { ReactComponent as HeGrenadeIcon } from './hegrenade.svg'
import hkp2000Svg, { ReactComponent as Hkp2000Icon } from './hkp2000.svg'
import incgrenadeSvg, {
  ReactComponent as IncGrenadeIcon
} from './incgrenade.svg'
import knifeButterflySvg, {
  ReactComponent as KnifeButterflyIcon
} from './knife_butterfly.svg'
import knifeFalchionSvg, {
  ReactComponent as KnifeFalchionIcon
} from './knife_falchion.svg'
import knifeFlipSvg, { ReactComponent as KnifeFlipIcon } from './knife_flip.svg'
import knifeGutSvg, { ReactComponent as KnifeGutIcon } from './knife_gut.svg'
import knifeKarambitSvg, {
  ReactComponent as KnifeKarambitIcon
} from './knife_karambit.svg'
import knifeM9BayonetSvg, {
  ReactComponent as KnifeM9BayonetIcon
} from './knife_m9_bayonet.svg'
import knifePushSvg, { ReactComponent as KnifePushIcon } from './knife_push.svg'
import knifeSvg, { ReactComponent as KnifeIcon } from './knife.svg'
import knifeTSvg, { ReactComponent as KnifeTIcon } from './knife_t.svg'
import knifeTacticalSvg, {
  ReactComponent as KnifeTacticalIcon
} from './knife_tactical.svg'
import m249Svg, { ReactComponent as M249Icon } from './m249.svg'
import m4a1SilencerSvg, {
  ReactComponent as M4a1SilencerIcon
} from './m4a1_silencer.svg'
import m4a1Svg, { ReactComponent as M4a1Icon } from './m4a1.svg'
import mac10Svg, { ReactComponent as Mac10Icon } from './mac10.svg'
import mag7Svg, { ReactComponent as Mag7Icon } from './mag7.svg'
import molotovSvg, { ReactComponent as MolotovIcon } from './molotov.svg'
// TODO: Get SVG
// import mp5sdSvg , { ReactComponent as Mp5sdIcon } from './mp5sd.svg'
import mp7Svg, { ReactComponent as Mp7Icon } from './mp7.svg'
import mp9Svg, { ReactComponent as Mp9Icon } from './mp9.svg'
import negevSvg, { ReactComponent as NegevIcon } from './negev.svg'
import novaSvg, { ReactComponent as NovaIcon } from './nova.svg'
import p250Svg, { ReactComponent as P250Icon } from './p250.svg'
import p90Svg, { ReactComponent as P90Icon } from './p90.svg'
import revolverSvg, { ReactComponent as RevolverIcon } from './revolver.svg'
import sawedoffSvg, { ReactComponent as SawedOffIcon } from './sawedoff.svg'
import scar20Svg, { ReactComponent as Scar20Icon } from './scar20.svg'
import sg556Svg, { ReactComponent as Sg556Icon } from './sg556.svg'
import smokegrenadeSvg, {
  ReactComponent as SmokeGrenadeIcon
} from './smokegrenade.svg'
import ssg08Svg, { ReactComponent as Ssg08Icon } from './ssg08.svg'
import taserSvg, { ReactComponent as TaserIcon } from './taser.svg'
import tec9Svg, { ReactComponent as Tec9Icon } from './tec9.svg'
import ump45Svg, { ReactComponent as Ump45Icon } from './ump45.svg'
import uspSilencerSvg, {
  ReactComponent as UspSilencerIcon
} from './usp_silencer.svg'
import vestSvg, { ReactComponent as VestIcon } from './kevlar.svg'
import vestHelmSvg, { ReactComponent as VestHelmIcon } from './kevlarhelm.svg'
import xm1014Svg, { ReactComponent as XM1014Icon } from './xm1014.svg'

export const weapons = {
  1: {
    name: 'Desert Eagle',
    sname: 'deagle',
    displayName: 'Deagle',
    killfeedOffset: 12,
    url: deagleSvg,
    icon: DeagleIcon
  },
  2: {
    name: 'Dual Berettas',
    sname: 'elite',
    displayName: 'Dualies',
    killfeedOffset: 0,
    killfeedOffsetLeft: -7,
    url: eliteSvg,
    icon: EliteIcon
  },
  3: {
    name: 'Five-SeveN',
    sname: 'fiveseven',
    displayName: 'Five7',
    killfeedOffset: 15,
    killfeedOffsetLeft: -15,
    url: fivesevenSvg,
    icon: FiveSevenIcon
  },
  4: {
    name: 'Glock-18',
    sname: 'glock',
    displayName: 'Glock',
    killfeedOffset: 13,
    url: glockSvg,
    icon: GlockIcon
  },
  7: {
    name: 'AK-47',
    sname: 'ak47',
    displayName: 'AK47',
    killfeedOffset: 0,
    killfeedOffsetLeft: -20,
    url: ak47Svg,
    icon: Ak47Icon
  },
  8: {
    name: 'AUG',
    sname: 'aug',
    displayName: 'AUG',
    killfeedOffset: 0,
    killfeedOffsetLeft: -15,
    url: augSvg,
    icon: AugIcon
  },
  9: {
    name: 'AWP',
    sname: 'awp',
    displayName: 'AWP',
    killfeedOffset: -10,
    killfeedOffsetLeft: -20,
    url: awpSvg,
    icon: AwpIcon
  },
  10: {
    name: 'FAMAS',
    sname: 'famas',
    displayName: 'Famas',
    killfeedOffset: 4,
    killfeedOffsetLeft: -10,
    url: famasSvg,
    icon: FamasIcon
  },
  11: {
    name: 'G3SG1',
    sname: 'g3sg1',
    displayName: 'Auto',
    killfeedOffset: -3,
    killfeedOffsetLeft: -15,
    url: g3sg1Svg,
    icon: G3sg1Icon
  },
  13: {
    name: 'Galil AR',
    sname: 'galilar',
    displayName: 'Galil',
    killfeedOffset: -7,
    killfeedOffsetLeft: -7,
    url: galilarSvg,
    icon: GalilarIcon
  },
  14: {
    name: 'M249',
    sname: 'm249',
    displayName: 'M249',
    killfeedOffset: -1,
    killfeedOffsetLeft: 15,
    url: m249Svg,
    icon: M249Icon
  },
  16: {
    name: 'M4A4',
    sname: 'm4a1',
    displayName: 'M4A4',
    killfeedOffset: 0,
    killfeedOffsetLeft: -20,
    url: m4a1Svg,
    icon: M4a1Icon
  },
  17: {
    name: 'MAC-10',
    sname: 'mac10',
    displayName: 'Mac10',
    killfeedOffset: 17,
    killfeedOffsetLeft: -20,
    url: mac10Svg,
    icon: Mac10Icon
  },
  19: {
    name: 'P90',
    sname: 'p90',
    displayName: 'P90',
    killfeedOffset: 5,
    killfeedOffsetLeft: -20,
    url: p90Svg,
    icon: P90Icon
  },
  23: {
    name: 'MP5-SD',
    sname: 'mp5sd',
    displayName: 'MP5',
    killfeedOffset: 13,
    killfeedOffsetLeft: -5,
    url: mp7Svg,
    icon: Mp7Icon
    // url: mp5sdSvg
  },
  24: {
    name: 'UMP-45',
    sname: 'ump45',
    displayName: 'UMP',
    killfeedOffset: 5,
    killfeedOffsetLeft: -13,
    url: ump45Svg,
    icon: Ump45Icon
  },
  25: {
    name: 'XM1014',
    sname: 'xm1014',
    displayName: 'XM',
    killfeedOffset: 0,
    killfeedOffsetLeft: -13,
    url: xm1014Svg,
    icon: XM1014Icon
  },
  26: {
    name: 'PP-Bizon',
    sname: 'bizon',
    displayName: 'Bizon',
    killfeedOffset: 0,
    killfeedOffsetLeft: -13,
    url: bizonSvg,
    icon: BizonIcon
  },
  27: {
    name: 'MAG-7',
    sname: 'mag7',
    displayName: 'MAG-7',
    killfeedOffset: -5,
    url: mag7Svg,
    icon: Mag7Icon
  },
  28: {
    name: 'Negev',
    sname: 'negev',
    displayName: 'Negev',
    killfeedOffset: -20,
    url: negevSvg,
    icon: NegevIcon
  },
  29: {
    name: 'Sawed-Off',
    sname: 'sawedoff',
    displayName: 'Sawed',
    killfeedOffset: 0,
    killfeedOffsetLeft: -13,
    url: sawedoffSvg,
    icon: SawedOffIcon
  },
  30: {
    name: 'Tec-9',
    sname: 'tec9',
    displayName: 'Tec-9',
    killfeedOffset: 10,
    url: tec9Svg,
    icon: Tec9Icon
  },
  31: {
    name: 'Zeus x27',
    sname: 'taser',
    displayName: 'Zeus',
    killfeedOffset: 15,
    killfeedOffsetLeft: -10,
    url: taserSvg,
    icon: TaserIcon
  },
  32: {
    name: 'P2000',
    sname: 'hkp2000',
    displayName: 'P2000',
    killfeedOffset: 15,
    url: hkp2000Svg,
    icon: Hkp2000Icon
  },
  33: {
    name: 'MP7',
    sname: 'mp7',
    displayName: 'MP7',
    killfeedOffset: 13,
    killfeedOffsetLeft: -8,
    url: mp7Svg,
    icon: Mp7Icon
  },
  34: {
    name: 'MP9',
    sname: 'mp9',
    displayName: 'MP9',
    killfeedOffset: 5,
    url: mp9Svg,
    icon: Mp9Icon
  },
  35: {
    name: 'Nova',
    sname: 'nova',
    displayName: 'Nova',
    killfeedOffset: -0,
    killfeedOffsetLeft: -20,
    url: novaSvg,
    icon: NovaIcon
  },
  36: {
    name: 'P250',
    sname: 'p250',
    displayName: 'P250',
    killfeedOffset: 15,
    url: p250Svg,
    icon: P250Icon
  },
  38: {
    name: 'SCAR-20',
    sname: 'scar20',
    displayName: 'Auto',
    killfeedOffset: -2,
    killfeedOffsetLeft: -25,
    url: scar20Svg,
    icon: Scar20Icon
  },
  39: {
    name: 'SG 553',
    sname: 'sg556',
    displayName: 'Krieg',
    killfeedOffset: 0,
    killfeedOffsetLeft: -13,
    url: sg556Svg,
    icon: Sg556Icon
  },
  40: {
    name: 'SSG 08',
    sname: 'ssg08',
    displayName: 'Scout',
    killfeedOffset: -20,
    url: ssg08Svg,
    icon: Ssg08Icon
  },
  42: {
    name: 'Knife',
    sname: 'knife',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeSvg,
    icon: KnifeIcon
  },
  43: {
    name: 'Flashbang',
    sname: 'flashbang',
    displayName: 'Flash',
    killfeedOffset: 25,
    killfeedOffsetLeft: 5,
    url: flashbangSvg,
    icon: FlashbangIcon
  },
  44: {
    name: 'High Explosive Grenade',
    sname: 'hegrenade',
    displayName: 'HE',
    killfeedOffset: 25,
    killfeedOffsetLeft: 5,
    url: hegrenadeSvg,
    icon: HeGrenadeIcon
  },
  45: {
    name: 'Smoke Grenade',
    sname: 'smokegrenade',
    displayName: 'Smoke',
    killfeedOffset: 25,
    killfeedOffsetLeft: 5,
    url: smokegrenadeSvg,
    icon: SmokeGrenadeIcon
  },
  46: {
    name: 'Molotov',
    sname: 'molotov',
    displayName: 'Molotov',
    killfeedOffset: 27,
    killfeedOffsetLeft: -20,
    url: molotovSvg,
    icon: MolotovIcon
  },
  47: {
    name: 'Decoy Grenade',
    sname: 'decoy',
    displayName: 'Decoy',
    killfeedOffset: 25,
    killfeedOffsetLeft: 5,
    url: decoySvg,
    icon: DecoyIcon
  },
  48: {
    name: 'Incendiary Grenade',
    sname: 'incgrenade',
    displayName: 'Incend',
    killfeedOffset: 27,
    killfeedOffsetLeft: -20,
    url: incgrenadeSvg,
    icon: IncGrenadeIcon
  },
  49: {
    name: 'C4 Explosive',
    sname: 'c4',
    displayName: 'Bomb',
    killfeedOffset: 22,
    url: c4Svg,
    icon: C4Icon
  },
  59: {
    name: 'Knife',
    sname: 'knife_t',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeTSvg,
    icon: KnifeTIcon
  },
  60: {
    name: 'M4A1-S',
    sname: 'm4a1_silencer',
    displayName: 'M4A1-S',
    killfeedOffset: -10,
    killfeedOffsetLeft: -15,
    url: m4a1SilencerSvg,
    icon: M4a1SilencerIcon
  },
  61: {
    name: 'USP-S',
    sname: 'usp_silencer',
    displayName: 'USP',
    killfeedOffset: 2,
    killfeedOffsetLeft: -4,
    url: uspSilencerSvg,
    icon: UspSilencerIcon
  },
  63: {
    name: 'CZ75-Auto',
    sname: 'cz75a',
    displayName: 'CZ',
    killfeedOffset: 15,
    url: cz75aSvg,
    icon: Cz75aIcon
  },
  64: {
    name: 'R8 Revolver',
    sname: 'revolver',
    displayName: 'R8',
    killfeedOffset: 10,
    url: revolverSvg,
    icon: RevolverIcon
  },
  500: {
    name: 'Bayonet',
    sname: 'bayonet',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: bayonetSvg,
    icon: BayonetIcon
  },
  503: {
    name: 'Classic Knife',
    sname: 'knife_css',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeSvg, // TODO new icon
    icon: KnifeIcon // TODO new icon
  },
  505: {
    name: 'Flip Knife',
    sname: 'knife_flip',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeFlipSvg,
    icon: KnifeFlipIcon
  },
  506: {
    name: 'Gut Knife',
    sname: 'knife_gut',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeGutSvg,
    icon: KnifeGutIcon
  },
  507: {
    name: 'Karambit',
    sname: 'knife_karambit',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeKarambitSvg,
    icon: KnifeKarambitIcon
  },
  508: {
    name: 'M9 Bayonet',
    sname: 'knife_m9_bayonet',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeM9BayonetSvg,
    icon: KnifeM9BayonetIcon
  },
  509: {
    name: 'Huntsman Knife',
    sname: 'knife_tactical',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeTacticalSvg,
    icon: KnifeTacticalIcon
  },
  512: {
    name: 'Falchion Knife',
    sname: 'knife_falchion',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeFalchionSvg,
    icon: KnifeFalchionIcon
  },
  514: {
    name: 'Bowie Knife',
    sname: 'knife_survival_bowie',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeTSvg, // TODO new icon
    icon: KnifeIcon // TODO new icon
  },
  515: {
    name: 'Butterfly Knife',
    sname: 'knife_butterfly',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeButterflySvg,
    icon: KnifeButterflyIcon
  },
  516: {
    name: 'Shadow Dag',
    sname: 'knife_push',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifePushSvg,
    icon: KnifePushIcon
  },
  517: {
    name: 'Paracord Knife',
    sname: 'weapon_knife_cord',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeSvg, // TODO new icon
    icon: KnifeIcon // TODO new icon
  },
  519: {
    name: 'Ursus Knife',
    sname: 'knife_ursus',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeTSvg, // TODO new icon
    icon: KnifeTIcon // TODO new icon
  },
  520: {
    name: 'Navaja Knife',
    sname: 'knife_gypsy_jackknife',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeTSvg, // TODO new icon
    icon: KnifeTIcon // TODO new icon
  },
  521: {
    name: 'Nomad Knife',
    sname: 'weapon_knife_outdoor',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeTSvg, // TODO new icon
    icon: KnifeTIcon // TODO new icon
  },
  522: {
    name: 'Stiletto Knife',
    sname: 'knife_stiletto',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeTSvg, // TODO new icon
    icon: KnifeTIcon // TODO new icon
  },
  523: {
    name: 'Talon Knife',
    sname: 'knife_widowmaker',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeTSvg, // TODO new icon
    icon: KnifeTIcon // TODO new icon
  },
  524: {
    name: 'Survival Knife',
    sname: 'weapon_knife_canis',
    displayName: 'Knife',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: knifeSvg, // TODO new icon
    icon: KnifeIcon // TODO new icon
    // TODO check sname and number
  },
  1000: {
    // dummy value (unwieldable)
    name: 'Kevlar',
    sname: 'vest',
    displayName: 'Kevlar',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: vestSvg,
    icon: VestIcon
  },
  1001: {
    // dummy value (unwieldable)
    name: 'Kevlar and Helmet',
    sname: 'vesthelm',
    displayName: 'Kevlar and Helmet',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: vestHelmSvg,
    icon: VestHelmIcon
  },
  1002: {
    // dummy value (unwieldable)
    name: 'Defuse Kit',
    sname: 'defuser',
    displayName: 'Kit',
    killfeedOffset: 7,
    killfeedOffsetLeft: -12,
    url: defuserSvg,
    icon: DefuserIcon
  },
  1003: {
    name: 'Incendiary Grenade',
    sname: 'inferno',
    displayName: 'Incend',
    killfeedOffset: 27,
    killfeedOffsetLeft: -20,
    url: incgrenadeSvg,
    icon: IncGrenadeIcon
  }
}

export const findWeaponOrFail = (weapon) => {
  const target = Object.values(weapons).find((w) => w.sname === weapon)
  if (target) {
    return target
  }

  throw new Error(`Missing weapon: ${weapon}`)
}
