import { useTranslation } from 'react-i18next'

import { useProgressionAssignmentFeedback } from 'src/hooks/use-feedback'
import { shuffle } from 'src/lib/shuffle'
import { useAssignmentViewProps } from 'src/screens/match/assessments/assignment-list/assignment/Assignment'
import { AssignmentDesktop } from 'src/screens/match/assessments/assignment-list/assignment/AssignmentDesktop'
import { AssignmentMobile } from 'src/screens/match/assessments/assignment-list/assignment/AssignmentMobile'
import { ResourceList } from 'src/screens/match/assessments/assignment-list/assignment/expansion/resources/ResourceList'
import type { Progression } from 'src/screens/progression-report/ProgressionResources'
import { Expansion } from 'src/screens/match/assessments/assignment-list/assignment/expansion/Expansion'
import { isNewResource } from 'src/screens/match/assessments/assignment-list/assignment/expansion/resources/utils'
import { AssignmentOverviewButton } from 'src/screens/match/assessments/AssignmentOverviewButton'

export function ProgressionAssignment(props: {
  progression: Progression
  isSelected: boolean
  onClickChart: () => void
}) {
  const { t } = useTranslation()
  const assignmentFeedback = useProgressionAssignmentFeedback(props.progression)

  const resources = shuffle(props.progression.assignment.resources)
  const hasNewResource = resources.filter(isNewResource).length > 0

  const viewProps = useAssignmentViewProps({
    department: props.progression.department,
    title: props.progression.assignment.title.text,
    description: props.progression.assignment.body.text,
    score: props.progression.assignment.grade.score,
    breakdowns: props.progression.assignment.breakdowns,
    onAssignmentFeedback: assignmentFeedback,
    narrow: true,
    hideBody: true,
    expansion: (
      <>
        <Expansion
          one={{
            title: t('assessments:assignment:expansion:tab:resources'),
            body: <ResourceList resources={resources} />,
            bodyCount: resources.length,
            isNew: hasNewResource
          }}
          two={{
            title: (
              <AssignmentOverviewButton
                label={
                  props.progression.assignment.title.text
                    .toLowerCase()
                    .replaceAll(' ', '-') // TODO: update to use new label from schema
                }
              />
            )
          }}
        />
      </>
    )
  })

  return (
    <>
      <AssignmentDesktop {...viewProps} />
      <AssignmentMobile {...viewProps} />
    </>
  )
}
