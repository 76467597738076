import styled from '@emotion/styled'

import { Markdown } from 'src/screens/match/assessments/assignment-list/assignment/expansion/resources/Markdown'
import type { ResourceText as ResourceTextData } from 'src/graphql/types'

export function ResourceText(props: { resource: ResourceTextData }) {
  return (
    <Text>
      <Markdown>{props.resource.body.text}</Markdown>
    </Text>
  )
}

const Text = styled.div`
  max-width: 600px;
`
