import type { ReactElement } from 'react'
import styled from '@emotion/styled'

export function SocialMediaIcon(props: {
  name: string
  icon: ReactElement
  link: string
}) {
  return (
    <a href={props.link}>
      <StyledIcon>{props.icon}</StyledIcon>
    </a>
  )
}

const StyledIcon = styled.i`
  font-size: 24px;
  color: ${(props) => props.theme.d.colors.text.primary};
`
