import { useTheme } from '@mui/system'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import { Box, Icon, Message } from 'src/routes/HandleCheckout'
import { Button } from 'src/system/ui/button/Button'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'
import { useRouting } from 'src/routes'

export function CheckoutSuccess() {
  const theme = useTheme()
  const { t } = useTranslation()
  const { routes } = useRouting()

  return (
    <Box>
      <Icon icon={faCheckCircle} color={theme.d.colors.success} />
      <Message>{t('checkout:success')}</Message>
      <RelativeLink to={routes.root} disableStyles>
        <Button>{t('checkout:go_to_matches')}</Button>
      </RelativeLink>
    </Box>
  )
}
