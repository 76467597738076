import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { faBug, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container } from '@mui/material'

import { Button, ButtonVariant } from 'src/system/ui/button/Button'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'

export function AssignmentFeedbackButton(props: { onClick: () => void }) {
  const { t } = useTranslation()
  const [isSent, setIsSent] = useState(false)

  const onClick = () => {
    props.onClick()
    setIsSent(true)
  }

  return (
    <StyledButton
      disabled={isSent}
      variant={ButtonVariant.link}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={isSent ? faCheckCircle : faBug} />{' '}
      {isSent
        ? t('feedback:assignment:report_thanks')
        : t('feedback:assignment:report')}
    </StyledButton>
  )
}
export function GenericFeedbackButton() {
  const { t } = useTranslation()

  return (
    <FeedbackContainer>
      <AbsoluteLink to={t('feedback:report_link')} newTab>
        <FontAwesomeIconStyled icon={faBug} />
        {t('feedback:assignment:report')}
      </AbsoluteLink>
    </FeedbackContainer>
  )
}

const StyledButton = styled(Button)`
  font-size: 12px;
`

const FeedbackContainer = styled(Container)`
  font-weight: normal;
  font-size: 12px;
`

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  margin-right: 3px;
`
