import { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'

import type { Timeout } from 'src/types'

const defaultTypeIntervalMs = 20

export function TypedMessage(props: {
  typeIntervalMs?: number
  children: string
}) {
  const { children } = props
  const typeIntervalMs = props.typeIntervalMs ?? defaultTypeIntervalMs

  const timer = useRef<Timeout | null>(null)
  const [typed, setTyped] = useState('')

  /**
   * Clear the text, and start typing from the
   * beginning whenever the message changes.
   */
  useEffect(() => {
    setTyped('')
  }, [children])

  useEffect(() => {
    const typeCharacter = () => {
      const hasAllText = typed.length === children.length
      if (hasAllText) {
        if (timer.current !== null) {
          globalThis.clearTimeout(timer.current)
        }
        return
      }

      const nextChar = children.charAt(typed.length)

      const withNextChar = `${typed}${nextChar}`
      setTyped(withNextChar)
    }

    timer.current = globalThis.setTimeout(typeCharacter, typeIntervalMs)

    return () => {
      if (timer.current !== null) {
        globalThis.clearTimeout(timer.current)
      }
    }
  }, [children, typed, typeIntervalMs])

  return (
    <Box>
      <TypedText>{typed}</TypedText>
      <Shadow>{children}</Shadow>
    </Box>
  )
}

const Box = styled.div`
  position: relative;
`

const TypedText = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
`

/**
 * Add a shadow to prevent the description div height
 * from expanding as the text is 'typed'
 */
const Shadow = styled.p`
  margin: 0;
  opacity: 0;
`
