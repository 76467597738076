import { DateTime } from 'luxon'

type Page = {
  edges?: (Edge | null | undefined)[]
}

type Edge = {
  node?: object | null
}

export const pageToEdges = (page: Page | null | undefined) => {
  if (page == null) return []
  const { edges } = page
  if (edges == null) return []
  return edges
    .filter(isNotNull)
    .map((e) => e.node)
    .filter(isNotNull)
}

function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null
}

export const formatMatchDate = (date: string) =>
  DateTime.fromISO(date).toRelative()

export const range = (n: number, m: number) =>
  Array.from(Array(m - n), (x, i) => i + n)
