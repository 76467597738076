import type {
  NormalizedCacheObject,
  PossibleTypesMap,
  Resolvers,
  TypePolicies
} from '@apollo/client'
import {
  ApolloClient as Client,
  InMemoryCache,
  createHttpLink,
  from,
  split
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import { CachePersistor } from 'apollo-cache-persist'

import type { AuthClient } from 'src/lib/auth-client'

import { createWebsocketLink } from './appsync'

type Config = {
  authClient: AuthClient
  origin: string
  wsOrigin: string
  path: string
  apiKey: string
  appsyncHost: string
  possibleTypes: PossibleTypesMap
  typePolicies: TypePolicies
  resolvers: Resolvers
  schemaVersion: string
  schemaVersionKey: string
}

export type ApolloClient = Client<NormalizedCacheObject>

export const createApolloClient = ({
  authClient,
  origin,
  wsOrigin,
  path,
  apiKey,
  appsyncHost,
  possibleTypes,
  typePolicies,
  resolvers,
  schemaVersion,
  schemaVersionKey
}: Config) => {
  const uri = [origin, path].join('')
  const getAuthorization = () => authClient.getJwtToken().catch(() => null)

  const httpLink = createHttpLink({
    uri
  })

  const { wsLink, setWsAuthorization } = createWebsocketLink({
    origin: wsOrigin,
    path,
    host: appsyncHost,
    getAuthorization
  })

  const authLink = setContext(async (_, { headers }) => {
    const authorization = await getAuthorization()
    return {
      headers: {
        ...headers,
        ...(authorization ? { authorization } : { 'x-api-key': apiKey })
      }
    }
  })

  const errorLink = onError(({ networkError }) => {
    if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === 401
    ) {
      authClient.signOut().catch(() => {
        globalThis.location.reload()
      })
    }
  })

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    from([authLink, errorLink, httpLink])
  )

  const cache = new InMemoryCache({
    typePolicies,
    possibleTypes
  })

  const client = new Client<NormalizedCacheObject>({
    resolvers,
    link,
    cache
  })

  const storage = globalThis.localStorage as any

  const persistor = new CachePersistor({
    cache,
    storage
  })

  const init = async () => {
    const currentSchemaVersion =
      globalThis.localStorage.getItem(schemaVersionKey)
    if (currentSchemaVersion === schemaVersion) {
      await persistor.restore()
    } else {
      await persistor.purge()
      globalThis.localStorage.setItem(schemaVersionKey, schemaVersion)
    }

    const authorization = await getAuthorization()
    setWsAuthorization(authorization)
  }

  return {
    client,
    init
  }
}
