import styled from '@emotion/styled'
import { Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Meta } from 'src/system/Meta'
import { GuestAppBar } from 'src/system/app-bar/GuestAppBar'
import { Background } from 'src/system/ui/layout/Background'

export function SignUpSuccess() {
  const { t } = useTranslation()

  return (
    <>
      <Meta name='signup_success' />
      <GuestAppBar />
      <StyledBackground full>
        <StyledContainer>
          <EmailIcon icon={faEnvelope} />
          <Title>{t('sign_up:check_email')}</Title>
          <Details>{t('sign_up:check_spam_folder')}</Details>
        </StyledContainer>
      </StyledBackground>
    </>
  )
}

const StyledContainer = styled(Container)`
  flex: 1;
  padding: ${(props) => props.theme.d.spacing[4]};
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
`

const Title = styled.h2`
  text-align: center;
  font-family: ${(props) => props.theme.d.font.secondary};
  color: ${(props) => props.theme.d.colors.success};
  margin: 0 0 ${(props) => props.theme.d.spacing[2]} 0;
  font-size: 32px;
  max-width: 500px;
  line-height: 150%;
`

const EmailIcon = styled(FontAwesomeIcon)`
  width: 6em !important;
  height: 6em !important;
  color: ${(props) => props.theme.d.colors.success};
  margin: 0 0 ${(props) => props.theme.d.spacing[4]} 0;
`

const Details = styled.p`
  text-align: center;
  margin: 0 0 ${(props) => props.theme.d.spacing[2]} 0;
`

const StyledBackground = styled(Background)`
  flex: 1;
  display: flex;
  align-items: center;
  p {
    font-size: 16px;
  }
`
