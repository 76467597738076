import type { ReactElement } from 'react'
import { createContext, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'
import { coursesQuery } from 'src/graphql/courses'
import { useMatch } from 'src/hooks/use-match'
import { Department } from 'src/graphql/types'
import type { CoursesQuery, CoursesQueryVariables } from 'src/graphql/types'
import { CenterLoadingSpinner } from 'src/system/ui/LoadingSpinner'

export type Assessment = NonNullable<CoursesQuery['match']>
export type Player = Assessment['players'][0]
export type Course = NonNullable<Assessment['courses']>[0]
export type Assignment = NonNullable<Course['assignments']>[0]
export type Mark = Assignment['marks'][0]

const AssessmentContext = createContext<null | Assessment>(null)

export function AssessmentProvider(props: { children: ReactElement }) {
  const match = useMatch()
  const { t } = useTranslation()
  const [dexterEnabled] = useFeatureFlags([FeatureFlag.dexter])

  const { data, loading, error } = useQuery<
    CoursesQuery,
    CoursesQueryVariables
  >(coursesQuery, {
    fetchPolicy: fetchPolicy(match.courseProgress),
    errorPolicy: 'all',
    variables: {
      matchId: match.id
    }
  })

  if (error) {
    const k = match.isOwner ? 'load_error' : 'load_error_shared'
    return <p>{t(`assessments:${k}`)}</p>
  }

  if ((!data || !match.isOwner) && loading) {
    return <CenterLoadingSpinner />
  }

  if (!data?.match) {
    return null
  }

  const value = {
    ...data.match,
    courses: data?.match?.courses
      ?.filter(({ department }) => {
        if (dexterEnabled) return true
        return department !== Department.Dexter
      })
      .filter(({ department }) => {
        return department !== Department.WinProbability
      })
  }

  return (
    <AssessmentContext.Provider value={value}>
      {props.children}
    </AssessmentContext.Provider>
  )
}

const fetchPolicy = (courseProgress: number | null | undefined) =>
  courseProgress === 1 ? 'cache-first' : 'cache-and-network'

export function useAssessment() {
  const assessment = useContext(AssessmentContext)
  if (assessment === null) {
    throw new Error('useAssessment must be used within a AssessmentProvider')
  }

  return assessment
}
