import { createContext, useContext } from 'react'

import type { ChildrenProps } from 'src/types'

import type { Routing } from './config'
import { createRouting } from './config'

export function useRouting() {
  return useContext(routingContext)
}

type Props = {
  routing: Routing
} & ChildrenProps

export function RoutesProvider({ routing, children }: Props) {
  const { Provider } = routingContext
  return <Provider value={routing}>{children}</Provider>
}

const defaultRoutes = createRouting({
  apiVerifyCallbackPath: '',
  apiPasswordCallbackPath: ''
})

const routingContext = createContext(defaultRoutes)
