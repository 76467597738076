import styled from '@emotion/styled'
import { useTheme } from '@mui/system'
import { useTranslation } from 'react-i18next'
import {
  faGraduationCap,
  faUpload,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { PerkProtector } from 'src/system/perk/PerkProtector'
import { PerkType } from 'src/system/perk/perks'
import { MembershipLevel } from 'src/graphql/types'
import { useProfile } from 'src/hooks/use-profile'
import { useMaybeUser } from 'src/hooks/use-user'
import { LinkDiscord } from 'src/screens/account/discord/LinkDiscord'
import { LinkFaceit } from 'src/screens/account/faceit/LinkFaceit'
import { DiscordButton } from 'src/system/DiscordButton'
import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'
import { useIsGamersenseiAffiliate } from 'src/hooks/use-affiliate'
import { Button } from 'src/system/ui/button/Button'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'
import { useRouting } from 'src/routes'
import { useIsMembershipUpgradable } from 'src/hooks/use-membership'
import { CenterLoadingSpinner } from 'src/system/ui/LoadingSpinner'
import { ReactComponent as GamersenseiIcon } from 'src/assets/icons/gamersensei.svg'
import discordLogoBg from 'src/assets/icons/discord-logo-bg.svg'
import heartBg from 'src/assets/icons/heart-bg.svg'
import gamersenseiBg from 'src/assets/icons/gamersensei-bg.svg'
import faceitBg from 'src/assets/icons/faceit-bg.svg'
import uploadBg from 'src/assets/icons/upload-bg.svg'
import upgradeStarBg from 'src/assets/icons/upgrade-star-bg.svg'
import { ReactComponent as StarIcon } from 'src/assets/icons/upgrade-star.svg'

export function ActionButtons(props: { className?: string }) {
  return (
    <Box className={props.className}>
      <GamersenseiPanel />
      <MidPanel />
      <EndPanel />
    </Box>
  )
}

function MidPanel() {
  const { user } = useMaybeUser()
  const [discordFeatureEnabled] = useFeatureFlags([FeatureFlag.discord])

  const { profile, loading } = useProfile('cache-first')

  if (user == null) {
    return <SignUpPanel />
  }

  if (!discordFeatureEnabled) return <DiscordPanel />

  if (loading) {
    return <LoadingPanel />
  }

  const isDiscordLinked = profile?.discord?.name != null

  if (!isDiscordLinked) return <DiscordLinkPanel />
  return <UploadPanel />
}

function EndPanel() {
  const { user } = useMaybeUser()
  const { profile, loading } = useProfile('cache-first')

  if (user == null) {
    return null
  }

  if (loading) {
    return <LoadingPanel />
  }

  const isDiscordLinked = profile?.discord?.name != null
  const isFaceitLinked = profile?.faceit?.name != null
  const isMvp = user?.membership?.level === MembershipLevel.Mvp

  if (!isFaceitLinked) return <FaceitLinkPanel />
  if (!isDiscordLinked) return <UploadPanel />
  if (isMvp) return <MvpPanel />
  return <PremiumPanel />
}

function LoadingPanel() {
  return (
    <ActionPanel>
      <CenterLoadingSpinner />
    </ActionPanel>
  )
}

function FaceitLinkPanel() {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <ActionPanel>
      <Top>
        <Subheading color={theme.d.colors.faceit.primary.orange}>
          {t('faceit_link_panel:title')}
        </Subheading>
        <Title>{t('faceit_link_panel:action')}</Title>
      </Top>
      <Background icon={faceitBg} />
      <LinkFaceitButton />
    </ActionPanel>
  )
}

function DiscordLinkPanel() {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <ActionPanel>
      <Top>
        <Subheading color={theme.d.colors.discord.blurple}>
          {t('discord_link_panel:title')}
        </Subheading>
        <Title>{t('discord_link_panel:action')}</Title>
      </Top>
      <Background icon={discordLogoBg} />
      <LinkDiscordButton />
    </ActionPanel>
  )
}

function SignUpPanel() {
  const { t } = useTranslation()
  const theme = useTheme()
  const { routes } = useRouting()
  return (
    <ActionPanel>
      <Top>
        <Subheading color={theme.d.colors.accent}>
          {t('signup_panel:title')}
        </Subheading>
        <Title>{t('signup_panel:action')}</Title>
      </Top>
      <Background icon={upgradeStarBg} />
      <SignUpButtonStyled to={routes.signUp} disableStyles>
        <ActionButton fullWidth>
          <FontAwesomeIcon icon={faUser} size='lg' />
          {t('signup_panel:button')}
        </ActionButton>
      </SignUpButtonStyled>
    </ActionPanel>
  )
}

function DiscordPanel() {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <ActionPanel>
      <Top>
        <Subheading color={theme.d.colors.discord.blurple}>
          {t('discord_panel:problems:title')}
        </Subheading>
        <Title>{t('discord_panel:action')}</Title>
      </Top>
      <Background icon={discordLogoBg} />
      <DiscordButton />
    </ActionPanel>
  )
}

function GamersenseiPanel() {
  const { t } = useTranslation()
  const theme = useTheme()
  const isGamersenseiAffiliate = useIsGamersenseiAffiliate()
  const [featureEnabled] = useFeatureFlags([FeatureFlag.gamersenseiBox])
  if (!featureEnabled) return null
  const namespace = isGamersenseiAffiliate
    ? 'gamersensei_panel:affiliate'
    : 'gamersensei_panel:non_affiliate'
  return (
    <ActionPanel>
      <Top>
        <Subheading color={theme.d.colors.gamersensei.green}>
          {t(`${namespace}:subheading`)}
        </Subheading>
        <Title>{t(`${namespace}:action`)}</Title>
      </Top>
      <Background icon={gamersenseiBg} />
      <GamersenseiButton isGamersenseiAffiliate={isGamersenseiAffiliate} />
    </ActionPanel>
  )
}

function GamersenseiButton(props: { isGamersenseiAffiliate: boolean }) {
  const theme = useTheme()
  const { t } = useTranslation()
  const namespace = props.isGamersenseiAffiliate
    ? 'gamersensei_panel:affiliate'
    : 'gamersensei_panel:non_affiliate'

  const buttonIcon = props.isGamersenseiAffiliate ? (
    <GamersenseiIcon height='25px' />
  ) : (
    <FontAwesomeIcon icon={faGraduationCap} size='lg' />
  )

  return (
    <GamersenseiButtonStyled to={t('link:gamersensei_affiliate')} disableStyles>
      <ActionButton color={theme.d.colors.gamersensei.green} fullWidth>
        {buttonIcon}
        {t(`${namespace}:button`)}
      </ActionButton>
    </GamersenseiButtonStyled>
  )
}

function UploadPanel() {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <ActionPanel>
      <Top>
        <Subheading color={theme.d.colors.accent}>
          {t('upload_panel:title')}
        </Subheading>
        <Title>{t('upload_panel:action')}</Title>
      </Top>
      <Background icon={uploadBg} />
      <PerkProtector perk={PerkType.adhoc} renderGate={UploadButton}>
        <UploadButton />
      </PerkProtector>
    </ActionPanel>
  )
}

function UploadButton(props: { onClick?: () => void }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { routes } = useRouting()

  const button = (
    <ActionButton
      onClick={props.onClick}
      color={theme.d.colors.accent}
      fullWidth
    >
      <FontAwesomeIcon icon={faUpload} size='lg' />
      {t('upload_panel:button')}
    </ActionButton>
  )

  if (props.onClick == null) {
    return (
      <UploadButtonStyled to={routes.account} disableStyles>
        {button}
      </UploadButtonStyled>
    )
  }

  return button
}

function PremiumPanel() {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMembershipUpgradable = useIsMembershipUpgradable()
  const tPath = isMembershipUpgradable ? 'non_premium' : 'premium'
  return (
    <ActionPanel>
      <Top>
        <Subheading color={theme.d.colors.accent}>
          {t(`premium_panel:${tPath}:subheading`)}
        </Subheading>
        <Title>{t(`premium_panel:${tPath}:action`)}</Title>
      </Top>
      <Background icon={heartBg} />
      <PremiumButton isMember={!isMembershipUpgradable} />
    </ActionPanel>
  )
}

function PremiumButton(props: { isMember: boolean }) {
  const { routes } = useRouting()
  const theme = useTheme()
  const { t } = useTranslation()
  if (props.isMember) {
    return null
  }
  return (
    <PremiumButtonStyled to={routes.pricing} disableStyles>
      <ActionButton color={theme.d.colors.accent} fullWidth>
        <StarIcon />
        {t('premium_panel:non_premium:button')}
      </ActionButton>
    </PremiumButtonStyled>
  )
}

function MvpPanel() {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <ActionPanel>
      <Top>
        <Subheading color={theme.d.colors.lifetime}>
          {t('mvp_panel:title')}
        </Subheading>
        <Title>{t('mvp_panel:action')}</Title>
      </Top>
      <Background icon={heartBg} />
    </ActionPanel>
  )
}

const Box = styled.div`
  display: flex;
  flex-wrap: wrap;

  div:nth-of-type(2) {
    margin-right: 0;
  }

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    flex-wrap: nowrap;
    div:nth-of-type(2) {
      margin-right: ${(props) => props.theme.d.spacing[5]};
    }
  }

  div:last-of-type {
    margin-right: 0;
  }
`

export const actionPanelHeight = 190

const ActionPanel = styled.div`
  flex: 1;
  padding: ${(props) =>
    `${props.theme.d.spacing[1]} ${props.theme.d.spacing[1]} ${props.theme.d.spacing[2]}`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 140px;
  height: ${actionPanelHeight}px;
  position: relative;
  overflow: hidden;
  background: ${(props) => props.theme.d.colors.panel.light};
  border: 1px solid ${(props) => props.theme.d.colors.border.dark};
  border-radius: 4px;
  margin-right: ${(props) => props.theme.d.spacing[5]};
  margin-bottom: ${(props) => props.theme.d.spacing[5]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    height: auto;
    min-width: unset;
    margin-bottom: 0;
  }
`

const Top = styled.div`
  padding: ${(props) => props.theme.d.spacing[3]};
`

const Background = styled.div<{ icon: string }>`
  background: url(${(props) => props.icon});
  opacity: 0.05;
  background-size: 28px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 45%;
  left: 0;
`

const Subheading = styled.h6<{ color: string }>`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.color};
  text-transform: uppercase;
  line-height: 16px;
  margin: 0 0 ${(props) => props.theme.d.spacing[1]};
`
const Title = styled.h4`
  color: ${(props) => props.theme.d.colors.text.secondary};
  font-size: 18px;
  margin: 0;
`

const UploadButtonStyled = styled(RelativeLink)`
  z-index: 2;
`

const SignUpButtonStyled = styled(RelativeLink)`
  z-index: 2;
`

const GamersenseiButtonStyled = styled(AbsoluteLink)`
  z-index: 2;
`

const LinkDiscordButton = styled(LinkDiscord)`
  z-index: 2;
  padding: ${(props) =>
    `${props.theme.d.spacing[2]} ${props.theme.d.spacing[4]}`};
  height: 42px;
  width: 160px;
  margin: 0 auto;

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    padding: ${(props) =>
      `${props.theme.d.spacing[3]} ${props.theme.d.spacing[7]}`};
  }
`

const LinkFaceitButton = styled(LinkFaceit)`
  z-index: 2;
  padding: ${(props) =>
    `${props.theme.d.spacing[2]} ${props.theme.d.spacing[4]}`};
  height: 42px;
  width: 160px;
  margin: 0 auto;

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    padding: ${(props) =>
      `${props.theme.d.spacing[3]} ${props.theme.d.spacing[7]}`};
  }
`

const PremiumButtonStyled = styled(RelativeLink)`
  z-index: 2;
`

const ActionButton = styled(Button)`
  z-index: 2;
  padding: ${(props) =>
    `${props.theme.d.spacing[2]} ${props.theme.d.spacing[4]}`};
  height: 42px;
  width: 160px;
  margin: 0 auto;

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    padding: ${(props) =>
      `${props.theme.d.spacing[3]} ${props.theme.d.spacing[7]}`};
  }
`
