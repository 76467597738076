import styled from '@emotion/styled'

import type {
  Facet,
  FacetIntFieldsFragment,
  FacetIntListFieldsFragment
} from 'src/graphql/types'
import { FacetShape, FacetUnit } from 'src/graphql/types'
import { ReactComponent as Icon } from 'src/assets/icons/health.svg'

type FacetHealth = Facet & FacetIntFieldsFragment
export const isFacetHealth = (facet: Facet): facet is FacetHealth =>
  facet.shape === FacetShape.Int && facet.unit === FacetUnit.Health

export function FacetHealthValue(props: { facet: FacetHealth }) {
  return <Body>{props.facet.intValue}</Body>
}

type FacetHealthList = Facet & FacetIntListFieldsFragment
export const isFacetHealthList = (facet: Facet): facet is FacetHealthList =>
  facet.shape === FacetShape.IntList && facet.unit === FacetUnit.Health

export function FacetHealthListValue(props: { facet: FacetHealthList }) {
  return (
    <>
      {props.facet.intValues.map((val, index) => (
        <Body key={index}>{val}</Body>
      ))}
    </>
  )
}

function Body(props: { children: number }) {
  return (
    <Box>
      <Icon width={12} height={12} />
      {props.children}
    </Box>
  )
}

const Box = styled.div`
  svg {
    width: 12px;
    height: 12px;
    margin-right: 8px;
    fill: ${(props) => props.theme.d.colors.text.secondary};
  }

  display: flex;
  align-items: center;
`
