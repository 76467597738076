import { DateTime, Duration } from 'luxon'
import { useApolloClient, useQuery, useSubscription } from '@apollo/client'

import {
  matchQuery,
  matchesQuery,
  pendingMatchQuery,
  pendingMatchSubscription
} from 'src/graphql'
import type {
  MatchFieldsFragment as Match,
  MatchQuery,
  MatchQueryVariables,
  PendingMatchFieldsFragment as PendingMatch,
  PendingMatchQuery,
  PendingMatchQueryVariables,
  PendingMatchUpdatedSubscription,
  PendingMatchUpdatedSubscriptionVariables
} from 'src/graphql/types'
import { PendingMatchStatus } from 'src/graphql/types'
import { isMatch } from 'src/lib/matches/matches'
import { MatchCard } from 'src/screens/matches/collection/MatchCard'

const pollInterval = Duration.fromObject({ seconds: 30 }).as('milliseconds')

export function MatchLifecycle(props: { source: Match | PendingMatch }) {
  const { source } = props
  if (isMatch(source) && source.courseProgress === 1) {
    return <MatchCard match={source} />
  }

  return <InProgressMatch source={source} />
}

function InProgressMatch(props: { source: PendingMatch | Match }) {
  const id = props.source.id

  const apolloClient = useApolloClient()

  const refetchMatches = () =>
    apolloClient.refetchQueries({
      include: [matchesQuery]
    })

  const { data: update } = useSubscription<
    PendingMatchUpdatedSubscription,
    PendingMatchUpdatedSubscriptionVariables
  >(pendingMatchSubscription, {
    variables: { id },
    onSubscriptionData: refetchMatches
  })

  const { data } = useQuery<PendingMatchQuery, PendingMatchQueryVariables>(
    pendingMatchQuery,
    {
      pollInterval,
      variables: { id },
      onCompleted: (data) => {
        const date = data?.pendingMatch?.updatedAt
        if (date && isRecent(date)) {
          refetchMatches()
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  )

  const latest = getLatestPendingMatchUpdate([
    data?.pendingMatch,
    update?.pendingMatch
  ])

  if (
    isMatch(props.source) ||
    props.source?.status === PendingMatchStatus.Completed ||
    latest?.status === PendingMatchStatus.Completed
  ) {
    return <PartialMatch source={props.source} latest={latest} />
  }

  return <MatchCard match={latest || props.source} />
}

function PartialMatch(props: {
  source: Match | PendingMatch
  latest: Match | PendingMatch
}) {
  const id = props.source.id

  const { data } = useQuery<MatchQuery, MatchQueryVariables>(matchQuery, {
    pollInterval,
    variables: { id },
    fetchPolicy: 'cache-and-network'
  })

  const match = data?.match ?? props.source
  return <MatchCard match={mergeUpdate(match, props.latest)} />
}

const mergeUpdate = (
  match: Match | PendingMatch,
  update: Match | PendingMatch | null | undefined
) => {
  if (!update) return match
  if (isNewer(match, update)) return match
  return {
    ...match,
    courseProgress: update.courseProgress
  }
}

const getLatestPendingMatchUpdate = (
  updates: (PendingMatch | null | undefined)[]
) => {
  const noNulls = updates.filter((m) => !!m) as PendingMatch[]
  const ordered = noNulls.sort(({ updatedAt }) => updatedAt).reverse()
  return ordered[0]
}

const isNewer = (a: { updatedAt: string }, b: { updatedAt: string }) =>
  DateTime.fromISO(a.updatedAt) >= DateTime.fromISO(b.updatedAt)

const isRecent = (date: string) =>
  Math.abs(DateTime.fromISO(date).diffNow('day').days) < 1
