import styled from '@emotion/styled'

import coachLeft from 'src/assets/coach-left.svg'
import coachRight from 'src/assets/coach-right.svg'

export function FullLoading(props: { hidden?: boolean }) {
  if (props.hidden) {
    return null
  }

  return (
    <Box>
      <Image src={coachRight} isTop />
      <Image src={coachLeft} />
    </Box>
  )
}

const Box = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  background: #1f222b;
`

const Image = styled.img<{ isTop?: boolean }>`
  position: absolute;
  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  z-index: ${(props) => (props.isTop ? 1 : 0)};
  animation: flickerAnimation ${(props) => (props.isTop ? 1 : 0)}s infinite;
`
