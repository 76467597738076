import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import type {
  MembershipLevel,
  MembershipPlan,
  MembershipProvidence
} from 'src/graphql/types'
import { Meta } from 'src/system/Meta'
import { useSearchParams } from 'src/lib/hooks/use-search-params'
import { AppBar } from 'src/system/app-bar/AppBar'
import { StarBackgroundPage } from 'src/system/ui/layout/StarBackgroundPage'
import { VerifyToggle } from 'src/screens/checkout/toggle/VerifyToggle'
import { VerifyNewMember } from 'src/screens/checkout/new-member/VerifyNewMember'
import { RedirectToCheckout } from 'src/screens/checkout/new-member/RedirectToCheckout'

type SearchParams = {
  success?: 'true' | 'false' | undefined
  canceled?: 'true' | 'false' | undefined
  plan?: MembershipPlan | undefined
  providence?: MembershipProvidence | undefined
  discount?: MembershipLevel | undefined
}

export function HandleCheckout() {
  return (
    <>
      <Meta name='handle_checkout' />
      <AppBar />
      <StarBackgroundPage>
        <Content />
      </StarBackgroundPage>
    </>
  )
}

function Content() {
  const searchParams = useSearchParams<SearchParams>()
  const { success, canceled, providence, plan, discount } = searchParams
  const toggledMembership = canceled != null
  const checkedOut = success != null
  const wasCanceled = canceled === 'true'
  const isSuccess = success === 'true'

  if (toggledMembership) {
    return <VerifyToggle canceled={wasCanceled} />
  }

  if (checkedOut) {
    return (
      <VerifyNewMember
        plan={plan}
        providence={providence}
        isSuccess={isSuccess}
      />
    )
  }

  return (
    <RedirectToCheckout
      discount={discount ?? undefined}
      providence={providence ?? undefined}
      plan={plan ?? undefined}
    />
  )
}

export const Icon = styled(FontAwesomeIcon)<{ color: string }>`
  font-size: 4em;
  color: ${(props) => props.color};
`

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.d.spacing[6]};
  flex-direction: column;
`

export const Message = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 150%;
  margin-top: ${(props) => props.theme.d.spacing[2]};
  margin-bottom: ${(props) => props.theme.d.spacing[8]};
`
