import { useQuery } from '@apollo/client'

import { useMatch } from 'src/hooks/use-match'
import type { RoundQuery, RoundQueryVariables } from 'src/graphql/types'
import { roundQuery } from 'src/graphql'

export function useMatchOwnerUpgradeStatus() {
  const match = useMatch()
  const isOwner = match?.isOwner
  const { data, loading } = useQuery<RoundQuery, RoundQueryVariables>(
    roundQuery,
    {
      fetchPolicy: 'cache-first',
      skip: isOwner,
      variables: { matchId: match.id, roundNumber: 1 }
    }
  )

  return {
    loading,
    isOwner,
    isOwnerUpgraded: data?.match?.round?.tickUrl != null
  }
}
