import { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { Duration } from 'luxon'
import { useTranslation } from 'react-i18next'

import type { Timeout } from 'src/types'
import { Meta } from 'src/system/Meta'
import { TextField } from 'src/system/ui/form/TextField'
import { useAuth } from 'src/hooks/use-auth'
import { useRouting } from 'src/routes'
import { useSearchParams } from 'src/lib/hooks/use-search-params'
import {
  isUserAlreadyAuthenticatedError,
  isUserNotFoundError
} from 'src/lib/auth-client'
import { AuthForm } from 'src/screens/auth/AuthForm'
import { LinkColor } from 'src/system/ui/link/types'
import { RelativeLink } from 'src/system/ui/link/RelativeLink'

const loginRedirectTimeout = Duration.fromObject({ seconds: 20 }).as(
  'milliseconds'
)

type SearchParams = {
  verified?: string
}

type FormData = {
  email: string
  password: string
}

export function Login() {
  return (
    <>
      <Meta name='login' />
      <LoginForm />
    </>
  )
}

function LoginForm() {
  const { routes } = useRouting()
  const { verified } = useSearchParams<SearchParams>()
  const [formError, setFormError] = useState('')
  const [processing, setProcessing] = useState(false)
  const timeoutRef = useRef<Timeout | null>(null)
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<FormData>({ defaultValues: { email: '', password: '' } })
  const { signIn } = useAuth()

  const submit = async () => {
    if (processing) {
      return
    }
    setProcessing(true)
    if (timeoutRef.current !== null) {
      globalThis.clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = globalThis.setTimeout(() => {
      setProcessing(false)
      setFormError(t('login:timeout_error'))
    }, loginRedirectTimeout)

    try {
      const { email, password } = getValues()
      await signIn(email, password)
    } catch (err: any) {
      if (timeoutRef.current !== null) {
        globalThis.clearTimeout(timeoutRef.current)
      }
      setProcessing(false)
      if (isUserNotFoundError(err)) {
        setFormError(t('login:user_not_found_error'))
        return
      }
      if (isUserAlreadyAuthenticatedError(err)) {
        globalThis.location.reload()
        await new Promise((resolve) =>
          globalThis.setTimeout(() => resolve(null), 2000)
        )
      }
      setFormError(err?.message)
    }
  }

  return (
    <AuthForm
      headline={t('login:headline')}
      subhead={t('login:subhead')}
      error={formError}
      footer={<CreateAccount />}
      isVerified={verified ? verified === 'true' : null}
      render={({ SubmitButton, FieldBox }) => (
        <form onSubmit={handleSubmit(submit)}>
          <FieldBox>
            <TextField
              label={t('login:email_label')}
              placeholder={t('login:email_placeholder')}
              fullWidth
              type='email'
              error={errors?.email?.message}
              {...register('email', {
                required: `${t('login:email_required')}`
              })}
            />
          </FieldBox>
          <FieldBox disableGutter>
            <TextField
              fullWidth
              label={t('login:password')}
              placeholder={t('login:password_placeholder')}
              type='password'
              error={errors?.password?.message}
              {...register('password', {
                required: `${t('login:password_required')}`
              })}
            />
          </FieldBox>
          <ForgotPasswordLink
            to={routes.forgotPassword}
            color={LinkColor.green}
          >
            {t('login:forgot_password')}
          </ForgotPasswordLink>
          <SubmitButton fullWidth type='submit' processing={processing}>
            {t('nav:login')}
          </SubmitButton>
        </form>
      )}
    />
  )
}

function CreateAccount() {
  const { t } = useTranslation()
  const { routes } = useRouting()

  return (
    <CreateAccountText>
      {t('login:new_around')}{' '}
      <RelativeLink to={routes.signUp} color={LinkColor.green}>
        {t('login:create_account')}
      </RelativeLink>
    </CreateAccountText>
  )
}

const CreateAccountText = styled.p`
  font-size: 16px;
  margin-bottom: 0;
`

const ForgotPasswordLink = styled(RelativeLink)`
  display: block;
  font-size: 14px;
  margin-top: ${(props) => props.theme.d.spacing[4]};
`
