import { createContext, useContext } from 'react'

import type { ChildrenProps } from 'src/types'
import type { Logger } from 'src/lib/analytics/logger'
import { createLogger } from 'src/lib/analytics/logger'
import type { Sentry } from 'src/lib/analytics/sentry'
import { createSentry } from 'src/lib/analytics/sentry'

export type Log = Logger

export function useLog() {
  return useContext(logContext)
}

type Props = {
  log: Logger
  sentry: Sentry
} & ChildrenProps

export function LogProvider({ log, sentry, children }: Props) {
  const { Provider } = logContext
  return <Provider value={{ log, sentry }}>{children}</Provider>
}

const { log: defaultLogger } = createLogger({ name: 'default' })

const { client: defaultSentry } = createSentry({
  dsn: null,
  tunnel: null,
  name: '',
  environment: '',
  version: ''
})

const logContext = createContext({
  log: defaultLogger,
  sentry: defaultSentry
})
