import styled from '@emotion/styled'

import type { Facet, FacetSentimentFieldsFragment } from 'src/graphql/types'
import { FacetShape, FacetUnit } from 'src/graphql/types'
import { SentimentIcon } from 'src/screens/match/assessments/assignment-list/assignment/expansion/breakdowns/SentimentIcon'

type FacetSentiment = Facet & FacetSentimentFieldsFragment
export const isFacetSentiment = (facet: Facet): facet is FacetSentiment =>
  facet.shape === FacetShape.Enum && facet.unit === FacetUnit.Sentiment

export function FacetSentimentValue(props: { facet: FacetSentiment }) {
  return <StyledSentimentIcon value={props.facet.sentimentValue} />
}

const StyledSentimentIcon = styled(SentimentIcon)`
  width: 18px;
`
