import { useQuery } from '@apollo/client'

import type { MatchesUiQuery, MatchesUiQueryVariables } from 'src/graphql/types'
import { matchesUiQuery } from 'src/graphql'

export function useMatchesUi() {
  const { data, error } = useQuery<MatchesUiQuery, MatchesUiQueryVariables>(
    matchesUiQuery,
    {
      fetchPolicy: 'cache-only'
    }
  )

  if (error) {
    throw error
  }

  if (!data?.ui) throw new Error('No UI state')

  return data.ui
}
