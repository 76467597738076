import styled from '@emotion/styled'

export function PageSubhead(props: { children: string; className?: string }) {
  return <Text className={props.className}>{props.children}</Text>
}

const Text = styled.h4`
  font-family: ${(props) => props.theme.d.font.primary};
  color: ${(props) => props.theme.d.colors.text.primary};
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  margin-top: 0;
  margin-bottom: ${(props) => props.theme.d.spacing[4]};
`
