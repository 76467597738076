import { useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Grid } from '@mui/material'

import { useAuth } from 'src/hooks/use-auth'
import { TextField } from 'src/system/ui/form/TextField'
import { Button, ButtonVariant } from 'src/system/ui/button/Button'

type FormData = {
  password: string
  newPassword: string
  confirmNewPassword: string
}

export function ChangePasswordForm(props: {
  cancelChangePassword: () => void
}) {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors, isSubmitting }
  } = useForm<FormData>({
    defaultValues: {
      password: '',
      newPassword: '',
      confirmNewPassword: ''
    }
  })

  const [error, setError] = useState('')

  const { changePassword } = useAuth()

  const submit = async () => {
    if (isSubmitting) {
      return
    }

    setError('')
    try {
      const { password, newPassword } = getValues()
      await changePassword(password, newPassword)
      props.cancelChangePassword()
    } catch (err: any) {
      setError(err?.message)
    }
  }

  const handleClickedBack = props.cancelChangePassword

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <StyledTextField
          fullWidth
          label={t('password_fields:password_label')}
          placeholder={t('password_fields:password_placeholder')}
          type='password'
          error={errors?.password?.message}
          {...register('password', {
            required: `${t('password_fields:password_error')}`
          })}
        />
        <StyledTextField
          fullWidth
          label={t('change_password:new_password_label')}
          placeholder={t('change_password:new_password_placeholder')}
          type='password'
          error={errors?.newPassword?.message}
          {...register('newPassword', {
            required: `${t('change_password:new_password_error')}`
          })}
        />
        <StyledTextField
          fullWidth
          label={t('change_password:confirm_new_password_label')}
          placeholder={t('change_password:confirm_new_password_placeholder')}
          type='password'
          error={errors.confirmNewPassword?.message}
          {...register('confirmNewPassword', {
            required: `${t(
              'change_password:confirm_new_password_error:required'
            )}`,
            validate: (value: string) =>
              value === watch('newPassword') ||
              (t(
                'change_password:confirm_new_password_error:mismatch'
              ) as string)
          })}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <StyledButton
              type='button'
              variant={ButtonVariant.secondary}
              fullWidth
              onClick={handleClickedBack}
            >
              {t('change_password:cancel')}
            </StyledButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledButton fullWidth type='submit' processing={isSubmitting}>
              {t('change_password:submit')}
            </StyledButton>
          </Grid>
        </Grid>
      </form>
      <FormError>{error}</FormError>
    </>
  )
}

function FormError(props: { children: string }) {
  if (!props.children) {
    return null
  }

  return <ErrorText>{props.children}</ErrorText>
}

const StyledTextField = styled(TextField)`
  margin-bottom: ${(props) => props.theme.d.spacing[5]};
`

const StyledButton = styled(Button)`
  height: 52px;
`

const ErrorText = styled.div`
  color: ${(props) => props.theme.d.colors.error};
  margin-top: ${(props) => props.theme.d.spacing[4]};
  text-align: center;
`
