import type { Resource as ResourceData } from 'src/graphql/types'

import {
  isLinkListResource,
  isLinkResource,
  isMatchmakingSteamCsgoResource,
  isNewResource,
  isTextResource,
  isWorkshopSteamCsgoResource,
  isYoutubeListResource,
  isYoutubeResource
} from './utils'
import { ResourceText } from './ResourceText'
import { ResourceLink } from './ResourceLink'
import { ResourceLinkList } from './ResourceLinkList'
import { ResourceYoutube } from './ResourceYoutube'
import { ResourceYoutubeList } from './ResourceYoutubeList'
import { ResourceMatchmakingSteamCsgo } from './ResourceMatchmakingSteamCsgo'
import { ResourceWorkshopSteamCsgo } from './ResourceWorkshopSteamCsgo'
import { Resource } from './Resource'

export function ResourceList(props: { resources: ResourceData[] }) {
  return (
    <div>
      {props.resources.map((resource, index) => (
        <Resource
          key={resource.id}
          title={resource.title.text}
          isNew={isNewResource(resource)}
          isExclusive={resource.isExclusive}
          isPremium={resource.isPremium}
          isFirst={index === 0}
        >
          <Content resource={resource} />
        </Resource>
      ))}
    </div>
  )
}

function Content(props: { resource: ResourceData }) {
  if (isTextResource(props.resource)) {
    return <ResourceText resource={props.resource} />
  }

  if (isYoutubeResource(props.resource)) {
    return <ResourceYoutube resource={props.resource} />
  }

  if (isYoutubeListResource(props.resource)) {
    return <ResourceYoutubeList resource={props.resource} />
  }

  if (isLinkResource(props.resource)) {
    return <ResourceLink resource={props.resource} />
  }

  if (isLinkListResource(props.resource)) {
    return <ResourceLinkList resource={props.resource} />
  }

  if (isMatchmakingSteamCsgoResource(props.resource)) {
    return <ResourceMatchmakingSteamCsgo resource={props.resource} />
  }

  if (isWorkshopSteamCsgoResource(props.resource)) {
    return <ResourceWorkshopSteamCsgo resource={props.resource} />
  }

  throw new Error(`Unimplemented resource: ${props.resource.format}`)
}
