import { createContext, useContext, useEffect } from 'react'
import { DateTime } from 'luxon'
import { useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { useAffiliateCode } from 'src/hooks/use-affiliate'
import { useReferrerCode } from 'src/hooks/use-referrer'
import { createLogger } from 'src/lib/analytics/logger'
import type { ChildrenProps, StringMap } from 'src/types'
import type { TrackingConsent } from 'src/lib/analytics/tracking-consent'
import { trackingConsentStub } from 'src/lib/analytics/tracking-consent'
import type { Posthog } from 'src/lib/analytics/posthog'
import { posthogStub } from 'src/lib/analytics/posthog'
import type { Rank, UserFieldsFragment as User } from 'src/graphql/types'

export enum AnalyticsEvent {
  accountCreated = 'account created',
  accountVerified = 'account verified',
  accountNotVerified = 'account not verified',
  accountLinkStarted = 'account link started',
  accountLinkNotStarted = 'account not started',
  accountLinked = 'account linked',
  accountNotLinked = 'account not linked',
  accountUnlinked = 'account unlinked',
  passwordChanged = 'password changed',
  passwordForgot = 'password forgot',
  passwordReset = 'password reset',
  sessionSignin = 'session login',
  sessionSignout = 'session logout',
  automatchSharelinkAdded = 'automatch sharelink added',
  automatchSharelinkNotAdded = 'automatch sharelink not added',
  automatchSteamKeyLinked = 'automatch steam key linked',
  automatchSteamKeyNotLinked = 'automatch steam key not linked',
  automatchDisabled = 'automatch disabled',
  automatchTriggered = 'automatch triggered',
  automatchNotTriggered = 'automatch not triggered',
  automatchSkippedTrigger = 'automatch skipped trigger',
  customerPortalStarted = 'customer portal started',
  customerPortalNotStarted = 'customer portal not started',
  checkoutStarted = 'checkout started',
  checkoutNotStarted = 'checkout not started',
  checkoutCompleted = 'checkout completed',
  checkoutFailed = 'checkout failed',
  membershipFailed = 'membership failed',
  membershipCanceled = 'membership canceled',
  membershipUncanceled = 'membership uncanceled',
  demoDownloaded = 'demo downloaded',
  perkProtected = 'perk protected',
  videoPlay = 'video play',
  videoPause = 'video pause',
  videoEnd = 'video end'
}

type Props = {
  trackingConsent: TrackingConsent
  identifyUser: (userId: string | null, username: string | null) => void
  posthog: Posthog
} & ChildrenProps

export function AnalyticsProvider({
  trackingConsent,
  identifyUser,
  posthog,
  children
}: Props) {
  const { Provider } = analyticsContext
  return (
    <Provider value={{ trackingConsent, identifyUser, posthog }}>
      {children}
    </Provider>
  )
}

function useAnalytics() {
  return useContext(analyticsContext)
}

export function useTrackingConsentAnalytics() {
  const { trackingConsent } = useContext(analyticsContext)
  const { optOutCookieName, setOptOut } = trackingConsent
  const [cookies] = useCookies([optOutCookieName])
  const isNotOptedInOrOut = cookies[optOutCookieName] == null
  return {
    reset: () => trackingConsent.reset(),
    isNotOptedInOrOut,
    setOptOut
  }
}

export function useIdentifyUser() {
  const { identifyUser } = useContext(analyticsContext)
  return identifyUser
}

export function useUserAnalytics() {
  const { posthog } = useAnalytics()
  const affiliate = useAffiliateCode()
  const referrer = useReferrerCode()
  return (user: User | null) => {
    if (!user) return
    const account = fromUserToAccountProp(user)
    const properties = {
      account,
      affiliate,
      referrer
    }
    posthog.people.set(properties)
  }
}

type Player = {
  isOwner: boolean
  rank?: Rank | null
}

export function useRankAnalytics() {
  const { posthog } = useAnalytics()
  return (players: Player[] | null, date: string) => {
    if (!players) return

    const isRecent = DateTime.fromISO(date).diffNow(['weeks']).weeks > -1
    if (!isRecent) return

    const owner = players.find((p) => p.isOwner)
    if (!owner) return

    const { rank } = owner
    if (!rank) return

    const properties = { csgo_rank: rank }
    posthog.people.set(properties)
  }
}

const fromUserToAccountProp = (user: User) => {
  if (user?.automatch?.isActive) return 'active'
  if (user.isAccountLinked) return 'linked'
  return 'unlinked'
}

export function useEventAnalytics(eventName: AnalyticsEvent) {
  const { posthog } = useAnalytics()
  return (properties: StringMap = {}) => {
    posthog.capture(eventName, properties)
  }
}

export function usePageviewAnalytics() {
  const { posthog } = useAnalytics()
  const loc = useLocation()

  useEffect(() => {
    posthog.capture('$pageview')
  }, [loc, posthog])
}

const { log: defaultLogger } = createLogger({ name: 'default' })

export const analyticsContext = createContext({
  trackingConsent: trackingConsentStub(),
  identifyUser: (_userId: string | null, _username: string | null) => {},
  posthog: posthogStub(defaultLogger, {})
})
