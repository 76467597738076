import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { useIsGamersenseiAffiliate } from 'src/hooks/use-affiliate'
import { Button } from 'src/system/ui/button/Button'
import { TextField } from 'src/system/ui/form/TextField'
import {
  addSteamIdKeyMutation,
  matchesStatusQuery,
  triggerAutomatchMutation,
  userQuery
} from 'src/graphql'
import type {
  AddSteamIdKeyMutation,
  AddSteamIdKeyMutationVariables,
  TriggerAutomatchMutation,
  TriggerAutomatchMutationVariables,
  UserFieldsFragment as User
} from 'src/graphql/types'
import { AnalyticsEvent, useEventAnalytics } from 'src/hooks/use-analytics'
import { useRouting } from 'src/routes'
import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'

type FormData = {
  authCode: string
}

export function AuthCodeInput(props: { user: User }) {
  const { t } = useTranslation()
  const [welcomeVideoEnabled] = useFeatureFlags([FeatureFlag.welcomeVideo])
  const {
    routes: { root, welcome, privateProfile }
  } = useRouting()
  const navigate = useNavigate()
  const isGamersenseiAffiliate = useIsGamersenseiAffiliate()
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<FormData>({ defaultValues: { authCode: '' } })
  const [submitError, setSubmitError] = useState<string | null>(null)

  const automatchSteamKeyLinkedEvent = useEventAnalytics(
    AnalyticsEvent.automatchSteamKeyLinked
  )
  const automatchSteamKeyNotLinkedEvent = useEventAnalytics(
    AnalyticsEvent.automatchSteamKeyNotLinked
  )
  const automatchTriggeredEvent = useEventAnalytics(
    AnalyticsEvent.automatchTriggered
  )
  const automatchNotTriggeredEvent = useEventAnalytics(
    AnalyticsEvent.automatchNotTriggered
  )

  const [addSteamIdKey, { loading }] = useMutation<
    AddSteamIdKeyMutation,
    AddSteamIdKeyMutationVariables
  >(addSteamIdKeyMutation, {
    refetchQueries: [{ query: userQuery }, { query: matchesStatusQuery }],
    awaitRefetchQueries: true
  })

  const [triggerAutomatch] = useMutation<
    TriggerAutomatchMutation,
    TriggerAutomatchMutationVariables
  >(triggerAutomatchMutation, {
    refetchQueries: [{ query: userQuery }, { query: matchesStatusQuery }],
    awaitRefetchQueries: true
  })

  const { steamId } = props.user

  if (!steamId) {
    return null
  }

  const linkId = steamId

  const getSuccessRoute = () => {
    if (isGamersenseiAffiliate) return privateProfile
    if (welcomeVideoEnabled) return welcome
    return root
  }

  const submit = async () => {
    const { authCode } = getValues()
    setSubmitError(null)

    try {
      await addSteamIdKey({
        variables: {
          linkId,
          key: authCode.trim()
        }
      })
      automatchSteamKeyLinkedEvent()

      try {
        await triggerAutomatch({ variables: { linkId } })
        automatchTriggeredEvent({ automatch_trigger_reason: 'setup' })
      } catch (err: any) {
        automatchNotTriggeredEvent({
          error: err?.message,
          automatch_trigger_reason: 'setup'
        })
      }

      navigate(getSuccessRoute())
    } catch (err: any) {
      const message = err?.graphQLErrors?.[0].message ?? err?.message ?? err
      setSubmitError(message)
      automatchSteamKeyNotLinkedEvent({ error: message })
    }
  }

  return (
    <>
      <SubmitError error={submitError} />
      <Form onSubmit={handleSubmit(submit)}>
        <StyledInput
          fullWidth
          placeholder={t('account:match_token:paste_auth_code_text')}
          hideDetails
          disabled={loading}
          {...register('authCode', {
            required: `${t('account:match_token:auth_code_required_error')}`
          })}
        />
        <StyleButton processing={loading}>
          {t('account:match_token:add_auth_code_button')}
        </StyleButton>
      </Form>
      <ValidationError>{errors?.authCode?.message ?? ''}</ValidationError>
    </>
  )
}

function ValidationError(props: { children: string }) {
  if (!props.children) {
    return null
  }

  return <CodeErrorText>{props.children}</CodeErrorText>
}

function SubmitError(props: { error: string | null }) {
  if (!props.error) {
    return null
  }

  return <SubmitErrorText>{props.error}</SubmitErrorText>
}

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.d.breakpoints.sm}) {
    flex-direction: row;
  }
`

const StyledInput = styled(TextField)`
  flex: 1;
  margin-right: ${(props) => props.theme.d.spacing[6]};
`

const StyleButton = styled(Button)`
  padding: ${(props) =>
    `${props.theme.d.spacing[3]} ${props.theme.d.spacing[12]}`};
`

const CodeErrorText = styled.span`
  color: ${(props) => props.theme.d.colors.error};
`

const SubmitErrorText = styled.span`
  color: ${(props) => props.theme.d.colors.error};
  text-align: center;
  display: block;
  font-size: 16px;
  margin-bottom: ${(props) => props.theme.d.spacing[4]};
`
