import styled from '@emotion/styled'

export function PageTitle(props: { children: string; className?: string }) {
  return <Text className={props.className}>{props.children}</Text>
}

const Text = styled.h1`
  font-family: ${(props) => props.theme.d.font.secondary};
  display: inline-block;
  color: ${(props) => props.theme.d.colors.text.secondary};
  font-weight: normal;
  font-size: 36px;
  margin-top: 0;
  margin-bottom: ${(props) => props.theme.d.spacing[3]};

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    font-size: 52px;
    margin-bottom: ${(props) => props.theme.d.spacing[4]};
  }
`
