import styled from '@emotion/styled'

import type {
  MatchFieldsFragment as MatchData,
  PendingMatchFieldsFragment as PendingMatchData
} from 'src/graphql/types'
import { MatchLifecycle } from 'src/screens/matches/collection/MatchLifecycle'

export function MatchesList(props: {
  matches: (PendingMatchData | MatchData)[]
}) {
  return (
    <Box>
      {props.matches.map((match) => (
        <MatchLifecycle key={match.id} source={match} />
      ))}
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
`
