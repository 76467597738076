import { useTranslation } from 'react-i18next'

import { Button, ButtonVariant } from 'src/system/ui/button/Button'
import { NextBillingText } from 'src/screens/membership/manage/ManageMembership'
import { useToggleMembership } from 'src/hooks/use-toggle-membership'
import { useMembershipEndDate } from 'src/hooks/use-membership'

export function UncancelMembership() {
  const { t } = useTranslation()

  const endDate = useMembershipEndDate()
  const text =
    endDate == null
      ? t('membership:lifetime')
      : t('membership:end_date', { date: endDate })

  const { toggle, loading } = useToggleMembership()
  return (
    <>
      <NextBillingText>{text}</NextBillingText>
      <Button
        variant={ButtonVariant.link}
        processing={loading}
        onClick={toggle}
      >
        {t('membership:uncancel')}
      </Button>
    </>
  )
}
