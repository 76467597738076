import { useTranslation } from 'react-i18next'

import { Section } from 'src/screens/account/header/Section'
import { Button, ButtonVariant } from 'src/system/ui/button/Button'

export function Password(props: {
  className?: string
  onChangePasswordClick: () => void
}) {
  const { t } = useTranslation()

  return (
    <Section className={props.className} title={t('account:header:password')}>
      <Button
        variant={ButtonVariant.link}
        onClick={props.onChangePasswordClick}
      >
        {t('account:header:change_password')}
      </Button>
    </Section>
  )
}
