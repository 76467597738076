import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { FeatureFlag, useFeatureFlags } from 'src/hooks/use-feature-flags'
import { useProfile } from 'src/hooks/use-profile'
import { LinkDiscord } from 'src/screens/account/discord/LinkDiscord'
import { UnlinkDiscord } from 'src/screens/account/discord/UnlinkDiscord'
import { DiscordProfile } from 'src/screens/account/discord/DiscordProfile'
import { Panel } from 'src/system/ui/layout/Panel'
import { SectionTitle } from 'src/system/ui/typography/SectionTitle'
import { Description } from 'src/screens/account/step/Description'

export function ManageDiscord() {
  const { profile, loading } = useProfile('cache-first')
  const { t } = useTranslation()
  const [featureEnabled] = useFeatureFlags([FeatureFlag.discord])

  if (!featureEnabled) {
    return null
  }

  if (loading) {
    return null
  }

  if (profile?.discord?.name) {
    return (
      <>
        <StyledPanel>
          <SectionTitle>{t('account:discord:linked_title')}</SectionTitle>
          <Description>{t('account:discord:linked_subtitle')}</Description>
          <DiscordProfile />
          <UnlinkDiscord />
        </StyledPanel>
      </>
    )
  }

  return (
    <StyledPanel>
      <SectionTitle>{t('account:discord:unlinked_title')}</SectionTitle>
      <Description>{t('account:discord:unlinked_subtitle')}</Description>
      <LinkDiscord />
    </StyledPanel>
  )
}

const StyledPanel = styled(Panel)`
  margin-top: ${(props) => props.theme.d.spacing[4]};
`
