import { DateTime } from 'luxon'

import { hasKey } from 'src/lib/utils/object'

export const sortByDate = <T extends Record<string, any>>(
  keys: string | string[],
  collection: T[]
) => {
  return collection.sort((a, b) => {
    const aKey = getKey(keys, a)
    const bKey = getKey(keys, b)

    if (!aKey || !bKey) {
      throw new Error('Failed sorting by date; missing key.')
    }

    const aDate = DateTime.fromISO(a[aKey])
    const bDate = DateTime.fromISO(b[bKey])

    return bDate.diff(aDate).milliseconds
  })
}

function getKey(keys: string | string[], obj: Record<string, any>) {
  if (typeof keys === 'object') {
    return keys.find((key: string) => hasKey(key, obj))
  }

  if (hasKey(keys, obj)) {
    return keys
  }
}
