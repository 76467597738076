import ctBadge from 'src/assets/csgo/team/team_badge_ct.png'
import tBadge from 'src/assets/csgo/team/team_badge_t.png'
import { Team } from 'src/graphql/types'

export function TeamBadge(props: { team: Team; className?: string }) {
  const isCt = props.team === Team.Ct
  const badge = isCt ? ctBadge : tBadge
  return (
    <img className={props.className} src={badge} alt={`${props.team} badge`} />
  )
}
