import { gql } from '@apollo/client'

export const localizedContentFragments = gql`
  fragment LocalizedContentFields on LocalizedContent {
    text
  }

  fragment LocalizedUrlFields on LocalizedUrl {
    text
    href
  }

  fragment LocalizedVideoFields on LocalizedVideo {
    text
    externalId
  }
`

export const breakdownFragments = gql`
  fragment BreakdownFields on Breakdown {
    id
    format
    sentiment
    title {
      ...LocalizedContentFields
    }
  }

  fragment BreakdownTextFields on BreakdownText {
    ...BreakdownFields
    body {
      ...LocalizedContentFields
    }
  }
`

export const resourceFragments = gql`
  fragment ResourceFields on Resource {
    id
    format
    createdAt
    isExclusive
    isPremium
    title {
      ...LocalizedContentFields
    }
  }

  fragment ResourceTextFields on ResourceText {
    ...ResourceFields
    body {
      ...LocalizedContentFields
    }
  }

  fragment ResourceLinkFields on ResourceLink {
    ...ResourceFields
    url {
      ...LocalizedUrlFields
    }
  }

  fragment ResourceLinkListFields on ResourceLinkList {
    ...ResourceFields
    urls {
      ...LocalizedUrlFields
    }
  }

  fragment ResourceYoutubeFields on ResourceYoutube {
    ...ResourceFields
    video {
      ...LocalizedVideoFields
    }
  }

  fragment ResourceYoutubeListFields on ResourceYoutubeList {
    ...ResourceFields
    videos {
      ...LocalizedVideoFields
    }
  }

  fragment ResourceMatchmakingSteamFieldsCsgo on ResourceMatchmakingSteamCsgo {
    ...ResourceFields
    optionalBody: body {
      ...LocalizedContentFields
    }
    platform
    mode
  }

  fragment ResourceWorkshopSteamFieldsCsgo on ResourceWorkshopSteamCsgo {
    ...ResourceFields
    optionalBody: body {
      ...LocalizedContentFields
    }
    platform
    externalId
    optionalVideo: video {
      ...LocalizedVideoFields
    }
  }
`

export const coursesQuery = gql`
  query Courses($matchId: ID!) {
    match(id: $matchId) {
      id
      resourceId
      date
      map
      isOwner
      platform
      players {
        name
        isOwner
        steamId
        team
      }
      courses {
        ...CourseFields
      }
    }
  }

  fragment CourseFields on Course {
    isDone
    coach
    department
    revision
    aggregate {
      gpa
      assignmentScores
    }
    assignments {
      id
      title {
        ...LocalizedContentFields
      }
      body {
        ...LocalizedContentFields
      }
      grade {
        ...GradeFields
      }
      marks {
        id
        start {
          round
          tick
        }
        end {
          round
          tick
        }
        facets {
          ...FacetBoolFields
          ...FacetBoolListFields
          ...FacetIntFields
          ...FacetIntListFields
          ...FacetFloatFields
          ...FacetFloatListFields
          ...FacetWeaponFields
          ...FacetWeaponListFields
          ...FacetTeamFields
          ...FacetTeamListFields
          ...FacetIdFields
          ...FacetIdListFields
          ...FacetHitBoxFields
          ...FacetHitBoxListFields
          ...FacetSentimentFields
        }
      }
      breakdowns {
        ...BreakdownTextFields
      }
      resources {
        ...ResourceTextFields
        ...ResourceLinkFields
        ...ResourceLinkListFields
        ...ResourceYoutubeFields
        ...ResourceYoutubeListFields
        ...ResourceMatchmakingSteamFieldsCsgo
        ...ResourceWorkshopSteamFieldsCsgo
      }
    }
  }

  fragment FacetFields on Facet {
    id
    shape
    unit
    label
  }

  fragment FacetBoolFields on FacetBool {
    ...FacetFields
    boolValue: value
  }

  fragment FacetBoolListFields on FacetBoolList {
    ...FacetFields
    boolValues: value
  }

  fragment FacetIntFields on FacetInt {
    ...FacetFields
    intValue: value
  }

  fragment FacetIntListFields on FacetIntList {
    ...FacetFields
    intValues: value
  }

  fragment FacetFloatFields on FacetFloat {
    ...FacetFields
    floatValue: value
  }

  fragment FacetFloatListFields on FacetFloatList {
    ...FacetFields
    floatValues: value
  }

  fragment FacetWeaponFields on FacetWeapon {
    ...FacetFields
    weaponValue: value
  }

  fragment FacetWeaponListFields on FacetWeaponList {
    ...FacetFields
    weaponValues: value
  }

  fragment FacetTeamFields on FacetTeam {
    ...FacetFields
    teamValue: value
  }

  fragment FacetTeamListFields on FacetTeamList {
    ...FacetFields
    teamValues: value
  }

  fragment FacetIdFields on FacetId {
    ...FacetFields
    idValue: value
  }

  fragment FacetIdListFields on FacetIdList {
    ...FacetFields
    idValues: value
  }

  fragment FacetHitBoxFields on FacetHitBox {
    ...FacetFields
    hitBoxValue: value
  }

  fragment FacetHitBoxListFields on FacetHitBoxList {
    ...FacetFields
    hitBoxValues: value
  }

  fragment FacetSentimentFields on FacetSentiment {
    ...FacetFields
    sentimentValue: value
  }

  ${breakdownFragments}

  ${resourceFragments}

  ${localizedContentFragments}

  fragment GradeFields on Grade {
    difficulty
    impact
    relevancy
    score
  }
`
