import styled from '@emotion/styled'

import type {
  Facet,
  FacetWeaponFieldsFragment,
  FacetWeaponListFieldsFragment,
  Weapon
} from 'src/graphql/types'
import { FacetShape, FacetUnit } from 'src/graphql/types'
import { findWeaponOrFail } from 'src/lib/match-viewer/assets/weapons'

type FacetWeapon = Facet & FacetWeaponFieldsFragment
export const isFacetWeapon = (facet: Facet): facet is FacetWeapon =>
  facet.shape === FacetShape.Enum && facet.unit === FacetUnit.Weapon

export function FacetWeaponValue(props: { facet: FacetWeapon }) {
  return <Icon weapon={props.facet.weaponValue} />
}

type FacetWeaponList = Facet & FacetWeaponListFieldsFragment
export const isFacetWeaponList = (facet: Facet): facet is FacetWeaponList =>
  facet.shape === FacetShape.EnumList && facet.unit === FacetUnit.Weapon

export function FacetWeaponListValue(props: { facet: FacetWeaponList }) {
  return (
    <WeaponsContainer>
      {props.facet.weaponValues.map((weapon, index) => (
        <Icon key={index} weapon={weapon} />
      ))}
    </WeaponsContainer>
  )
}

function Icon(props: { weapon: Weapon }) {
  const Svg = findWeaponOrFail(props.weapon).icon

  return (
    <IconBox>
      <Svg fill='#FFFFFF' />
    </IconBox>
  )
}

const IconBox = styled.div`
  width: 30px;
  height: 30px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`

const WeaponsContainer = styled.div`
  display: flex;
`
