import styled from '@emotion/styled'

import type { Assignment } from 'src/screens/match/assessments/assignment'
import { ViewLink } from 'src/screens/match/assessments/assignment-list/assignment/expansion/marks/ViewLink'
import { Summary } from 'src/screens/match/assessments/assignment-list/assignment/expansion/marks/Summary'

export function Marks(props: { assignment: Assignment; className?: string }) {
  const marks = props.assignment.marks

  return (
    <div className={props.className}>
      {marks.map((mark) => (
        <Mark key={mark.id}>
          <StyledSummary department={props.assignment.department} mark={mark} />
          <ViewLinkBox>
            <ViewLink mark={mark} />
          </ViewLinkBox>
        </Mark>
      ))}
    </div>
  )
}

const Mark = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.d.colors.border.dark};
  padding: ${(props) =>
    `${props.theme.d.spacing[5]} ${props.theme.d.spacing[3]}`};

  &:last-of-type {
    border-bottom: none;
  }

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    padding: ${(props) =>
      `${props.theme.d.spacing[7]} ${props.theme.d.spacing[4]}`};
    display: flex;
  }
`

const StyledSummary = styled(Summary)`
  margin-right: ${(props) => props.theme.d.spacing[4]};
  overflow: auto;
`

const ViewLinkBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.d.breakpoints.md}) {
    justify-content: flex-start;
  }
`
