import styled from '@emotion/styled'
import { useTheme } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { RadioButtonChecked as RadioButtonCheckedIcon } from '@mui/icons-material'

import { PendingMatchStatus } from 'src/graphql/types'
import { useTimedOut } from 'src/screens/matches/collection/match/status/utils'
import { useProgress } from 'src/screens/matches/collection/match/status/use-progress'
import { ProgressIcon } from 'src/screens/matches/collection/match/status/ProgressIcon'

export function Status(props: {
  current?: PendingMatchStatus
  className?: string
  courseProgress: number | null | undefined
  updatedAt: string
  createdAt: string
}) {
  const { t } = useTranslation()
  const { current: currentStatus, updatedAt, createdAt, courseProgress } = props

  if (!currentStatus) throw new Error('Missing match status')

  const status = useComputedStatus(updatedAt, currentStatus, courseProgress)
  const progress = useProgress(createdAt, status)
  const text = t(`matches:status:${status}`)
  const color = useColor(status)

  return (
    <Box className={props.className}>
      <Icon statusColor={color} progress={progress} />
      <Text statusColor={color}>{text}</Text>
    </Box>
  )
}

export function useComputedStatus(
  updatedAt: string,
  status: PendingMatchStatus,
  courseProgress: number | null | undefined
) {
  const isTimedOut = useTimedOut(updatedAt)

  const uncompletedStatus = isTimedOut ? PendingMatchStatus.Failed : status

  const partialCourseProgressStatus = isTimedOut
    ? status
    : PendingMatchStatus.Verified

  if (status !== PendingMatchStatus.Completed) {
    return uncompletedStatus
  }

  if (courseProgress !== 1) return partialCourseProgressStatus

  return status
}

function useColor(status: PendingMatchStatus) {
  const theme = useTheme()

  const color = {
    [PendingMatchStatus.Completed]: theme.d.colors.pendingMatchStatus.completed,
    [PendingMatchStatus.Created]: theme.d.colors.pendingMatchStatus.created,
    [PendingMatchStatus.Failed]: theme.d.colors.pendingMatchStatus.failed,
    [PendingMatchStatus.Verified]: theme.d.colors.pendingMatchStatus.verified
  }
  return color[status]
}

const Box = styled.div`
  display: flex;
  align-items: center;
`

const DoneIcon = styled(
  ({ statusColor: _, ...otherProps }: { statusColor: string }) => (
    <RadioButtonCheckedIcon {...otherProps} />
  )
)`
  color: ${(props) => props.statusColor};
`

const Icon = styled((props: { progress: number; statusColor: string }) => {
  if (props.progress === 1) return <DoneIcon {...props} />
  return <ProgressIcon {...props} />
})`
  margin-right: 6px;
  width: 14px !important;
  height: 14px !important;
`

const Text = styled(
  ({
    statusColor: _,
    ...otherProps
  }: {
    statusColor: string
    children: string
  }) => <span {...otherProps} />
)`
  color: ${(props) => props.statusColor};
  font-weight: bold;
  line-height: 100%;
`
