import styled from '@emotion/styled'

import type {
  Facet,
  FacetIntFieldsFragment,
  FacetIntListFieldsFragment
} from 'src/graphql/types'
import { FacetShape, FacetUnit } from 'src/graphql/types'
import { ReactComponent as Icon } from 'src/lib/match-viewer/assets/weapons/kevlar.svg'

type FacetKevlar = Facet & FacetIntFieldsFragment
export const isFacetKevlar = (facet: Facet): facet is FacetKevlar =>
  facet.shape === FacetShape.Int && facet.unit === FacetUnit.Armor

export function FacetKevlarValue(props: { facet: FacetKevlar }) {
  return <Body>{props.facet.intValue}</Body>
}

type FacetKevlarList = Facet & FacetIntListFieldsFragment
export const isFacetKevlarList = (facet: Facet): facet is FacetKevlarList =>
  facet.shape === FacetShape.IntList && facet.unit === FacetUnit.Armor

export function FacetKevlarListValue(props: { facet: FacetKevlarList }) {
  return (
    <>
      {props.facet.intValues.map((val, index) => (
        <Body key={index}>{val}</Body>
      ))}
    </>
  )
}

function Body(props: { children: number }) {
  return (
    <Box>
      <Icon width={12} height={12} />
      {props.children}
    </Box>
  )
}

const Box = styled.div`
  svg {
    width: 12px;
    height: 16px;
    margin-right: 4px;
    fill: ${(props) => props.theme.d.colors.text.secondary};
  }

  display: flex;
  align-items: center;
`
