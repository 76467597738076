import type { LogLevelDesc } from 'loglevel'

import type { FeatureFlags } from 'src/lib/analytics/posthog'

import defaultConfig from './default.json'

export { default as schemaVersion } from 'src/graphql/version.json'
export { default as possibleTypesResultData } from 'src/graphql/possible-types'

export type Config = {
  name: string
  env: string
  app: string
  owner: string
  version: string
  logLevel: LogLevelDesc
  gqlOrigin: string
  gqlWsOrigin: string
  gqlPath: string
  gqlApiKey: string
  appsyncHost: string
  authOrigin: string
  authPath: string
  authUserPoolId: string
  authClientId: string
  reportUri: string
  reportingUrl: string
  sentryDsn: string | null
  sentryTunnel: string | null
  stripePublishableKey: string
  posthogApiKey: string | null
  posthogTunnel: string | null
  verifyCallbackPath: string
  passwordCallbackPath: string
  featureFlags: FeatureFlags
}

let config: Config = globalThis.config
if (process.env.NODE_ENV !== 'production') config = defaultConfig as Config

export const nonce = globalThis.__webpack_nonce__

export const name = config.name
export const app = config.app
export const owner = config.owner
export const env = config.env
export const version = config.version

export const logLevel = config.logLevel

export const featureFlags = config.featureFlags ?? {}

export const apolloClientOrigin = config.gqlOrigin
export const apolloClientWsOrigin = config.gqlWsOrigin
export const apolloClientPath = config.gqlPath
export const apolloClientApiKey = config.gqlApiKey
export const appsyncHost = config.appsyncHost

export const userPoolOrigin = config.authOrigin
export const userPoolPath = config.authPath
export const userPoolId = config.authUserPoolId
export const userPoolClientId = config.authClientId

export const sentryDsn = config.sentryDsn
export const sentryTunnel = config.sentryTunnel || null

export const stripePublishableKey = config.stripePublishableKey

export const posthogApiKey = config.posthogApiKey
export const posthogTunnel = config.posthogTunnel || null

export const apiVerifyCallbackPath = config.verifyCallbackPath
export const apiPasswordCallbackPath = config.passwordCallbackPath

export const userAttributes = {
  'custom:origin': config.app,
  'custom:env': config.env
}
