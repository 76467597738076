/* eslint-disable camelcase */

import type { Map } from 'src/graphql/types'
import cs_agency from 'src/assets/csgo/map/preview/cs_agency.jpg' // eslint-disable-line camelcase
import cs_apollo from 'src/assets/csgo/map/preview/cs_apollo.jpg' // eslint-disable-line camelcase
import cs_climb from 'src/assets/csgo/map/preview/cs_climb.jpg' // eslint-disable-line camelcase
import cs_insertion2 from 'src/assets/csgo/map/preview/cs_insertion2.jpg' // eslint-disable-line camelcase
import cs_office from 'src/assets/csgo/map/preview/cs_office.jpg' // eslint-disable-line camelcase
import de_ancient from 'src/assets/csgo/map/preview/de_ancient.jpg' // eslint-disable-line camelcase
import de_anubis from 'src/assets/csgo/map/preview/de_anubis.jpg' // eslint-disable-line camelcase
import de_assembly from 'src/assets/csgo/map/preview/de_assembly.jpg' // eslint-disable-line camelcase
import de_basalt from 'src/assets/csgo/map/preview/de_basalt.jpg' // eslint-disable-line camelcase
import de_breach from 'src/assets/csgo/map/preview/de_breach.jpg' // eslint-disable-line camelcase
import de_chlorine from 'src/assets/csgo/map/preview/de_chlorine.jpg' // eslint-disable-line camelcase
import de_studio from 'src/assets/csgo/map/preview/de_studio.jpg' // eslint-disable-line camelcase
import de_cache from 'src/assets/csgo/map/preview/de_cache.jpg' // eslint-disable-line camelcase
import de_dust2 from 'src/assets/csgo/map/preview/de_dust2.jpg' // eslint-disable-line camelcase
import de_engage from 'src/assets/csgo/map/preview/de_engage.jpg' // eslint-disable-line camelcase
import de_grind from 'src/assets/csgo/map/preview/de_grind.jpg' // eslint-disable-line camelcase
import de_inferno from 'src/assets/csgo/map/preview/de_inferno.jpg' // eslint-disable-line camelcase
import de_iris from 'src/assets/csgo/map/preview/de_iris.jpg' // eslint-disable-line camelcase
import de_memento from 'src/assets/csgo/map/preview/de_memento.jpg' // eslint-disable-line camelcase
import de_mills from 'src/assets/csgo/map/preview/de_mills.jpg' // eslint-disable-line camelcase
import de_mirage from 'src/assets/csgo/map/preview/de_mirage.jpg' // eslint-disable-line camelcase
import de_mocha from 'src/assets/csgo/map/preview/de_mocha.jpg' // eslint-disable-line camelcase
import de_mutiny from 'src/assets/csgo/map/preview/de_mutiny.jpg' // eslint-disable-line camelcase
import de_nuke from 'src/assets/csgo/map/preview/de_nuke.jpg' // eslint-disable-line camelcase
import de_overpass from 'src/assets/csgo/map/preview/de_overpass.jpg' // eslint-disable-line camelcase
import de_swamp from 'src/assets/csgo/map/preview/de_swamp.jpg' // eslint-disable-line camelcase
import de_thera from 'src/assets/csgo/map/preview/de_thera.jpg' // eslint-disable-line camelcase
import de_train from 'src/assets/csgo/map/preview/de_train.jpg' // eslint-disable-line camelcase
import de_tuscan from 'src/assets/csgo/map/preview/de_tuscan.jpg' // eslint-disable-line camelcase
import de_vertigo from 'src/assets/csgo/map/preview/de_vertigo.jpg' // eslint-disable-line camelcase
import placeholder from 'src/assets/csgo/map/preview/map_screenshot_placeholder.svg'

const images: Record<Map, string | null> = {
  cs_agency,
  cs_agency_scrimmagemap: cs_agency,
  cs_apollo,
  cs_apollo_scrimmagemap: cs_apollo,
  cs_climb,
  cs_climb_scrimmagemap: cs_climb,
  cs_insertion2,
  cs_insertion2_scrimmagemap: cs_insertion2,
  cs_office,
  cs_office_scrimmagemap: cs_office,
  de_ancient,
  de_ancient_scrimmagemap: de_ancient,
  de_anubis,
  de_anubis_scrimmagemap: de_anubis,
  // eslint-disable-next-line
  de_assembly: de_assembly,
  de_basalt,
  de_basalt_scrimmagemap: de_basalt,
  de_breach,
  de_breach_scrimmagemap: de_breach,
  de_cache,
  de_cache_scrimmagemap: de_cache,
  de_chlorine,
  de_chlorine_scrimmagemap: de_chlorine,
  de_dust2,
  de_dust2_scrimmagemap: de_dust2,
  de_engage,
  de_engage_scrimmagemap: de_engage,
  de_grind,
  de_grind_scrimmagemap: de_grind,
  de_inferno,
  de_inferno_scrimmagemap: de_inferno,
  de_iris,
  de_iris_scrimmagemap: de_iris,
  // eslint-disable-next-line
  de_memento: de_memento,
  // eslint-disable-next-line
  de_mills: de_mills,
  de_mirage,
  de_mirage_scrimmagemap: de_mirage,
  de_mocha,
  de_mocha_scrimmagemap: de_mocha,
  de_mutiny,
  de_mutiny_scrimmagemap: de_mutiny,
  de_nuke,
  de_nuke_scrimmagemap: de_nuke,
  de_overpass,
  de_overpass_scrimmagemap: de_overpass,
  de_studio,
  de_studio_scrimmagemap: de_studio,
  de_swamp,
  de_swamp_scrimmagemap: de_swamp,
  // eslint-disable-next-line
  de_thera: de_thera,
  de_train,
  de_train_scrimmagemap: de_train,
  de_tuscan,
  de_tuscan_scrimmagemap: de_tuscan,
  de_vertigo,
  de_vertigo_scrimmagemap: de_vertigo
}

export function image(map?: Map | null) {
  if (!map) {
    return placeholder
  }

  const image = images[map]

  if (!image) {
    return placeholder
  }

  return image
}

export const isPlaceholder = (image: string) => image === placeholder
