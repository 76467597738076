import { LinkAccount } from 'src/screens/account/step/LinkAccount'
import { AutoDownload } from 'src/screens/account/step/auto-download/AutoDownload'
import { Manage } from 'src/screens/account/step/manage/Manage'
import type { RenderContent } from 'src/screens/account/Account'
import type { UserFieldsFragment as User } from 'src/graphql/types'

export function Step(props: {
  renderBody: RenderContent
  renderAdditionalContent: RenderContent
  user: User
}) {
  const { isAccountLinked } = props.user

  const isAutomatchActive = props.user.automatch.isActive

  if (!isAccountLinked) {
    return (
      <LinkAccount
        renderBody={props.renderBody}
        renderAdditionalContent={props.renderAdditionalContent}
      />
    )
  }

  if (!isAutomatchActive) {
    return (
      <AutoDownload
        user={props.user}
        renderBody={props.renderBody}
        renderAdditionalContent={props.renderAdditionalContent}
      />
    )
  }

  return (
    <Manage
      user={props.user}
      renderBody={props.renderBody}
      renderAdditionalContent={props.renderAdditionalContent}
    />
  )
}
