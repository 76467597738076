import type { MouseEvent, Ref } from 'react'
import { forwardRef } from 'react'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import { HashLink as RouterLink } from 'react-router-hash-link'
import type { To } from 'history'

import type { LinkProps } from 'src/system/ui/link/types'
import { useLinkStyle } from 'src/system/ui/link/style'

type Props = LinkProps & {
  to: To
}

export const RelativeLink = forwardRef(
  (props: Props, ref: Ref<HTMLAnchorElement>) => {
    const style = useLinkStyle(props)
    const handleOnClick = (e: MouseEvent) => e.preventDefault()

    if (props.disabled) {
      return (
        <DisabledLink
          ref={ref}
          className={props.className}
          onClick={handleOnClick}
        >
          {props.children}
        </DisabledLink>
      )
    }

    return (
      <StyledRouterLink
        ref={ref}
        className={props.className}
        to={props.to}
        underline={style.underline}
        color={style.color}
      >
        {props.children}
      </StyledRouterLink>
    )
  }
)

const StyledRouterLink = styled(RouterLink, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'underline'
})<{ underline?: boolean }>`
  color: ${(props) => props.color};
  cursor: pointer;
  font-size: inherit;
  &:hover {
    ${(props) => (props.underline ? 'text-decoration: underline;' : null)}
  }
`

const DisabledLink = styled.a`
  cursor: not-allowed;
  font-size: inherit;
  opacity: 0.4;
`
