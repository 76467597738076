import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/material'

import { SectionTitle } from 'src/system/ui/typography/SectionTitle'
import { Description } from 'src/screens/account/step/Description'
import { LoadingSpinner } from 'src/system/ui/LoadingSpinner'
import type { RenderContent } from 'src/screens/account/Account'
import steamSignInImage from 'src/assets/steam_link.png'
import { MustConnectEmphasisBadge } from 'src/screens/account/step/MustConnectEmphasisBadge'
import { useLinkAccount } from 'src/hooks/use-link-account'
import { JoinDiscordPanel } from 'src/system/DiscordPanel'
import { UserLinkType } from 'src/graphql/types'
import { AbsoluteLink } from 'src/system/ui/link/AbsoluteLink'

export function LinkAccount(props: {
  renderBody: RenderContent
  renderAdditionalContent: RenderContent
}) {
  const { t } = useTranslation()
  const { redirect, isLoading, isError } = useLinkAccount(UserLinkType.Steam)

  return (
    <>
      {props.renderBody(
        <>
          <SectionTitle>{t('account:link_account:title')}</SectionTitle>
          <Description>{t('account:link_account:description_1')}</Description>
          <Button
            disabled={isLoading}
            onClick={() => redirect()}
            aria-label={t('account:link_account:label')}
          />
          {isLoading && <StyledLoadingSpinner />}
          {isError && <ErrorText>{t('account:link_account:error')}</ErrorText>}
          <StyledDescription>
            {t('account:link_account:description_2')}
          </StyledDescription>
          <StyledContainer>
            <AbsoluteLink
              to={t('account:link_account:instructional_video_link')}
              newTab
            >
              {t('account:link_account:instructional_video_text')}
            </AbsoluteLink>
          </StyledContainer>
          <MustConnectEmphasisBadge>
            {t('account:must_link_account')}
          </MustConnectEmphasisBadge>
        </>
      )}
      {props.renderAdditionalContent(
        <JoinDiscordPanel
          title={t('discord_panel:problems:title')}
          description={t('discord_panel:problems:description')}
        />
      )}
    </>
  )
}

const StyledContainer = styled(Container)`
  margin-bottom: 10px;
`

const StyledDescription = styled(Description)`
  margin-top: 10px;
`

const StyledLoadingSpinner = styled(LoadingSpinner)`
  display: inline-block;
  margin-left: 20px;
`

const Button = styled.button`
  cursor: pointer;
  padding: 12px;
  display: inline-block;
  background: url(${steamSignInImage}) no-repeat;
  width: 180px;
  height: 35px;
  border: 0;
`

const ErrorText = styled.div`
  color: ${(props) => props.theme.d.colors.error};
  margin-top: ${(props) => props.theme.d.spacing[4]};
`
