import type { StringMap } from 'src/types'

export type Config = {
  apiVerifyCallbackPath: string
  apiPasswordCallbackPath: string
}

export type Routing = ReturnType<typeof createRouting>

export const createRouting = (config: Config) => ({
  routes: routes(config),
  toCallbackSuccess: () => ({
    pathname: toPath([login]),
    search: toSearch({ verified: 'true' })
  }),
  toCallbackFailed: () => ({
    pathname: toPath([login]),
    search: toSearch({ verified: 'false' })
  }),
  getAccountLinkCallback: () =>
    [globalThis.location.origin, toPath([accountLink])].join(''),
  getCheckoutCallback: () =>
    [globalThis.location.origin, toPath([checkout])].join(''),
  getCustomerPortalCallbackUrl: () =>
    [globalThis.location.origin, toPath([account])].join(''),
  toLoginAfterResetPassword: () => ({
    pathname: toPath([login]),
    search: toSearch({ reset: 'true' })
  }),
  toSignUpSuccess: () => ({
    pathname: toPath([signUp]),
    search: toSearch({ success: 'true' })
  }),
  toCancelMembership: () => ({
    pathname: toPath([checkout]),
    search: toSearch({ canceled: 'true' })
  }),
  toUncancelMembership: () => ({
    pathname: toPath([checkout]),
    search: toSearch({ canceled: 'false' })
  }),
  toAssignment: (profileId: string, assignmentName: string) => ({
    pathname: toPath([profile, profileId]),
    hash: `#${assignmentName}`
  }),
  toProfile: (profileId: string) => toPath([profile, profileId]),
  toViewer: (matchId: string, roundNumber: number, tick?: number | null) => {
    const pathname = toPath([match, matchId, round, roundNumber])
    if (!tick) {
      return pathname
    }

    return {
      pathname,
      search: toSearch({ tick: `${tick}` })
    }
  },
  toAssessments: (matchId: string) => toPath([match, matchId]),
  toScoreboard: (matchId: string, roundNumber: number) =>
    toPath([match, matchId, round, roundNumber, 'scoreboard'])
})

const routes = ({
  apiVerifyCallbackPath,
  apiPasswordCallbackPath
}: Config) => ({
  login: toPath([login]),
  welcome: toPath([welcome]),
  forgotPassword: toPath([forgotPassword]),
  resetPassword: apiPasswordCallbackPath,
  checkout: toPath([checkout]),
  signUp: toPath([signUp]),
  signUpSuccess: toPath([signUpSuccess]),
  pricing: toPath([pricing]),
  membership: toPath([membership]),
  root: toPath(['']),
  viewer: toPath([match, ':matchId', round, ':round']),
  privateProfile: toPath([profile]),
  publicProfile: toPath([profile, ':profileId']),
  assessments: toPath([match, ':matchId']),
  scoreboard: toPath([match, ':matchId', round, ':round', 'scoreboard']),
  accountLink: toPath([accountLink]),
  verifyCallback: apiVerifyCallbackPath,
  account: toPath([account]),
  cookiesPolicy: toPath([cookiesPolicy])
})

const forgotPassword = 'forgot-password'
const signUp = 'signup'
const signUpSuccess = 'signup-success'
const accountLink = 'link-account'
const pricing = 'pricing'
const membership = 'membership'
const checkout = 'checkout'
const profile = 'profile'
const match = 'match'
const round = 'round'
const account = 'account'
const login = 'login'
const cookiesPolicy = 'cookies-policy'
const welcome = 'welcome'

const toPath = (arr: (string | number)[]) => `/${arr.join('/')}`

const toSearch = (obj: StringMap) => {
  const params = new URLSearchParams()
  for (const [k, v] of Object.entries(obj)) params.set(k, v)
  params.sort()
  return params.toString()
}
